import React, { Component } from "react";
import { Link } from "react-router-dom";

import MapRenderer from "../map-components/MapRenderer";

class MapView extends Component {
    state = {
        center: {
            lat: 22.772653,
            lng: 79.09079,
        },
        zoomLevel: 5,
        hoveredEntityLocation: null,
    };

    changeCenter = index => {
        const location = this.props.locations[index];
        this.setState({
            center: {
                lat: location.lat,
                lng: location.long,
            },
            zoomLevel: 8,
        });
    };

    handleMouseOver = location => {
        // console.log('handleMouseOver called');
        // console.log('location = ', location);
        this.setState({ hoveredEntityLocation: location });
    };

    handleMouseLeave = location => {
        // console.log('handleMouseLeave called');
        // console.log(location);
        this.setState({ hoveredEntityLocation: null });
    };

    render() {
        return (
            <div className="search__mapview_wrapper">
                <div className="seach__mapview_left">
                    {this.props.locations.map((location, index) => (
                        <Link
                            onMouseEnter={() => this.handleMouseOver(location)}
                            onMouseLeave={() => this.handleMouseLeave(location)}
                            to={`/entities/${location.id}`}
                            className="search__mapview-card"
                            key={index}
                        >
                            <div className="search__mapview-card-head">
                                {location.label}
                            </div>
                            <div className="search__mapview-card-subhead">
                                {location.city}
                            </div>
                        </Link>
                    ))}
                    {this.props.hasNextPage &&
                        (this.props.isNextPageLoading ? (
                            <div
                                className="search__load-more"
                                style={{
                                    cursor: "default",
                                }}
                            >
                                Loading...{" "}
                            </div>
                        ) : (
                            <div
                                className="search__load-more"
                                onClick={this.props.loadNextPage}
                            >
                                Load More <i className="fas fa-angle-down" />
                            </div>
                        ))}
                </div>
                <div className="search__map-wrapper">
                    <MapRenderer
                        locations={this.props.locations}
                        height="800px"
                        loading={false}
                        hoveredEntity={this.state.hoveredEntityLocation}
                    />
                </div>
            </div>
        );
    }
}

export default MapView;
