import React, { Component } from 'react';
import EmptyStateIconSvg from '../../inline-svg-components/EmptyStateIconSvg';
import { httpfyUrl } from "../../utils/helpers";
import editorCommentsImg from "../../img/editor-comments.svg";
import { withRouter } from 'react-router-dom'; // Import withRouter

class InitiativesProject extends Component {
    state = {
        expandedItem: "",
        numItemsToShow: 8,
    };
    setExpandedItem = item => this.setState({ expandedItem: item });


    handleLoadMore = () => {
        // Increment the number of items to show
        this.setState(prevState => ({
            numItemsToShow: prevState.numItemsToShow + 8,
        }));
    };
    inititaives = (listName) => {
        // console.log(listName)
        return (
            <div className={this.props.type==="researcher" ? 'entityview__desc-cards-wrapper-researcher': "entityview__desc-cards-wrapper"}>
                {this.props.title === "Projects"
                    ? (
                        listName && listName.length > 0 ? (
                            listName.slice(0, this.state.numItemsToShow).map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        title="Show more"
                                        className="entityview__desc-card entityview__papers-card"
                                        onClick={this.setExpandedItem.bind(null, item.name)}
                                    >
                                        <div className="entityview__desc-card-top">
                                            <div className="entityview__desc-card-left">
                                                <div className="entityview__desc-card-head">
                                                    {item.name ? item.name : this.props.title}
                                                </div>
                                                <div className="entityview__desc-card-detail entityview__desc-card-detail-url">
                                                     
                                                </div>
                                                <div className="entityview__desc-card-detail entityview__desc-card-detail-about">
                                                    {item.about_product &&
                                                        (this.state
                                                            .expandedItem ===
                                                            item.name
                                                            ? item.about_product
                                                            : `${item.about_product.substring(
                                                                0,
                                                                150
                                                            )}${item
                                                                .about_product
                                                                .length >
                                                                150
                                                                ? "..."
                                                                : ""
                                                            }`)}
                                                </div>
                                              <div>  {item.url && (
                            <a
                              className="entityview__desc-card-link"
                              href={httpfyUrl(item.url)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fas fa-link" />
                            </a>
                          )}

                                                    </div>
                                            </div>

                                            <div>
                                                
                                            </div>
                                        </div>

                                        {/*    (this.state.expandedItem ===
                                                item.name ? (
                                                <div className="entityview__desc-card-bottom--expanded">
                                                    <img
                                                        src={editorCommentsImg}
                                                        alt="Editor Comment"
                                                        className="entityview__desc-bottom-icon"
                                                    />{" "}
                                                    <strong>EDITOR'S COMMENTS</strong>

                                                    <div className="entityview__desc-card-bottom">
                                                        {item.ra_commentary}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="entityview__desc-card-bottom">
                                                    <img
                                                        src={editorCommentsImg}
                                                        alt="Editor Comment"
                                                        className="entityview__desc-bottom-icon"
                                                    />
                                                    {`${item.ra_commentary.substring(0, 100)}...`}
                                                </div>
                                            )) */}
                                    </div>
                                )
                            })
                        ) : (
                            <></>
                        )
                    ) : null}

                {/* Load More button */}
                {listName && listName.length > this.state.numItemsToShow && (
                    <button onClick={this.handleLoadMore} className="load-more-button">
                        View All
                    </button>
                )}

            </div>

        )
    }
    render() {
        // console.log(this.props.location)
        const project = this.inititaives(this.props.projects);
        const workshop = this.inititaives(this.props.workshops);
        const initiatives = this.inititaives(this.props.initiatives)
        return (
            <>
                {this.props.projects.length > 0 || this.props.initiatives.length > 0 || this.props.workshops.length > 0 ?
                    <div className={this.props.type=== "researcher" ? 'entityview__desc-wrapper-researcher' : 'entityview__desc-wrapper'}>
                         {this.props.initiatives.length > 0 && 
                         <div>
                             
                                <p className='initiatives__heading'>INITIATIVES({this.props.initiatives.length})</p> 
                            {initiatives}
                        </div>}

                        {this.props.projects.length > 0 &&
                        <div className={this.props.initiatives.length > 0 ? 'initiatives__container' : ""}
                        >
                             
                                <p className='initiatives__heading'>INITIATIVES AND PROJECTS ({this.props.projects.length})</p>
                            {project}
                        </div>}
                        {this.props.workshops.length > 0 &&
                        <div className='initiatives__container'>
                             
                                <p className='initiatives__heading'>WORKSHOPS ({this.props.workshops.length})</p>
                            {workshop}
                        </div>}
                    </div> :

                    <div>
                        {this.props.type!=="researcher" && 
                        <div className="entityview__no-items-wrapper">
                            <EmptyStateIconSvg className='emptyIcon' />
                            <p className="entityview__no-items-head"
                            >No {this.props.title} Found!</p>
                            <p className="entityview__no-items-desc"
                            >It seems that no {this.props.title}{" "}
                                are available to display.<br />
                                Once available, they will appear here.</p>
                        </div> }
                    </div>
                }

            </>
        );
    }
}

export default InitiativesProject;