import React, { Fragment } from "react";

// CSS
import "./Loader.css";

// Custom Component for Signup Form Input Field
const Loader = ({ loading = true, height, children }) => {
    const loader = (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20rem",
                height: height ? height : "",
            }}
        >
            <div>
                <div className="lds-css ng-scope">
                    <div
                        style={{ width: "100%", height: "100%" }}
                        className="lds-ellipsis"
                    >
                        <div>
                            <div />
                        </div>
                        <div>
                            <div />
                        </div>
                        <div>
                            <div />
                        </div>
                        <div>
                            <div />
                        </div>
                        <div>
                            <div />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    return <Fragment>{loading ? loader : children}</Fragment>;
};

export default Loader;
