import React from 'react';
import Empty  from '../img/empty_view.svg';
import { withRouter, Redirect } from 'react-router-dom';
import "../css/Message.css";
 


class Message extends React.Component {
   


  render() {
    document.title = "Message - Technology Capability Repository || DSCI";

    const { userInfo } = this.props;
    const redirect = !userInfo || !userInfo.token ?
      <Redirect to='/' /> : null;
    return (
      <div>
          <div className="entityview__top">
            <div className="entityview__top-desc-wrapper">
                 <p className='message-heading'>Messaging</p>
              </div>
            </div>
        <div>
            <img alt="empty" className="img-message" src={Empty}></img>
            <p className='heading'>You don't have any messages yet</p>
        </div>
          </div>
        
 
    )
  }
}

const mapStateToProps = state => ({
  userInfo: state.auth.userInfo
});
 

export default withRouter(Message);