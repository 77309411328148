import React from "react";
import { Field, reduxForm } from "redux-form";

import FormInputField from "../form-components/FormInputField";
import FormButton from "../form-components/FormButton";

import "../../css/UserForm.css";
import "../../css/Form.css";

class SignupPart3 extends React.Component {
  state = {
    otpResends: 0,
    isResendBtnDisabled: true,

  }

  // componentDidMount() {
  //   console.log('props of p3 = ', this.props);
  //   this.enableOtpResendCountdown();
  // }

  // //  Sets the isResendBtnDisabled to true after 30 seconds
  // enableOtpResendCountdown = () => {
  //   setTimeout(() => {
  //     this.setState({
  //       isResendBtnDisabled: false
  //     })
  //   }, 3000);
  // }

  // resendOtp = () => {
  //   this.setState(prevState => ({
  //     isResendBtnDisabled: true,
  //     otpResends: prevState.otpResends + 1,
  //   }), this.enableOtpResendCountdown);
  // }

  render() {
    const { handleSubmit, contactNumber, formLoader } = this.props;

    return (
      <div className="user-form__right">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p className="user-form__progress">Step 3 of 3</p>
        <p
          className="user-form__progress"
          style={{ marginRight: "30px", cursor: "pointer" }}
          onClick={this.props.prevPage}
        >Previous Page</p>
      </div>

        <div className="user-form__right-head">
          Enter the OTP sent to <em>{contactNumber}</em>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="user__form-container">
            <div className="row user__form-row">
              <Field
                widthClass="col-md-6"
                name="otp"
                component={FormInputField}
                type="number"
                placeholder="OTP"
                tabable={true}
              />
            </div>

            {/* <div className='signup__otp-resend-container'>
              <p className='signup__otp-resend-text'>
                Haven't received the OTP?
            </p>

              <button
                type='button'
                onClick={this.resendOtp}
                className='signup__otp-resend-btn'
                disabled={this.state.isResendBtnDisabled}
              >
                {this.state.isResendBtnDisabled ? 'Wait 5 seconds' : 'Resend OTP'}
              </button>
            </div> */}

          </div>
          <div className="user-signup-3" >

          {this.props.resendBtn === false ? <FormButton label="SUBMIT" formLoader={formLoader}/> :<FormButton label="SUBMIT"/>} 
         {this.props.resendBtn === true ? <p className="user-resend" onClick={handleSubmit}>Resend OTP</p>:<></>}    
          </div>
        </form>
       
      </div>
    );
  }
}

export default reduxForm({
  form: "signup", // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // validate
})(SignupPart3);
