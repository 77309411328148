import React from "react";
import PropTypes from "prop-types";

const FormInputField = props => {
    let cls = `form__input-field-wrapper ${
        props.widthClass ? props.widthClass : ""
    }`;
    
    return (
        <div className={cls}>
            <label>
                <div className="form__label-wrapper">
                    <p className="form__label">{props.placeholder}</p>
                    {props.placeholder === "Password" && (
                        <p
                            className="form__reset-password user-form__link-text"
                            onClick={() => props.changeType("forgotPassword")}
                        >
                            Forgot Password?
                        </p>
                    )}
                </div>
                <input
                    {...props.input}
                    className={`form__input-field ${
                        props.placeholder === "Mobile Number"
                            ? "form__input-field-mobile"
                            : ""
                    } ${props.inputClass}`}
                    type={props.type}
                    name={props.name}
                    autoComplete="off"
                    placeholder={
                        props.placeholder === "Create Password"
                            ? "Enter Password"
                            : props.placeholder
                    }
                    tabIndex={!props.tabable ? "-1" : ""}
                />
                {props.label}
            </label>
            <div className={`form__input-field-error ${
              props.placeholder === 'Mobile Number' ?
              'form__input-field-error--mobile' : ''
            }`}>
                {props.meta && props.meta.touched ? props.meta.error : ""}
            </div>
        </div>
    );
};

FormInputField.propTypes = {
    input: PropTypes.object,
    type: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
};

export default FormInputField;
