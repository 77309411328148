import {
    UPDATE_SEARCH_QUERY,
    UPDATE_SEARCH_REDIRECT,
    UPDATE_CAPABILITY,
    UPDATE_AREA,
} from "../actions/_types";

const initialState = {
    query: "",
    searchRedirect: false,
};

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SEARCH_QUERY:
            return {
                ...state,
                query: action.query,
            };
        case UPDATE_SEARCH_REDIRECT:
            return {
                ...state,
                searchRedirect: action.redirect,
            };
        case UPDATE_AREA:
            return {
                ...state,
                area: action.area,
            };
        case UPDATE_CAPABILITY:
            return {
                ...state,
                capability: action.capability,
            };
        default:
            return state;
    }
};

export default searchReducer;
