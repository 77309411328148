const baseUrl = 'https://tcr-phase2-documents.s3.ap-south-1.amazonaws.com/images';
const bannerDict = {
  IOTBanner: baseUrl + "/iot-banner.svg",
  CompVisionBanner: baseUrl + "/comp-vision-banner.svg",
  EnterpriseBusinessTechnologyBanner: baseUrl + "/enterprise-banner.svg",
  BlockchainBanner: baseUrl + "/blockchain-banner.svg",
  ARVRBanner: baseUrl + "/ar-vr-banner.svg",
  OSBanner: baseUrl + "/os-banner.svg",
  AIMLBanner: baseUrl + "/ai-ml-dl-banner.svg",
  DataScienceBanner: baseUrl + "/data-science-banner.svg",
  AppInfraBanner: baseUrl + "/app-infra-banner.svg",
  CloudBanner: baseUrl + "/cloud-banner.svg",
  MaterialScienceBanner: baseUrl + "/material-science-banner.svg",
  SoftwareDefinedNetworkBanner: baseUrl + "/software-networks-banner.svg",
  WirelessNetworksBanner: baseUrl + "/wireless-banner.svg",
  NextGenComputingBanner: baseUrl + "/nextgen-computing-banner.svg",
  UAVBanner: baseUrl + "/uav-banner.svg",
  HardwareBanner: baseUrl + "/hardware-banner.svg",
  CryptologyBanner: baseUrl + "/cryptology-banner.svg",
  HumanCompInteractionBanner: baseUrl + "/human-comp-banner.svg",
  CyberSecurityBanner: baseUrl + "/cybersecurity-banner.svg",
  DigitalForensicsBanner: baseUrl + "/digital-forensics-banner.svg",
  MobilityBanner: baseUrl + "/mobility-banner.svg",
  RoboticsBanner: baseUrl + "/robotics-banner.svg",
  IdentityBanner: baseUrl + "/identity-banner.svg",
  LocationBanner: baseUrl + "/location-banner.svg",
  NLPBanner: baseUrl + "/nlp-banner.svg",
  QuantumComputing : baseUrl + '/Banner.svg',
  FiveGtoSixG : baseUrl + '/5G&6G Banner@2x.png',
}


const getBannerImage = techAreaName => {
  let correspondingBanner;
    
  switch (techAreaName) {
    case "Internet of Things":
      correspondingBanner = bannerDict.IOTBanner;
      break;
    case "Blockchain":
      correspondingBanner = bannerDict.BlockchainBanner;
      break;
    case "AI and ML":
      correspondingBanner = bannerDict.AIMLBanner;
      break;
    case "Cloud Computing and Virtualization":
      correspondingBanner = bannerDict.CloudBanner;
      break;
    case "Wireless and Networking Technologies":
      correspondingBanner = bannerDict.WirelessNetworksBanner;
      break;
    case "Hardware, Semiconductors and Embedded":
      correspondingBanner = bannerDict.HardwareBanner;
      break;
    case "Cybersecurity":
      correspondingBanner = bannerDict.CyberSecurityBanner;
      break;
    case "Robotics and Automation":
      correspondingBanner = bannerDict.RoboticsBanner;
      break;
    case "Natural Language Processing":
      correspondingBanner = bannerDict.NLPBanner;
      break;
    case "Computer Vision":
      correspondingBanner = bannerDict.CompVisionBanner;
      break;
    case "Augmented and Virtual Reality":
      correspondingBanner = bannerDict.ARVRBanner;
      break;
    case "Data Science":
      correspondingBanner = bannerDict.DataScienceBanner;
      break;
    case "Material Science":
      correspondingBanner = bannerDict.MaterialScienceBanner;
      break;
    case "Next Generation Computing":
      correspondingBanner = bannerDict.NextGenComputingBanner;
      break;
    case "Cryptology":
      correspondingBanner = bannerDict.CryptologyBanner;
      break;
    case "Digital Forensics":
      correspondingBanner = bannerDict.DigitalForensicsBanner;
      break;
    case "Identity Management and Authentication":
      correspondingBanner = bannerDict.IdentityBanner;
      break;
    case "Enterprise Business Technologies":
      correspondingBanner = bannerDict.EnterpriseBusinessTechnologyBanner;
      break;
    case "Operating Systems":
      correspondingBanner = bannerDict.OSBanner;
      break;
    case "Application Infrastructure and Software":
      correspondingBanner = bannerDict.AppInfraBanner;
      break;
    case "Unmanned Aerial Vehicles":
      correspondingBanner = bannerDict.UAVBanner;
      break;
    case "Human Computer Interaction":
      correspondingBanner = bannerDict.HumanCompInteractionBanner;
      break;
    case "Software Defined Infrastructure":
      correspondingBanner = bannerDict.SoftwareDefinedNetworkBanner;
      break;
    case "Mobility and End Points":
      correspondingBanner = bannerDict.MobilityBanner;
      break;
    case "Location and Presence":
      correspondingBanner = bannerDict.LocationBanner;
      break;
    case "Quantum Technology":
      correspondingBanner = bannerDict.QuantumComputing;
      break
    case "5G and 6G":
      correspondingBanner = bannerDict.FiveGtoSixG;
      break
    default:
      correspondingBanner = bannerDict.IOTBanner;
      break;
  }
 
  return correspondingBanner;
};

export default getBannerImage;
