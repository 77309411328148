import React from "react";

function PrivacyPolicy() {
  document.title =`Privacy Policy - Technology Capability Repository || DSCI`;
  return (
    <>
      {/* Banner Section */}
      <div className="infoPage__banner infoPage__banner--privacy">
        {/* <p className="infoPage__bannerTitle">Privacy Policy</p> */}
      </div>
      {/* End of banner section */}

      {/* Main section */}
      <main className="infoPage__body">
        <div className="infoPage__bodyBox">
          <p className="infoPage__bodyText">
          We understand that online privacy is important to users/visitors of TechSagar Portal and
        DSCI believes that privacy and confidentiality of an individual’s personal information is
        important. This Privacy Policy is demonstration DSCI’s firm commitment to privacy.<br /><br />


            You are encouraged to review the privacy policy whenever you visit this portal to make sure
            that you understand how any personal information you provide may be used. By using this portal,
            you are accepting the practices described in this privacy policy. DSCI may modify this Privacy Policy
            from time to time to address new issues and to reflect changes on our portal. Change in the
            Privacy Policy will be notified through emails or by other means to the registered users of the portal.
            This policy was last modified in October 2019.<br /><br />   
            
            The privacy practices outlined in this privacy policy are
            for this portal only. If you link to other websites, please review the privacy 
            policies posted at those sites.
          </p>

          <p className="infoPage__bodyHeading">Notice</p>

          <p className="infoPage__bodyText">
            DSCI believes that the users of this portal should be given appropriate notice on how their personal information
            will be collected, used, or disclosed.<br /><br /> 
            
            The privacy practices outlined in this privacy policy are for this portal
            only. If, through this portal, you are redirected to other websites or by accessing links / URLs posted on the
            portal, it is advisable to review the privacy policies posted at those respective websites.
          </p>

          <p className="infoPage__bodyHeading">
            Collection of Information
          </p>

          <p className="infoPage__bodyText">
            The TechSagar portal does not collect or store any PII from guest visitors to this site who merely browse or
            access any content without Sign-Up or Login-In. However, if a visitor wishes to access additional premium content
            & services, they might be requested to register. Registration entails filling up of a form that may contain some
            attributes of PII. The portal collects PII, like name, Job title, affiliation/organization, contact information
            including email address & phone number etc. and other sensitive information such as payment methods, as well as
            other relevant information of users who register / Sign- Up to access/browse any premium content.<br /><br />
          </p>

          <p className="infoPage__bodyHeading">
            Information Use
          </p>

          <p className="infoPage__bodyText">
            The information collected by TechSagar Portal is used for the purpose it is intended and to provide specific and 
            better services to users. As applicable, the information you provide to us may be used to:

            <ul>
              <li>Better user experience and recommendations/suggestions</li>
              <li>Contact you for periodic information updates, Newsletters, etc. and other specific updates of your interest technologies & entities</li>
              <li>Contact you for your subscription/membership status</li>
              <li>Notify you for the outreach activities/programs that you show your interest for</li>
              <li>Monitor or improve the use of Techsagar Portal</li>
              <li>Monitor compliance with our Site privacy policy</li>
              <li>Provide special information materials to you from us and/or our respective affiliates, subsidiaries, and other third parties</li>
            </ul>
            
            DSCI may share your information with organizations affiliated with the TechSagar portal. Collected information will 
            not be sold, exchanged, transferred, or given to any other company for any reason whatsoever without your consent, 
            other than the exceptions like required by law, search warrants, other court orders, or exigent national circumstances.
          </p>

          <p className="infoPage__bodyHeading">
            Access and Correction
          </p>

          <p className="infoPage__bodyText">
            DSCI is a firm believer that individuals own their personal information, and hence, wherever applicable, DSCI 
            provides provision to access and even update your personal information either directly through the web or by 
            notifying us directly via electronic mail at the contact addresses specified below.<br /><br />
            
            DSCI may collect the same information multiple times from the same individuals to update and correct 
            individuals’ records that are already in possession of DSCI.
          </p>

          <p className="infoPage__bodyHeading">
            Safeguards and Disclosure
          </p>

          <p className="infoPage__bodyText">
          DSCI shall, at all times, endeavour to safeguard the privacy of your information and,
          accordingly, will not disclose the same to any third party or external organizations.<br /><br />

          PII disclosed in whitepapers/presentations/reports/newsletters or any other section of the
          Portal may be collected, correlated and used by visitors or any other third parties for any
          other purpose. Such activities are beyond the control of DSCI, and DSCI makes no
          warranties about the discoverability of your identity by such methods beyond DSCI’s control.<br /><br />

            While DSCI at all times, endeavour to safeguard the privacy of your Information, DSCI may disclose your information
            to other entities, strictly for the requirement of TechSagar Portal, where such disclosure:
            <ul>
              <li>is requested or authorized by you;</li>
              <li>is used for the purposes of completing, verifying or restricting any transaction made by you;</li>
              <li>is lawfully permitted or required;</li>
              <li>is in compliance with any judicial order or legal requirement;</li>
              <li>is necessary to protect and defend us and our property; and</li>
              <li>is necessary to protect the interests of the public, including but not limited
                to the detection of crimes and the apprehension of criminals.
              </li>  
            </ul>
            
            TechSagar Portal may contain links to other websites of interest. However, once you have used these links to
            leave our site, you should note that DSCI does not have any control over other websites. 
            Therefore, DSCI cannot be responsible for the protection and privacy of any information which you provide 
            whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise 
            caution and look at the privacy statement applicable to the website in question.
          </p>
 

          <p className="infoPage__bodyHeading">Privacy Contact Information</p>

          <p className="infoPage__bodyText">
            We welcome your questions and comments about Privacy Policy of our website. Comments or complaints can be directed 
            to DSCI by email at <span style={{textDecoration: 'underline'}}>techsagar@dsci.in</span>. Alternatively you can contact us at:

            <br /><br />
            <address>
              Data Security Council of India<br />
              4th Floor, NASSCOM Campus,<br />
              Plot No- 7-10, Sector 126,<br />
              Noida (UP) 201303, India<br />
              Phone: +91-120-4990253<br />
              Email: techsagar@dsci.in<br />
            </address>
          </p>
        </div>
      </main>
    </>
  );
}

export default PrivacyPolicy;