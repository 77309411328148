import React, { Component, Fragment, lazy } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import '../css/inline-svgs.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { setUserInfo } from "../actions/auth";
import { setFormType } from "../actions/user-form";
import { updateArea, updateCapability } from "../actions/search";

import featureImg from '../../src/img/creativeTeam.png'
import "../css/Home.css";
import "../css/HomeNew.css";

import LazyLoad from 'react-lazy-load';


class FeatureYourself extends Component {


    state = {
        showAllAreas: false,
        searchQuery: "",
        CarouselImages: [],
        showSuggestions: false,
        searchRedirect: false,
        resultType: "companies",
        suggestedEntities: [],
        suggestedLocations: [],
        suggestedTechAreas: [],
        suggestedProducts: [],
        eventData: [],
        eventState: '',
        signalNewsData: [],
        articleData: [],
        recentlyAddData: [],
        entitySummaryData: {},
        caseStudyData: [],
        contributorData: [],
        trendingData: [],
        contributorEmailId: "",
        liked: false,
        isloading: true,
        token: '',
    };







    render() {


        return (
            <>

                {this.state.isloading &&
                    <div className="home_cta_background sections-Container-Top redesignfeatureContainer">
                        <div className="home__cta">
                            <div className="home__cta__section-left">
                                <LazyLoad offset={300}>
                                    <img src={featureImg} alt='feature yourself' className='home__cta_img' />
                                </LazyLoad>
                            </div>
                            <div className="home__cta__section-right">
                                <h2 className="home__cta__desc"
                                >Evergrowing network of companies,
                                    startups, individuals, researchers,
                                    academia and R&D Institutes</h2>
                                <br />

                                <div
                                    onClick={this.props.handleFeatureYourself}
                                    className="home__cta__join__btn">Feature Yourself</div>
                            </div>
                        </div></div>}
            </>
        );
    }
}

const mapStateToProps = state => ({
    userInfo: state.auth.userInfo
});

const mapDispatchToProps = dispatch => ({
    setUserInfo: userInfo => dispatch(setUserInfo({ userInfo: userInfo })),
    setFormType: formType => dispatch(setFormType({ formType: formType })),
    updateArea: area => dispatch(updateArea({ area: area })),
    updateCapability: capability =>
        dispatch(updateCapability({ capability: capability })),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FeatureYourself);
