import React from 'react';
import { reduxForm, Field } from 'redux-form';

//  Redux form field component imports
import FormInputField from '../../feature_yourself-components/form-elements/FormInputField';
import FormSelectAsyncCreatable from '../../feature_yourself-components/form-elements/FormSelectAsyncCreatable';
import FormTextAreaField from '../../feature_yourself-components/form-elements/FormTextAreaField';

import api from '../../../api';

class AcademiaForm1 extends React.Component {

  getSimilarCompanies = (input, cb) => {
    let options = [];

    if (input.length < 3) {
      cb(options);
      return;
    }

    // input length 3 or more, fetch suggestions
    const requestConfig = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: input })
    };

    fetch(api.entityLookup, requestConfig)
      .then(response => {
        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then(({ data }) => {
        // Generate options array 
        options = data.map(company => ({
          value: company.name,
          label: company.name,
          id: company.id,
          className: 'select-option'
        }));

        cb(options);
      })
      .catch(err => {
        // console.log('error fetching options', err);
      })
  };


  render() {
    const { totalParts, handleSubmit, onSubmit } = this.props;

    return (
      <div className="feature_form__wrapper">
        <p className="feature__form__subhead"
        >Step 3 of {totalParts}</p>

        <p className="feature_form__head"
        >About Academia</p>

        <div className="feature_form__inner-wrapper">
          <form
            autoComplete='off'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="feature_form__inner-wrapper">
              <Field
                name="name"
                component={FormSelectAsyncCreatable}
                label="Academia / Institute Name"
                placeholder="E.g Indian Institute of Technology "
                selectConfig={{
                  isClearable: true,
                  isOptionDisabled: option => {
                    if (option.label.indexOf("Create"))
                      return true;
                  },
                }}
                loadOptions={this.getSimilarCompanies}
              />

              <Field
                name="url"
                // widthClass="col-md-6"
                component={FormInputField}
                type="text"
                placeholder="Enter website URL"
                label="Institute website/URL"
                tabable={true}
              />

              <Field
                name="about"
                // widthClass="col-md-6"
                component={FormTextAreaField}
                type="text"
                placeholder="Describe what the insitute does, its vision etc."
                label="About"
                tabable={true}
              />

              <Field
                name="offerings_description"
                // widthClass="col-md-6"
                component={FormTextAreaField}
                type="text"
                placeholder="Please give additional details of the research papers, programmes, workshops etc."
                label="Top Offerings"
                tabable={true}
              />
            </div>

            <div className="feature_form__btn-wrapper">
              <button
                type='submit'
                className="feature_form__btn"
              >Continue</button>

              <button
                onClick={this.props.prevPage}
                className="feature_form__btn feature_form__btn--back"
              >Previous Page</button>
            </div>
          </form>
        </div>



      </div>
    )
  }
}

export default reduxForm({
  form: 'feature_yourself',
  destroyOnUnmount: false
})(AcademiaForm1);