import React from "react";
import { connect } from "react-redux";
import "./EntityFiltersDrawer.css";
import { titleCase } from "../../../utils/helpers";

const EntityFiltersDrawer = props => {
    const sideDrawerClasses = ["entity-page--side-drawer"];
    if (props.show) {
        sideDrawerClasses.push("entity-page--side-drawer--open");
        document.querySelector("html").style.overflow = "hidden";
        document.querySelector("body").style.overflow = "hidden";
    } else {
        sideDrawerClasses.push("entity-page--side-drawer--close");
        document.querySelector("html").style.overflow = "auto";
        document.querySelector("body").style.overflow = "auto";
    }

    return (
        <div className={sideDrawerClasses.join(" ")}>
            <div className="entity-page--side-drawer-header">
                <div>
                    <i
                        className="fa fa-caret-left entity-page--side-drawer-header-backIcon"
                        onClick={props.toggleDrawer}
                        aria-hidden="true"
                    />
                    <span className="entity-page--side-drawer-header-text">
                        Filters
                    </span>
                </div>
                <div
                    onClick={props.resetAllFilters}
                    className="entity-page--side-drawer-header-clearFilters-button"
                >
                    Clear Filters
                </div>
            </div>

            <div className="entity-page--side-drawer-filters">
                {props.technologyAreas &&
                    props.technologyAreas.map((area, index) => (
                        <div key={index} className="entityview__tech-content">
                            <div className="entityview__tech-area">
                                <div className="entityview__tech-area-filter">
                                    <label
                                        className="entityview__tech-area-filter-label"
                                        style={{
                                            fontWeight: `${
                                                area.name &&
                                                area.name.toLowerCase() ===
                                                    props.searchQuery.toLowerCase()
                                                    ? "700"
                                                    : ""
                                            }`,
                                        }}
                                        title={
                                            area.name &&
                                            area.name.toLowerCase() ===
                                                props.searchQuery.toLowerCase()
                                                ? "You searched for this term."
                                                : ""
                                        }
                                    >
                                        <input
                                            className="entityview__tech-input"
                                            type="checkbox"
                                            name={area.name}
                                            data-filtertype="techArea"
                                            checked={props.techArea.includes(
                                                area.name
                                            )}
                                            onChange={
                                                props.handleFilterCheckboxInputChange
                                            }
                                            disabled={props.disableFilters}
                                        />
                                        <span className="customCheckbox" />
                                        <p>
                                            &nbsp;
                                            {area.name}
                                        </p>
                                    </label>
                                </div>
                            </div>
                            <div className="entityview__tech-timeline">
                                {area.tech_capability &&
                                    area.tech_capability.length === 0 && (
                                        <div className="entityview__tech-timeline-item">
                                            Capabilities not found
                                        </div>
                                    )}
                                {area.tech_capability &&
                                    area.tech_capability.length >= 0 &&
                                    area.tech_capability.map(
                                        (capability, index) => (
                                            <label
                                                key={index}
                                                className="entityview__tech-timeline-item"
                                                style={{
                                                    fontWeight: `${
                                                        capability.toLowerCase() ===
                                                        props.searchQuery.toLowerCase()
                                                            ? "700"
                                                            : ""
                                                    }`,
                                                }}
                                                title={
                                                    capability.toLowerCase() ===
                                                    props.searchQuery.toLowerCase()
                                                        ? "You searched for this term."
                                                        : ""
                                                }
                                            >
                                                <input
                                                    type="checkbox"
                                                    name={capability}
                                                    data-filtertype="capability"
                                                    data-techarea={area.name}
                                                    checked={props.capability.includes(
                                                        capability
                                                    )}
                                                    onChange={
                                                        props.handleFilterCheckboxInputChange
                                                    }
                                                    disabled={
                                                        props.disableFilters
                                                    }
                                                />
                                                <span className="customCheckbox" />
                                                &nbsp;
                                                {titleCase(capability)}
                                            </label>
                                        )
                                    )}
                            </div>
                        </div>
                    ))}
            </div>

            <div
                className="search-filters--side-drawer--apply-button"
                onClick={props.toggleDrawer}
            >
                APPLY FILTERS
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    searchQuery: state.search.query,
});

export default connect(mapStateToProps)(EntityFiltersDrawer);
