import React, { Fragment } from 'react';
import { reduxForm, Field, FieldArray, FormSection } from 'redux-form';

// Redux form field component imports
import FormInputField from '../../feature_yourself-components/form-elements/FormInputField';
import FormSelect from '../../feature_yourself-components/form-elements/FormSelect';
import FormSelectAsyncCreatable from '../../feature_yourself-components/form-elements/FormSelectAsyncCreatable';
import FormTextAreaField from '../../feature_yourself-components/form-elements/FormTextAreaField';

import validate from '../validate';

class IndustryForm1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTypes: [],
      showAddButtons: true, // New state to control the visibility of the add buttons
    };
  }

  handleClick = (buttonType) => {
    this.setState({ visibleButton: buttonType });
    // Add your additional logic here if needed
  };

  handleAddClick = (type) => {
    this.setState((prevState) => ({
      selectedTypes: [...prevState.selectedTypes, type],
      showAddButtons: false, // Hide the add buttons after one is clicked
    }), () => {
      // Immediately push an empty object to the FieldArray
      this.props.change(`${type}_description.${type}_Offerings`, [{}]);
    });
  };

  renderFields = () => {
    const { selectedTypes } = this.state;
    return (
      <div>
        {selectedTypes.map((selectedType) => (
          <FormSection key={selectedType} name={`${selectedType}_description`}>
            <FieldArray
              name={`${selectedType}_Offerings`}
              component={renderContactPersons(selectedType)}
            />
          </FormSection>
        ))}
      </div>
    );
  };

  render() {
    const { totalParts, handleSubmit, onSubmit, companySizes, companyTypes } = this.props;
    const { selectedTypes, showAddButtons } = this.state; // Correctly reference selectedTypes and showAddButtons from state
    const remainingTypes = ['product', 'service', 'solution'].filter(
      (type) => !selectedTypes.includes(type)
    );

    return (
      <div className="feature_form__wrapper">
        <p className="feature__form__subhead">Step 3 of {totalParts}</p>
        <p className="feature_form__head">About Company</p>

        <div className="feature_form__inner-wrapper">
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className="feature_form__inner-wrapper">
              <Field
                name="name"
                component={FormInputField}
                type="text"
                label="Company’s Legal Name"
                placeholder="E.g Reliance Industries Limited"
                tabable={true}
              />

              <Field
                name="alias"
                component={FormInputField}
                type="text"
                placeholder="Eg. Reliance"
                label="Company’s Alias"
                tabable={true}
              />

              <Field
                name="url"
                component={FormInputField}
                type="text"
                placeholder="Enter the URL of the company"
                label="Company’s Website"
                tabable={true}
              />

              <Field
                name="type"
                component={FormSelect}
                placeholder="Select company type"
                label="Type of company"
                valueType="string"
                options={companyTypes.map((type) => ({
                  label: type,
                  value: type,
                }))}
              />

              <Field
                name="size"
                placeholder="Select"
                label="Number of Employees"
                component={FormSelect}
                options={companySizes.map((type) => ({
                  label: type,
                  value: type,
                }))}
                valueType="string"
              />

              <Field
                name="about"
                component={FormTextAreaField}
                type="text"
                placeholder="Describe company does, its vision etc."
                label="About"
                tabable={true}
              />

              {showAddButtons && (
                <div>
                  <label>Add Offerings</label>
                  <div>
                    {remainingTypes.map((type) => (
                      <button
                        key={type}
                        type="button"
                        className="feature_form__btn"
                        style={{ margin: '4px' }}
                        onClick={() => this.handleAddClick(type)}
                      >
                        Add {type.charAt(0).toUpperCase() + type.slice(1)}
                      </button>
                    ))}
                  </div>
                </div>
              )}

              {this.renderFields()}

              {selectedTypes.length > 0 && (
                <div>
                  {remainingTypes.map((type) => (
                    <button
                      key={type}
                      className="feature_form__btn"
                      style={{ margin: '4px' }}
                      type="button"
                      onClick={() => this.handleAddClick(type)}
                    >
                      Add {type.charAt(0).toUpperCase() + type.slice(1)}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <div className="feature_form__btn-wrapper">
              <button type="submit" className="feature_form__btn">Continue</button>
              <button onClick={this.props.prevPage} className="feature_form__btn feature_form__btn--back">Previous Page</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const renderContactPersons = (type) => ({ fields }) => (
  <Fragment>
    {fields.map((person, index) => (
      <Fragment key={index}>
        {fields.length > 0 && (
          <p className="feature_form_sub_heading" style={{ marginTop: '3rem' }}>
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </p>
        )}

        <Field
          name={`${person}.name`}
          component={FormInputField}
          placeholder={`${type.charAt(0).toUpperCase() + type.slice(1)} Name`}
          label="Name"
        />
        <Field
          name={`${person}.about`}
          component={FormInputField}
          placeholder={`About the ${type}`}
          label="About"
        />
        <Field
          name={`${person}.url`}
          component={FormInputField}
          label="Url"
          placeholder="E.g. hello@yourcompany.com"
        />
        {fields.length > 1 && (
          <div className="feature_form__field-array-remove">
            <span onClick={() => fields.remove(index)}>
              - Remove
            </span>
          </div>
        )}
      </Fragment>
    ))}

    <div className="feature_form__field-array-add">
      <span onClick={() => fields.push({})}>+ Add {type.charAt(0).toUpperCase() + type.slice(1)}</span>
    </div>
  </Fragment>
);

export default reduxForm({
  form: 'feature_yourself',
  destroyOnUnmount: false,
  validate,
})(IndustryForm1);







// import React, {Fragment} from 'react';
// import { reduxForm, Field, FieldArray,FormSection } from 'redux-form';

// //  Redux form field component imports
// import FormInputField from '../../feature_yourself-components/form-elements/FormInputField';
// import FormSelect from '../../feature_yourself-components/form-elements/FormSelect';
// import FormSelectAsyncCreatable from '../../feature_yourself-components/form-elements/FormSelectAsyncCreatable';
// import FormTextAreaField from '../../feature_yourself-components/form-elements/FormTextAreaField';
 
// import validate from '../validate';

// class IndustryForm1 extends React.Component {

//   getSimilarCompanies = (input, cb) => {
//     let options = [];

//     if (input.length > 2) {
//       fetch(
//         `${
//         process.env.REACT_APP_API_URL
//         }/api/ra/search/company_lookup/`,
//         {
//           method: "POST",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             name: input,
//           }),
//         }
//       )
//         .then(response => {
//           // console.log(response);
//           if (response.status !== 200) {
//             // console.log(
//             //   "Looks like there was a problem. Status Code: " +
//             //   response.status
//             // );
//             return;
//           }

//           // Examine the text in the response
//           response.json().then(response => {
//             options = response.data.map(company => ({
//               value: company.name,
//               label: company.name,
//               id: company.id,
//               className: "select-option",
//             }));
//             // console.log(options);
//             cb(options);
//             return;
//           });
//         })
//         .catch(err => {
//           // console.log("Cannot retrieve Data:", err);
//         });
//     } else {
//       cb(options);
//     }
//   };

//   render() {
//     // console.log('if1 props = ', this.props);
//     const { totalParts, handleSubmit, onSubmit, companySizes, companyTypes } = this.props;

//     return (
//       <div className="feature_form__wrapper">
//         <p className="feature__form__subhead"
//         >Step 3 of {totalParts}</p>

//         <p className="feature_form__head"
//         >About Company</p>

//         <div className="feature_form__inner-wrapper">
//           <form
//             autoComplete='off'
//             onSubmit={handleSubmit(onSubmit)}
//           >
//             <div className="feature_form__inner-wrapper">
//               <Field
//                 name="name"
//                 component={FormInputField}
//                  type="text"
//                 // component={FormSelectAsyncCreatable}
//                 label="Company’s Legal Name"
//                 placeholder="E.g Reliance Industries Limited"
//                 tabable={true}
//                 // selectConfig={{
//                 //   isClearable: true,
//                 //   isOptionDisabled: option => {
//                 //     if (option.label.indexOf("Create"))
//                 //       return true;
//                 //   },
//                 // }}
//                 // loadOptions={this.getSimilarCompanies}
//               />

//               <Field
//                 name="alias"
//                 // widthClass="col-md-6"
//                 component={FormInputField}
//                 type="text"
//                 placeholder="Eg. Reliance"
//                 label="Company’s Alias"
//                 tabable={true}
//               />

//               <Field
//                 name="url"
//                 // widthClass="col-md-6"
//                 component={FormInputField}
//                 type="text"
//                 placeholder="Enter the URL of the company"
//                 label="Company’s Website"
//                 tabable={true}
//               />

//               <Field
//                 name="type"
//                 component={FormSelect}
//                 placeholder="Select company type"
//                 label="Type of company"
//                 valueType="string"
//                 //type="text"
                 
//                 options={companyTypes.map(type => ({
//                   label: type,
//                   value: type,
//                 }))}
//               />

//               <Field
//                 name="size"
//                 placeholder="Select"
//                 label="Number of Employees"
//                 component={FormSelect}
//                 options={companySizes.map(type => ({
//                   label: type,
//                   value: type,
//                 }))}
//                 valueType="string"
//               />

//               <Field
//                 name="about"
//                 // widthClass="col-md-6"
//                 component={FormTextAreaField}
//                 type="text"
//                 placeholder="Describe company does, its vision etc."
//                 label="About"
//                 tabable={true}
//               />
//               <FormSection name="offering_description">
//                    <FieldArray
//                   name="Offerings"
//                   component={renderContactPersons}
//                 />
//                 </FormSection>
//               {/* <Field
//                 name="offerings_description"
//                 // widthClass="col-md-6"
//                 component={FormTextAreaField}
//                 type="text"
//                 placeholder="Please give additional details of the top products, services and other offerings, the technologies involved etc."
//                 label="Top Offerings"
//                 tabable={true}
//               /> */}
//             </div>
//             <div className="feature_form__btn-wrapper">
//               <button
//                 type='submit'
//                 className="feature_form__btn"
//               >Continue</button>

//               <button
//                 onClick={this.props.prevPage}
//                 className="feature_form__btn feature_form__btn--back"
//               >Previous Page</button>
//             </div>
//           </form>
//         </div>
//       </div>
//     )
//   }
// }

// const renderContactPersons = ({ fields }) => (

//   <Fragment>
//     {fields.map((person, index) => (
      
//       <Fragment key={index}>
//         {fields.length > 0 && (
//           <p className='feature_form_sub_heading' style={{
//             marginTop: '3rem'
//           }}>
//             Products, Service & Solution
//           </p>
//         )}
//         <Field
//           name={`${person}.name`}
//           // name= "Product"
//           component={FormInputField}
//           placeholder="Product Name"
//           label="Name"
//         />
//         <Field
//            name={`${person}.about`}
//           // name = "About"
//           component={FormInputField}
//           placeholder="About the product"
//           label="About"
//         />
//         <Field
//            name={`${person}.url`}
//           // name= "Url"
//           component={FormInputField}
//           label="Url"
//           placeholder="E.g. hello@yourcompany.com"
//         />
//         {fields.length > 1 && (
//           <div className="feature_form__field-array-remove">
//             <span onClick={() => fields.remove(index)}>
//               - Remove
//                         </span>
//           </div>
//         )}
//       </Fragment>
//     ))}

//     <div className="feature_form__field-array-add">
//       <span onClick={() => fields.push({})}>+ Add</span>
//     </div>
//   </Fragment>
// );

// export default reduxForm({
//   form: 'feature_yourself',
//   destroyOnUnmount: false,
//   validate,
// })(IndustryForm1);