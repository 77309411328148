import React from "react";

import locationIcon from "../../../img/location.svg";
import calendarIcon from "../../../img/calendar.svg";

import "../../../css/InvestorInfo.css";

const TabInvestor = ({ data }) => {
    let previousExperience = null;
    let shareholderBoardMember = null;
    let personalInvestments = null;
    let companyInvestments = null;

    //  Individual counts to be displayed
    const shareHolderCount = data.positions ? data.positions.length : "0";

    const personalInvestmentsCount = data.personal_investments
        ? data.personal_investments.length
        : "0";

    const comapanyInvestmentsCount = data.company_investments
        ? data.company_investments.length
        : "0";

    //  Create the previous experience items by mapping over data.experience
    if (data.experience) {
        previousExperience = data.experience.map(exp_item => (
            <div
                key={exp_item.company}
                className="tabinvestor__previous-exp-item"
            >
                <div className="tabinvestor__previous-exp-item--top">
                    <p className="tabinvestor__tile--heading">
                        {exp_item.company}
                    </p>
                    <p className="tabinvestor__tile--subheading">
                        {exp_item.position}
                    </p>
                </div>

                <div className="tabinvestor__previous-exp-item--bottom">
                    <p className="tabinvestor__previous-exp-item--bottom-text">
                        <img
                            src={locationIcon}
                            alt="icon"
                            className="tabinvestor__location-icon"
                        />
                        {exp_item.city},&nbsp;{exp_item.country}
                    </p>
                    <p className="tabinvestor__previous-exp-item--bottom-text">
                        <img
                            src={calendarIcon}
                            alt="icon"
                            className="tabinvestor__calendar-icon"
                        />
                        {exp_item.since} - {exp_item.till}
                    </p>
                </div>
            </div>
        ));
    }

    //  Create the shareholder items by mapping over data.positions
    if (data.positions) {
        shareholderBoardMember = data.positions.map(pos_item => (
            <div
                key={pos_item.company}
                className="tabinvestor__shareholder-item"
            >
                <div>
                    <p className="tabinvestor__tile--heading">
                        {pos_item.company}
                    </p>
                    <p className="tabinvestor__tile--subheading">
                        {pos_item.position}
                    </p>
                </div>

                <p className="tabinvestor__stat-content tabinvestor__stat-content--tile">
                    {pos_item.stake}
                </p>
            </div>
        ));
    }

    //  Create the personal investment items by mapping over data.personal_investments
    if (data.personal_investments) {
        personalInvestments = data.personal_investments.map(inv_item => {
            const investmentRounds = inv_item.rounds.map(round => (
                <p key={round} className="tabinvestor__investment-round">
                    {round}
                </p>
            ));

            return (
                <div
                    key={inv_item.company}
                    className="tabinvestor__investment-item"
                >
                    <p className="tabinvestor__tile--heading">
                        {inv_item.company}
                    </p>
                    <p className="tabinvestor__tile--subheading">
                        Announced in {inv_item.announced_in}
                    </p>

                    <div className="tabinvestor__investment-rounds-wrapper">
                        {investmentRounds}
                    </div>

                    <p className="tabinvestor__personal_investment__is-lead">
                        {inv_item.lead_investor ? "Lead" : <span>&nbsp;</span>}
                    </p>

                    <p className="tabinvestor__stat-content tabinvestor__stat-content--tile">
                        ${inv_item.amount_invested}
                    </p>
                </div>
            );
        });
    }

    //  Create the company investment items by mapping over data.company_investments
    if (data.company_investments) {
        companyInvestments = data.company_investments.map(inv_item => {
            const investmentRounds = inv_item.rounds.map(round => (
                <p key={round} className="tabinvestor__investment-round">
                    {round}
                </p>
            ));

            return (
                <div
                    key={inv_item.company}
                    className="tabinvestor__investment-item"
                >
                    <p className="tabinvestor__tile--heading">
                        {inv_item.company}
                    </p>
                    <p className="tabinvestor__tile--subheading">
                        Announced in {inv_item.announced_in}
                    </p>

                    <div className="tabinvestor__investment-rounds-wrapper">
                        {investmentRounds}
                    </div>

                    <p className="tabinvestor__personal_investment__is-lead">
                        {inv_item.lead_investor ? "Lead" : <span>&nbsp;</span>}
                    </p>

                    <p className="tabinvestor__stat-content tabinvestor__stat-content--tile">
                        ${inv_item.amount_invested}
                    </p>
                </div>
            );
        });
    }

    return (
        <div className="tabinvestor-wrapper">
            <div className="tabinvestor__left-col">
                <div className="tabinvestor__left-col--section">
                    <p className="tabinvestor--section-heading">
                        Previous Experience
                    </p>

                    <div className="tabinvestor__previous-exp-wrapper">
                        {previousExperience}
                    </div>
                </div>

                <div className="tabinvestor__left-col--section">
                    <p className="tabinvestor--section-heading">
                        Shareholder/Board Member ({shareHolderCount})
                    </p>

                    <div className="tabinvestor__shareholder-wrapper">
                        {shareholderBoardMember}
                    </div>
                </div>

                <div className="tabinvestor__left-col--section">
                    <p className="tabinvestor--section-heading">
                        Personal Investments ({personalInvestmentsCount})
                    </p>

                    <div className="tabinvestor__personal_investments-wrapper">
                        {personalInvestments}
                    </div>
                </div>

                <div className="tabinvestor__left-col--section">
                    <p className="tabinvestor--section-heading">
                        Company Investments ({comapanyInvestmentsCount})
                    </p>

                    <div className="tabinvestor__personal_investments-wrapper">
                        {companyInvestments}
                    </div>
                </div>
            </div>
            <div className="tabinvestor__right-col">
                <p className="tabinvestor__stat-heading">
                    Previous Experience
                </p>
                <p className="tabinvestor__stat-content">
                    {data.work_experience}
                </p>

                <p className="tabinvestor__stat-heading">
                    Shareholder/Board Member
                </p>
                <p className="tabinvestor__stat-content">
                    {shareHolderCount}
                </p>

                <p className="tabinvestor__stat-heading">
                    Personal Investments
                </p>
                <p className="tabinvestor__stat-content">
                    {personalInvestmentsCount}
                </p>

                <p className="tabinvestor__stat-heading">
                    Company Investments
                </p>
                <p className="tabinvestor__stat-content">
                    {comapanyInvestmentsCount}
                </p>
            </div>
        </div>
    );
};

export default TabInvestor;
