import React from 'react';

import Modal from './modal/Modal';
import successImage from '../../img/success.svg';
import failedImage from '../../img/failed.svg';


const PaymentStatusModal = ({
  show,
  toggleModal,
  amount,
  paymentStatus,
  transactionId,
}) => {
 // console.log(show, toggleModal, amount, paymentStatus, transactionId);
  return (
    <Modal
      show={show}
      toggleModal={toggleModal}
      width={600}
      height="50rem"
    >
      <div className='success-modal'>
        <img 
          src={paymentStatus ? successImage : failedImage} 
          alt="Payment Successful" 
          className='success-modal__image' 
        />
        <p className='success-modal__heading'>
          {paymentStatus ? 'Payment Successful' : 'Payment Failed'}
        </p>
        <p className='success-modal__info-text'>
          {paymentStatus ? 'The transaction has been processed' : 'Sorry, the transaction has failed.'}  
          <br/>Transaction details are as follows.
        </p>

        <div className='success-modal__transaction-details'>
          <div 
            className='success-modal__transaction-detail-item
            sucess-modal__text-dark'
          >
            <p>Amount</p>
            <p>&#8377;{amount}</p>
          </div>

          <div className='success-modal__transaction-detail-item'>
            <p className='success-modal__text-dark1'>Transaction ID</p>
            <p className='success-modal__text-dark2'>{transactionId}</p>
          </div>
        </div>

        <button
          onClick={toggleModal}
          className='success-modal__btn'
        >{paymentStatus ? 'Close' : 'Try Again'}</button>
      </div>
    </Modal>
  );
};

export default PaymentStatusModal;


