import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import Backdrop from "../backdrop/Backdrop";
import "./HomeSideDrawer.css";
// import dsciLogo from "../../../img/dsc-logo/dsc-logo2.png";
import dsciLogo from "../../../img/dsc-logo/tech-sagar-logo-exploration-70.png";

class SideDrawer extends Component {
    drawerGoToHome = () => {
        this.props.closeHomeSideDrawer();
        this.props.history.push("/");
    };

    render() {
        const sideDrawerClasses = ["home--side_drawer"];
        if (this.props.show) {
            sideDrawerClasses.push("home--side_drawer--open");
            document.getElementsByTagName("body")[0].style.overflow = "hidden";
            document.getElementsByTagName("html")[0].style.overflow = "hidden";
        } else {
            sideDrawerClasses.push("home--side_drawer--close");
            document.getElementsByTagName("body")[0].style.overflow = "auto";
            document.getElementsByTagName("html")[0].style.overflow = "auto";
        }

        return (
            <Fragment>
                <Backdrop
                    show={this.props.show}
                    clicked={this.props.closeHomeSideDrawer}
                    zIndex="100"
                />
                <div className={sideDrawerClasses.join(" ")}>
                    <div className="home__side-drawer--img-wrapper">
                        <div onClick={this.drawerGoToHome}>
                            <img
                                src={dsciLogo}
                                className="home__side-drawer--img"
                                alt="DSCI Logo"
                            />
                        </div>
                    </div>

                    <div
                        className="home__side-drawer--content"
                        style={{
                            justifyContent: this.props.user
                                ? "flex-start"
                                : "space-between",
                        }}
                    >
                        <div>
                            <p style={{ marginBottom: "40px",fontSize:"1.4rem",fontWeight:"bold" }}>
                                <span onClick={this.drawerGoToHome}>HOME</span>
                            </p>

                            <Link
                                className="home__side-drawer--content__feature-yourself"
                                to={"/feature_yourself"}
                            >
                                Feature Yourself
                            </Link>
                            
                        </div>

                        {!this.props.user ? (
                            <div className="home__side-drawer--content__buttons">
                                <div
                                    className="home__side-drawer--content__login-btn"
                                    onClick={() => {
                                      this.props.closeHomeSideDrawer();
                                      this.props.toggleUserForm("login")
                                      }
                                    }
                                >
                                    Login
                                </div>

                                <div
                                    className="home__side-drawer--content__signup-btn"
                                    onClick={() => {
                                      this.props.closeHomeSideDrawer();
                                      this.props.toggleUserForm("signup");
                                    }}
                                >
                                    SIGN UP
                                </div>
                            </div>
                        ) : (
                            <Fragment>
                                <hr className="home__side-drawer-content--hr" />

                                <div
                                    className="home__side-drawer--header__user-text"
                                    onClick={this.props.toggleUserDropDown}
                                >
                                    {this.props.user.first_name +
                                        " " +
                                        this.props.user.last_name}
                                </div>

                                <ul className="header__dropdown-ul">
                                  <li 
                                    className="home__side-drawer-content-item"
                                    onClick={() => {
                                      this.props.closeHomeSideDrawer();
                                      this.props.history.push('/dashboard');
                                    }}
                                  >Dashboard</li>

                                    <li
                                      className="home__side-drawer-content-item"
                                      onClick={() => {
                                        this.props.closeHomeSideDrawer();
                                        this.props.history.push('/my_account')
                                      }}
                                    >My Account</li>

                                    <li
                                        className="home__side-drawer-content-item"
                                        onClick={this.props.toggleUserForm.bind(
                                            null,
                                            "forgotPassword"
                                        )}
                                    >
                                        Change Password
                                    </li>
                                    <li
                                        className="home__side-drawer-content-item"
                                        onClick={this.props.userLogout}
                                    >
                                        Log Out
                                    </li>

                                    
                                </ul>
                            </Fragment>
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default SideDrawer;
