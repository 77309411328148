import React, { Component } from "react";
import "../../css/FinancialInfo.css";

import InvestmentTimeline from "./InvestmentTimeline";
import EmptyStateIconSvg from '../../inline-svg-components/EmptyStateIconSvg';

class FinancialInfo extends Component {

    dateConvert=(date)=>{
        const d = new Date(date).toString()
        const key = d.slice(3,15)
        return key
    }
    render() {
       
        //  Destructure the required data
        let {
            totalFunding,
            totalValuation,
            totalInvestors,
            fundingRounds,
            shareholders,
            companiesAcquired,
        } = this.props.data;
   
        let fundingRoundsCount = null;
        // let isUnicorn = false;

        // !  BYPASS RENDER
        // !  IF FUNDING ROUNDS IS EMTPY, SHOW EMPTY STATE ICON
        if (!fundingRounds || fundingRounds.length < 1)
            return (
                <div>
                    <div className="entityview__no-items-wrapper">
                        <EmptyStateIconSvg className='emptyIcon' />
                        <p className="entityview__no-items-head">
                            The Financial Information is coming soon!
                        </p>
                        <p className="entityview__no-items-desc">
                            Once available, you can view the financial
                            information here.
                            <br />
                            Stay Tuned!
                        </p>
                    </div>
                </div>
            );

        //  Format the destructured data as required
        if (!totalFunding || totalFunding === -1) totalFunding = "Undisclosed";
        else totalFunding = `$${totalFunding} million`;

        if (!totalValuation || totalValuation === -1)
            totalValuation = "Unknown";
        else totalValuation = `${totalValuation} billion`;

        if (!totalInvestors || totalInvestors === -1)
            totalInvestors = "Unknown";

        if (!fundingRounds || !fundingRounds.length)
            fundingRoundsCount = "Unknown";
        else fundingRoundsCount = fundingRounds.length;
        
        return (
            <div className="financial-container">
                <p className="financial__section-heading">
                    Investment Timeline
                </p>

                <section className="financial__investments-wrapper">
                    <div className="financial__timeline-wrapper">
                        <InvestmentTimeline data={fundingRounds} />
                    </div>

                    <div className="financial__investment-stats-wrapper">
                        <div className="financial__investment-stat">
                            <p className="financial__investment-stat-heading">
                                Total Funding
                            </p>
                            <p className="financial__investment-stat-content">
                                {totalFunding}
                            </p>
                        </div>

                        <div className="financial__investment-stat">
                            <p className="financial__investment-stat-heading">
                                Current Valuation (approx)
                            </p>
                            <p className="financial__investment-stat-content">
                                {totalValuation}
                                {/* <span className="financial__investment-stat--company-type">
                                    Unicorn
                                </span> */}
                            </p>
                            {/* <p className="financial__investment-stat--as-of-date">
                                as of June 2019
                            </p> */}
                        </div>

                        <div className="financial__investment-stat">
                            <p className="financial__investment-stat-heading">
                                Total Investors
                            </p>
                            <p className="financial__investment-stat-content">
                                {totalInvestors}
                            </p>
                        </div>

                        <div className="financial__investment-stat">
                            <p className="financial__investment-stat-heading">
                                Investment Rounds
                            </p>
                            <p className="financial__investment-stat-content">
                                {fundingRoundsCount}
                            </p>
                        </div>
                    </div>
                </section>

                {shareholders && shareholders.length ? (
                    <section className="financial_shareholders-wrapper">
                        <div className="financial__section-heading">
                            Shareholders ({shareholders.length})&nbsp;
                            <span className="financial_shareholders__as-of-date">
                                as on June 2019
                            </span>
                        </div>

                        <div className="financial_shareholders-content">
                            {shareholders && shareholders.map( (key,i)=>(
                            <div className="financial__tile-item financial__one-shareholder">
                                <div className="financial__one-shareholder-text-wrapper">
                                    <p className="financial__one-shareholder-name">
                                        {key.name}
                                    </p>
                                    <p className="financial__one-shareholder-identity">
                                        {key.designation}
                                    </p>
                                </div>

                                <div className="financial__one-shareholder-stake">
                                    {key.percentage}
                                </div>
                            </div>
                            ))  }
                            
                        </div>
                    </section>
                ) : null}

                {companiesAcquired && companiesAcquired.length ? (
                    <section className="fiancial__companies-acquired-wrapper">
                        <div className="financial__section-heading">
                            Companies Acquired ({companiesAcquired.length})
                        </div>

                        <div className="financial_shareholders-content">
                            {companiesAcquired && companiesAcquired.map(
                                (key,i) => (
                                    
                                     
                            <div className="financial__tile-item financial__one-company">
                                <p className="financial__one-company-name">
                                    {key.acquiree_name}
                                </p>
                                <p className="financial__one-company-acquired-date">
                                    Acquired on {this.dateConvert(key.announced_date)}
                                </p>
                              {key.amount && <p className="financial_one-company-acquisition-amount">
                                ${key.amount}M
                                </p>}
                            </div>)
                            )}
 
                        </div>
                    </section>
                ) : null}
            </div>
        );
    }
}

export default FinancialInfo;
