import { useState, useEffect } from 'react';
import api, { getGETConfig } from '../../api';

function useWhitepapers(userInfo) {
  const [whitepapers, setWhitepapers] = useState([]);
  
  async function fetchPapers() {
    const config = getGETConfig();
    if (userInfo && userInfo.token) config.headers.TCRAUTH = userInfo.token;
    config.headers['Auth-Key'] = process.env.REACT_APP_AUTH_KEY;
    
    try {
      const response = await fetch(api.studyWhitepaper, config);
      const { data }  = await response.json();
      setWhitepapers(data);
    } catch (err) {
      // console.log('Error fetching whitepapers');
    }
  }

  useEffect(() => { fetchPapers(); }, [userInfo]);
  return whitepapers;
}

export default useWhitepapers;