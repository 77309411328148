import React, { Component } from 'react';
import { httpfyUrl } from "../../utils/helpers";
import EmptyStateIconSvg from '../../inline-svg-components/EmptyStateIconSvg';

class Patents extends Component {
    patents =()=>{
     // console.log(this.props)
        return(
            <>
            {this.props.items.length>0 && <p className='patents__heading'>PATENTS({this.props.items.length})</p>}
            { this.props.items && this.props.items.length > 0 ? (
                      this.props.items.map((item, index) => (
                        <div
                          key={index}
                          className="entityview__desc-card entityview__papers-card entityview__papers-card-patent"
                        >
                          <div className="entityview__desc-card-left">
                            <div className="entityview__desc-card-head">
                              {item.title}
                            </div>
                            <div className="entityview__desc-card-detail">
                              {item.year ? `Published in ${item.year}` : ''}
                            </div>
                          </div>
                          <div>
                            {item.url && (
                              <a
                                className="entityview__desc-card-link entityview__desc-card-link--small"
                                href={httpfyUrl(item.url)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fas fa-link" />
                              </a>
                            )}
                          </div>
                        </div>     
                      ))
                    ) :<></>
                  }
            </>
        )
    }

    reports =()=>{
        return(
            <>
            {this.props.reports.length>0 && 
            <p className='patents__heading'>REPORTS({this.props.reports.length})</p>}
            { this.props.reports && this.props.reports.length > 0 ? (
                      this.props.reports.map((item, index) => (
                        <div
                          key={index}
                          className="entityview__desc-card entityview__papers-card"
                        >
                          <div className="entityview__desc-card-left">
                            <div className="entityview__desc-card-head">
                              {item.title}
                            </div>
                            <div className="entityview__desc-card-detail">
                              {item.year ? `Published in ${item.year}` : ''}
                            </div>
                          </div>
                          <div>
                            {item.url && (
                              <a
                                className="entityview__desc-card-link entityview__desc-card-link--small"
                                href={httpfyUrl(item.url)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fas fa-link" />
                              </a>
                            )}
                          </div>
                        </div>     
                      ))
                    ) :<></>
                  }
            </>
        )
    }
    render() {
      
        const patents = this.patents();
        const reports = this.reports();
        return (
        <div> 
          {this.props.reports.length > 0 || this.props.items.length > 0 ?
            <div className="patents">
                {patents}
                {reports}
            </div>:<>
            <div>
                    <div className="entityview__no-items-wrapper">
                        <EmptyStateIconSvg className='emptyIcon' />
                        <p className="entityview__no-items-head">
                            The Patents and Reports Information is coming soon!
                        </p>
                        <p className="entityview__no-items-desc">
                            Once available, you can view the  Patents and Reports
                            information here.
                            <br />
                            Stay Tuned!
                        </p>
                    </div>
                </div></>}
        </div>);
    }
}

export default Patents;