import React from "react";
import { Field, reduxForm } from "redux-form";
import FormButton from "../form-components/FormButton";
import "../../css/UserForm.css";
import "../../css/Form.css";
import FIleInput from "../form-components/FileInputField"
import uploadIcon from "../../img/check.png"
import "../../css/becomeContributor.css"
import { Redirect } from "react-router";
import Modal from "./Modal";
import { required, email } from 'redux-form-validators'

class ContributorPart3 extends React.Component {
    state = {
        toggleModal: true
    }
    setModalToggle = () => {
        this.setState({ toggleModal: !this.state.toggleModal }
        )
    }
    render() {
        window.scrollTo(0, 0)
        const { handleSubmit, contactNumber, formLoader } = this.props;
        // console.log(this.props.uploaded)
        if (this.state.toggleModal === false) {
            return (
                <Redirect to={'/'} />)
        }
        return (
            <div className="become_contributor">
                <Modal show={this.props.uploaded}
                    close={this.setModalToggle}>
                    <div style={{ width: "100%" }}>
                        <div className="modal__container">
                            <div className="uploaded_successfully">
                                <img src={uploadIcon} className="uploaded_successfully_img" alt="upload icon"/>
                                <p className="uploaded_successfully_text">File uploaded Successfully</p>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <p className="user-form__progress">Step 3 of 3</p>

                    {this.props.uploaded === false ?
                        <p
                            className="user-form__progress"
                            style={{ marginRight: "30px", cursor: "pointer" }}
                            onClick={this.props.prevPage}
                        >Previous Page</p> : <></>}
                </div>
                <div className="contributor__upload_sec_heading">
                    Upload Document
                </div>

                <form onSubmit={handleSubmit}>
                   
                        
                            <div className="user__form-container">
                                <div className="row user__form-row">
                            
                                    <Field
                                        name="file"
                                        component={FIleInput}
                                        type="file"
                                        validate={required()}
                                        
                                    />
                                    
                                </div>
                            </div>
                            <div className="contributor__button">
                            <FormButton label="SUBMIT" formLoader={formLoader} />
                            </div>
                       
                </form>

            </div>
        );
    }
}

export default reduxForm({
    form: "contributor", // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    // validate
})(ContributorPart3);
