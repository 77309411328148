import React, { Component, Fragment } from "react";
import { reduxForm } from "redux-form";

import FormProducts from "../form-elements/FormProducts";

import validate from "../utils/validate";

class FormPart7 extends Component {
    render() {
        const { handleSubmit } = this.props;
        const onKeyPress = event => {
            if (event.key === "Enter") {
                event.preventDefault();
            }
        };

        return (
            <Fragment>
                <p className="feature_form__head">Talks/Workshops</p>
                <form
                    onSubmit={handleSubmit(this.props.onSubmit)}
                    onKeyPress={onKeyPress}
                >
                    <div className="feature_form__inner-wrapper">
                        <div className="feature_form__inner-wrapper">
                            <FormProducts
                                title="Talks/Workshops"
                                singular="Talk/Workshop"
                                section="services"
                                formType="academia"
                                technologyAreas={this.props.technologyAreas}
                            />
                        </div>
                    </div>
                    <div className="feature_form__btn-wrapper">
                        <button
                            className="feature_form__btn feature_form__btn-back"
                            onClick={this.props.prevPage}
                        >
                            GO BACK
                        </button>
                        <button type="submit" className="feature_form__btn">
                            CONTINUE
                        </button>
                    </div>
                </form>
            </Fragment>
        );
    }
}

export default reduxForm({
    form: "feature_yourself_old", // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(FormPart7);
