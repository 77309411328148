import React, { Component } from "react";

import FormPart2 from "./individual-components/FormPart2";
import FormPart3 from "./individual-components/FormPart3";
import FormPart4 from "./individual-components/FormPart4";
import FormPart5 from "./individual-components/FormPart5";
import FormPart6 from "./individual-components/FormPart6";
import FormPart7 from "./individual-components/FormPart7";
import FormPart8 from "./individual-components/FormPart8";
import FormPart9 from "./individual-components/FormPart9";

import api, { getGETConfig } from '../../api';


class IndividualWrapper extends Component {
    state = {
        technologyAreas: [],
        cities: [],
    };

    componentDidMount() {
        this.getTechnologyAreas();
        this.getCitiesList();
    }

    // Fetch technology areas list
    getTechnologyAreas = () => {
        fetch(api.tech_areas, getGETConfig())
            .then(response => {
                if (!response || !response.ok) throw Error(response.status);
                return response.json();
            })
            .then(({ data }) => this.setState({ technologyAreas: data }))
            .catch(err => console.log('Error fetching technology areas', err));
    };

    getCitiesList = () => {
        const url = `${api.list_of_cities}?country=India`;

        fetch(url, getGETConfig())
            .then(response => {
                if (!response || !response.ok) throw Error(response.status);
                return response.json();
            })
            .then(({ data }) => this.setState({ cities: data }))
            .catch(err => console.log('Error fetching cities', err));
    };

    // abortController = new window.AbortController();
    // componentWillUnmount = () => this.abortController.abort();

    render() {
        return (
            <div className="feature_form__wrapper">
                <div className="feature_form__subhead">
                    STEP {this.props.formPart} OF {this.props.totalParts}
                </div>
                {this.props.formPart === 2 && (
                    <FormPart2
                        prevPage={this.props.prevPage}
                        onSubmit={this.props.nextPage}
                    />
                )}
                {this.props.formPart === 3 && (
                    <FormPart3
                        onSubmit={this.props.nextPage}
                        prevPage={this.props.prevPage}
                        technologyAreas={this.state.technologyAreas}
                    />
                )}
                {this.props.formPart === 4 && (
                    <FormPart4
                        onSubmit={this.props.nextPage}
                        prevPage={this.props.prevPage}
                        cities={this.state.cities}
                    />
                )}
                {this.props.formPart === 5 && (
                    <FormPart5
                        onSubmit={this.props.nextPage}
                        prevPage={this.props.prevPage}
                        technologyAreas={this.state.technologyAreas}
                    />
                )}
                {this.props.formPart === 6 && (
                    <FormPart6
                        onSubmit={this.props.nextPage}
                        prevPage={this.props.prevPage}
                        technologyAreas={this.state.technologyAreas}
                    />
                )}
                {this.props.formPart === 7 && (
                    <FormPart7
                        onSubmit={this.props.nextPage}
                        prevPage={this.props.prevPage}
                        technologyAreas={this.state.technologyAreas}
                    />
                )}
                {this.props.formPart === 8 && (
                    <FormPart8
                        onSubmit={this.props.nextPage}
                        prevPage={this.props.prevPage}
                        technologyAreas={this.state.technologyAreas}
                    />
                )}
                {this.props.formPart === 9 && (
                    <FormPart9
                        prevPage={this.props.prevPage}
                        onSubmit={this.props.submitForm}
                    />
                )}
            </div>
        );
    }
}

export default IndividualWrapper;
