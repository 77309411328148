import React from 'react';
// import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import Input from '../ui/paymentFormFields/Input';
import validateProfileForm from '../../utils/validateProfileForm';

const EditProfileForm = props => {
  const handleOnKeyPress = event => {
      if (event.key === "Enter") event.preventDefault();
    };

  const { submitting, invalid, pristine, myRef } = props;

  return (
    <div>
      <form 
        onSubmit={props.handleSubmit}
        className='edit-profile-form'
        onKeyPress={handleOnKeyPress}
        initialValues={props.initialValues}
      >

        <div className="flex-inputs-wrapper">
          <Field
            component={Input}
            name="firstName"
            label="First Name"
            placeholder="First Name"
            labelClass="payment__field-label"
            inputClass="payment__field-input"
            wrapperClass="payment__field-wrapper edit-profile-field-wrapper--name"
            errorClass="payment__field-error"
            myRef={myRef}
          />

          <Field
            component={Input}
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            labelClass="payment__field-label"
            inputClass="payment__field-input"
            wrapperClass="payment__field-wrapper edit-profile-field-wrapper--name"
            errorClass="payment__field-error"
          />
        </div>
        <Field
          component={Input}
          name="designation"
          label="Designation"
          placeholder="Designation"
          labelClass="payment__field-label"
          inputClass="payment__field-input"
          wrapperClass="payment__field-wrapper edit-profile-field-wrapper--email"
          errorClass="payment__field-error"
        />
          <Field
          component={Input}
          name="company"
          label="Company"
          placeholder="Company"
          labelClass="payment__field-label"
          inputClass="payment__field-input"
          wrapperClass="payment__field-wrapper edit-profile-field-wrapper--email"
          errorClass="payment__field-error"
        />
        <Field
          component={Input}
          name="email"
          label="Email Adress"
          placeholder="Email Address"
          labelClass="payment__field-label"
          inputClass="payment__field-input"
          wrapperClass="payment__field-wrapper edit-profile-field-wrapper--email"
          errorClass="payment__field-error"
          disabled
        />
        <Field
          component={Input}
          name="phoneNo"
          label="Phone No."
          placeholder="Phone No."
          labelClass="payment__field-label"
          inputClass="payment__field-input"
          wrapperClass="payment__field-wrapper edit-profile-field-wrapper--email"
          errorClass="payment__field-error"
          disabled
        />
         {/* <Field
          component={Input}
          name="phoneNo"
          label="Phone No."
          type="number" 
          placeholder="Phone No."
          labelClass="payment__field-label"
          inputClass="payment__field-input"
          wrapperClass="payment__field-wrapper edit-profile-field-wrapper--email"
          errorClass="payment__field-error"
          disabled
  />*/}

        <button 
          disabled={invalid || submitting || pristine}
          type="submit"
          className='edit-profile-form__submit-btn'
          style={{zIndex: "0"}}
        >SAVE CHANGES</button>
      </form>
    </div>
  )
}

export default reduxForm({
  form: 'edit_profile',
  enableReinitialize: true,
  validate: validateProfileForm
})(EditProfileForm);
