import React, { Fragment } from "react";
import { Field } from "redux-form";

// Components
import FormInputField from "./FormInputField";
import FormTextareaField from "./FormTextAreaField";

import TechAreaCapTag from "./TechAreaCapTag";

import saperatorImg from "../../../img/form-imgs/object-separator.svg";

const ProductServiceItem = ({
    product,
    fields,
    index,
    singular,
    technologyAreas,
    dispatch,
    formType,
}) => (
        <div className="form__products-item">
            <div className="feature_form__part_head">Description</div>
            {/* <div className="feature_form__part_head" style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <p>Description</p>

                <button type="button" onClick={() => {
                    dispatch(
                        clearFields(
                            'feature_yourself_old',
                            false,
                            false,
                            product
                        )
                    );
                }}>Reset {singular}</button>
            </div> */}
            <Field
                name={`${product}.name`}
                component={FormInputField}
                type="text"
                label={`${singular} name`}
                placeholder="E.g. Remote Sensing Satellite"
                tabable={true}
            />
            <Field
                name={`${product}.url`}
                component={FormInputField}
                widthClass="form__field-wrapper--60"
                label="URL"
                placeholder={`Please provide the sub-site of the ${singular}`}
                tabable={true}
            />
            <Field
                name={`${product}.about_product`}
                component={FormTextareaField}
                label="Brief Description"
                placeholder="Enter minimum 120 characters"
                tabable={true}
            />

            <TechAreaCapTag
                technologyAreas={technologyAreas}
                arrayName={`${product}.technology_type`}
                singular={singular}
                dispatch={dispatch}
            />

            <div className="form__products-entitlements">
                <div className="feature_form__part_head">Rights/Entitlements</div>
                <Field
                    name={`${product}.associations`}
                    component={FormInputField}
                    widthClass="form__field-wrapper--100"
                    label="Associations"
                    optional={true}
                    tabable={true}
                />
                <Field
                    name={`${product}.patent_url`}
                    component={FormInputField}
                    placeholder="Enter the URL of the patent granted (if any)"
                    label="Patent Url"
                    optional={true}
                    tabable={true}
                />
                <Field
                    name={`${product}.tagging`}
                    component={FormInputField}
                    widthClass="form__field-wrapper--60"
                    label="Product Tags"
                    optional={true}
                    tabable={true}
                />
            </div>

            {/* {formType === "company" && (
                <div className="form__products-oppurtunities">
                    <div className="feature_form__part_head">Opportunity</div>
                    <FieldArray
                        name={`${product}.competitors`}
                        component={renderCompetitors}
                    />
                </div>
            )} */}

            <div className="form__row">
                <Field
                    name={`${product}.future_plan`}
                    component={FormTextareaField}
                    widthClass="form__field-wrapper--100"
                    label="Future Plans and Targets"
                    optional={true}
                    tabable={true}
                />
            </div>
            <div className="form__row">
                <Field
                    name={`${product}.global_baseline`}
                    component={FormTextareaField}
                    widthClass="form__field-wrapper--100"
                    label="Global Baseline"
                    optional={true}
                    tabable={true}
                />
            </div>
            {fields.length > 1 && (
                <div className="feature_form__field-array-remove">
                    <span onClick={() => fields.remove(index)}>Remove</span>
                </div>
            )}
            {fields.length > index + 1 && (
                <div className="feature_form__saperator-img-wrapper">
                    <img src={saperatorImg} alt="Seperator" />
                </div>
            )}
        </div>
    );

// eslint-disable-next-line
const renderCompetitors = ({ fields, singular, technologyAreas }) => (
    <Fragment>
        {fields.map((competitor, index) => (
            <div
                key={index}
                className="form__row form__products-oppurtunities-competitors"
            >
                <Field
                    name={`${competitor}.name`}
                    component={FormInputField}
                    type="text"
                    label="Competitor's Name"
                    tabable={true}
                />
                <Field
                    type="text"
                    name={`${competitor}.url`}
                    component={FormInputField}
                    label="URL"
                    tabable={true}
                />
                {fields.length > 1 && (
                    <div className="feature_form__field-array-remove">
                        <span onClick={() => fields.remove(index)}>Remove</span>
                    </div>
                )}
            </div>
        ))}
        <div className="feature_form__field-array-add">
            <span onClick={() => fields.push({})}>+ Add Another</span>
        </div>
    </Fragment>
);

export default ProductServiceItem;
