import React from 'react';
import { Link } from 'react-router-dom';


const ExpandableFollowingList = (props) => {
  let itemButton = null;
  let itemLocation = null;
  let itemLink = null;
// console.log(props.items)
  const listItems = props.items.map(item => {
 

 
    itemButton = (
      <button
        className="expFollList__item-btn"
        onClick={props.handleClick(props.isCompany ? item.id : item.name)}
      >Unfollow</button>
    );

    itemLocation = props.isCompany && item.location ? (
      <p className='expFollList__item-location'
      >{item.location}</p>
    ) : null;

    itemLink = props.isCompany 
      ? `/${item.route}/${item.name.replace("%", "percentage").replace(/ /g, "-").replaceAll(".", "").replace("---", "-")}` 
      : `/technology/${item.name.replace(/ /g, "-")}`;

    return (
      <Link 
      onContextMenu={() => {props.isCompany ? window.localStorage.setItem('entity',item.id) : window.localStorage.setItem('techarea',item.name)}}
      onClick={() => {props.isCompany ? window.localStorage.setItem('entity',item.id) : window.localStorage.setItem('techarea',item.name)}}
        key={item.name} 
        to={itemLink} 
      >
        <div className="expFollList__desc-card-top"> 
        <div className="expFollList__item-text">
          <p className='expFollList__item-heading'>{item.name}</p>
          {itemLocation}
        </div>
        {itemButton}
        </div>
      </Link>
    );
  })

  return (
    <div className='expFollList__wrapper'>
      <p className='expFollList__heading'
      >{props.heading} <span>{`(${props.totalItems})`}</span></p>
      <div className='expFollList__items-wrapper'>
        <div  className='expFollList__item'>
        {listItems}
        {props.showToggleButton && <button
        type='button'
        className='expFollList__toggle-btn'
        onClick={props.toggleList(props.isCompany ? 'company' : 'technology')}
      >See {props.items.length < props.totalItems ? 'More' : 'Less'}</button>}  
</div>
     
</div>       
    </div>
  )

}

export default ExpandableFollowingList;