import React from "react";

const FormTextAreaField = props => {
    let cls = `feature_form__input-field-wrapper ${
        props.widthClass ? props.widthClass : ""
    }`;

   
    return (
        <div className={cls}>
            <label className="feature_form__input-label">{props.label}</label>
            <textarea
                {...props.input}
                className={`feature_form__text-area`}
                type="text"
                name={props.name}
                placeholder={props.placeholder}
                tabIndex={!props.tabable ? "-1" : ""}
            />
            <div className="feature_form__field-error">
                {props.meta && props.meta.touched ? props.meta.error : ""}
            </div>
        </div>
    );
};

export default FormTextAreaField;
