import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import ExpandableFollowingList from './ExpandableFollowingList';

import "../../css/Dashboard.css";
import notFollowingImage from '../../img/not-following.svg';
import sprite from "../../img/payment-icons-sprite.svg";

const FollowingComponent = ({
  companiesToShow,
  techAreasToShow,
  totalCompanies,
  totalTechAreas,
  unfollowCompany,
  unfollowTechnology,
  showCompListToggle,
  showTechListToggle,
  toggleList,
  currentCredits,
  expirationDate
}) => {

  const companiesList = companiesToShow && companiesToShow.length ?
    <ExpandableFollowingList
      isCompany
      items={companiesToShow}
      heading='Following Companies'
      handleClick={unfollowCompany}
      totalItems={totalCompanies}
      showToggleButton={showCompListToggle}
      toggleList={toggleList}
    /> : null;

  const technologiesList = techAreasToShow && techAreasToShow.length ?
    <ExpandableFollowingList
      items={techAreasToShow}
      heading='Following Tech Areas'
      handleClick={unfollowTechnology}
      totalItems={totalTechAreas}
      showToggleButton={showTechListToggle}
      toggleList={toggleList}
    /> : null;

  {/*let creditBalanceDisplay = (
    <p className='dashboard__credits-box-text--balance'
    >{currentCredits}</p>
  );
  if (currentCredits === -1) creditBalanceDisplay = (
    <Fragment>
      <p className='payment__available-credits-heading--unlimited'
      >Unlimited Credits</p>

      <p className="payment__available-credits-balance payment__available-credits-balance--unlimited"
      >{expirationDate}</p>
    </Fragment>
  );*/}

  //  Following content = expandle lists of companies and tech areas
  let followingContent = <Fragment>
    {companiesList}
    {technologiesList}
  </Fragment>;

  //  If user isn't following any comopany and any tech area
  //  Show empty state UI for not following 
  if (!totalCompanies && !totalTechAreas) {
    followingContent = (
      <div className='dashboard__not-following-wrapper'>
        <img src={notFollowingImage} alt='Not following anything' />
        <p className='dashboard__not-following-heading'>You are not following anything yet</p>
        <p className='dashboard__not-following-text'>
          Start following companies or technologies<br />
          and they will appear here once you do
        </p>
      </div>
    );
  }



  return (
    <div className='dashboard__following-wrapper'>
      <main className='dashboard__following-content'>
        {followingContent}
      </main>

      {/* <aside className='dashboard__following-side'>
        <div className='dashboard__credits-box'>
          <h3 className='dashboard__credits-box-heading'
          >Available credit balance</h3>

          <div className='dashboard__credits-box-subcontent'>
            <svg className='dashboard__credits-box-icon'>
              <use href={sprite + "#credits"} />
            </svg>
            <div className='dashboard__credits-box-text'>
              {creditBalanceDisplay}
              
              <p className='dashboard__credits-box-text--info'
              >Credits help you access more information & reports</p>

              {currentCredits !== -1 && <Link
                to='/payments'
                className='dashboard__credits-box-btn'
              >Buy Credits</Link>}

            </div>
          </div>
        </div>

        {// <div className='dashboard__my-company-box'>
          <div className='dashboard__my-company-box-header'>
            <h3 className='dashboard__my-company-box-heading'
            >My Company</h3>

            <button
              type='button'
              className='dashboard__my-company-box-header__add-button'
              // TODO onClick={doSomething()}
            >Add</button>
          </div>

          <div className='dashboard__my-company-box__companies-wrapper'>
            <MyCompanies />
          </div>
        </div> }
      </aside>*/}
    </div>
  )
};

export default FollowingComponent;