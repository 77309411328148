import React, { Component } from 'react';
import "../css/BrowsingTech.css";
//import Carousel from 'react-grid-carousel'
import download from "../img/event.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { httpfyUrl } from "../../src/utils/helpers";
import DateCountdown from 'react-date-countdown-timer';
import LazyLoad from 'react-lazy-load';
import BeatLoader from "react-spinners/BeatLoader";

class EventsWebinar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dateTime: "",
            eventState: ""
        }
    }
    handleId = (e) => {
        var eventId = e.target.id
        this.state.eventState = eventId
        //console.log(this.state.eventState)
    }

    createDate(date) {
        const index = date.indexOf("T")
        const time = date.slice(index)
        const newDate = date.slice(0, index)
        const res = newDate.replace("-", "/");
        const res1 = res.replace("-", "/")
        var strToDate = new Date(res1);
        var options = { year: 'numeric', month: 'long', day: 'numeric', time: 'numeric' };
        this.state.dateTime = strToDate.toLocaleDateString("en-US", options)
    }
    capitalize = () => {
        //var word = this.props.eventType
        //return word[0].toUpperCase() + word.slice(1).toLowerCase();
        return "upcoming"
    }
    openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    render() {

        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 5
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3
            },
            tablet: {
                breakpoint: { max: 1024, min: 624 },
                items: 2
            },
            mobile: {
                breakpoint: { max: 624, min: 0 },
                items: 1
            }
        };
//console.log(this.props.data)
        return (

            <div className={this.props.eventCss}>
                <p className='events_heading' >EVENTS AND WEBINARS</p>
                <div className='events-grid'>
                    <p className={this.props.activeState === 'upcoming' ? 'events_type active' : 'events_type'} id="upcoming" onClick={this.props.onChangeHandler}>UPCOMING </p> 
                    <p className={this.props.activeState === 'previous' ? 'events_type active' : 'events_type'} id="previous" onClick={this.props.onChangeHandler} style={{marginLeft:"1.4rem"}}>PREVIOUS</p>
                </div>
                {this.props.data && this.props.data.length > 0 ?
                    <Carousel responsive={responsive}>
                        {this.props.data && this.props.data.map((obj, i) => {
                            return (
                                <div className='events__carousel_container'>
                                    <div className='events__carousel'>
                                        <div>

                                            {this.createDate(obj.schedule_date)}
                                            <LazyLoad > 
                                            <img src={obj.thumbnail_url} className='events_img' alt="thumbnail image"/>
                                            </LazyLoad>
                                          {this.props.activeState === 'upcoming' &&
                                            <div className='events_bottom-right-image'>
                                                <DateCountdown dateTo={this.state.dateTime} />
                                                </div>
                                              }

                                            <div className='events__webinar__container'>
                                                <div className='events_webinar_content'>
                                                    <p className='card-text'>{obj.title}</p>
                                                    <p className='card-date'>{this.state.dateTime}</p>
                                                </div>
                                                {this.props.activeState === 'upcoming' ?
                                                    <a
                                                        href={httpfyUrl(obj.registration_url)}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <div className='events__btn'>
                                                            <button className='events__register_btn'>Register</button>
                                                        </div></a> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                        })
                        }
                    </Carousel> :
                    <div className='techarea__desc-card'>
                        {this.props.eventType === 'previous' || this.props.eventType===""?

                            <p className='events__no_data_heading' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '14vh'}}><BeatLoader color="#706b69" speedMultiplier={0.7} /></p> :
                            <>
                            <div className='techarea__card'>
                            <div>
                                <p className='techarea__no_data'>
                                    The {this.capitalize()} Event Webinar Information is coming soon!
                                </p>
                                <p className='events__no_data_text'>
                                    Once available, you can view the {this.capitalize()} Event Webinar
                                    information here.
                                    <br />
                                    Stay Tuned!
                                </p>
                                </div>
                                </div>
                            </>}

                    </div>}
            </div>
        );
    }
}

export default EventsWebinar;