import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import EntityCard from "./EntityCard";
import EntityCard2 from "./EntityCard2";

const techDescString = description => {
  if (!description || description.length === 0)
    return "No Description Available";

  if (description.length <= 203) return description;
  return description.substring(0, 200) + "...";
};

const PaginatedContainer = props => {
  let entityCardContent;
    
  //  Use EntityCard2 for Products
  if (props.resultType === "products") {

    entityCardContent =
      props.entities.length > 0 &&
      props.entities.map((entity, index) => (
        <EntityCard2
          key={index}
          entity={entity}
          authToken={props.authToken}
          toggleUserForm={props.toggleUserForm}
        />
      ));
  }

  //  Custom search_listing for Technologies

  else if (props.resultType === "tech_area") 
   
  { 
// console.log("entity type")
   
    let classes = ["search__listing-wrapper"];
    classes.push("col-xs-12 col-sm-6 col-lg-4");

    entityCardContent =
      props.entities.length > 0 &&
      props.entities.map((entity, index) => {
    // console.log(entity,index)
        return (
          <div key={index} className={classes.join(" ")}>
            {entity.name ?
              <Link
                to={{
                pathname: entity.keyword_type === "capability" 
                    ? `/technology/${entity.root.replace("%", "percentage").replaceAll(/ /g, "_")}/${entity.name.replace(/\ /g,"_").replace(/\//g, "--")
                      
                    }`
                    : `/technology/${entity.root!=null? entity.root.replace(/ /g, "_") : entity.name.replace(/ /g, "_")}`,
                  
                  state:{"capability":`${entity.name}`}
                }}
                className="search__listing search__listing--technology"
              >
                <p style={{ marginBottom: "0" }}>{entity.name}</p>
                <p className="search__listing--technology-desc">
                  {techDescString(entity.description)}
                </p>
              </Link> : ''}

          </div>
        )
      });

  }

  //  For all other entities
  else {
     
    entityCardContent =
      props.entities.length > 0 &&
      props.entities.map((entity, index) => (
        <EntityCard
          key={index}
          entity={entity}
          authToken={props.authToken}
          toggleUserForm={props.toggleUserForm}
        />
      ));
  }
  // console.log(props.loadNextPage)
  return (

    <Fragment>
      <div className="search__paginated-container">
        {entityCardContent}
      </div>
      {props.hasNextPage &&
        (props.isNextPageLoading ? (
          <div
            className="search__load-more"
            style={{ cursor: "default" }}
          >
            Loading...{" "}
          </div>
        ) : (
          <div
            className="search__load-more"
            onClick={props.loadNextPage}
          >
            Load More <i className="fas fa-angle-down" />
          </div>
        ))}
    </Fragment>
  );
};


export default PaginatedContainer;
