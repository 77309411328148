import React, { Fragment } from "react";
import { Field } from "redux-form";
import FormInputField from "./FormInputField";

import saperatorImg from "../../../img/form-imgs/object-separator.svg";

const ManagementHeadsSection = ({ fields, title }) => (
    <Fragment>
        <div className="feature__form-management__section-head">
            <div className="feature_form__part_head">{title}</div>
        </div>

        {fields.map(ManagementHead)}
        <div className="feature_form__field-array-add">
            <span onClick={() => fields.push({})}>+Add Another Person</span>
        </div>
    </Fragment>
);

const ManagementHead = (head, index, fields) => (
    <div key={index} className="feature__form-management__section-item">
        <Field
            type="text"
            name={`${head}.name`}
            component={FormInputField}
            widthClass="form__field-wrapper--100"
            label="Name"
            placeholder="Eg. John snow"
            tabable={true}
        />
        <Field
            type="text"
            name={`${head}.email`}
            component={FormInputField}
            widthClass="form__field-wrapper--100"
            label="Email"
            placeholder="Eg. john.snow@gmail.com"
            tabable={true}
        />
        <Field
            type="text"
            name={`${head}.number`}
            component={FormInputField}
            label="Mobile No."
            placeholder="10 digit mobile no."
            tabable={true}
        />
        <Field
            type="text"
            name={`${head}.linkedin`}
            component={FormInputField}
            label="LinkedIn profile"
            placeholder="Eg. www.linkedin.com/profile/"
            tabable={true}
        />
        {fields.length > 1 && (
            <div className="feature_form__field-array-remove">
                <span onClick={() => fields.remove(index)}>Remove</span>
            </div>
        )}
        {fields.length > index + 1 && (
            <div className="feature_form__saperator-img-wrapper">
                <img src={saperatorImg} alt="Saperator" />
            </div>
        )}
    </div>
);

export default ManagementHeadsSection;
