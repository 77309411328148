// techTypeUtils.js
const prepareTechnologyTypeString = (techTypeArray) => {
    const unique = [];
    techTypeArray.map(x => unique.filter(a => a.name === x.name).length > 0 ? null : unique.push(x));
  
    var techArea = []
    var techArea1 = []
    for (let i = 0; i < unique.length; i++) {
      if ("capability_type" in unique[i]) {
        techArea.push(unique[i]["name"]);
      } else {
        techArea1.push(unique[i]["name"])
        techArea1 = techArea1.slice(0, 2)
      }
    }
    const finalTechArea = techArea.concat(techArea1)
  
    return finalTechArea.toString().replaceAll(",", " , ");
  };
  
  export default prepareTechnologyTypeString;
  