import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import EntityCard from "../searchresults-components/EntityCard";

// Slick carousel themes
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
// Custom Carousel Styling
import "../../css/SimilarEntitiesCarousel.css";

const CustomNextArrow = props => {
    const { className, style, onClick } = props;

    return (
        <div
            onClick={onClick}
            className={className}
            style={{
                ...style,
                display: "block",
                backgroundColor: "#81858c",
                borderRadius: "50%",
            }}
        />
    );
};

const CustomPrevArrow = props => {
    const { className, style, onClick } = props;

    return (
        <div
            className={className}
            onClick={onClick}
            style={{
                ...style,
                backgroundColor: "#81858c",
                display: "block",
                borderRadius: "50%",
            }}
        />
    );
};

class SimilarEntitiesCarousel extends Component {
    constructor(props) {
        super(props);
        this.carouselRef = React.createRef();
    }

    sliderOptions = {
        speed: 1000, //  Animation duration speed
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000, //  Autoplay every 5 seconds
        pauseOnHover: true,
        slidesPerRow: 3,
        swipeToSlide: true,
        arrows: true,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    slidesPerRow: 2,
                },
            },
            {
                breakpoint: 650,
                settings: {
                    slidesPerRow: 1,
                    dots: false,
                    arrows: true,
                },
            },
        ],
        // fade: true,
    };

    render() {
        // Map and transform the similar entites here
        // console.log(this.props.carouselItems);
        const carouselItems =
            this.props.carouselItems && this.props.carouselItems.length > 0
                ? this.props.carouselItems.map((entity, index) => (
                      <div key={index}>
                          <EntityCard
                              skipBootstrapGridClasses
                              entity={entity}
                          />
                      </div>
                  ))
                : null;

        return (
            <Fragment>
                <Slider ref={this.carouselRef} {...this.sliderOptions}>
                    {carouselItems}
                </Slider>
            </Fragment>
        );
    }
}

export default SimilarEntitiesCarousel;
