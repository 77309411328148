import React, { Component } from 'react';
import "../css/BrowsingTech.css";
import { httpfyUrl } from "../../src/utils/helpers";
import '../css/Cards.css';
import { getFormattedDateTime } from '../components/helper-functions/helper'
 
class Spotlight extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dateTime: "",
            count: "",

        }
    }
   
   
    render() {
        //console.log(this.props.data)
        
        return (
            
        
            <div className='techarea_webinar'>
                <p className='techarea_heading'>{this.props.title}({this.props.count})</p>
                {this.props.data && this.props.data.length > 0 ?
                <div>
                {this.props.data &&
                    this.props.data.map(
                        (obj, index) => (
                            <div
                            className="techarea__desc-card"
                            
                        >
                            <div className="techarea__desc-card-top">
                                <div className="techarea__desc-card-left">
                                    <div className="techarea__desc-card-head">{obj.title}</div>
                                   {obj.publish_date && <p className='techarea__desc-card-date'>{getFormattedDateTime(obj.publish_date)}</p>}
                                </div>
                                {obj.file_url &&
                                <div>
                                    <a
                                        href={httpfyUrl(obj.file_url)}
                                        className="techarea__desc-card-link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fas fa-file-pdf" style={{color:"#F40F02",fontSize:"2rem"}} />
                                    </a></div>}
                            </div>

                        </div>
                        )
                    )}
                    </div>: <div
                            className="techarea__desc-card"
                             
                        >
                            <div className='techarea__card'>
                                <div>
                                    <p className='techarea__no_data'>
                                        The {this.props.title} Information is coming soon!
                                    </p>
                                    <p>
                                        Once available, you can view the {this.props.title}
                                        information here.
                                        <br />
                                        Stay Tuned!
                                    </p>
                                </div>
                            </div>
                        </div>}
                     
                {this.props.count > 10 && this.props.pageCount!=this.props.totalPage?
                <div> 
                    <button className='button-spotlight' onClick={this.props.onChangeHandler}>SEE MORE</button>
                    </div>: ''}
            </div>
              
            
        );
    }
}

export default Spotlight;