import React, { Component } from 'react'
import "../../css/SummaryGlance.css";
import arrow from '../../img/card-arrow.svg'
import { Link } from "react-router-dom";
 
export class SummaryGlance extends Component {

  
  render() {
     
    return (
      
      <div className='summaryGlance'>
      
       <Link
       
         to={{

            pathname: "/search/",
            search: `?keyword=${this.props.url}&resultType=${this.props.search}`,
         
          // pathname: "/searchresults",
          // search: `?q=${encodeURIComponent(
          //   JSON.stringify({
          //     search_type: "search",
          //     keyword: this.props.name,
          //     resultType: this.props.search,
          //   })
          // )}`,
         }}
        >
        <div className='summaryGlance__topbar'>
      
          <div className='summaryGlance__text-sec'>
            <div className='summaryGlance__main-text'>{this.props.name}</div>
            {/*<div className='summaryGlance__text'>{this.props.data.recently_added_count} newly added</div>*/}
          </div>
          {/* <div className="summaryGlance__arrow-img">
            <img src={arrow} alt="Go" />
          </div> */}
        </div>
       
        <div className='summaryGlance__bottambar'>
          <div>
            <img className="home__area__card__img"
              src={this.props.logo}
              alt="Next Generation Computing"
            />
          </div>

          <span className='summaryGlance__value'>
            {this.props.data.total_count}
          </span>
 
        </div>
 </Link> 
      </div>

    )
  }
}

export default SummaryGlance