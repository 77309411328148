const validate = values => {
  const errors = {};
  const passwordRegex = /^(?=.*\d)(?=.*[#$@!%&._*])[A-Za-z\d#$@!%&._*]{8,16}$/;
  
  if (!values.currentPassword || !values.currentPassword.trim()) {
    errors.currentPassword = 'Please enter your current password';
  }
  else if (!passwordRegex.test(values.currentPassword)){
    errors.currentPassword = 'Password must be between 8-16 characters, and include one special character and number'
  }

  if (!values.newPassword || !values.newPassword.trim()) {
    errors.newPassword = 'Please enter the new password';
  } else if (!passwordRegex.test(values.newPassword)) {
    errors.newPassword = 'Password must be between 8-16 characters, and include one special character and number';
  } 

  if (!values.confirmPassword || !values.confirmPassword.trim()) {
    errors.confirmPassword = 'Please confirm your new password';
  }

  //  Match passwords
  if (!errors.newPassword && !errors.confirmPassword && (
    values.newPassword !== values.confirmPassword
  )) {
    errors.newPassword = 'Passwords do not match';
    errors.confirmPassword = 'Passwords do not match';
  }

  if (!errors.newPassword && !errors.confirmPassword && (
    values.currentPassword === values.newPassword
  )) {
    //errors.newPassword = 'New password cannot be the same as current password';
    errors.newPassword = "Your new password must be unique and different from your current choice."
  }

  return errors;
}

export default validate;