import React, { Component } from "react";
import { Link } from "react-router-dom";

import { httpfyUrl } from "../../utils/helpers";

import CardsWithFilters from "./CardsWithFilters";
import locationIcon from '../../img/location-light.svg';
import About from "./About";
import SimilarEntitiesCarousel from "./SimilarEntitiesCarousel";

import "../../css/EntityView.css";
import OfferingsTab from "./OfferingsTab";

class RnDWrapper extends Component {
  // state = {
  //   currentMenuItem: "offerings",
  // };

  state = {
    currentMenuItem: (
      (this.props.details.hasOwnProperty("products") && Array.isArray(this.props.details.products) && this.props.details.products.length > 0) ||
      (this.props.details.hasOwnProperty("services") && Array.isArray(this.props.details.services) && this.props.details.services.length > 0) ||
      (this.props.details.hasOwnProperty("solutions") && Array.isArray(this.props.details.solutions) && this.props.details.solutions.length > 0) 
    )
    ? "offerings" : "about"
  };

  changeCurrentMenuItem = item => event => {

    this.setState({ currentMenuItem: item });
  };

  getOfferingsTotal = () => {
    //  Temporary references to the offerings
    const prods = this.props.details.products;
    const servs = this.props.details.services;
    const sols = this.props.details.solutions;

    const result =
      (prods && prods.length > 0 ? prods.length : 0) +
      (servs && servs.length > 0 ? servs.length : 0) +
      (sols && sols.length > 0 ? sols.length : 0);

    return result > 0 ? `(${result})` : "";
  };

  render() {
    const filteredProjects = this.props.details.products.filter(item => item.name);
    const filteredWorkshops = this.props.details.services.filter(item => item.name);
    document.title = `${this.props.details.name} - Technology Capability Repository || DSCI`;
    const path = this.props.path
    // document.getElementsByTagName("LINK")[0].href=  `https://techsagar.in/rd-centre/${this.props.details.name.replace("%", "percentage").replace(/ /g, "-").replaceAll(".","").replace("---","-").replace(",","").toLowerCase()}`
    document.getElementsByTagName("LINK")[0].href = ["company", "institute", "rd-centre", "researcher"].includes(this.props.path[1]) ? `https://www.techsagar.in` : `https://www.techsagar.in/technology/${path[1].replaceAll("_", "-")}`
    // document.getElementsByTagName("LINK")[0].href=  `https://techsagar.in`

    return (
      <div className="entityview__wrapper">
        <div className="entityview__top">
          <div className="entityview__top-desc-redesign-wrapper">
            {this.props.path.length === 4 ?
              <div className="entityview__top-breadcrumb">
                <Link to="/">Home</Link> / {" "}
                {<Link to={{ pathname: `/technology/${this.props.path[1].replace(/ /g, "_")}` }}>
                  {this.props.path[1].replaceAll("_", "")}
                </Link>
                }{" "}/
                {<Link to={{ pathname: `/technology/${this.props.path[1].replaceAll(/ /g, "_")}/${this.props.path[2].replaceAll('_', " ")}` }}>
                  {this.props.path[2].replaceAll("_", " ").replace("12", "/")}
                </Link>
                }{" "}/
                {this.props.details.name}
              </div> :

              this.props.path.length === 3 && this.props.path[1] != "rd-centre" ?
                <div className="entityview__top-breadcrumb">
                  <Link to="/">Home</Link> / {" "}
                  {<Link to={{ pathname: `/technology/${this.props.path[1].replaceAll(/ /g, "_")}` }}>
                    {this.props.path[1].replaceAll("_", " ")}
                  </Link>
                  }{" "}/{this.props.details.name}
                </div> :
                this.props.path[1] === "rd-centre" ?
                  <div className="entityview__top-breadcrumb">
                    <Link to="/">Home</Link> / {this.props.details.name}
                  </div> : <></>}
            <div>
              <p className="entityview__top-head">
                {this.props.details.name}
              </p>
              <div className="entityview__subhead">
                <div className="entityview__subhead-section entityview__stats-section">
                  {this.props.details.address && (
                    <div className="entityview__subhead-desc">
                      <img src={locationIcon} alt='briefcase icon' className='entityview__subhead-desc-icon' />
                      {this.props.details.address.city}
                    </div>
                  )}
                </div>
                <div className="entityview__subhead-section entityview__subhead__buttons">
                  <div
                    className="entityview__subhead-btn"
                    onClick={this.props.toggleModal}
                  >
                    <i className="fas fa-share-alt" />
                  </div>
                  {this.props.details.url && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={httpfyUrl(
                        this.props.details.url
                      )}
                      className="entityview__subhead-btn"
                    >
                      <i className="fas fa-globe" />
                      &nbsp; Website
                    </a>
                  )}
                  {this.props.followButton}
                </div>
              </div>
            </div>
          </div>
          <div className="entityview__top-nav">

          {
              (
                (this.props.details.hasOwnProperty("products") && Array.isArray(this.props.details.products) && this.props.details.products.length > 0) ||
                (this.props.details.hasOwnProperty("services") && Array.isArray(this.props.details.services) && this.props.details.services.length > 0) ||
                (this.props.details.hasOwnProperty("solutions") && Array.isArray(this.props.details.solutions) && this.props.details.solutions.length > 0) 
              )
              &&
            <div
              className={`entityview__top-nav-btn ${this.state
                .currentMenuItem === "offerings" &&
                "entityview__top-nav-btn--active"}`}
              onClick={this.changeCurrentMenuItem(
                "offerings"
              )}
            >
              OFFERINGS {this.getOfferingsTotal()}
            </div>
          }



            {/* <div
              className={`entityview__top-nav-btn ${this.state
                .currentMenuItem === "projects" &&
                "entityview__top-nav-btn--active"}`}
              onClick={this.changeCurrentMenuItem("projects")}
            >
              {`PROJECTS ${
                filteredProjects && filteredProjects.length
                  ? "(" + filteredProjects.length + ")"
                  : ""
                }`}
            </div>
            <div
              className={`entityview__top-nav-btn ${this.state
                .currentMenuItem === "workshops" &&
                "entityview__top-nav-btn--active"}`}
              onClick={this.changeCurrentMenuItem("workshops")}
            >
              {`WORKSHOPS ${
                filteredWorkshops && filteredWorkshops.length
                  ? "(" + filteredWorkshops.length + ")"
                  : ""
                }`}
            </div> */}
            <div
              className={`entityview__top-nav-btn ${this.state
                .currentMenuItem === "about" &&
                "entityview__top-nav-btn--active"}`}
              onClick={this.changeCurrentMenuItem("about")}
            >PROFILE</div>

            {
              (this.props.details.hasOwnProperty("patents") && Array.isArray(this.props.details.patents) && this.props.details.patents.length > 0)
              &&
              <div
                className={`entityview__top-nav-btn ${this.state
                  .currentMenuItem === "patents" &&
                  "entityview__top-nav-btn--active"}`}
                onClick={this.changeCurrentMenuItem("patents")}
              >
                {`PATENTS ${this.props.details.patents &&
                    this.props.details.patents.length > 0
                    ? "(" +
                    this.props.details.patents.length +
                    ")"
                    : ""
                  }`}
              </div>
            }

            {/* <div
              className={`entityview__top-nav-btn ${this.state
                .currentMenuItem === "recognitions" &&
                "entityview__top-nav-btn--active"}`}
              onClick={this.changeCurrentMenuItem("recognitions")}
            >
              {`RECOGNITIONS ${
                this.props.details.recognitions &&
                  this.props.details.recognitions.length > 0
                  ? "(" +
                  this.props.details.recognitions.length +
                  ")"
                  : ""
                }`}
            </div>
            <div
              className={`entityview__top-nav-btn ${this.state
                .currentMenuItem === "blogs" &&
                "entityview__top-nav-btn--active"}`}
              onClick={this.changeCurrentMenuItem("blogs")}
            >
              {`BLOGS ${
                this.props.details.blogs &&
                  this.props.details.blogs.length > 0
                  ? "(" +
                  this.props.details.blogs.length +
                  ")"
                  : ""
                }`}
              </div>*/}

          </div>
        </div>
        {this.state.currentMenuItem === "offerings" && (
          <OfferingsTab
            isRnD
            title="Offerings"
            technologyAreas={this.props.details.technology_type}
            products={filteredProjects}
            services={filteredWorkshops}
          // items={filteredProjects}
          />
        )}
        {/*{this.state.currentMenuItem === "projects" && (
          <CardsWithFilters
            title="Projects"
            technologyAreas={this.props.details.technology_type}
            items={filteredProjects}
          />
        )}
         {this.state.currentMenuItem === "workshops" && (
          <CardsWithFilters
            title="Workshops"
            technologyAreas={this.props.details.technology_type}
            items={filteredWorkshops}
          />
        )}
        
        {this.state.currentMenuItem === "recognitions" && (
          <CardsWithFilters
            title="Recognitions"
            technologyAreas={this.props.details.technology_type}
            disableFilters={true}
            items={this.props.details.recognitions}
          />
        )}
        {this.state.currentMenuItem === "blogs" && (
          <CardsWithFilters
            title="Blogs"
            technologyAreas={this.props.details.technology_type}
            disableFilters={true}
            items={this.props.details.blogs}
          />
        )}*/}
        {this.state.currentMenuItem === "patents" && (
          <CardsWithFilters
          type = 'r_and_D'
            title="Patents"
            technologyAreas={this.props.details.technology_type}
            disableFilters={true}
            items={this.props.details.patents}
          />
        )}
        {this.state.currentMenuItem === "about" && (
          <About
            title="Recognitions"
            technologyAreas={this.props.details.technology_type}
            disableFilters={true}
            news={this.props.details.news}
            items={this.props.details.recognitions}
            showStats={false}
            showGeographies={true}
            name={this.props.details.name}
            about={this.props.details.about}
            businessHeads={this.props.details.business_heads}
            sectorsServed={this.props.details.sectors_served}
            geographiesServed={
              this.props.details.geographies_served
            }
            // Contact information
            url={this.props.details.url}
            address={this.props.details.address}
            contactPerson={this.props.details.contact_person}
          />
        )}

        {this.props.similarEntities && this.props.similarEntities.length &&
          <div className="entityview__similar-wrapper">
            <div className="entityview__similar-head">
              Similar R&D Centers
            </div>
            <SimilarEntitiesCarousel
              carouselItems={this.props.similarEntities}
            />
          </div>}

      </div>
    );
  }
}

export default RnDWrapper;
