import React from "react";
import Select from "react-select";

const customStyles = {
    control: provided => ({
        // none of react-select's styles are passed to <Control />
        ...provided,
        background: "none",
        outline: "none",
        // height: "5rem",
        paddingLeft: "1.4rem",
        maxWidth: "100%",
        border: "1px solid rgba(60, 70, 92, 0.15)",
        "border-radius": "4px",
    }),
    option: provided => ({
        ...provided,
        cursor: "pointer",
    }),
    menuList: provided => ({
        ...provided,
        // height: "20rem",
    }),
};

const FormSelect = props => {
  //console.log('formselect props = ', props);
  //console.log(props.meta)
   
    let cls = `form__input-field-wrapper ${props.widthClass}`;
    return (
        <div className={cls}>
            {/* <pre>{JSON.stringify(props, 0, 2)}</pre> */}
            <div className="form__label-wrapper">
                <p className="form__label">{props.placeholder}</p>
            </div>
            <Select
                styles={customStyles}
                className="form__select1"
                options={props.options}
                {...props.selectConfig}
                placeholder={`Select ${props.placeholder}`}
                name={props.name}
                value={props.input.value}
                theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: "#ddd",
                        primary: "#444",
                    },
                })}
                onFocus={e => {
                    props.input.onFocus(e);
                }}
                onBlur={e => {
                    // props.input.onBlur(e);
                    // props.input.onBlur(props.input.value);
                    e.preventDefault();
                }}
                onChange={e => {
                    props.input.onChange(e);
                }}
            />
            <div className="form__input-field-error">
                {props.meta && props.meta.touched ? props.meta.error : ""}
            </div>
        </div>
    );
};

export default FormSelect;
