import React from "react";

import TabInvestor from "./tabs/TabInvestor";

const IndividualInvestor = ({
  data,
  followButton,
  currentMenuItem,
  changeCurrentMenuItem,
}) => {
  return (
    <div>
      <div className="entityview__top">
        <div className="entityview__top-desc-wrapper">
          <div className="entityview__top-breadcrumb"
          >Home / Rajeev Bajaj</div>

          <div>
            <p className="entityview__top-head">Rajeev Bajaj</p>

            <div className="entityview__subhead">
              <div className="entityview__subhead-section entityview__stats-section">
                {/* // TODO -- add checks for address, type and size and then only render  */}
                <div className="entityview__subhead-desc">
                  <i className="fas fa-map-marker-alt entityview__subhead-desc-icon" />
                  New Delhi
                </div>

                <div className="entityview__subhead-desc">
                  <i className="fas fa-briefcase entityview__subhead-desc-icon" />
                  Chief Executive Officer (CEO)
                </div>

                <div className="entityview__subhead-desc">
                  <i className="fas fa-users entityview__subhead-desc-icon" />
                  Nexus Ventures Private Limited
                </div>
              </div>

              <div className="entityview__subhead-section entityview__subhead__buttons">
                <div
                  className="entityview__subhead-btn"
                //  onClick={this.props.toggleModal}
                >
                  <i className="fas fa-share-alt" />
                </div>
                {followButton}
              </div>
            </div>
          </div>
        </div>

        <div className="entityview__top-nav">
          <div
            onClick={changeCurrentMenuItem.bind(null, "investor")}
            className={`entityview__top-nav-btn ${currentMenuItem ===
              "investor" && "entityview__top-nav-btn--active"}`}
          >INVESTOR</div>

          <div
            onClick={changeCurrentMenuItem.bind(null, "profile")}
            className={`entityview__top-nav-btn ${currentMenuItem ===
              "profile" && "entityview__top-nav-btn--active"}`}
          >PROFILE</div>

          <div
            onClick={changeCurrentMenuItem.bind(null, "blogs")}
            className={`entityview__top-nav-btn ${currentMenuItem ===
              "blogs" && "entityview__top-nav-btn--active"}`}
          >BLOGS</div>

          <div
            onClick={changeCurrentMenuItem.bind(null, "about")}
            className={`entityview__top-nav-btn ${currentMenuItem ===
              "about" && "entityview__top-nav-btn--active"}`}
          >ABOUT</div>

          <div
            onClick={changeCurrentMenuItem.bind(null, "contact")}
            className={`entityview__top-nav-btn ${currentMenuItem ===
              "contact" && "entityview__top-nav-btn--active"}`}
          >CONTACT</div>
        </div>
      </div>
      {/* End of entityview__top */}

      {currentMenuItem === "investor" && <TabInvestor data={data} />}
    </div>
  );
};

export default IndividualInvestor;
