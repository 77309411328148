import React, { Component, Fragment } from "react";
import { Field, FieldArray, FormSection, reduxForm } from "redux-form";

import FormInputField from "../form-elements/FormInputField";
import FormTextAreaField from "../form-elements/FormTextAreaField";
import FormSelect from "../form-elements/FormSelect";

import validate from "../utils/validate";

import facebookInputIcon from "../../../img/form-imgs/facebook.svg";
import twitterInputIcon from "../../../img/form-imgs/twitter.svg";
import linkedinInputIcon from "../../../img/form-imgs/linkedin.svg";

class FormPart3 extends Component {
    render() {
        const { handleSubmit } = this.props;
        const onKeyPress = event => {
            if (event.key === "Enter") {
                event.preventDefault();
            }
        };

        return (
            <Fragment>
                <p className="feature_form__head">Contact Details</p>
                <form
                    onSubmit={handleSubmit(this.props.onSubmit)}
                    onKeyPress={onKeyPress}
                >
                    <div className="feature_form__inner-wrapper">
                        <FormSection name="address">
                            <Field
                                name="line1"
                                // widthClass="col-md-6"
                                component={FormTextAreaField}
                                type="text"
                                placeholder="Building number, street address etc."
                                label="Office Address"
                                tabable={true}
                            />
                            <Field
                                name="pin_code"
                                // widthClass="col-md-6"
                                component={FormInputField}
                                type="text"
                                label="Pincode"
                                tabable={true}
                            />
                            <Field
                                name="city"
                                component={FormSelect}
                                placeholder="Select"
                                label="City"
                                valueType="string"
                                options={this.props.cities.map(city => ({
                                    label: city.name,
                                    value: city.name,
                                }))}
                            />
                        </FormSection>
                        <FormSection name="contact_person">
                            <FieldArray
                                name="persons"
                                component={renderContactPersons}
                            />
                            <hr />
                            <Field
                                name="facebook"
                                component={FormInputField}
                                label="Facebook URL"
                                img={facebookInputIcon}
                                tabable={true}
                            />
                            <Field
                                name="twitter"
                                component={FormInputField}
                                label="Twitter URL"
                                img={twitterInputIcon}
                                tabable={true}
                            />
                            <Field
                                name="linkedin"
                                component={FormInputField}
                                label="Linkedin URL"
                                img={linkedinInputIcon}
                                tabable={true}
                            />
                        </FormSection>
                        {/* ==== */}
                    </div>
                    <div className="feature_form__btn-wrapper">
                        <button
                            className="feature_form__btn feature_form__btn-back"
                            onClick={this.props.prevPage}
                        >
                            GO BACK
                        </button>

                        <button type="submit" className="feature_form__btn">
                            CONTINUE
                        </button>
                    </div>
                </form>
            </Fragment>
        );
    }
}

const renderContactPersons = ({ fields }) => (
    <Fragment>
        {fields.map((person, index) => (
            <Fragment key={index}>
                <Field
                    name={`${person}.name`}
                    component={FormInputField}
                    placeholder="Contact Person's Name"
                    label="Name"
                    tabable={true}
                />
                <Field
                    name={`${person}.number`}
                    component={FormInputField}
                    placeholder="10 digit mobile no."
                    label="Mobile No."
                    tabable={true}
                />
                <Field
                    name={`${person}.email`}
                    component={FormInputField}
                    label="Email Address"
                    placeholder="E.g. hello@yourcompany.com"
                    tabable={true}
                />
                {fields.length > 1 && (
                    <div className="feature_form__field-array-remove">
                        <span onClick={() => fields.remove(index)}>
                            - Remove
                        </span>
                    </div>
                )}
            </Fragment>
        ))}

        <div className="feature_form__field-array-add">
            <span onClick={() => fields.push({})}>+ Add A Contact Person</span>
        </div>
    </Fragment>
);

export default reduxForm({
    form: "feature_yourself_old", // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(FormPart3);
