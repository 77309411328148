import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import authReducer from "./auth";
import userFormReducer from "./user-form";
import searchReducer from "./search";
import NotificationReducer from "./notification";
 


export default combineReducers({
    form: formReducer,
    auth: authReducer,
    userForm: userFormReducer,
    search: searchReducer,
    NotifcationCount : NotificationReducer  
});