import React from "react";

function FeatureSuccessfulSvg(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 496 270'
      preserveAspectRatio="xMinYMin meet"
      className={props.className}
    >
      <defs>
        <linearGradient id='a' x1='50%' x2='50%' y1='0%' y2='100%'>
          <stop offset='0%' stopColor='#142F70' stopOpacity='0.4'></stop>
          <stop offset='100%' stopColor='#142F70' stopOpacity='0'></stop>
        </linearGradient>
        <linearGradient id='b' x1='50%' x2='50%' y1='0%' y2='100%'>
          <stop offset='0%' stopColor='#142F70' stopOpacity='0.4'></stop>
          <stop offset='100%' stopColor='#142F70' stopOpacity='0'></stop>
        </linearGradient>
        <linearGradient id='c' x1='50%' x2='50%' y1='0%' y2='100%'>
          <stop offset='0%' stopColor='#142F70' stopOpacity='0.4'></stop>
          <stop offset='100%' stopColor='#142F70' stopOpacity='0'></stop>
        </linearGradient>
        <linearGradient id='d' x1='50%' x2='50%' y1='0%' y2='100%'>
          <stop offset='0%' stopColor='#142F70' stopOpacity='0.4'></stop>
          <stop offset='100%' stopColor='#142F70' stopOpacity='0'></stop>
        </linearGradient>
        <linearGradient id='e' x1='50%' x2='50%' y1='0%' y2='100%'>
          <stop offset='0%' stopColor='#142F70' stopOpacity='0.4'></stop>
          <stop offset='100%' stopColor='#142F70' stopOpacity='0'></stop>
        </linearGradient>
        <linearGradient id='f' x1='50%' x2='50%' y1='0%' y2='100%'>
          <stop offset='0%' stopColor='#142F70' stopOpacity='0.4'></stop>
          <stop offset='100%' stopColor='#142F70' stopOpacity='0'></stop>
        </linearGradient>
        <linearGradient id='g' x1='0%' y1='50%' y2='50%'>
          <stop offset='0%' stopColor='#142F70' stopOpacity='0.4'></stop>
          <stop offset='100%' stopColor='#142F70' stopOpacity='0'></stop>
        </linearGradient>
        <linearGradient id='h' x1='50%' x2='50%' y1='0%' y2='100%'>
          <stop offset='0%' stopColor='#142F70' stopOpacity='0.4'></stop>
          <stop offset='100%' stopColor='#142F70' stopOpacity='0'></stop>
        </linearGradient>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <g opacity='0.5' transform='rotate(-90 143 98)'>
          <path fill='url(#a)' d='M48 1h2v148h-2z'></path>
          <circle cx='49' cy='3' r='3' fill='#19A7B1'></circle>
          <path fill='url(#b)' d='M156 41h47v2h-47v76h-2V41h2z'></path>
          <path fill='url(#c)' d='M93 61h2v58h-2z'></path>
          <circle cx='94' cy='63' r='3' fill='#1E61A4'></circle>
          <path fill='url(#c)' d='M206 107h2v58h-2z'></path>
          <circle cx='207' cy='109' r='3' fill='#19A7B1'></circle>
          <path fill='url(#c)' d='M6 122h2v58H6z'></path>
          <circle cx='7' cy='121' r='3' fill='#19A7B1'></circle>
          <path fill='url(#d)' d='M179 15h2v28h-2z'></path>
          <circle cx='180' cy='17' r='3' fill='#1E61A4'></circle>
          <path fill='url(#d)' d='M2 49h2v28H2z'></path>
          <circle cx='3' cy='51' r='3' fill='#1E61A4'></circle>
          <path
            fill='url(#e)'
            d='M108 84h2v29h-2z'
            transform='rotate(-90 109 98)'
          ></path>
          <circle
            cx='126'
            cy='98'
            r='3'
            fill='#1E61A4'
            transform='rotate(-90 126 98)'
          ></circle>
          <path
            fill='url(#f)'
            d='M75 2h2v49h-2z'
            transform='rotate(-90 76 26)'
          ></path>
          <circle
            cx='104'
            cy='26'
            r='3'
            fill='#1E61A4'
            transform='rotate(-90 104 26)'
          ></circle>
          <circle
            cx='208'
            cy='42'
            r='3'
            fill='#142F70'
            transform='rotate(-90 208 42)'
          ></circle>
          <path fill='url(#g)' d='M2 75h48v2H2z'></path>
          <path fill='url(#h)' d='M167 87h2v68h-2z'></path>
        </g>
        <g opacity='0.5' transform='rotate(90 208 238)'>
          <path fill='url(#a)' d='M48 1h2v148h-2z'></path>
          <circle cx='49' cy='3' r='3' fill='#19A7B1'></circle>
          <path fill='url(#b)' d='M156 41h47v2h-47v76h-2V41h2z'></path>
          <path fill='url(#c)' d='M93 61h2v58h-2z'></path>
          <circle cx='94' cy='63' r='3' fill='#1E61A4'></circle>
          <path fill='url(#c)' d='M206 107h2v58h-2z'></path>
          <circle cx='207' cy='109' r='3' fill='#19A7B1'></circle>
          <path fill='url(#c)' d='M6 122h2v58H6z'></path>
          <circle cx='7' cy='121' r='3' fill='#19A7B1'></circle>
          <path fill='url(#d)' d='M179 15h2v28h-2z'></path>
          <circle cx='180' cy='17' r='3' fill='#1E61A4'></circle>
          <path fill='url(#d)' d='M2 49h2v28H2z'></path>
          <circle cx='3' cy='51' r='3' fill='#1E61A4'></circle>
          <path
            fill='url(#e)'
            d='M108 84h2v29h-2z'
            transform='rotate(-90 109 98)'
          ></path>
          <circle
            cx='126'
            cy='98'
            r='3'
            fill='#1E61A4'
            transform='rotate(-90 126 98)'
          ></circle>
          <path
            fill='url(#f)'
            d='M75 2h2v49h-2z'
            transform='rotate(-90 76 26)'
          ></path>
          <circle
            cx='104'
            cy='26'
            r='3'
            fill='#1E61A4'
            transform='rotate(-90 104 26)'
          ></circle>
          <circle
            cx='208'
            cy='42'
            r='3'
            fill='#142F70'
            transform='rotate(-90 208 42)'
          ></circle>
          <path fill='url(#g)' d='M2 75h48v2H2z'></path>
          <path fill='url(#h)' d='M167 87h2v68h-2z'></path>
        </g>
        <path
          fill='#FFF'
          fillRule='nonzero'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M216 80h64v80h-64z'
        ></path>
        <path
          fill='#FFF'
          fillRule='nonzero'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M211 84h64v80h-64z'
        ></path>
        <path
          fill='#FFF'
          fillRule='nonzero'
          stroke='#4C96FF'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M206 88h64v80h-64z'
        ></path>
        <path
          fill='#9CA6CC'
          d='M217 100h10a1 1 0 010 2h-10a1 1 0 010-2zm16 0h12a1 1 0 010 2h-12a1 1 0 010-2zm-16 9h42a1 1 0 010 2h-42a1 1 0 010-2zm0 9h42a1 1 0 010 2h-42a1 1 0 010-2zm0 9h42a1 1 0 010 2h-42a1 1 0 010-2zm0 9h42a1 1 0 010 2h-42a1 1 0 010-2zm0 9h42a1 1 0 010 2h-42a1 1 0 010-2zm0 9h42a1 1 0 010 2h-42a1 1 0 010-2z'
        ></path>
        <g stroke='#FFF' transform='translate(252 69)'>
          <circle
            cx='16'
            cy='16'
            r='17.5'
            fill='#53D491'
            strokeWidth='3'
          ></circle>
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M10 16l3 5 10-10'
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default FeatureSuccessfulSvg;
