import React from "react";

import BarChart from "../../browsingtech-components/charts/BarChart";
import PieChart2 from "../../browsingtech-components/charts/PieChart2";
import linkedInIcon from "../../../img/linkedin2.svg";

const TabPortfolio = ({ data }) => {
    let activeInvestments = null;
    let exits = null;
    let teamMembers = null;

    //  Individual counts to be displayed
    const activeInvestmentsCount = data.active_investments
        ? data.active_investments.length
        : 0;

    const exitsCount = data.exits ? data.exits.length : 0;

    //  Create the active investment items by mapping over data.active_investments
    if (data.active_investments) {
        activeInvestments = data.active_investments.map(inv_item => {
            const investmentRounds = inv_item.rounds.map(round => (
                <p key={round} className="tabinvestor__investment-round">
                    {round}
                </p>
            ));

            return (
                <div
                    key={inv_item.company}
                    className="tabinvestor__investment-item"
                >
                    <p className="tabinvestor__tile--heading">
                        {inv_item.company}
                    </p>
                    <p className="tabinvestor__tile--subheading">
                        Announced in {inv_item.announced_in}
                    </p>

                    <div className="tabinvestor__investment-rounds-wrapper">
                        {investmentRounds}
                    </div>

                    <p className="tabinvestor__personal_investment__is-lead">
                        {inv_item.lead_investor ? "Lead" : <span>&nbsp;</span>}
                    </p>

                    <p className="tabinvestor__stat-content tabinvestor__stat-content--tile">
                        ${inv_item.amount_invested}
                    </p>
                </div>
            );
        });
    }

    //  Create the exits items by mapping over data.active_investments
    if (data.exits) {
        exits = data.exits.map(exit_item => (
            <div
                key={exit_item.company}
                className="tabinvestor__investment-item"
            >
                <p className="tabinvestor__tile--heading">
                    {exit_item.company}
                </p>
                <p className="tabinvestor__tile--subheading">
                    Announced in {exit_item.announced_in}
                </p>

                <p className="tabinvestor__stat-content tabinvestor__stat-content--tile">
                    ${exit_item.amount_invested}
                </p>
            </div>
        ));
    }

    //  Create the team items by mapping over data.team_members
    if (data.team_members) {
        teamMembers = data.team_members.map(member => {
            const companiesList = member.companies_invested_in.map(comp => (
                <p key={comp} className="tabportfolio__team-member--company">
                    {comp},
                </p>
            ));

            return (
                <div
                    key={member.name}
                    className="tabportfolio__team-member-item"
                >
                    <div>
                        <p className="tabportolio__team-member-name">
                            {member.name}
                        </p>
                        <p className="tabinvestor__tile--subheading tabinvestor__tile--subheading-team-member">
                            {member.position}
                        </p>

                        <div className="tabportfolio__team-member--companies-wrapper">
                            {companiesList}
                        </div>
                    </div>

                    <div>
                        <img
                            src={linkedInIcon}
                            alt="LinkedIn"
                            className="tabportfolio__team-member--linkedin-icon"
                        />
                    </div>
                </div>
            );
        });
    }

    return (
        <div className="tabinvestor-wrapper">
            <div className="tabinvestor__left-col">
                <div className="tabinvestor__left-col--section">
                    <p className="tabinvestor--section-heading">
                        Active Investments ({activeInvestmentsCount})
                    </p>

                    <div className="tabinvestor__previous-exp-wrapper">
                        {activeInvestments}
                    </div>
                </div>

                <div className="tabinvestor__left-col--section">
                    <p className="tabinvestor--section-heading">
                        Exits ({exitsCount})
                    </p>

                    <div className="tabinvestor__shareholder-wrapper">
                        {exits}
                    </div>
                </div>

                <div className="tabinvestor__left-col--section">
                    <div className="tabportfolio__bar-chart-wrapper">
                        <p className="tabportfolio__bar-chart-heading">
                            Investments Trend
                        </p>
                        <BarChart data={data.investmentTrendsData} />
                    </div>
                    <div className="tabportfolio__pie-charts-wrapper">
                        <div className="tabportfolio__pie-chart">
                            <p className="tabportfolio__pie-chart-heading">
                                Investments by Rounds
                            </p>
                            <PieChart2 data={data.roundInvestmentData} />
                        </div>

                        <div className="tabportfolio__pie-chart">
                            <p className="tabportfolio__pie-chart-heading">
                                Investments by Technology
                            </p>
                            <PieChart2 data={data.technologyInvestmentData} />
                        </div>

                        <div className="tabportfolio__pie-chart">
                            <p className="tabportfolio__pie-chart-heading">
                                Investments by Categpry
                            </p>
                            <PieChart2 data={data.roundInvestmentData} />
                        </div>

                        <div className="tabportfolio__pie-chart">
                            <p className="tabportfolio__pie-chart-heading">
                                Investments by Location
                            </p>
                            <PieChart2 data={data.technologyInvestmentData} />
                        </div>
                    </div>
                </div>

                <div className="tabinvestor__left-col--section">
                    <p className="tabinvestor--section-heading">Team</p>

                    <div className="tabportfolio__team-members-wrapper">
                        {teamMembers}
                    </div>
                </div>
            </div>

            <div className="tabinvestor__right-col">
                <p className="tabinvestor__stat-heading">Total Investments</p>
                <p className="tabinvestor__stat-content">
                    {data.total_investments}
                </p>

                <p className="tabinvestor__stat-heading">
                    Active Investments
                </p>
                <p className="tabinvestor__stat-content">
                    {activeInvestmentsCount}
                </p>

                <p className="tabinvestor__stat-heading">Exits</p>
                <p className="tabinvestor__stat-content">{exitsCount}</p>

                <p className="tabinvestor__stat-heading">HQ</p>
                <p className="tabinvestor__stat-content">
                    {data.headquarters}
                </p>
            </div>
        </div>
    );
};

export default TabPortfolio;
