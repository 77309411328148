

const monthDict = {
  1: { full: 'January', short: 'Jan' },
  2: { full: 'February', short: 'Feb' },
  3: { full: 'March', short: 'Mar' },
  4: { full: 'April', short: 'Apr' },
  5: { full: 'May', short: 'May' },
  6: { full: 'June', short: 'Jun' },
  7: { full: 'July', short: 'Jul' },
  8: { full: 'August', short: 'Aug' },
  9: { full: 'September', short: 'Sep' },
  10: { full: 'October', short: 'Oct' },
  11: { full: 'November', short: 'Nov' },
  12: { full: 'December', short: 'Dec' },
};

// Receives a date object (or creates one if none is passed)
export const getFormattedDateTime = (dateObj = new Date()) => {
  //console.log(dateObj.length)
  dateObj = new Date(dateObj)
  let date = dateObj.getDate();
  if (date < 10) date = '0' + date;
  const month = monthDict[dateObj.getMonth() + 1]['short'];
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes() == 0 ? '00' : dateObj.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  //return `${month} ${date}, ${year} ${hours}:${minutes} ${ampm} `;
  return `${month} ${date}, ${year}`;
}

export const counterDateTime = (dateObj = new Date()) => {
  dateObj = new Date(dateObj)
  let date = dateObj.getDate();
  if (date < 10) date = '0' + date;
  const month = monthDict[dateObj.getMonth() + 1]
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  let currentDateObj = new Date()
  let currentDate = currentDateObj.getDate();
  if (currentDate < 10) currentDate = '0' + currentDate;
  const currentMonth = monthDict[currentDateObj.getMonth() + 1]
  const currentHours = currentDateObj.getHours();
  const currentMinutes = currentDateObj.getMinutes();

  const counterMonth = month - currentMonth
  const counterDate = date

 // console.log(dateObj.getTime() - currentDateObj.getTime(), 'time difference')



  return `1 `;
}


