import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setFormType  } from '../actions/user-form';
import campaignImage from '../img/ad-campaign/ad-final.png';
import '../css/AdPopup.css';

const AdPopup = ({ show, closeAd, displayUserForm, setFormType, user }) => {
  function handleClick() {
    setFormType({ formType: 'signup' });
    displayUserForm();
    closeAd();
  }

  if (user && user.token) return null;

  return (
    <div className={`adpopup ${show && 'adpopup--visible'}`}>
      <img src={campaignImage} alt='campaignImage' className='adpopup__image' onClick={handleClick}/>
      <span className="adpopup__close" onClick={closeAd} title="Close Ad">&times;</span>
    </div>
  );
};

AdPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  closeAd: PropTypes.func.isRequired,
  displayUserForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ user: state.auth.userInfo });

export default connect(mapStateToProps, { setFormType })(AdPopup);
