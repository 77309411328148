import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import TopBar from "./searchresults-components/TopBar";
import SearchBox from "./searchresults-components/SearchBox";
import PaginatedContainer from "./searchresults-components/PaginatedContainer";
import Loader from "./ui/loader/Loader";
import Filters from "./searchresults-components/Filters";

import { updateSearchQuery, updateSearchRedirect } from "../actions/search";

import api from "../api";

import "../css/SearchResults.css";
import MapView from "./searchresults-components/MapView";


class SearchResults extends Component {
  // TODO: Add modal for Mobile verification if number is not verified and for login if not logged in

  constructor(props) {
    super(props);

    this.state = {
      resultType: "companies",
      viewType: "list",
      TypeName: '',
      searching: true,
      searchResults: [],
      totalCount: "",
      type: "",
      recentlyAddedFilter: "",
      recently_added: [],
      disable: "",
      TechLimit: 1,
      searchFilters: {
        search_type: "search",
        keyword: "",
        technology_type: [],
        type: [],
        size: [],
        locations: [],
        sectors_served: [],
        recently_added: [],
        geographies_served: [],
        company_category: [],
        application_area: [],
        filter_type: "",
        page: 1,
        loadingMore: false,
        hasNextPage: true,
      },
      onlyProducts: false,      // This flag, when set to true, will show only those companies having at least 1 product
    };

    this.controller = null;
    this.signal = null;

    this.setResultType = this.setResultType.bind(this);
    this.setViewType = this.setViewType.bind(this);
    this.handleResetFilters = this.handleResetFilters.bind(this);
    this.handleFilterCheckboxInputChange = this.handleFilterCheckboxInputChange.bind(this);
  }

  componentDidMount() {
    // create new search params object from location
    // const urlQueryParams = new URLSearchParams(this.props.location.search);

    //const queryFilters = JSON.parse(urlQueryParams.get("search"));


    const path = this.props.location.search
    const pathname = this.props.location.pathname.split('/').filter(e => e)
    const searchType = pathname[pathname.length - 1] === "technology" ? "tech_area" : pathname[pathname.length - 1] === "capability" ? "tech_capability" : pathname[pathname.length - 1]
    const type = path.split('&')
    const index = type[1].indexOf("=")
    const resultType = type[1].slice(index + 1)

    const keywordIndex = type[0].indexOf("=")
    const keyword = decodeURIComponent(pathname.length > 1 ? type[0].slice(keywordIndex + 1).replaceAll("-", " ") : type[0].slice(keywordIndex + 1) )
       
    const queryFilters = { "search_type": searchType, "resultType": resultType, "keyword": keyword.replaceAll("__", " & ").replaceAll("_", " ") }

    this.RoutePath();
    // console.log('queryFilters = ', queryFilters);
    // console.log('search_type =', queryFilters.search_type);

    this.setState(prevState => ({
      resultType: queryFilters.resultType ? queryFilters.resultType : 'companies',

      searchFilters: {
        ...prevState.searchFilters,
        ...queryFilters,
        search_type: queryFilters.search_type,
        filter_type: queryFilters.resultType
      }
    }), this.filterSearch);

    this.props.updateSearchQuery(queryFilters.keyword || "");
    this.props.updateSearchRedirect(false);
  }

  componentDidUpdate(prevProps, prevState) {
    // create new search params object from location

    //const urlQueryParams = new URLSearchParams(this.props.location.search);
    //
    //let queryFilters = JSON.parse(urlQueryParams.get("q"));
    const pathname = this.props.location.pathname.split('/').filter(e => e)
    const searchType = pathname[pathname.length - 1] === "technology" ? "tech_area" : pathname[pathname.length - 1] === "capability" ? "tech_capability" : pathname[pathname.length - 1]
    const path = this.props.location.search
    const type = path.split('&')
    const index = type[1].indexOf("=")
    const resultType = type[1].slice(index + 1)
    const keywordIndex = type[0].indexOf("=")
    const keyword = decodeURIComponent(type[0].slice(keywordIndex + 1).replaceAll("__", " & ").replaceAll("_", " ").replaceAll("12", "12"))
    
    const queryFilters = { "search_type": searchType, "resultType": resultType, "keyword": keyword }

    // console.log(this.props.location.state)

    // console.log(this.state.searchFilters.technology_type)
    if (this.props.location.state) {
      if (this.props.location.state.techAreaSelected) {
        this.state.searchFilters.technology_type.push(this.props.location.state.techAreaSelected);
        this.props.location.state = {};
      }
    }
    if (prevProps.location.search !== this.props.location.search) {
      // create new search params object from location
      const urlQueryParams = new URLSearchParams(
        this.props.location.search
      );
      // queryFilters = JSON.parse(urlQueryParams.get("q"));

      // Udate redux store
      this.props.updateSearchRedirect(false);
      this.props.updateSearchQuery(queryFilters.keyword);

    }

    if (
      queryFilters.keyword &&
      this.state.searchFilters.keyword !== queryFilters.keyword
    ) {
      this.setState(
        {
          resultType: queryFilters.resultType,
          searchFilters: {
            ...prevState.searchFilters,
            ...queryFilters,
            filter_type: queryFilters.resultType,
          },
        },
        () => this.filterSearch()
      );
      // Update redux store
      this.props.updateSearchRedirect(false);
      this.props.updateSearchQuery(queryFilters.keyword);
    }

    const locationChanged = this.props.location !== prevProps.location;
    if (locationChanged) {
      window.location.reload(false)
    }
  }

  componentWillUnmount() {
    if (this.abortController) this.abortController.abort();
  }

  //  componentDidUpdate(prevProps) {
  //    // will be true
  //console.log(prevProps)
  //    const locationChanged =
  //      this.props.location !== prevProps.location;
  //    if(locationChanged){
  //      //window.location.reload(false)
  //    }
  //      // INCORRECT, will *always* be false because history is mutable.
  //        const locationChange =
  //          this.props.history.location !== prevProps.history.location;
  //     // console.log(locationChange)
  //  }

  setResultType(type) {

    // console.log(this.props.history.location)
    // const urlQueryParams = new URLSearchParams(this.props.location.search);
    //let queryFilters = JSON.parse(urlQueryParams.get("q"));
    const path = this.props.location.search
    const pathType = path.split('&')
    const index = pathType[1].indexOf("=")
    const resultType = pathType[1].slice(index + 1)
    const keywordIndex = pathType[0].indexOf("=")
    const keyword = pathType[0].slice(keywordIndex + 1)
    // console.log(this.props.location)
    const queryFilters = { "search_type": this.props.location.pathname.split("/")[1], "resultType": resultType, "keyword": keyword }
    // console.log(queryFilters)
    //this.props.history.push(`searchresults?q=%7B"search_type"%3A"search"%2C"keyword"%3A"${queryFilters.keyword}"%2C"resultType"%3A"${type}"%7D`)
    this.props.history.push(`?keyword=${["academia", "r&d centers", "researchers", "unique products", "companies"].includes(queryFilters.keyword) ? queryFilters.keyword[0].toUpperCase() + queryFilters.keyword.slice(1) : queryFilters.keyword}&resultType=${type}`)

    // this.props.history.push(`searchresults?q=${encodeURIComponent(
    //   JSON.stringify({
    //     search_type:
    //       "search",
    //     keyword: ["academia","r&d centers","researchers","unique products","companies"].includes(queryFilters.keyword) ? queryFilters.keyword[0].toUpperCase() + queryFilters.keyword.slice(1)  : queryFilters.keyword,
    //     resultType: type,
    //   })
    // )}`)

    if (type === "tech_area") {
      this.filterSearch()
    }
    this.setState(prevState => ({
      resultType: type,
      searchFilters: {
        ...prevState.searchFilters,
        filter_type: type
      }
    }), this.filterSearch);
  }

  setViewType(type) {
    this.setState({ viewType: type });
  }

  setOnlyProductsView = flag => {
    this.setState({ onlyProducts: flag }, this.filterSearch);
  };

  // Handle filter checkbox input
  handleFilterCheckboxInputChange(event) {
    // console.log(event)
    const target = event.target;
    const checked = target.checked;
    const name = target.name;
    const filterType = target.getAttribute("data-filtertype");


    if (checked) {

      //  When a filter is checked
      this.setState(prevState => ({

        ...prevState,
        searchFilters: {
          ...prevState.searchFilters,
          [filterType]: [...prevState.searchFilters[filterType], name],
        },
        recentlyAddedFilter: name,
      }), this.filterSearch);

    }
    //  When a filter is unchecked
    else {

      this.setState(prevState => ({
        ...prevState,
        searchFilters: {
          ...prevState.searchFilters,
          [filterType]: prevState.searchFilters[filterType].filter(value => value !== name),
        },
        recentlyAddedFilter: "",
      }), this.filterSearch);

    }

    // console.log(this.state.searchFilters.technology_type)
  };

  handleResetFilters() {
    //const urlQueryParams = new URLSearchParams(this.props.location.search);
    //const queryFilters = JSON.parse(urlQueryParams.get("q"));
    const pathname = this.props.location.pathname.split('/').filter(e => e)
    const searchType = pathname[pathname.length - 1] === "technology" ? "tech_area" : pathname[pathname.length - 1] === "capability" ? "tech_capability" : pathname[pathname.length - 1]
    const path = this.props.location.search
    const type = path.split('&')
    const index = type[1].indexOf("=")
    const resultType = type[1].slice(index + 1)
    const keywordIndex = type[0].indexOf("=")
    const keyword = type[0].slice(keywordIndex + 1).replaceAll("__", " & ").replaceAll("_", " ").replaceAll("12", "/")

    const queryFilters = { "search_type": searchType, "resultType": resultType, "keyword": keyword }

    this.setState({
      resultType: queryFilters.resultType,
      searchFilters: {
        search_type: queryFilters.search_type,
        keyword: queryFilters.keyword,
        technology_type: [],
        type: [],
        size: [],
        resultType: queryFilters.resultType,
        recently_added: [],
        geographies_served: [],
        sectors_served: [],
        company_category: [],
        application_area: [],
        locations: [],
        filter_type: queryFilters.resultType,
      }
    }, this.filterSearch);
  }

  // Send Filter Query
  filterSearch = () => {

    //  console.log("1")
    if (this.controller !== null) {
      // Cancel the previous request
      this.controller.abort();
    }
    this.controller = new window.AbortController();
    this.signal = this.controller.signal;
    // set searching check to true
    this.setState({ searching: true });


    let url, requestConfig, requestBody;

    //  For Products, call products search API
    if (this.state.resultType === "products") {
      url = api.products_search;
      requestBody = {
        page: 1,
        limit: 9,
        keyword: this.props.searchQuery,
        ...this.state.searchFilters
      };
      requestConfig = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      };

      //  Call API
      fetch(url, requestConfig)
        .then(response => {
          if (response.status !== 200) {
            // console.log(
            //   "Failed to fetch product search results",
            //   response.status
            // );
            return;
          }

          //  Status code === 200
          response.json().then(response => {

            this.setState({

              totalCount: response.data.total_count,
              page: 1,
              searching: false,
              correctedWord: response.data.corrected_word,
              hasNextPage: response.data.hasNextPage,
              searchResults: response.data.results,
            });
          });
        })
        .catch(err => {
          // console.log("Error fetching products search results");
        });
    }
    //  For technologies, use the search suggestions API with limit param
    else if (this.state.resultType === "tech_area") {
      //  Create request URL and request config object
      requestBody = {
        ...this.state.searchFilters,
        page: 1,
        limit: 9,
        keyword: this.props.searchQuery,

      };
      const url = api.tech_area_search;
      const requestConfig = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody)
      };


      //  Call API
      fetch(url, requestConfig)
        .then(response => {
          if (response.status !== 200) {
            // console.log(
            //   "Failed to fetch tech area results",
            //   response.status
            // );
            return;
          }

          //  Proceed for Status OK
          response.json().then(response => {
            //console.log(response)
            this.setState({
              searching: false,
              page: 1,
              searchResults: response.data,
              hasNextPage: response.hasNextPage,
              totalCount: response.total_count,
              TechLimit: response.page + 1
            });
          });
        })
        .catch(err => {
          // console.log(
          //   "An error occurred while fetching tech area results"
          // );
        });
    }
    //  For all other categories
    else {
      fetch(api.filter_search, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          TCRAUTH: this.props.auth,
        },
        signal: this.signal,
        body: JSON.stringify({
          // keyword: this.state.searchKeyword,
          ...this.state.searchFilters,
          page: 1,

          // products: this.state.onlyProducts
          products: this.state.resultType === 'companies' ? this.state.onlyProducts : false
        }),
      })
        .then(response => {
          // console.log(response);
          if (response.status !== 200) {
            // console.log(
            //   "Looks like there was a problem. Status Code: " +
            //   response.status
            // );
            return;
          }

          // Examine the text in the response
          response.json().then(response => {

            let i = 0;
            this.setState({

              totalCount: response.data.total_count,
              type: response.data.filter_type,
              searchResults: response.data.results.map(item => ({
                ...item,
                animKey: i++,
              })),
              page: 1,
              searching: false,
              isVerified: response.data.flag,
              locations: response.data.locations,
              hasNextPage: response.data.hasNextPage,
              correctedWord: response.data.corrected_word,
              similarCapabilities:
                response.data.similartechcapabilities,
            });
          });
        })
        .catch(err => {
          // console.log("Cannot retrieve Data:", err);
        });
    }
  };

  loadNextPage = () => {


    this.setState(
      prevState => ({
        loadingMore: true,
        page: prevState.page + 1,
      }),

      () => {
        //  Load next result set after setting state
        let url, requestBody, requestConfig;
        if (this.state.resultType === "products") {
          // Load products
          url = api.products_search;
          requestBody = {
            ...this.state.searchFilters,
            page: this.state.page,
            limit: 9,
            keyword: this.props.searchQuery

          };
          requestConfig = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestBody),
          };

          fetch(url, requestConfig)
            .then(response => {
              if (response.status !== 200) {
                // console.log("Failed to fetch more products");
                return;
              }

              // Status code === 200
              response.json().then(response => {
                // console.log(response)
                this.setState({
                  totalCount: response.data.total_count,
                  loadingMore: false,
                  hasNextPage: response.data.hasNextPage,
                  searchResults: this.state.searchResults.concat(
                    response.data.results
                  ),
                });
              });
            })
            .catch(err => {
              // console.log("Error fetching more products");
              this.setState({ loadingMore: false });
            });
        }
        else if (this.state.resultType === "tech_area") {
          //  Create request URL and request config object
          // console.log(this.state.page)
          //console.log("call")
          requestBody = {
            ...this.state.searchFilters,
            page: this.state.page,
            limit: 9,
            keyword: this.props.searchQuery,

          };
          const url = api.tech_area_search;
          const requestConfig = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestBody)
          };


          //  Call API
          fetch(url, requestConfig)
            .then(response => {
              if (response.status !== 200) {
                // console.log(
                //   "Failed to fetch tech area results",
                //   response.status
                // );
                return;
              }

              //  Proceed for Status OK
              response.json().then(response => {
                // console.log(response)
                this.setState({
                  totalCount: response.total_count,
                  loadingMore: false,
                  hasNextPage: response.hasNextPage,
                  searchResults: this.state.searchResults.concat(
                    response.data
                  ),
                });
              });
            })
            .catch(err => {
              // console.log(
              //   "An error occurred while fetching tech area results"
              // );
            });
        }

        else {
          //  Load other entities
          url = api.filter_search;
          requestBody = {
            ...this.state.searchFilters,
            page: this.state.page,
            recently_added: this.state.recently_added
          };

          requestConfig = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestBody),
          };

          fetch(url, requestConfig).then(response => {
            if (response.status !== 200) {
              return;
            }
            response
              .json()
              .then(response => {
                //   console.log(response)
                let i = 0;
                let results = response.data.results.map(
                  item => ({
                    ...item,
                    animKey: i++,
                  })
                );

                const searchResults = this.state.searchResults.concat(
                  results
                );
                //  console.log(response.data.location)
                //const locations = this.state.locations.concat(
                //  response.data.locations
                //);

                this.setState({
                  loadingMore: false,
                  searchResults: searchResults,
                  //locations: locations,
                  hasNextPage: response.data.hasNextPage,
                });
              })
              .catch(err => {
                // console.log("Error fetching more results", err);
                this.setState({ loadingMore: false });
              });
          });
        }
      }
    );
  };

  RoutePath() {
    if (this.props.location.pathname.length > 0 && this.props.location.pathname) {

      const path = this.props.location.search

      //   const type = path.split('A%22')["2"]
      //   const index = type.indexOf("%22")
      //
      //   const TypeName = type.slice(0, index)

      const type = path.split('&')

      const index = type[0].indexOf("=")
      const TypeName = type[0].slice(index + 1)

      this.setState({
        TypeName: TypeName.replaceAll("-", " ")
      })
    }

  }


  render() {
    // console.log()
    // console.log(this.state.resultType,this.state.searchResults )
    var hideSearchBar = (this.state.resultType != this.state.TypeName.toLowerCase()) && this.state.TypeName != "Companies" && this.state.TypeName != "Academia" && this.state.TypeName != "R_and_D_Centers" && this.state.TypeName != "Researchers"
    && this.state.TypeName != "Unique_products"
    return (
      <Fragment>
        <div className="search__top-wrapper">
          {hideSearchBar &&
            <SearchBox resultType={this.state.resultType} />}
          {hideSearchBar &&
            <TopBar
              resultType={this.state.resultType}
              viewType={this.state.viewType}
              setResultType={this.setResultType}
            />
          }
        </div>
      {/*  <div className= {hideSearchBar ? "container-fluid search":"container-fluid entity_cards_container"}>*/}
      <div className= {hideSearchBar ? "search":"entity_cards_container"}>
          <Filters
            handle={this.state.recentlyAddedFilter}
            totalCount={this.state.totalCount}

            resultType={this.state.resultType}
            showCompanyTypeFilters={
              this.state.resultType === "companies"
            }
            filter={this.state.type}
            technology_type={
              this.state.searchFilters.technology_type
            }
            type={this.state.searchFilters.type}
            size={this.state.searchFilters.size}
            sectors_served={this.state.searchFilters.sectors_served}
            geographies_served={
              this.state.searchFilters.geographies_served
            }
            application_area={
              this.state.searchFilters.application_area
            }
            company_category={
              this.state.searchFilters.company_category
            }
            handleFilterCheckboxInputChange={
              this.handleFilterCheckboxInputChange
            }
            recently_added={this.state.searchFilters.recently_added}
            handleResetFilters={this.handleResetFilters}
            filterSearch={this.filterSearch}
            setViewType={this.setViewType}
            setOnlyProductsView={this.setOnlyProductsView}
            handleResetSingleFilter={this.handleResetSingleFilter}
          />
          <div className="search__results-wrapper">
            <Loader loading={this.state.searching}>
              {this.state.searchResults.length === 0 ? (
                <div className="search__no-data-wrapper">
                  <div className="search__no-data-head">No Results Found ...</div>
                  <div className="search__no-data-subhead">
                    Try different keywords to view the best search results
                  </div>
                </div>
              ) : this.state.viewType === "list" ? (
                <PaginatedContainer
                  resultType={this.state.resultType}
                  isNextPageLoading={this.state.loadingMore}
                  entities={this.state.searchResults}
                  hasNextPage={this.state.hasNextPage}
                  loadNextPage={this.loadNextPage}
                  toggleUserForm={this.props.toggleUserForm}
                  authToken={this.props.auth}
                />
              ) : this.state.viewType === "map" &&
                this.state.resultType !== "tech_area" &&
                this.state.resultType !== "products" ? (
                <MapView
                  locations={this.state.locations}
                  isNextPageLoading={this.state.loadingMore}
                  hasNextPage={this.state.hasNextPage}
                  loadNextPage={this.loadNextPage}
                />
              ) : (
                <div className="coming__soon">Coming Soon ...</div>
              )}
            </Loader>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  searchQuery: state.search.query,
  auth: state.auth.userInfo && state.auth.userInfo.token,
});

const mapDispatchToProps = dispatch => ({
  updateSearchQuery: query => dispatch(updateSearchQuery({ query: query })),
  updateSearchRedirect: redirect =>
    dispatch(updateSearchRedirect({ redirect: redirect })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResults);
