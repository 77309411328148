import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import useWhitepapers from './hooks/useWhitepapers';
import { setFormType } from '../actions/user-form';
import { getFormattedDateTime } from '../utils/helpers';
import "../css/Whitepapers.css";
import DOMPurify from 'dompurify';

function Whitepapers({ userInfo, setFormType, toggleUserForm }) {
  const whitepapers = useWhitepapers(userInfo);

  // const bgGradient = `linear-gradient(343deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.7))`;
  document.title = "Whitepapers - Technology Capability Repository || DSCI";
  function handleClick(e) {
    if (!userInfo) {
      e.preventDefault();
      toast.error('You must be logged in to view whitepapers');
      setFormType({ formType: 'login' });
      toggleUserForm();
      return;
    }
  }

  const downloadPdf = (url, pdfName) => {
    fetch(url).then((response) => {
      response.blob().then((blob) => {
       
          // Creating new object of PDF file
          const fileURL =
              window.URL.createObjectURL(blob);
               
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = pdfName;
          alink.click();
      });
  });
  };
 ;

  const count = whitepapers.length && whitepapers.length > 0
    ? `(${whitepapers.length})`
    : null;
  //console.log(whitepapers)
  const papers = whitepapers.map(paper => {
    // const bgImage = `url(${paper.thumbnail_url})`;
    // const bgString = `${bgGradient}, ${bgImage}`;

    // #### user not logged In ####
    // const downloadUrl = userInfo ? paper.file_url : '#';
    

    const downloadUrl = paper.link

    return (
      <div key={paper.thumbnail} className="whitepaper">
        <div >
          <img
            src={paper.thumbnail}
            alt="Whitepaper Thumbnail"
            className="whitepaper__image"
          />
        </div>

        <div className="whitepaper__content">
          <p className="whitepaper__title">{paper.title}</p>

          {paper.published_date ?
                 <p className="whitepaper__date">{paper.published_date}</p> : <></>}
           <div className="whitepaper_des">
                {paper.body &&
                   <div
                   className="whitepaper__description"
                   dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(paper.body) }}
                 />}
              </div>
          <p
            className="whitepaper__download" 
            onClick={(e) => {
             // handleClick(e);
              downloadPdf(downloadUrl, paper.title);
            }}
          
          >Download</p>
        </div>
      </div>
    );
  });

  return (
    <div>
      <div className="entityview__top">
        <div className="entityview__top-desc-wrapper">
          <div className="entityview__top-breadcrumb"
          >
            {/*<nav aria-label="Breadcrumb" class="breadcrumb">
              <ul>
              <li><a href="/">Home</a></li>
                <li><a href ="/resources">Resources</a></li>
                <li><span aria-current="page">Whitepapers</span></li>
              </ul>
  </nav>*/}

          </div>

          <div>
            {/* <p className="entityview__top-head--reports"
            >Whitepapers {count}</p>*/}
            <p className="resouces__heading"
            >Whitepapers</p>
          </div>
        </div>
      </div>

      <div className="whitepapers-wrapper">
        {papers}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({ userInfo: state.auth.userInfo });

export default connect(mapStateToProps, { setFormType })(Whitepapers);
