import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Android from "../img/android.png"
// import UserForm from "./UserForm";
import { setFormType } from "../actions/user-form";
import SupportAndFeedback from "./user-form-components/SupportAndFeedback";

//  Image imports
import TechSagarLogo from '../img/dsc-logo/tech-sagar-logo-exploration-72.png';
import DSCILogo from '../img/dsc-logo/dsci@3x.png';
import sprite from '../img/sprite.svg';
import IOS from '../img/ios.svg'

//  CSS import
import "../css/Footer.css";
import Modal from "./ui/modal/Modal";
 

class Footer extends Component {
  state = {
    showSupportModal: false,
  };

  toggleUserForm = () => {
    this.props.setFormType("signup");
    this.props.toggleUserForm();
  };

  toggleSupportFeedbackModal = () => {
    this.setState(prevState => ({
      showSupportModal: !prevState.showSupportModal,
    }));
  };
  closeSupportFeedbackModal = () => {
    this.setState(prevState => ({
      showSupportModal: !prevState.showSupportModal,
    }));
  };

  render() {
    
    const path = this.props.location.pathname.split('/')
    const selectPath = path[path.length -1]
    if (this.state.showSupportModal)
      return (
        <Modal
          show={this.state.showSupportModal}
          toggleModal={this.toggleSupportFeedbackModal}
        >
          <SupportAndFeedback
            toggleModal={this.toggleSupportFeedbackModal}
            closeModal={this.closeSupportFeedbackModal}
          />
        </Modal>
      );

    const shouldRender = ![
      '/feature_yourself',
      '/verified_feature/cba9b510e5b467fa185808ecd2481057'
    ].includes(this.props.location.pathname);

    return (
      shouldRender && (
        <div className="footer">
          {/* Premium Features Section */}
          {!["whitepapers","reports"].includes(selectPath) &&
          <div className="premium_section">
            <div className="premium_section__text-wrapper">
              <p className="premium_section__text_heading"
              >Access Premium Features</p>

              <p className="premium_section__text"
              >
                Follow companies & individuals, get instant updates,<br />
                report, industry news and much more when
              </p>
            </div>

            {this.props.user && this.props.user.token ? (
              <Link
                to='/payments'
                className="premium_section__signup_button"
              // onClick={this.toggleUserForm}
              >GO PREMIUM</Link>
            ) : (
                <button
                  className="premium_section__signup_button"
                  onClick={this.toggleUserForm}
                >SIGNUP NOW</button>
              )}
          </div> }
          {/* End of Premium Features Section */}

          <div className="footer-bottom">
            <div className='footer-logos-wrapper'>
              <Link to={"/"}> 
              <img
                className="footer_logo"
                src={TechSagarLogo}
                alt="TechSagar"
              />
              </Link>
              <a href="https://www.dsci.in/" target="_blank"> 
              <img
                className="footer_logo--dsci"
                src={DSCILogo}
                alt="DSCI"
             
              />
</a>
            </div>
            
            <div className="footer__links-wrapper">
              <div>
                <div className="text-links-wrapper">
                  
                  <Link to="/about_techsagar"
                  rel="canonical">
                    <span className="text-links-text"
                    >About TechSagar</span>
                  </Link>
                 

                 
                  <Link to="/contact_us" rel="canonical">
                    <span className="text-links-text"
                    >Contact Us</span>
                  </Link>
                

                
                  <Link to="/disclaimer" rel="canonical">
                    <span className="text-links-text"
                    >Disclaimer</span>
                  </Link>
                 

                  <div
                    onClick={this.toggleSupportFeedbackModal}
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="text-links-text "
                    >Support & Feedback</span>
                  </div>
                </div>
                <hr />

                <div className="other-links-wrapper">
                  <div className="other-links2-text"
                  >© {(new Date()).getFullYear()} DSCI. All Rights Reserved</div>
                  
                  <Link
                  rel="canonical"
                    to="/privacy_policy"
                    className="other-links2-text"
                  >Privacy Policy</Link>
                 
                  <Link
                  rel="canonical"
                    to="/terms_of_service"
                    className="other-links2-text"
                  >Terms of Service</Link>
                   
                </div>
              </div>

              {/* Social Icons */}
              <div>
              <div className="social-links-wrapper">
                <a
                  className="icons_pad"
                  href="https://twitter.com/TechSagar_DSCI"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg className='footer__social-link-icon'>
                    <use href={sprite + '#twitter'} />
                  </svg>
                </a>

                <a
                  className="icons_pad"
                  href="https://www.linkedin.com/company/techsagar-dsci/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg className='footer__social-link-icon'>
                    <use href={sprite + '#linkedin'} />
                  </svg>
                </a>
 
                 
                {/* <a
                  className="icons_pad"
                  href="https://www.facebook.com/techsagar.dsci/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg className='footer__social-link-icon'>
                    <use href={sprite + '#facebook'} />
                  </svg>
                </a> */}

                {/* <a
                  className="icons_pad"
                  href="https://www.instagram.com/techsagar.dsci/?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg className='footer__social-link-icon'>
                    <use href={sprite + '#instagram'} />
                  </svg>
                </a> */}


              </div>
              <div className="footer_icon_container">
              <a
                  href="https://play.google.com/store/apps/details?id=com.dsci.techsagar"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                   <img src={Android} className="footer__android" alt="Android image" /> 
                </a>
                <a
          
          href="https://apps.apple.com/us/app/techsagar/id1622773714"
          target="_blank"
          rel="noopener noreferrer"
        >
           <img src={IOS} className="footer__ios" alt="IOS image" /> 
        </a>
        </div>
              </div>
            </div>
           
            {/* End of Social Icons */}
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.userInfo
});

const mapDispatchToProps = dispatch => ({
  setFormType: formType => dispatch(setFormType({ formType: formType })),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Footer)
);
