import React, { useState, useEffect } from 'react';
import '../css/Blog.css'; 
import api from '../api';
 
import BlogDetail from './BlogDetail';
import { useHistory } from 'react-router-dom';
import Loader from './ui/loader/Loader';
function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const history = useHistory();
  useEffect(() => {
    const config = {
      headers: {
        'Auth-Key': process.env.REACT_APP_AUTH_KEY
      }
    };
    fetch(api.BlogList, config)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setBlogs(data.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div> <Loader loading={loading} height="500px"></Loader></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  const handleBlogClick = (link) => {
    const blogLink = link.split("/")[3]
    history.push(
           `/blogs/${blogLink}`
         );
    //setSelectedBlog(blog); // Set the selected blog link in the state
     
  };

  const blogUrlBuilder = (link) => {
    const blogLink = link.split("/")[3]
     return `/blogs/${blogLink}`
  };

  // console.log(loading)
  return (
    <div className='blog'>
      <div className='entityview__top'>
        <div className='entityview__top-desc-wrapper'>
          <p className='resouces__heading'>Blogs</p>
        </div>
      </div>
    <div className="blog-container">
    {blogs.map((blog, index) => (
    <a key={index} href={blogUrlBuilder(blog.link)} onClick={() => handleBlogClick(blog.link)} className="blog-card">
      <div className="blog-header">
        <span className="blog-date">
          {/*<i className="fa fa-calendar calendar-icon"></i>*/} 
          {blog.published_date}
        </span>
        <h2 className="blog-title">{blog.title}</h2>
      </div>
      <p className="blog-body">{blog.body}</p>
      {/*<a href={blog.link} className="blog-link">Read more</a>*/}
      <button to={`/blogs/${blog.title}`} className="blog-link" onClick={() => handleBlogClick(blog.link)} style={{outline: 'none'}}>Read more</button>
      
    </a>
  ))}
</div>
{selectedBlog && <BlogDetail link={selectedBlog.link} />}
 
</div>

  );
}

export default Blog;
