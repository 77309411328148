import React from "react";
import { AsyncCreatable } from "react-select";

const customStyles = {
   control: provided => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      background: "none",
      outline: "none",
      height: "4rem",
      paddingLeft: "6px",
      maxWidth: "100%",
      border: "solid 0.5px #aaa",
   }),
   option: provided => ({
      ...provided,
      cursor: "pointer",
   }),
   menuList: provided => ({
      ...provided,
      maxHeight: "20rem",
   }),
};

const FormSelectAsyncCreatable = ({
   name,
   label,
   placeholder,
   widthClass,
   selectConfig,
   loadOptions,
   components,
   input,
   meta
}) => {
 //console.log(meta)
if(meta.error==="Enter a valid name" || meta.error === "Min 2 to max 32 characters are required"){
   meta.touched = true
}
   return (
      <div className={`feature_form__input-field-wrapper ${widthClass}`}>
         <div className="feature_form__select-wrapper">
            <label className="feature_form__input-label">
               {label}
            </label>

            <AsyncCreatable
               styles={customStyles}
               className="feature_form__select"
               {...selectConfig}
               placeholder={placeholder}
               name={name}
               value={
                  input.value
                     ? {
                        value: input.value,
                        label: input.value,
                        className: "select-option",
                     }
                     : input.value
               }
               onFocus={e => input.onFocus(e)}
               onBlur={e => {
                  
                  e.preventDefault();
                  //input.onBlur(e);
                  //input.onBlur(input.value);
                   
               }}
               onChange={option => {
                  if (option) input.onChange(option.value);
                  else input.onChange(option);
               }}
               loadOptions={loadOptions}
               components={components}
            />
         </div>
         <div className="feature_form__field-error">
            {meta && meta.touched ? meta.error: ""}
         </div>
      </div>
   );
};

export default FormSelectAsyncCreatable;
