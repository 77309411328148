import { func } from "prop-types";
import CryptoJS from 'crypto-js';

export const httpfyUrl = url => {
  if (/^\.*\/|^(?!localhost)\w+:/.test(url)) {
    return url;
  }

  return url.replace(/^(?!(?:\w+:)?\/\/)/, "http://");
};

export const titleCase = str => {
  str = str.toLowerCase().split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
};

export const getSearchPlaceholder = type => {
  switch (type) {
    case 'companies': return 'Search Companies, Academia, and Researchers, & Products in Technologies, e.g. “Deep Learning”, “Sensor Networks”,  “FPGA”';
    case 'academia': return 'Search for research done by Academic Institutions  e.g. “IIT”';
    case 'individual': return 'Search for Individual Researchers & Freelancers working in various technologies e.g. “Text Analytics”, “Neural Networks”';
    case 'r_and_d': return 'Search For R&D Labs & Centres across the country e.g. “CDAC”';
    case 'tech_area': return 'Read more about various cyber technologies and capabilities e.g. “IOT”';
    case 'products': return 'Search for products by companies in various technologies';
    default: return 'Search for a company, researcher, institute, R&D center and more..';
  }
};

export const getkeyword = type => {
  if (type != "Undefined" && type.length > 0) {
    switch (type) {
      case (this.state.searchQuery === "Academia"): return "academia";
      case (this.state.searchQuery === "R&D Centers"): return "r_and_d";
      case (this.state.searchQuery === "Researchers"): return "individual";
      case (this.state.searchQuery == "Unique products"): return "products";

    }
  }
  else {
    return "companies"
  }
}

export function getFormattedDateTime(dateObj = new Date(), dateOnly = false) {
  const year = dateObj.getFullYear();

  let date = dateObj.getDate();
  if (date < 10) date = '0' + date;

  let month = dateObj.getMonth();
  month = monthDict[month];

  const hours = dateObj.getHours();
  const mins = dateObj.getMinutes();

  if (dateOnly) return `${month} ${date}, ${year}`;
  return `${month} ${date}, ${year} - ${hours}:${mins}`;
};


const monthDict = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec'
};

export const encryptAES = plaintext => {
  const secretKey = 'RUFqlY38xUWND5Yt';
  const key = CryptoJS.enc.Utf8.parse(secretKey);
  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(plaintext, key, { iv: iv, padding: CryptoJS.pad.Pkcs7 });
  const combined = CryptoJS.lib.WordArray.create();
  combined.concat(iv).concat(encrypted.ciphertext);
  return combined.toString(CryptoJS.enc.Base64);

}


