import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Backdrop from "../backdrop/Backdrop";

import "./HeaderTechnologyDrawer.css";
import sprite from '../../../img/sprite.svg';

class HeaderTechnologyDrawer extends Component {
    handleTechnologyClicked = areaName => {
        window.localStorage.setItem('techarea', areaName)
        this.props.history.push({
            pathname: "/technology/" + areaName.replace(/ /g, "_"),
        });

        this.props.closeTechnologiesDrawer();
    };

    render() {
        const sideDrawerClasses = ["home--technologies_drawer"];

        if (this.props.show) {
            sideDrawerClasses.push("home--technologies_drawer--open");
            document.querySelector("body").style.overflow = "hidden";
            document.querySelector("html").style.overflow = "hidden";
        } else {
            sideDrawerClasses.push("home--technologies_drawer--close");
            document.querySelector("body").style.overflow = "auto";
            document.querySelector("html").style.overflow = "auto";
        }

        const sortTechAreasAlphabetically = (areas) => {
            const sortedAreas = [...areas].sort((a, b) => {
                const aIsLetter = isNaN(a.name[0]);
                const bIsLetter = isNaN(b.name[0]);

                if (aIsLetter === bIsLetter) {
                    return a.name.localeCompare(b.name);
                }

                return aIsLetter ? -1 : 1;
            });

            return sortedAreas;
        };

        const sortedAreas = sortTechAreasAlphabetically(techAreas);
        const areas = sortedAreas.map(areaObj => (
            <div
                key={areaObj.name}
                className="home--technologies_drawer--tech-item"
                onClick={this.handleTechnologyClicked.bind(null, areaObj.name)}
                onContextMenu={() => { this.handleTechnologyClicked.bind(null, areaObj.name) }}

            >
                {areaObj.name}
            </div>
        ));

        return (
            <Fragment>
                <Backdrop
                    show={this.props.show}
                    clicked={this.props.closeTechnologiesDrawer}
                />
                <div className={sideDrawerClasses.join(" ")}>
                    <div className="home--technologies_drawer--header">
                        <p className="home--technologies_drawer--header-text">
                            Select a <strong>Technology</strong>
                        </p>
                        <div
                            className="home--technologies_drawer--close-btn"
                            onClick={this.props.closeTechnologiesDrawer}
                        >
                            <svg className="home--technologies_drawer--close-icon">
                                <use href={sprite + '#clear'} />
                            </svg>
                            {/* <img src={closeIcon} alt="Close" /> */}
                        </div>
                    </div>

                    <div className="home--technologies_drawer--content">
                        {areas}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export const techAreas = [
    {
        name: "Internet of Things",
        // oldName: "IOT",
    },
    {
        name: "Blockchain",
        // oldName: "Blockchain",
    },
    {
        name: "AI and ML",
        // oldName: "AI, ML and DL",
    },
    {
        name: "Cloud Computing and Virtualization",
        // oldName: "Cloud and Virtualization",
    },
    {
        name: "Wireless and Networking Technologies",
        // oldName: "Wireless and Networking Technologies",
    },
    {
        name: "Hardware, Semiconductors and Embedded",
        // oldName:
        // "Hardware and Semiconductors, Embedded Systems, Electromagnetic Technologies",
    },
    {
        name: "Cybersecurity",
        // oldName: "Cyber Security",
    },
    {
        name: "Robotics and Automation",
        // oldName: "Robotics and Automation",
    },
    {
        name: "Natural Language Processing",
        // oldName: "NLP",
    },
    {
        name: "Computer Vision",
        // oldName: "Computer Vision and Image or Video Processing",
    },
    {
        name: "Augmented and Virtual Reality",
        // oldName: "Augmented and Virtual Reality",
    },
    {
        name: "Data Science",
        // oldName: "Data Science and Big Data Analytics",
    },
    {
        name: "Material Science",
        // oldName: "Material Science for IT",
    },
    {
        name: "Next Generation Computing",
        // oldName: "Next Generation Computing",
    },
    {
        name: "Cryptology",
        // oldName: "Cryptology",
    },
    {
        name: "Digital Forensics",
        // oldName: "Digital Forensic",
    },
    {
        name: "Identity Management and Authentication",
        // oldName: "Identity Management and Authentication",
    },
    {
        name: "Enterprise Business Technologies",
        // oldName: "Enterprise Business Technologies",
    },
    {
        name: "Operating Systems",
        // oldName: "Operating Systems",
    },
    {
        name: "Application Infrastructure and Software",
        // oldName: "Application Infrastructure and Software",
    },
    {
        name: "Software Defined Infrastructure",
        // oldName: "Software Defined Networks and Infrastructure",
    },
    {
        name: "Unmanned Aerial Vehicles",
        // oldName: "Unmanned Aerial Vehicles",
    },
    {
        name: "Human Computer Interaction",
        // oldName: "Human Computer Interaction",
    },
    {
        name: "Mobility and End Points",
        // oldName: "Mobility and End Points",
    },
    {
        name: "Location and Presence",
        // oldName: "Location and Presence",
    },
    {
        name: "Quantum Technology"
    },
    {
        name: "5G and 6G"
    }
];

export default withRouter(HeaderTechnologyDrawer);
