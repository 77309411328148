import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import userIcon from '../img/ic_user.svg'
 
class HomeContributor extends Component {



    render() {
        
        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 2000 },
                items: 6
            },
            desktop: {
                breakpoint: { max: 1604, min: 1270 },
                items: 4.5
            },
            laptops:{
                breakpoint: { max: 1270, min: 997 },
                items: 3.5
            },
            tablet: {
                breakpoint: { max: 977, min: 842 },
                items: 3
            },
            phablet:{
                breakpoint: { max: 842, min: 590 },
                items: 2
            },
            mobile: {
                breakpoint: { max: 590, min: 0 },
                items: 1
            }
        };
         
       
        return (
            
            <div>
                 
            {this.props.contributorData &&
             
               
                 <Carousel responsive={responsive}> 
                {this.props.contributorData.map((data, i) => {
                  return (
                    <div key={i + data['email']} className="home__contributor-container">
                      <img className="home__contributor-userimg"
                        alt='user Img'
                        src={userIcon}
                      >
                      </img>
                      <div className="home__contributor-userName" >
                        {data['name']}
                      </div>
                      <div className="home__contributor-userProfile">
                        {data['designation']}, {data['organization']}
                      </div>
                      <div className="home__contributor-no">
                        {data['total_contribution']} Contrinbutions
                      </div>
                      
                      <button type='button'
                        className="home__contributor-button" id={data['email']} onClick={this.props.onChangeHandler}>{this.props.liked ? <p>Follow</p>: <p>Unfollow</p>}</button>

                    </div>
                  )
                })}</Carousel>
 
                }
               
            </div>
        );
    }
}

export default HomeContributor;