import React from 'react';

//  User defined component imports
import EntityLookupSuggestion from './EntityLookupSuggestion';

//  Import API file
import api from '../../api';

class AddOrClaim extends React.Component {
  state = {
    searchQuery: '',
    lookupResults: [],
    showSuggestions: false,
  }

  componentDidMount() {
    this.lookupEntity();
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  hideSuggestions = () => {
    this.setState({ showSuggestions: false });
  }

  handleClickOutside = event => {
    let suggestionsBox = document.getElementById("feature-form__suggestions");
    if (suggestionsBox && !suggestionsBox.contains(event.target)) {
      this.hideSuggestions();
    }
  };

  //  Prevent form submission
  handleSubmit = event => {
    event.preventDefault();
  }

  //  Sets value of controlled input
  handleQueryChange = event => {
    this.setState({ searchQuery: event.target.value }, this.dLookupEntity);
  }

  // --------- Debounced call configuration and methods
  lookupEntity = () => {
    if (this.state.searchQuery === '') {
      this.setState({ lookupResults: [], showSuggestions: false });
      return;
    }

    if (this.state.searchQuery.replace(/\s+/, "").length > 2) {
      const requestConfig = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: this.state.searchQuery })
      };
      const { selectedType } = this.props;
       console.log("props", this.props)
      //  Call the API
      fetch(api.entityLookup, requestConfig)
        .then(response => {
          if (!response || !response.ok) throw new Error(response.status);
          return response.json();
        })
        .then(response => {
          const relevantLookupData = response.data
            .map(entity => ({
              name: entity.name,
              id: entity.id,
              type: entity.type,
              city: entity.address.city,
              claimed: entity.claimed ? entity.claimed : false
            }))
            .filter(item => {
              return (
                (selectedType === 'company' && (
                  !['Individual Researcher', 'Academia', 'R & D Centres'].includes(item.type)
                )) ||
                (selectedType === 'individual' && item.type === 'Individual Researcher') ||
                (selectedType === 'academia' && item.type === 'Academia') ||
                (selectedType === 'r_and_d' && item.type === 'R & D Centres')
              );
            });

        //  console.log('relevantLookupData = ', relevantLookupData);
          this.setState({ lookupResults: relevantLookupData });
        })
        .catch(err => console.log('Could not lookup entity', err))
        .finally(() => this.setState({ showSuggestions: true }));
    }
  }

  debounced = (delay, fn) => {
    let timerId;
    return function (...args) {
      if (timerId) clearTimeout(timerId);

      timerId = setTimeout(() => {
        fn(...args);
        timerId = null;
      }, delay);
    };
  };

  dLookupEntity = this.debounced(400, this.lookupEntity);

  render() {
    const { selectedType } = this.props;
    let title = null;
    let placeholder = null;

    const suggestions = this.state.lookupResults.length ?
      this.state.lookupResults.map(suggestion => (
        <EntityLookupSuggestion
          key={suggestion.id}
          item={suggestion}
          switchToClaimEntity={this.props.switchToClaimEntity}
        />
      )) : (
        <div className='featureForm__emptySuggestions'>
          <p className='featureForm__emptySuggestionsText'>
            No Matching Results Found...
          </p>
        </div>
      );

    if (selectedType === 'company') {
      title = 'Company';
      placeholder = 'Eg. Infosys Ltd.'
    }
    else if (selectedType === 'academia') {
      title = 'Institute';
      placeholder = 'Eg. Indian Institute of Technology';
    }
    else if (selectedType === 'individual') {
      title = 'Researcher';
      placeholder = 'Eg. Abhinav Saxena';
    }
    else if (selectedType === 'r_and_d') {
      title = 'R&D Center';
      placeholder = 'Eg. IBM Research Lab';
    }
    else {
      title = 'Entity';
      placeholder = 'Eg. Indian Institute of Technology';
    }

    return (
      <div className="feature_form__wrapper">
        <p className="feature__form__subhead"
        >Step 2 of {this.props.totalParts}</p>

        <p className="feature_form__head"
        >Add {title}</p>
{/* / Search  */}
        {/* <div className="feature_form__inner-wrapper">
          <form
            autoComplete='off'
            onSubmit={this.handleSubmit}
            className='feature-form__entity-lookup-form'
          >
            <label className='feature-form__search-entity-label'>Search</label>
            <input
              type='text'
              className='feature-form__search-entity-input'
              placeholder={placeholder}
              onChange={this.handleQueryChange}
              value={this.state.searchQuery}
            />

            {this.state.showSuggestions && <div
              id='feature-form__suggestions'
              className='feature-form__search-entity-suggestions'
            >
              {suggestions}
            </div>}
          </form>
        </div> */}

        {/* <p style={{ fontSize: '1.4rem' }}>OR</p> */}
        <div className="feature_form__btn-wrapper">
          <button
            onClick={this.props.switchToAddEntity}
            className="feature_form__btn"
          >Add {title}</button>

          <button
            onClick={this.props.prevPage}
            className="feature_form__btn feature_form__btn--back"
          >Previous Page</button>
        </div>
      </div>
    )
  }
}

export default AddOrClaim;