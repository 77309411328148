import React from "react";

// Custom Component for Signup Form Checkbox Field
const FormCheckBoxField = props => (
    <div className="form__input-checkbox-wrapper">
        <div className="form__input-checkbox">
            <input
                {...props.input}
                name={props.name}
                id={props.id}
                type="checkbox"
                tabIndex={!props.tabable ? "-1" : ""}
                className='form__input-checkbox-field'
            />
            <label
                className="userform__signup-confirm-label"
                htmlFor={props.id}
            >
                <span style={{ paddingLeft: "20px" }}>
                  I hereby confirm to have read the <a 
                    href='/terms_of_service'
                    target="_blank"
                    rel="noopener noreferrer"
                    className='signup__termsOfServiceLink'
                  >Terms of Use</a> and declare my agreement
                </span>
            </label>
            <div className="form__input-field-error">
                {props.meta && props.meta.touched ? props.meta.error : ""}
            </div>
        </div>
    </div>
);

export default FormCheckBoxField;
