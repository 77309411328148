import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Captcha = props => (
    <div className="form__input-field-wrapper">
        {/* {props.meta.touched && props.meta.error} */}
        <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={response => {
             //   console.log(response,'31222222222222223');
                
                props.input.onChange(response)
            }}
            size="normal"
            style={{
                transform: "scale(0.7)",
                transformOrigin: "0 0 ",
                marginBottom: "-29px",
            }}
        />
        <div className="form__input-field-error">
            {props.meta && props.meta.touched ? props.meta.error : ""}
        </div>
    </div>
);

export default Captcha;
