import React from "react";
import { Field, reduxForm } from "redux-form";

import FormInputField from "../form-components/FormInputField";
import FormButton from "../form-components/FormButton";
import FormSelect from "../form-components/FormSelect";
import FormCheckBoxField from "../form-components/FormCheckBoxField";
import Captcha from "../form-components/Captcha";
 
import Input from '../ui/paymentFormFields/Input';
import validate  from "../user-form-components/signup-validate";
import "../../css/UserForm.css";
import "../../css/Form.css";
import api from '../../api';
import { required} from 'redux-form-validators'
const ContributorPart2 = props => {
 
    const userTypes = [{"name":"Blogs","type":"blogs"},
    {"name":"Reports","type":"report"},
    {"name":"Case Study","type":"case_study"}]

    const techAreas = [
      { name: "Internet of Things" },
      { name: "Blockchain" },
      { name: "AI and ML" },
      { name: "Cloud Computing and Virtualization" },
      { name: "Wireless and Networking Technologies" },
      { name: "Hardware, Semiconductors and Embedded" },
      { name: "Cybersecurity" },
      { name: "Robotics and Automation" },
      { name: "Natural Language Processing" },
      { name: "Computer Vision" },
      { name: "Augmented and Virtual Reality" },
      { name: "Data Science" },
      { name: "Material Science" },
      { name: "Next Generation Computing" },
      { name: "Cryptology" },
      { name: "Digital Forensics" },
      { name: "Identity Management and Authentication" },
      { name: "Enterprise Business Technologies" },
      { name: "Operating Systems" },
      { name: "Application Infrastructure and Software" },
      { name: "Software Defined Infrastructure" },
      { name: "Unmanned Aerial Vehicles" },
      { name: "Human Computer Interaction" },
      { name: "Mobility and End Points" },
      { name: "Location and Presence" },
    ];

    const {
    handleSubmit,
    formLoader,
  } = props;
  window.scrollTo(0, 0)

  return (
    <div className="become_contributor">
      <div style={{ display: "flex", justifyContent: "space-between"}}>
        <p className="user-form__progress">Step 2 of 3</p>
        <p
          className="user-form__progress"
          style={{ marginRight: "30px", cursor: "pointer" }}
          onClick={props.prevPage}
        >Previous Page</p>
      </div>

      <div className="user-form__right-head user-form__right-head--signup"
      >Contribution Details</div>

      <form onSubmit={handleSubmit}>
        <div className="user__form-container">
          <div className="row user__form-row">
         
          
          <Field
          component={FormSelect}
          name="tech_area"
          placeholder="Tech Area"
          options={techAreas.map(type => ({
              label: type.name,
              value: type.name,
              className: "select-option",
            }))}
          type='text'
          labelClass="payment__field-label"
          inputClass="payment__field-input"
          wrapperClass="payment__field-wrapper edit-profile-field-wrapper--name"
          errorClass="payment__field-error"
          validate={required()}
                />   

          <Field
          component={FormSelect}
          name="contribution"
          label="contribution"
          placeholder="Contribution"
          options={userTypes.map(type => ({
              label: type.name,
              value: type.type,
              className: "select-option",
            }))}
          type='name'
          labelClass="payment__field-label"
          inputClass="payment__field-input"
          wrapperClass="payment__field-wrapper edit-profile-field-wrapper--name"
          errorClass="payment__field-error"
          validate={required()}
                />   
          </div>
          
        </div>
        <div className="contributor__button">
          <FormButton  label="CONTINUE" />
        </div>
        
      </form>
    </div>
  );
};

export default reduxForm({
  form: "contributor", // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  
  asyncBlurFields: ['contact_number'],
})(ContributorPart2);

 