import React, { Component } from 'react';
import feed from '../../img/feed.jpg'
import Avatar from '../../img/profile.png'
import '../../css/Feed.css';
import { allAreas } from '../.././staticData'
import Carousel from "react-multi-carousel";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { httpfyUrl } from "../../utils/helpers"
class ContentFeed extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dateTime: "",
            avatarName: ""

        }
    }


    createDate(date) {
        const index = date.indexOf("T")
        const time = date.slice(index)
        const newDate = date.slice(0, index)
        const res = newDate.replace("-", "/");
        const res1 = res.replace("-", "/")
        var strToDate = new Date(res1);
        var options = { year: 'numeric', month: 'long', day: 'numeric', time: 'numeric' };
        this.state.dateTime = strToDate.toLocaleDateString("en-US", options)
    }
    nameAvatar = (name) => {
        var techAreaIcon
        var sliceName = name.slice(0, 1)
        this.state.avatarName = sliceName.toUpperCase()
        if (this.props.techarea) {
            for (let i = 0; i < allAreas.length; i++) {
                if (allAreas[i].name === this.props.fullName) {
                    techAreaIcon = allAreas[i].image
                }
            }
            return (
                <img src={techAreaIcon} className='feed-avatar' alt="tech area icon" />
            )
        }
        else {
            return (
                < div className='feed-text-avatar' >
                    <p className='feed-avatar-font'>{this.state.avatarName}</p>
                </div >
            )
        }
    }

    render() {
        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 3
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 2
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2
            },
            mobile: {
                breakpoint: { max: 560, min: 0 },
                items: 1
            }
        };

        if (this.props.isCompany === "no") {
            return (
                <>
                    <div className='feed-card'>
                        <div className='feed-card-header'>
                            {this.props.profileImage && this.props.profileImage != "" ?
                                <img src={Avatar} className='feed-avatar' alt="avatar" />
                                :
                                <>
                                    {this.nameAvatar(this.props.fullName)}
                                </>
                            }
                            <div>
                                <div>
                                    <p className='feed-headline'>
                                        <span className='feed-title'>{this.props.fullName}</span>
                                        <span className='feed-subtitle'>added a {this.props.docType}</span>
                                    </p>
                                </div>
                                {this.props.time &&
                                    <>
                                        {this.createDate(this.props.time)}
                                        <p className='feed-date'>{this.state.dateTime}</p></>}
                            </div>
                        </div>

                        <a href={this.props.image} data-tip data-for="registerTip">
                            <div>
                                <img src={this.props.image_url} className="feed-img" alt="image" />
                                <ReactTooltip id="registerTip" place="right" effect="solid">
                                    Click to download PDF
                                </ReactTooltip>
                            </div>
                        </a>

                    </div>
                </>
            );
        }
        else if (this.props.isCompany === "researchPaper") {
            // console.log(this.props.data)
            return (
                <>
                    {this.props.data.length > 0 ?
                        <div className='feed-card'>
                            <div className='feed-card-header'>
                                {this.props.profileImage && this.props.profileImage != "" ?
                                    <img src={Avatar} className='feed-avatar' alt="avatar" />
                                    :
                                    <>
                                        {this.nameAvatar(this.props.fullName)}
                                    </>
                                }
                                <div>
                                    <div>
                                        <p className='feed-headline'>
                                            <span className='feed-title'>{this.props.fullName}</span>
                                            <span className='feed-subtitle'>added a {this.props.docType}</span>
                                        </p>
                                    </div>
                                    {this.props.time &&
                                        <>
                                            {this.createDate(this.props.time)}
                                            <p className='feed-date'>{this.state.dateTime}</p></>}
                                </div>
                            </div>
                            <div>
                                <Carousel responsive={responsive}>
                                    {this.props.data && this.props.data.map((obj, i) => {
                                        //console.log(this.props.type)
                                        return (
                                            <div className='feed__carousel_container'>
                                                <div className='feed__carousel'>
                                                    <div className='feed_research'>
                                                        <div>
                                                            <p className='feed_card-text'>{this.props.type === "Academia" ? obj.name : obj.title}</p>
                                                            <p className='feed_card-date'>Published in {obj.year}</p>
                                                        </div>
                                                        <div>
                                                            {obj.url &&
                                                                <a
                                                                    href={obj.url}
                                                                    className="techarea__desc-card-link"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <i className="fas fa-link" />
                                                                </a>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)
                                    })
                                    }
                                </Carousel>
                            </div>
                        </div> : <></>}
                </>
            )
        }
        else {
            return (
                <>
                    <div className='feed-card'>
                        <div className='feed-card-header'>
                            {allAreas &&
                                allAreas.map(
                                    (obj, index) => (
                                        <>
                                            {obj.name === this.props.techAreaName &&
                                                <img src={obj.image} className='feed-avatar' alt="image" />}
                                        </>
                                    )
                                )}
                            <div>
                                <div>
                                    <p className='feed-headline'>
                                        <span className='feed-techarea-heading'>{this.props.count} new companies added in</span>
                                        <span className='feed-techarea-name'>{this.props.techAreaName}</span>
                                    </p>
                                </div>
                                {this.props.time &&
                                    <>
                                        {this.createDate(this.props.time)}
                                        <p className='feed-date'>{this.state.dateTime}</p>
                                    </>}
                            </div>
                        </div>
                        {this.props.data.length > 0 ? this.props.data.map((obj, i) => {
                            return (
                                <div className='feed-teacharea'>
                                    <div className='feed-teacharea-content'>
                                        <p className='feed-techarea-text'>
                                            <Link
                                                to={{
                                                    pathname: `/company/${obj['name'].replace("%", "percentage").replace(/ /g, "-").replace("---", "_")}`,
                                                }}
                                            >

                                                {obj['name']}
                                            </Link>
                                        </p>
                                        <p className='feed-company-type'>{obj.type}</p>
                                    </div>
                                </div>
                            )
                        }) : ''}

                        {this.props.showToggleButton &&
                            <button
                                id={this.props.techAreaName}
                                type='button'
                                className='feed__button'
                                onClick={this.props.toggleCapList}
                            >See {this.props.data.length < this.props.totalCount ? 'More' : 'Less'}</button>}
                    </div>


                </>
            )

        }

    }
}

export default ContentFeed;