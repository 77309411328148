import React from "react";

const Input = props => {
    //  Redux Field props
    const { input, type, disabled, myRef } = props;
    const { visited, error } = props.meta;
    return (
        <div className={props.wrapperClass}>
            <input
                {...input}
                type={type ? type : 'text'}
                className={props.inputClass}
                placeholder={props.placeholder}
                required={props.required}
                autoComplete={props.autoComplete}
                disabled={disabled}
                ref={myRef ? myRef : null}
                spellCheck={false}
            />
            <label className={props.labelClass}>{props.label}</label>

            {visited && error && <p className={props.errorClass}>{error}</p>}
        </div>
    );
};

export default Input;
