import React from 'react';
import { Link } from 'react-router-dom';
import FeatureSuccessfulSvg from '../../inline-svg-components/FeatureSuccessfulSvg';


const RequestSubmitted = props => {

  return (
    <div className="feature_form__wrapper">
      
      <p className="feature_form__head feature_form__head--confirmed" 
      >Details submitted successfully!</p>

      <p className='feature_form__subheading'>
        Your application is under review and we will notify you<br/>
        once it is added on the portal
      </p>
      
      <div style={{textAlign: 'center', marginTop: '5rem'}}>
        <FeatureSuccessfulSvg className='featureSuccessful' />
      </div>

      <Link
        to='/'
        className="feature_form__btn feature-form__back-to-home-btn"
      >Back To Home</Link>
    </div>
  );
}

export default RequestSubmitted;