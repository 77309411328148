// Form validations
import {phone} from 'phone';

const validate = values => {
    //console.log("validate form")
    const errors = {};

    // Errors
    const reqError = "This field is required";
    const urlError = "Enter a valid url";
    const nameError = "Enter a valid name";
    const aliasError = "Enter a valid Company’s Alias";
    const addressLineError =
        "Cannot contain any special characters other than ' - / & ." ;
    const addressPincodeError = "Enter a valid pincode";
    const phoneError = "Enter a valid number";
    const emailError = "Enter a valid email";
    const facebookError = "Enter a valid link";
    const linkedinError = "Enter a valid link";
    const twitterError = "Enter a valid link";
    const min120CharsError = "Minimum 120 characters are required and some special characters[.!$#%&]";
    const aliasLength = "Minimum 3 characters are required"
   const aboutRegex = /^([A-Za-z0-9_.,!$#%&,:;"'/\-\s\*]){120,600}$/;
    // const aboutRegex = /^([ A-Za-z0-9_.,!$#%&]){120,600}$/
    const topRegex = /^([ A-Za-z0-9_.&]){3,120}$/
    const aliasLengthRegex = /^([ A-Za-z0-9_.&]){3,32}$/

    // Regex
    /* eslint-disable no-useless-escape */
    const nameRegex =  /[a-zA-Z]+[a-zA-Z0-9 _.,!$#%&]{2,32}$/
    const urlRegex = "^((ftp|http|https)://)?(www.)?(?!.*(ftp|http|https|www.))[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_+.~#?&//=]*)";
    const addressLineRegex = /^[a-zA-Z0-9\s,.'-/&#]*$/;
    const addressPincodeRegex = /^\d{6}$/;
    const phoneRegex = /^(\+)?((\d){0,4})([-\s])?(\d{10})$/;
    const emailRegex = /^([a-z A-Z 0-9 _\.\-])+\@(([a-z A-Z 0-9\-])+\.)+([a-z A-z 0-9]{2,})$/;
    const facebookRegex = /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9/_.]{1,256}/;
    const aliasRegex = /([a-zA-Z ][0-9 ]{0,5})$/;
    const contactPersonNameRegex = /([a-zA-Z. ]{2,32})$/;
     const linkedinRegex = /^(https?:\/\/)?(www\.)?linkedin.com\/[a-zA-Z0-9/_.]{1,256}/;
     const twitterRegex =  /^(https?:\/\/)?(www\.)?x.com\/[a-zA-Z0-9/_.]{1,256}/;

    // const linkedinRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9/_.]{2,10}linkedin[a-zA-Z0-9/_.]{2,10}\/[a-zA-Z0-9-/_.]{2,256}/;
    // const twitterRegex = /^(https?:\/\/)?(www\.)?twitter.com\/[a-zA-Z0-9/_.]{2,256}/;
    /* eslint-enable no-useless-escape */

    //console.log(values,"%^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^66")
    //  --> Form part 3 validation
   
 

    if (!values.name || !values.name.trim()) errors.name = reqError;
    if (!values.url || !values.url.trim()) errors.url = reqError;
    if (!values.type) errors.type = reqError;
    if (!values.size) errors.size = reqError;
    if (!values.sectors_served) errors.sectors_served = reqError;
    if (!values.geographies_served) errors.geographies_served = reqError;
    if (!values.about || !values.about.trim()) errors.about = reqError;
    
    // if (values.alias && !values.alias.match(aliasRegex)) errors.alias = aliasError
    // if(values.alias && !values.alias.match(aliasLengthRegex) ) errors.alias =   "Min 3 to max 32 characters are required"
    // if (values.name && !values.name.match(nameRegex)) {
     //   console.log("enter")
    //     errors.name = nameError;}
    // if (values.name && values.name.length<2) errors.name = "Min 2 to max 32 characters are required"
    if (values.url && !values.url.match(urlRegex)) errors.url = urlError;
    // if (values.about && !values.about.match(aboutRegex))
    //     {errors.about = "Allowed characters are _.,!$#%&,:;\"'\-";

    // }else
     if(values.about && values.about.length<120){
        errors.about = "Min 120 characters required"
    }
  
    if (values.offerings_description && !values.offerings_description.match(topRegex))
        errors.offerings_description = "Min 3 to max 120 characters are required";
    //  --> Form part 4 validation
    // address

     
    if (values.address) {
        const addressErrors = {};
        const line1 = values.address.line1;
        const pin_code = values.address.pin_code;
        const city = values.address.city;

        if (line1.trim() === "") addressErrors.line1 = reqError;
        if (pin_code.trim() === "") addressErrors.pin_code = reqError;
        if (city.trim() === "") addressErrors.city = reqError;

        //  line1
        if (line1 && !line1.trim().match(addressLineRegex)) {
            addressErrors.line1 = addressLineError;
        }
        //  pin code
        if (pin_code && !pin_code.trim().match(addressPincodeRegex)) {
            addressErrors.pin_code = addressPincodeError;
        }

        errors.address = addressErrors;
    }

    // contact_person
     
    if (!values.contact_person) {
        errors.contact_person = {
            email: reqError,
        };
    }
    
    if (values.contact_person) {
        const contactErrors = {};
        const contactArrayErrors = [];
        const persons = values.contact_person.persons;

        const facebook = values.contact_person.facebook;
        const twitter = values.contact_person.twitter;
        const linkedin = values.contact_person.linkedin;

        // number
        if (persons && persons.length) {
            persons.forEach((person, personIndex) => {
                const personErrors = {};
 
 
                if (!person.name || person.name.trim() === ""){
                    personErrors.name = reqError;
                    contactArrayErrors[personIndex] = personErrors;
                }
                
                if (person.name && !person.name.match(contactPersonNameRegex)) {
                    personErrors.name = "Enter a valid name";
                    contactArrayErrors[personIndex] = personErrors;
                }
                if (!person.number || person.number.trim() === ""){
                personErrors.number = reqError;
                contactArrayErrors[personIndex] = personErrors;
            }

                //console.log(phone(person.number, {country: 'India'}) )
                const phoneValidation = phone(person.number, {country: 'India'}) 
                //console.log(phoneValidation , phoneValidation.isValid)
                if (person.number!="" && !phoneValidation.isValid) {
                    personErrors.number = phoneError;
                    contactArrayErrors[personIndex] = personErrors;
                }

                // email
                if (personIndex === 0 && !person.email) {
                    personErrors.email = reqError;
                    contactArrayErrors[personIndex] = personErrors;
                }

                if (person.email && !person.email.match(emailRegex)) {
                    personErrors.email = emailError;
                    contactArrayErrors[personIndex] = personErrors;
                }
               // console.log(personErrors)
                contactArrayErrors.push(personErrors)
            });
        }
        //console.log(errors)
         
        // facebook, linkedin and twitter
        if (facebook && !facebook.trim().match(facebookRegex))
            contactErrors.facebook = facebookError;
        if (linkedin && !linkedin.trim().match(linkedinRegex))
            contactErrors.linkedin = linkedinError;
        if (twitter && !twitter.trim().match(twitterRegex))
            contactErrors.twitter = twitterError;

        errors.contact_person = contactErrors;

        if (contactArrayErrors.length) {
            errors.contact_person.persons = contactArrayErrors;
        }
       // console.log(errors)
    }

    // recaptcha
    if (!values.recaptcha) {
        errors.recaptcha = reqError;
    }

    // ClaimEntity validation
    if (!values['claimer__firstName']) {
      errors['claimer__firstName'] = reqError;
    } else if (!nameRegex.test(values['claimer__firstName'].trim())) {
      errors['claimer__firstName'] = 'Please enter a valid Name';
    }

    if (!values['claimer__lastName']) {
      errors['claimer__lastName'] = reqError;
    } else if (!nameRegex.test(values['claimer__lastName'].trim())) {
      errors['claimer__lastName'] = 'Please enter a valid Name';
    }

    if (!values['claimer__designation']) {
      errors['claimer__desgination'] = reqError;
    } else if (!nameRegex.test(values['claimer__designation'].trim())) {
      errors['claimer__desgination'] = 'Invalid Input';
    }

    if (!values.claimer__contactNo) {
      errors['claimer__contactNo'] = reqError;
    } else if (!phoneRegex.test(values.claimer__contactNo.trim())) {
      errors['claimer__contactNo'] = 'Please enter a valid phone number';
    }
    if (!values['associated_with']) {
        errors['associated_with'] = reqError;
      }
 
    return errors;
};

export default validate;
