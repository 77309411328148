import React from "react";

function UniqueProductsSvg(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 72 56'
      preserveAspectRatio="xMinYMin meet"
      className={props.className}
    >
      <g fill='none'>
        <g
          fill='#FFF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
        >
          <path d='M15 34V17c0-1-1-2-3-2l-2 2v17m0 0l7 13v5m9 0V42c0-4-4-8-9-8'></path>
          <path d='M24 37l-6-7c-1-1-3-2-4-1a2 2 0 000 4l5 6'></path>
        </g>
        <g
          fill='#FFF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
        >
          <path d='M55 34V17c0-1 1-2 3-2l2 2v17m0 0l-7 13v5m-9 0V42c0-4 4-8 9-8'></path>
          <path d='M46 37l6-7c1-1 3-2 4-1a2 2 0 010 4l-5 6'></path>
        </g>
        <path
          fill='#FFF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M49 11l-14 9v16l14-9z'
        ></path>
        <path
          fill='#FFF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M49 11L35 3l-14 8 14 9z'
        ></path>
        <path
          fill='#4C96FF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M49 11l-6-3-14 8 6 4z'
        ></path>
        <path fill='#C9DFFF' d='M21 12l3 2v15l-3-2z'></path>
        <path
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M21 11l14 9v16l-14-9z'
        ></path>
      </g>
    </svg>
  );
}

export default UniqueProductsSvg;
