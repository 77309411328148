import React from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";



const FormSelect2 = props => {


    const handleOnSearch = (string, results) => {
       // console.log(string, results, 'onSearch');
    };

    const handleOnHover = (result) => {
        //console.log(result, 'onHover');
    };

    const handleOnFocus = () => {
        //console.log("Focused");
    };

    const handleOnClear = () => {
        //console.log("Cleared");
    };


    let cls = `form__input-field-wrapper ${props.widthClass}`;
    return (
        <div className={cls}>
            <div className="form__label-wrapper">
                <p className="form__label">{props.placeholder}</p>
            </div>
            <ReactSearchAutocomplete
                items={props.options}
                fuseOptions={{ keys: ["name", "code"] }} // Search on both fields
                resultStringKeyName="name" // String to display in the results
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={e => {
                    props.input.onChange(e);
                }}
                onFocus={handleOnFocus}
                onClear={handleOnClear}
                showIcon={false}
                styling={{
                    height: "34px",
                    border: "1px solid rgba(60, 70, 92, 0.15)",
                    background: "None",
                    boxShadow: "none",
                    borderRadius: 'none',
                    fontWeight: '100',
                    fontSize: '1.2rem',
                    maxWidth: "100%",
                    outline: 'none',
                    clearIconMargin: "3px 8px 0 0",
                    zIndex: 2,
                }}
            />
            <div className="form__input-field-error">
                {props.meta && props.meta.touched ? props.meta.error : ""}
            </div>
        </div>
    );
};

export default FormSelect2;
