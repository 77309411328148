import React from "react";

function AcademiaSvg(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 72 56'
      preserveAspectRatio="xMinYMin meet"
      className={props.className}
    >
      <g fill='none'>
        <path fill='#C9DFFF' d='M9 53h52v2H9z'></path>
        <path
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M9 51h52v4H9z'
        ></path>
        <path fill='#C9DFFF' d='M9 33h52v2H9z'></path>
        <path
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M9 31h52v4H9z'
        ></path>
        <path fill='#C9DFFF' d='M49 8h4l6 10h-4z'></path>
        <path
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M16 8h37l6 10H10z'
        ></path>
        <path
          fill='#FFF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M27 8l8-6 8 6v10H27z'
        ></path>
        <circle
          cx='35'
          cy='11'
          r='3'
          fill='#4C96FF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></circle>
        <path
          fill='#FFF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M13 35h44v16H13z'
        ></path>
        <path
          fill='#FFF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M19 44a2 2 0 012 2v5h-4v-5a2 2 0 012-2zm7 0a2 2 0 012 2v5h-4v-5a2 2 0 012-2zm18 0a2 2 0 012 2v5h-4v-5a2 2 0 012-2zm7 0a2 2 0 012 2v5h-4v-5a2 2 0 012-2z'
        ></path>
        <path fill='#C9DFFF' d='M35 41a3 3 0 013 3v7h-3V41z'></path>
        <path
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M35 41a3 3 0 013 3v7h-6v-7a3 3 0 013-3z'
        ></path>
        <path
          fill='#FFF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M13 18h44v13H13z'
        ></path>
        <path
          fill='#4C96FF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M20 25h4v6h-4zm26 0h4v6h-4z'
        ></path>
        <path
          fill='#FFF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M32 23h6v8h-6z'
        ></path>
      </g>
    </svg>
  );
}

export default AcademiaSvg;