import React from 'react';
import { reduxForm, Field } from 'redux-form';

import Input from '../ui/paymentFormFields/Input';
import validate from '../../utils/validatePasswordForm';

const ChangePasswordForm = props => {
    const handleOnKeyPress = event => {
        if (event.key === "Enter") event.preventDefault();
    };

    const { invalid, submitting, pristine } = props;

    return (
        <div>
            <form
                onSubmit={props.handleSubmit}
                className='edit-profile-form'
                onKeyPress={handleOnKeyPress}
                initialValues={props.initialValues}
            >

                <Field
                    component={Input}
                    name="fullname"
                    label="fullname"
                    placeholder="Full Name"
                    type='name'
                    labelClass="payment__field-label"
                    inputClass="payment__field-input"
                    wrapperClass="payment__field-wrapper edit-profile-field-wrapper--email"
                    errorClass="payment__field-error"             
                />

                <Field
                   component={Input}
                   name="organization"
                   label="Organization"
                   placeholder="Organization"
                   labelClass="payment__field-label"
                   inputClass="payment__field-input"
                   wrapperClass="payment__field-wrapper edit-profile-field-wrapper--email"
                   errorClass="payment__field-error"
                />

                <Field
                      component={Input}
                      name="designation"
                      label="Designation"
                      placeholder="Designation"
                      labelClass="payment__field-label"
                      inputClass="payment__field-input"
                      wrapperClass="payment__field-wrapper edit-profile-field-wrapper--email"
                      errorClass="payment__field-error"
                />
                <Field
                    component={Input}
                    name="email"
                    label="Email Address"
                    placeholder="Email Address"
                    labelClass="payment__field-label"
                    inputClass="payment__field-input"
                    wrapperClass="payment__field-wrapper edit-profile-field-wrapper--email"
                    errorClass="payment__field-error"
                />
                <Field
                    component={Input}
                    name="phoneNo"
                    label="Contact number"
                    type="number" 
                    placeholder="Contact Number"
                    labelClass="payment__field-label"
                    inputClass="payment__field-input"
                    wrapperClass="payment__field-wrapper edit-profile-field-wrapper--email"
                    errorClass="payment__field-error"
                />
                
                <Field
                    component={Input}
                    name="profilepicture"
                    label="Profile picture"
                    type='profilepicture'
                    placeholder="Upload a profile picture"
                    labelClass="payment__field-label"
                    inputClass="payment__field-input"
                    wrapperClass="payment__field-wrapper edit-profile-field-wrapper--email"
                    errorClass="payment__field-error"
                />
 
               
                 
            </form>
        </div>
    )
}

export default reduxForm({
    form: 'change_password',
    validate: validate,
})(ChangePasswordForm);
