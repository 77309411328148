import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";

// import Technology from "./Technology";
// import EntityFiltersDrawer from "../ui/EntityFiltersDrawer/EntityFiltersDrawer";
import filterIcon from "../../img/filter.svg";

import {
  handleAreaFilterChange,
  handleCapabilityFilterChange,
} from "./filterHelper";
import { httpfyUrl } from "../../utils/helpers";

import editorCommentsImg from "../../img/editor-comments.svg";
import EmptyStateIconSvg from '../../inline-svg-components/EmptyStateIconSvg';

class CardsWithFilters extends Component {
  state = {
    filters: {
      techArea: [],
      capability: [],
    },
    expandedItem: "",
    showEntityFiltersDrawer: false,
    numItemsToShow: window.innerWidth <= 1200 ? 6 : 8,
  };

  setExpandedItem = item => this.setState({ expandedItem: item });

  resetAllFilters = () => this.setState({
    filters: {
      techArea: [],
      capability: {}
    }
  });

  //*  Handle check/uncheck of technology filters
  handleFilterCheckboxInputChange = event => {
    const target = event.target;

    const name = target.name;
    const checked = target.checked;
    const filterType = target.getAttribute("data-filtertype");
    const selectedArea = target.getAttribute("data-techarea");

    this.setState(prevState => {
      const updatedFilters =
        filterType === "techArea"
          ? handleAreaFilterChange(
            name,
            checked,
            prevState.filters,
            this.props.technologyAreas
          )
          : handleCapabilityFilterChange(
            name,
            checked,
            selectedArea,
            prevState.filters,
            this.props.technologyAreas
          );

      return {
        filters: {
          techArea: updatedFilters.techArea,
          capability: updatedFilters.capability,
        },
      };
    });
  };

  prepareTechAreaString = techAreas => {
    let tempStr = techAreas.slice(0, 2)
      .map(techArea => techArea.name)
      .join(", ");
    if (techAreas.length <= 3) return tempStr;

    tempStr =
      tempStr.length > 55 ? tempStr.substring(0, 52) + "..." : tempStr;
    return `${tempStr} & ${techAreas.length - 3} more`;
  };

  toggleEntityFiltersDrawer = () =>
    this.setState(prevState => ({
      showEntityFiltersDrawer: !prevState.showEntityFiltersDrawer,
    }));

  filterByArea = items => {
    if (items && items.length && "technology_type" in items[0]) {
      const areaFilteredItems = items.filter(item => {
        // if (!item.name) return false;

        // Should return true/false to include this item in returned array
        return item.technology_type.some(areaObj => {
          return (
            this.state.filters.techArea.indexOf(areaObj.name) > -1
          );
        });
      });
      return areaFilteredItems;
    } else return [];
  };

  filterByCapability = items => {
    if (items && items.length && "technology_type" in items[0]) {
      const capabilityFilteredItems = items.filter(item => {
        return item.technology_type.some(areaObj => {

          return areaObj.tech_capability.some(capability => {
            return (
              this.state.filters.capability.indexOf(capability) >
              -1
            );
          });
        });
      });
      return capabilityFilteredItems;
    } else return [];
  };

  handleLoadMore = () => {
    // Increment the number of items to show
    this.setState(prevState => ({
      numItemsToShow: this.props.items.length,
    }));
  };


  render() {
    let pathList = this.props.location.pathname.split('/')[1]
    // console.log(pathList, 'pathList')
    let filteredItems = this.props.items;
    if (['Projects', 'Workshops', 'Products', 'Services'].includes(this.props.title)) {
      filteredItems = filteredItems.filter(item => item.name);

    }

    const visibleItems = filteredItems && filteredItems.slice(0, this.state.numItemsToShow);


    if (filteredItems) filteredItems = filteredItems.slice();

    if (this.state.filters.techArea.length > 0) {
      filteredItems = this.filterByCapability(
        this.filterByArea(filteredItems)
      );
    }

    //  Prepare researchers JSX here
    let researchersContent = null;
    if (this.props.title === "Researchers") {
      //  Empty / non-existent researchersList prop => empty state display
      if (
        !this.props.researchersList ||
        !this.props.researchersList.length
      ) {
        // console.log("Empty state condition");
        researchersContent = (
          <div>
            <div className="entityview__no-items-wrapper">
              {/* <img src={noPatentsImg} alt="No Researchers" /> */}
              <EmptyStateIconSvg className='emptyIcon' />
              <p className="entityview__no-items-head"
              >No Researchers Found!</p>

              <p className="entityview__no-items-desc"
              >It seems that no researchers information is
                available to display.<br />
                Once available, it will appear here.</p>
            </div>
          </div>
        );
      }

      //  Prepare JSX for valid researchers list
      else {
        // console.log("Non-empty condition");
        researchersContent = this.props.researchersList.map(
          (item, index) => (
            <Link
              key={index}
              //   to={`/entities/${item.id}`}
              //onContextMenu={()=>  {window.localStorage.setItem('entity', item.id)}}
              //        onClick={()=>  {window.localStorage.setItem('entity', item.id)}}
              to={{
                pathname: `/researcher/${item.name.replace("%", "percentage").replace(/ /g, "_").replace("---", "-")}`,

              }}
              state={item.id}
              title="Go to researcher's page"
              className="entityview__desc-card entityview__desc-researcher-link"
            //  Not sure why to attribute is not working. Currently workaround with onClick()

            >
              <div>
                <p className="entityview__desc-card-head"
                >{item.name ? item.name : this.props.title}</p>
              </div>

              <div>
                <p>
                  {item.designation && (
                    <span>
                      <strong>{item.designation}</strong>{" "}
                      |{" "}
                    </span>
                  )}

                  <span>
                    {this.prepareTechAreaString(item.technology_type)}
                  </span>
                </p>
              </div>
            </Link>
          )
        );

        // console.log("The element is ", researchersContent);
      }
    }

    // console.log("researchersList: ", this.props.researchersList)

    return (
      <div className={this.props.type === "researcher" ? "entityview__desc-wrapper-researcher" : "entityview__desc-wrapper"}>
        {/* <EntityFiltersDrawer
                    show={this.state.showEntityFiltersDrawer}
                    toggleDrawer={this.toggleEntityFiltersDrawer}
                    searchQuery={this.props.searchQuery}
                    technologyAreas={this.props.technologyAreas}
                    techArea={this.state.filters.techArea}
                    capability={this.state.filters.capability}
                    disableFilters={this.props.disableFilters}
                    resetAllFilters={this.resetAllFilters}
                    handleFilterCheckboxInputChange={
                        this.handleFilterCheckboxInputChange
                    }
                />
                <Technology
                    technologyAreas={this.props.technologyAreas}
                    techArea={this.state.filters.techArea}
                    capability={this.state.filters.capability}
                    handleFilterCheckboxInputChange={
                        this.handleFilterCheckboxInputChange
                    }
                    disableFilters={this.props.disableFilters}
                    resetAllFilters={this.resetAllFilters}
                /> */}
        <div className={this.props.type === "researcher" ? "entityview__desc-right-researcher" : "entityview__desc-right"}>
          <div className="entityview__desc-head">
            {this.props.title !== "Researchers" ? (
              <Fragment>
                <p>
                  Research{" "}{this.props.title}{" "}({filteredItems.length})
                </p>
                {this.props.disableFilters ? null : (
                  <div
                    onClick={this.toggleEntityFiltersDrawer}
                    className="entity-filterToggle-mobileOnly"
                  >
                    <p className="entity-filterToggle-mobileOnly-text">
                      Filters
                    </p>
                    <img src={filterIcon} alt="Filters" />
                  </div>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <p>
                 
                 {/* {this.props.title}{" "}({filteredItems.length}) */}
                 {this.props.title}{" "}({this.props.researchersList.length})
                </p>
                {this.props.disableFilters ? null : (
                  <div className="entity-filterToggle-mobileOnly">
                    <p>Filters</p>
                    <img src={filterIcon} alt="Filters" />
                  </div>
                )}
              </Fragment>
            )}
          </div>

          <div className={this.props.type === "researcher" ? "entityview__desc-cards-wrapper-researcher": "entityview__desc-cards-wrapper"}>
            {researchersContent}

            {this.props.title === "Products" ||
              this.props.title === "Services" ||
              this.props.title === "Solutions" ||
              this.props.title === "Projects" ||
              this.props.title === "Workshops" ? (
              filteredItems && filteredItems.length > 0 ? (
                visibleItems.map((item, index) => {
                  console.log("item", item)
                  return (
                    <div
                      key={index}
                      title="Show more"
                      className="entityview__desc-card"
                      onClick={this.setExpandedItem.bind(null, item.name)}
                    >
                      <div className="entityview__desc-card-top">
                        <div className="entityview__desc-card-left">
                          <div className="entityview__desc-card-head">
                            {item.name ? item.name : this.props.title}
                          </div>
                          <div className="entityview__desc-card-detail">
                            {item.about_product &&
                              (this.state
                                .expandedItem ===
                                item.name
                                ? item.about_product
                                : `${item.about_product.substring(
                                  0,
                                  150
                                )}${item
                                  .about_product
                                  .length >
                                  150
                                  ? "..."
                                  : ""
                                }`)}
                          </div>
                        </div>

                        <div>
                          {item.url && (
                            <a
                              className="entityview__desc-card-link"
                              href={httpfyUrl(item.url)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fas fa-link" />
                            </a>
                          )}
                        </div>
                      </div>

                      {item.ra_commentary &&
                        (this.state.expandedItem ===
                          item.name ? (
                          <div className="entityview__desc-card-bottom--expanded">
                            <img
                              src={editorCommentsImg}
                              alt="Editor Comment"
                              className="entityview__desc-bottom-icon"
                            />{" "}
                            <strong>EDITOR'S COMMENTS</strong>

                            <div className="entityview__desc-card-bottom">
                              {item.ra_commentary}
                            </div>
                          </div>
                        ) : (
                          <div className="entityview__desc-card-bottom">
                            <img
                              src={editorCommentsImg}
                              alt="Editor Comment"
                              className="entityview__desc-bottom-icon"
                            />
                            {`${item.ra_commentary.substring(0, 100)}...`}
                          </div>
                        ))}
                    </div>
                  )
                })
              ) : (
                <div>
                  <div className="entityview__no-items-wrapper">
                    <EmptyStateIconSvg className='emptyIcon' />

                    <p className="entityview__no-items-head"
                    >No {this.props.title} Found!</p>

                    <p className="entityview__no-items-desc"
                    >It seems that no {this.props.title}{" "}
                      are available to display.<br />
                      Once available, they will appear here.</p>
                  </div>
                </div>
              )
            ) : null}

            {this.props.title === "Papers" ||
              this.props.title === "Patents" ||
              this.props.title === "Blogs" ||
              this.props.title === "Technical Reports" ? (
              filteredItems && filteredItems.length > 0 ? (
                visibleItems.map((item, index) => (
                  <div
                    key={index}
                    className="entityview__desc-card entityview__papers-card"
                  >
                    <div className="entityview__desc-card-left">
                      <div className="entityview__desc-card-head">
                        {item.title}
                      </div>
                      <div className="entityview__desc-card-detail">
                        {item.year ? `${item.year}` : ''}
                        {item.url && (
                        <a
                          className="entityview__desc-card-link entityview__desc-card-link--small"
                          href={httpfyUrl(item.url)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-link" />
                        </a>
                      )}
                      </div>
                    </div>
                    <div>
                     
                    </div>
                  </div>

                ))
              ) : (
                <div>
                  <div className="entityview__no-items-wrapper">
                    <EmptyStateIconSvg className='emptyIcon' />
                    <p className="entityview__no-items-head"
                    >No {this.props.title} Found!</p>

                    <p className="entityview__no-items-desc">
                      It seems that no {this.props.title}{" "}
                      are available to display.<br />
                      Once available, they will appear here.
                    </p>
                  </div>
                </div>
              )
            ) : null}
          </div>

          {/* Load More button */}
          {filteredItems && filteredItems.length > this.state.numItemsToShow && (
            <button onClick={this.handleLoadMore} className="load-more-button">
              View All
            </button>
          )}
          {/* {this.props.title === "Technical Reports" &&
                        (!this.props.items ||
                            this.props.items.length === 0) && (
                            <div>
                                <div className="entityview__desc-head">
                                    <p>SHOWING 0 TECHNICAL REPORTS</p>
                                </div>
                                <div className="entityview__no-items-wrapper">
                                    <img
                                        src={noDocumentsImg}
                                        alt="no documents"
                                    />
                                    <h2 className="entityview__no-items-head">
                                        No Technical Reports Found!
                                    </h2>
                                    <p className="entityview__no-items-desc">
                                        Seems there are none available as of
                                        now! <br /> Once available, they will
                                        appear here.
                                    </p>
                                </div>
                            </div>
                        )} */}
          {/* {this.props.title === "Patents" &&
                        (!this.props.items ||
                            this.props.items.length === 0) && (
                            <div>
                                <div className="entityview__no-items-wrapper">
                                    <img
                                        src={noPatentsImg}
                                        alt="no documents"
                                    />
                                    <h2 className="entityview__no-items-head">
                                        No Patents Found!
                                    </h2>
                                    <p className="entityview__no-items-desc">
                                        It seems no patents are registered.
                                        <br />
                                        Once registered, they will appear here.
                                    </p>
                                </div>
                            </div>
                        )} */}

          {this.props.title === "Recognitions" &&
            (this.props.items && this.props.items.length > 0 ? (
              this.props.items.map((item, index) => (
                console.log("item", item)
                // <div
                //   key={index}
                //   className="entityview__desc-card entityview__papers-card"
                // >
                //   <div className="entityview__desc-card-left">
                //     <div className="entityview__desc-card-head">
                //       {item.content}
                //     </div>
                //     <div className="entityview__desc-card-detail">
                //       {item.year ? `Published in ${item.year}` : ''}
                //     </div>

                //   </div>
                // </div>
              ))
            ) : (
              <div>
                <div className="entityview__no-items-wrapper">
                  <EmptyStateIconSvg className='emptyIcon' />

                  <p className="entityview__no-items-head"
                  >No Recognitions Found!</p>

                  <p className="entityview__no-items-desc">
                    It seems that no recognitions are
                    available to display.<br />
                    Once available, they will appear here.
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default withRouter(CardsWithFilters);
