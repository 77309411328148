import React from "react";

function CompaniesSvg(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 72 56'
      preserveAspectRatio="xMinYMin meet"
      className={props.className}
    >
      <g fill='none'>
        <path
          fill='#FFF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M12 28h16v27H12z'
        ></path>
        <path fill='#C9DFFF' d='M24 28h2v27h-2zm24 9h4v18h-4z'></path>
        <path
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M47 37h8v18h-8zm-35-9h14v6H12z'
        ></path>
        <path
          fill='#FFF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M19 45a2 2 0 012 2v8h-4v-8a2 2 0 012-2zm7-34h22v44H26z'
        ></path>
        <path
          fill='#4C96FF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M31 19h4v4h-4zm8 0h4v4h-4zm-8 7h4v4h-4zm8 0h4v4h-4zm-8 7h4v4h-4zm8 0h4v4h-4zm-8 7h4v4h-4zm8 0h4v4h-4z'
        ></path>
        <path
          fill='#FFF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M24 11h26v4H24zm36 44H2'
        ></path>
        <g
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          transform='translate(3 45)'
        >
          <path fill='#FFF' d='M3 10V5'></path>
          <circle cx='3' cy='3' r='3' fill='#4C96FF'></circle>
        </g>
      </g>
    </svg>
  );
}

export default CompaniesSvg;
