import React, { Component } from "react";

import errorImg from "../img/error-img.svg";

import "../css/Error.css";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  redirectToHome = () => {
    this.setState({ hasError: false }, () => {
      window.location.href = 'https://www.techsagar.in/';
    });
  };

  render() {
    if (!this.state.hasError) return this.props.children;

    //  Custom fallback UI here 
    return (
      <div className="error-container">
        <img src={errorImg} className="error-img" alt="Error!" />
        <p className="error-heading">Looks like there was a problem.</p>

        <p className="error-text">
          Some unexpected error occurred, please <b>click the button below</b><br />
          to be redirected to the home page.
        </p>

        <button
          type="button"
          className='error-homeButton'
          onClick={this.redirectToHome}
        >Go Back to Home</button>
      </div>
    );
  }
}

export default ErrorBoundary;
