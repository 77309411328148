import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import Contact from "./Contact";
import { httpfyUrl } from "../../utils/helpers";
import { titleCase } from "../../utils/helpers";
import linkedInIcon from "../../img/linkedin2.svg";
import EmptyStateIconSvg from '../../inline-svg-components/EmptyStateIconSvg';

class About extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      dateTime: "",

    }

  }
  createDate(date) {
    const index = date.indexOf("T")
    const newDate = date.slice(0, index)
    const replaceFirst = newDate.replace("-", "/");
    const dateTime = replaceFirst.replace("-", "/")
    return dateTime

  }
  //* Returns the mapped array of JSX elements containing all key persons
  renderKeyPersons = () => {
    const { businessHeads, technologyHeads } = this.props;
    let keyPersons = null;

    //  Keypersons are business heads and technology heads
    //  If both of these are unavailable, keyPersonsSection is not displayed
    if (businessHeads && businessHeads.length && technologyHeads && technologyHeads.length) {
      keyPersons = businessHeads.concat(technologyHeads);
    }
    else if (businessHeads && businessHeads.length) keyPersons = businessHeads;
    else if (technologyHeads && technologyHeads.length) keyPersons = technologyHeads;
    else return null;


    const mappedKeyPersons = keyPersons.map(person => {


      var nameSlice = person.name
      var nameIndex = nameSlice.indexOf(",")
      var personName = nameSlice.slice(0, nameIndex)
      var personPosition = nameSlice.slice(nameIndex + 2)
      // console.log(personName)
      // console.log(personPosition)
      //  Create the LinkedIn icon if user's linkedin profile URL is available
      let linkedIn = null;

      if (person.linkedin) linkedIn = (
        <div className="entityview__about-section__business-head--link">
          <a href={person.linkedin} target="_blank" rel="noopener noreferrer">
            <img src={linkedInIcon} alt="LinkedIn logo" />
          </a>
        </div>
      );

      //  Return the constructed item for one key person
      return (
        <div key={person.name} className="entityview__about-section__business-head">
          <div className="about-keyperson">
            <p className="about-keyperson-name">{titleCase(personName)}</p>
            <p className="about-keyperson-position">{personPosition}</p>
          </div>
          {linkedIn}
        </div>
      );
    });

    //  Return the required JSX
    return (
      <div className="entityview__about-section">
        <p className="entityview__about-head">Key Persons</p>
        <div className="entityview__business-heads--wrapper">
          {mappedKeyPersons}
        </div>
      </div>
    );
  }


  //* Returns the required JSX for the stats in about section
  renderStats = () => {
    if (!this.props.showStats) return null;

    let { papersPublished, totalCitations, averageCitations } = this.props;
    if (!papersPublished) papersPublished = 'NA';
    if (!totalCitations) totalCitations = 'NA';
    if (!averageCitations) averageCitations = 'NA';

    return (
      <div className="container-fluid">
        <div className="row about-stats-wrapper">

          <div className="col-md-4" style={{ borderRight: "1px solid#edeef2" }}>
            <p className="about-stats--title"
            >Published Papers</p>
            <p className="about-stats--stat">{papersPublished}</p>
          </div>

          <div className="col-md-4" style={{ borderRight: "1px solid#edeef2" }}>
            <p className="about-stats--title"
            >Total Citations</p>
            <p className="about-stats--stat">{totalCitations}</p>
          </div>

          <div className="col-md-4">
            <p className="about-stats--title"
            >Average Citations</p>
            <p className="about-stats--stat">{averageCitations}</p>
          </div>

        </div>
      </div>
    );
  }


  //* Returns the required JSX for the sectors served section
  /*renderSectorsServed = () => {
    const { sectorsServed } = this.props;
    if (!sectorsServed || !sectorsServed.length) return null;

    let searchString = '';
    const mappedSectorsServed = sectorsServed.map(sector => {
      searchString = `?q=${encodeURIComponent(JSON.stringify({
        search_type: "search",
        sectors_served: [sector]
      }))}`;

      //  JSX for a single link of sectors served
      return <Link
        key={sector}
        className="entityview__about-tag"
        title='Click to view more entities in this sector'
        to={{
          pathname: '/searchresults',
          search: searchString
        }}
      >
        {sector}
      </Link>;
    });

    //  Return the required JSX
    return (
      <div className="entityview__about-section">
        <p className="entityview__about-head">SECTORS SERVED</p>
        <div className="entityview__about-tags-wrapper">
          {mappedSectorsServed}
        </div>
      </div>
    );
  }


  //* Returns the required JSX for the geographies served section
  renderGeographiesServed = () => {
    const { showGeographies, geographiesServed } = this.props;
    if (!showGeographies || !geographiesServed || !geographiesServed.length) return null;

    let searchString = '';
    const mappedGeographiesServed = geographiesServed.map(geography => {
      searchString = `?q=${encodeURIComponent(JSON.stringify({
        search_type: "search",
        geographies_served: [geography]
      }))}`;

      //  JSX for a single link of geographies served
      return <Link
        key={geography}
        className="entityview__about-tag"
        title='Click to view more entities in this geography'
        to={{
          pathname: '/searchresults',
          search: searchString
        }}
      >
        {geography}
      </Link>;
    });

    return (
      <div className="entityview__about-section">
        <p className="entityview__about-head">GEOGRAPHIES SERVED</p>
        <div className="entityview__about-tags-wrapper">
          {mappedGeographiesServed}
        </div>
      </div>
    );
  }*/

  recognitions = () => {
    return (
      <>
        {this.props.items.length > 0 &&
          <p className="entitview__recognitions_title">Recognitions</p>}
        {this.props.title === "Recognitions" &&

          (this.props.items && this.props.items.length > 0 ? (
            this.props.items.map((item, index) => (
              <div
                key={index}
                className="entityview__desc-card-recog entityview__papers-card"
              >
                <div className="entityview__desc-card-left">
                  <div className="entityview__desc-card-head">
                    {item.content}
                  </div>
                  <div className="entityview__desc-card-detail">
                    {item.year ? `Published in ${item.year}` : ''}
                  </div>

                </div>
              </div>
            ))
          ) : (
            <>
            </>
          ))}
      </>
    )
  }
  News = () => {


    return (
      <div className="news" >
        {this.props.news != [] && this.props.news.length > 0 ? <p className="news__heading">Signals and News</p> : <></>}
        {this.props.news &&
          this.props.news.map(
            (obj, index) => (

              <div
                key={index}
                className="entityview__desc-card news__papers-card"
              >
                <div className="entityview__desc-card-left">
                  <div className="entityview__desc-card-head">
                    {obj.title}
                  </div>
                  <div className="entityview__desc-card-detail">

                  </div>
                </div>
                <div>
                  {obj.url && (
                    <a
                      className="entityview__desc-card-link entityview__desc-card-link--small"
                      href={httpfyUrl(obj.url)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fas fa-link" />
                    </a>
                  )}
                </div>
              </div>
            )
          )}
      </div>
    )
  }

  render() {
    // console.log('contact person = ', this.props.contactPerson);


    const statsSection = this.renderStats();
    //const sectorsServedSection = this.renderSectorsServed();
    const keyPersonsSection = this.renderKeyPersons();
    //const geographiesServedSection = this.renderGeographiesServed();
    const recognitions = this.recognitions();
    const news = this.News();
    //console.log(this.props.about)
    return (
      <Fragment>
        <div className="entityview__desc-wrapper entityview__desc__about-container">
          <div className="entityview__about-wrapper">

            <div className="entityview__about-section">
              <p className="entityview__about-head"
              >About</p>
              <p className="entityview__about-section-about" style={{ whiteSpace: 'pre-wrap' }}>{this.props.about}</p>
            </div>

            {statsSection}
            {/* {sectorsServedSection}
            {geographiesServedSection}*/}
            {keyPersonsSection}
            {recognitions}

            <Contact
          url={this.props.url}
          address={this.props.address}
          contactPerson={this.props.contactPerson}
        />
            

          </div>
        </div>

        {news}

        {/* <Contact
          url={this.props.url}
          address={this.props.address}
          contactPerson={this.props.contactPerson}
        /> */}


      </Fragment>
    )
  }
}

export default About;