import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const CapabilitiesList = props => {
    const buttonText = props.isListExpanded ? "See Less" : "See More";
    let capabilities = null;
 
    //  If list is received, generate JSX
     

    
    if (props.list) {
        capabilities = props.list.map(capability => (
            //console.log("capability##",capability.replace(/ /g, "_").replace(/\//g, "-"))
            <div key={capability} className="capability-list__item-wrapper">
                {/* replacing special character with word*/}
                
                <Link
                    className="capability-list__item"                            //   Blockchain Mining/Cryptocurrency Mining
                  
                     to={{
                        pathname:`/technology/${props.techarea.replace(/ /g, "-")}/${capability.replace(/ /g, "_").replace(/\//g, "--")}`,
                        state:{capability}
                     }}
                >
                    {capability}
                </Link>
            </div>
        ));
    }

    return (
        <Fragment>
            <div className="browsingtech__capabilities-list">
                {capabilities}
            </div>

            {props.showToggleButton && <button
                type="button"
                className="capability-list__toggle-btn"
                onClick={props.toggleCapList}
            >
                {buttonText}
            </button>}
        </Fragment>
    );
};

export default CapabilitiesList;
