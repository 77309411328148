import React, { useEffect, useState } from 'react';
import api, { getAuthGETConfig } from '../../api';  // Import api and config function
import BeatLoader from "react-spinners/BeatLoader";
import '../../css/claimedentity.css';
import "../../css/EntityView.css";
import { Link } from "react-router-dom";
import locationIcon from '../../img/location-light.svg'
import businessTypeIcon from '../../img/business-type-light.svg';
import peopleIcon from '../../img/people-light.svg';
import { httpfyUrl } from "../../utils/helpers";

function ClaimedEntity({ authToken }) {
    const [entityData, setEntityData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Function to fetch claimed entity data
        const fetchClaimedEntityData = async () => {
            try {
                const response = await fetch(api.claimed_entity, getAuthGETConfig(authToken));
                const data = await response.json();
                if (response.ok) {
                    setEntityData(data.data[0]);
                } else {
                    console.error('Error fetching claimed entity data', data.message);
                }
            } catch (error) {
                console.error('Error fetching claimed entity data', error);
            }
            finally {
                setLoading(false);  // Stop loading after data is fetched or error occurs
            }   
        };

        if (authToken) {
            fetchClaimedEntityData();
        }
    }, [authToken]);

    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5rem', marginBottom: '5rem' }}><BeatLoader color="#706b69" speedMultiplier={0.7} /></div>;
    }

    if (!entityData) {
        return <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', marginTop: '6rem', marginBottom: '6rem', fontWeight: 'bold', fontSize: '3rem' }}>
            <span>No Claimed Entity</span>
            <div>
            <Link
                // onClick={claimClickHandler}
                    to={"/claim"}
                    className="header__section__part header__section__btn entityview__subhead-btn" style={{color: 'white', background: 'rgb(13, 110, 253)'}}>
                    Claim
                  </Link>
            </div>
            {/* <div>a</div> */}
        </div>;
    }

    return (
        <div className='claimed__container'>
            <div className='claimed__elem__main'>
                <div className='claimed__elem__top'>
                    <span>ENTITY DETAILS</span>

                    <div style={{display: 'flex', flexDirection: 'row', gap: '30px', width: '100%'}}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '3px', width: '50%'}}>
                    <label className='claimed__field-label'>Company: </label>
                    <input type="text" className='payment__field-input' value={entityData.entity_name} readOnly />
                    </div>
                    
                    <div style={{display: 'flex', flexDirection: 'column', gap: '3px', width: '50%'}}>
                    <label className='claimed__field-label'>Website: </label>
                    <input type="text" className='payment__field-input' value={entityData.url} readOnly />
                    </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', gap: '30px', width: '100%'}}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '3px', width: '50%'}}>
                    <label className='claimed__field-label'>Employees: </label>
                    <input type="text" className='payment__field-input' value={`${entityData.size}`} readOnly />
                    </div>
                    
                    <div style={{display: 'flex', flexDirection: 'column', gap: '3px', width: '50%'}}>
                    <label className='claimed__field-label'>Company Type: </label>
                    <input type="text" className='payment__field-input' value={entityData.type} readOnly />
                    </div>
                    </div>
                    
                    <div style={{display: 'flex', flexDirection: 'row', gap: '30px', width: '100%'}}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '3px', width: '33.33%'}}>
                    <label className='claimed__field-label'>Facebook: </label>
                    <input type="text" className='payment__field-input' value={entityData.contact.facebook} readOnly />
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', gap: '3px', width: '33.33%'}}>
                    <label className='claimed__field-label'>Twitter: </label>
                    <input type="text" className='payment__field-input' value={entityData.contact.twitter} readOnly />
                    </div>
                    
                    <div style={{display: 'flex', flexDirection: 'column', gap: '3px', width: '33.33%'}}>
                    <label className='claimed__field-label'>LinkedIn: </label>
                    <input type="text" className='payment__field-input' value={entityData.contact.linkedin} readOnly />
                    </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', gap: '3px'}}>
                    <label className='claimed__field-label'>Address: </label>
                    <input type="text" className='payment__field-input' value={entityData.address} readOnly />
                    </div>
                    
                    <div style={{display: 'flex', flexDirection: 'row', gap: '30px', width: '100%'}}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '3px', width: '50%'}}>
                    <label className='claimed__field-label'>City: </label>
                    <input type="text" className='payment__field-input' value={entityData.city} readOnly />
                    </div>
                    

                    <div style={{display: 'flex', flexDirection: 'column', gap: '3px', width: '50%'}}>
                    <label className='claimed__field-label'>Pincode: </label>
                    <input type="text" className='payment__field-input' value={entityData.pin_code} readOnly />
                    </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', gap: '30px', width: '100%'}}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '3px', width: '50%'}}>
                    <label className='claimed__field-label'>Email: </label>
                    <input type="text" className='payment__field-input' value={entityData.contact.persons[0].email} readOnly />
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', gap: '3px', width: '50%'}}>
                    <label className='claimed__field-label'>Phone: </label>
                    <input type="text" className='payment__field-input' value={entityData.contact.persons[0].number} readOnly />
                    </div>
                    </div>

                </div>
                <div className="claimed__elem__mid">
                <span style={{ fontSize: '1.6rem', lineHeight: '2.2rem', fontWeight: 'bold', marginBottom: '1.5rem'}}>PRODUCTS</span>
                    {entityData && entityData.products.length > 0 ? (
                        entityData.products.map((product, index) => (
                            <div key={index} className="claimed-product-card">
                                {/* Read-only input fields for product */}
                                {/* <label>Product Name: </label> */}
                                <input 
                                    type="text" 
                                    value={product.name} 
                                    readOnly 
                                    style={{ fontWeight: 500, fontSize: '14px', lineHeight: '2.8rem', border: 'none' }} 
                                />
                                
                                {/* <label>About Product: </label> */}
                                <input 
                                    type="text" 
                                    value={product.about_product} 
                                    readOnly
                                    className='payment__field-input' 
                                    style={{ fontWeight: 400, fontSize: '14px', lineHeight: '2.2rem', color: '#81858C' }} 
                                />
                            </div>
                        ))
                    ) : (
                        <p>No products available.</p>
                    )}
                </div>

                <div className="claimed__elem__mid">
                <span style={{ fontSize: '1.6rem', lineHeight: '2.2rem', fontWeight: 'bold', marginBottom: '1.5rem'}}>SERVICES</span>
                    {entityData && entityData.services.length > 0 ? (
                        entityData.services.map((service, index) => (
                            <div key={index} className="claimed-service-card">
                               {/* Read-only input fields for service */}
                               {/* <label>Service Name: </label> */}
                                <input 
                                    type="text" 
                                    value={service.name} 
                                    readOnly 
                                    style={{ fontWeight: 500, fontSize: '14px', lineHeight: '2.8rem', border: 'none' }} 
                                />

                                {/* <label>About Service: </label> */}
                                <input 
                                    type="text" 
                                    value={service.about_product} 
                                    readOnly
                                    className='payment__field-input' 
                                    style={{ fontWeight: 400, fontSize: '14px', lineHeight: '2.2rem', color: '#81858C' }} 
                                />
                            </div>
                        ))
                    ) : (
                        <p>No services available.</p>
                    )}
                </div>

                <div className="claimed__elem__mid">
                <span style={{ fontSize: '1.6rem', lineHeight: '2.2rem', fontWeight: 'bold', marginBottom: '1.5rem'}}>SOLUTIONS</span>
                    {entityData && entityData.solutions.length > 0 ? (
                        entityData.solutions.map((solution, index) => (
                            <div key={index} className="claimed-solution-card">
                                {/* Read-only input fields for solution */}
                                {/* <label>Solution Name: </label> */}
                                <input 
                                    type="text" 
                                    value={solution.name} 
                                    readOnly 
                                    style={{ fontWeight: 500, fontSize: '14px', lineHeight: '2.8rem', border: 'none' }} 
                                />

                                {/* <label>About Solution: </label> */}
                                <input 
                                    type="text" 
                                    value={solution.about_product} 
                                    readOnly
                                    className='payment__field-input' 
                                    style={{ fontWeight: 400, fontSize: '14px', lineHeight: '2.2rem', color: '#81858C' }} 
                                />
                            </div>
                        ))
                    ) : (
                        <p>No solutions available.</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ClaimedEntity;
