import React from "react";
import MapPin from "../../img/map-pin-active.svg";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from "react-google-maps";

const RenderMap = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            defaultOptions={{ mapTypeControl: false }}
            defaultZoom={props.zoom}
            defaultCenter={props.center}
            region="in"
        >
            <Marker position={props.center} icon={MapPin} />
        </GoogleMap>
    ))
);

// { lat: -34.397, lng: 150.644 }

const EntityViewMap = props => (
    <RenderMap
        zoom={12}
        center={props.center}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${
            process.env.REACT_APP_GOOGLE_MAP_API_KEY
        }&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
    />
);

export default EntityViewMap;
