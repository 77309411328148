import React, { Fragment } from "react";

import editorCommentsImg from "../../img/editor-comments.svg";
import { httpfyUrl } from "../../utils/helpers";

const EntityItemCard = ({ title, item, isExpandedItem, setExpandedItem, websiteUrl }) => {
    let itemName = null;
    let itemUrl = null;
    let itemDescription = null;
    let raCommentary = null;

    // ! BYPASS RENDER
    // ! IF ITEM NAME IS NOT AVIALABLE, DON'T SHOW THAT ITEM, RETURN NULL
    if (!item.name) return null;

    //  Create item name
    itemName = item.name;

    //  Create item description
    if (item.about_product) {
        itemDescription = item.about_product;

        if (!isExpandedItem)
            itemDescription =
                itemDescription.length <= 200
                    ? itemDescription
                    : itemDescription.substring(0, 200) + "...";
    }

    //  Create itemUrl
    // if (item.url)
    //     itemUrl = (
    //         <a
    //             href={httpfyUrl(item.url)}
    //             className="entityview__desc-card-link"
    //             target="_blank"
    //             rel="noopener noreferrer"
    //         >
    //             <i className="fas fa-link" />
    //         </a>
    //     );

        if (websiteUrl)
            itemUrl = (
                <a
                    href={httpfyUrl(websiteUrl)}
                    className="entityview__desc-card-link"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i className="fas fa-link" />
                </a>
            );

    //  Create RA Commentary
    if (item.ra_commentary) {
        let div_class;
        let ra_commentary_desc;

        if (isExpandedItem) {
            div_class = "entityview__desc-card-bottom--expanded";
            ra_commentary_desc = item.ra_commentary;
        } else {
            div_class = "entityview__desc-card-bottom";
            ra_commentary_desc = item.ra_commentary.substring(0, 100) + "...";
        }

        raCommentary = (
            <div className={div_class}>
                <img
                    src={editorCommentsImg}
                    alt="Editor Comment"
                    className="entityview__desc-bottom-icon"
                />
                {!isExpandedItem ? (
                    ra_commentary_desc
                ) : (
                    <Fragment>
                        <strong>&nbsp;EDITOR'S COMMENTS</strong>
                        <div className="entityview__desc-card-bottom">
                            {ra_commentary_desc}
                        </div>
                    </Fragment>
                )}
            </div>
        );
    }

    return (
        <div
            onClick={setExpandedItem.bind(null, item)}
            className="entityview__desc-card"
            title="Show more"
        >
            <div className="entityview__desc-card-top">
                <div className="entityview__desc-card-left">
                    <div className="entityview__desc-card-head">{itemName}</div>
                    <div className="entityview__desc-card-detail" style={{whiteSpace:'pre-wrap'}}>
                        {itemDescription}
                    </div>
                </div>
                <div>{itemUrl}</div>
                {/* <div>{websiteUrl.length>0 ? websiteUrl : itemUrl}</div> */}
            </div>
            {raCommentary}
        </div>
    );
};

export default EntityItemCard;
