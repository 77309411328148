import React from 'react'
import FooterNew from './FooterNew'
import ReDesignHomeTech from './ReDesignHomeTech'
import ReDesignEventWebinar from './ReDesignEventWebinar'
import ReDesignSummaryGlance from './ReDesignSummaryGlance'

export const ReDesignHome = () => {
  return (
    <div>
        <ReDesignHomeTech />
        <ReDesignEventWebinar />
        <ReDesignSummaryGlance />
        {/* <FooterNew /> */}
    </div>
  )
}
