import React from "react";
import { withRouter } from "react-router-dom";
import { GoogleMap } from "react-google-maps";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";
import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";
import MapPin from "../../img/map-pin-active.svg";

import clusterIcon1 from "../../img/markerclusterericons/mcicon1.svg";
import clusterIcon2 from "../../img/markerclusterericons/mcicon2.svg";
import clusterIcon3 from "../../img/markerclusterericons/mcicon3.svg";
import clusterIcon4 from "../../img/markerclusterericons/mcicon4.svg";
import clusterIcon5 from "../../img/markerclusterericons/mcicon5.svg";

import { mapStyle } from "./MapStyles";

const labelStyles = {
    // backgroundColor: 'rgba(255, 255, 255, 1)',
    background: "transparent",
    // color: '#4c96ff',
    color: "red",
    padding: "2px",
    borderRadius: "5px",
    fontSize: "1.3rem",
    fontWeight: "bold",
    // maxWidth: '120px',
    zIndex: "100",
};

// ! Height and width of the cluster icons are styled using the following css selector
// ! .cluster img { height: 50px; width: 50px; }
// ! The code is in index.css (height width also need to be specified below in styles object)
const clusterIconStyles = [
    {
        textColor: "white",
        url: clusterIcon1,
        height: 50,
        width: 50,
    },
    {
        textColor: "white",
        url: clusterIcon2,
        height: 50,
        width: 50,
    },
    {
        textColor: "white",
        url: clusterIcon3,
        height: 50,
        width: 50,
    },
    {
        textColor: "white",
        url: clusterIcon4,
        height: 50,
        width: 50,
    },
    {
        textColor: "white",
        url: clusterIcon5,
        height: 50,
        width: 50,
    },
];

class MapCanvas extends React.Component {
    state = {
        hoveredEntityLatLng: null,
    };

    constructor(...args) {
        super(...args);
        this.state = {
            zoomLevel: 5,
            center: {
                lat: 22.772653,
                lng: 79.09079,
            },
        };
    }

    componentDidMount() {
        // console.log('Search results map mounted');
        const swLatLng = new window.google.maps.LatLng(6.4626999, 68.1097);
        const neLatLng = new window.google.maps.LatLng(
            37.513327,
            97.39535869999999
        );
        const bounds = new window.google.maps.LatLngBounds(swLatLng, neLatLng);
        // console.log("About to call fitBounds");
        this.refs.map.fitBounds(bounds, -100);
        this.setState({ zoomLevel: 14 });

        if (this.props.zoom) {
            const deviceWidth = window.screen.width;
            let zoomLevelToSet = this.props.zoom;

            //  Adjust zoom levels for mobile screens
            if (deviceWidth < 600) zoomLevelToSet = 4;
            this.setState({ zoomLevel: zoomLevelToSet });
        }
        // else {
        //     let zoomLevelToSet = 4;
        //     if (window.screen.width > 1400) zoomLevelToSet++;
        //     this.setState({ zoomLevel: zoomLevelToSet });
        // }
    }

    handleLabelClicked = entityId => {
        //   console.log(entityId)
        //    console.log(this.props.capability === "undefined")

        if (this.props.capability != "undefined") {
            this.props.history.push(`/${this.props.techarea.replace(/ /g, "_")}/${entityId.replace("%", "percentage").replace(/ /g, "_")}`);
        }
        else {
            this.props.history.push(`/${this.props.techarea.replace(/ /g, "_")}/${this.props.capability}/${entityId.replace("%", "percentage").replace(/ /g, "_")}`);
        }

    };

    // componentWillReceiveProps() {
    //     const zoomLevel = this.refs.map.getZoom();
    //     if (zoomLevel !== 5) {
    //         this.setState({
    //             zoomLevel: 5,
    //             center: {
    //                 lat: 22.772653,
    //                 lng: 79.09079,
    //             },
    //         });
    //     }
    // }

    // _handleZoomChanged(e) {
    //     const zoomLevel = this.refs.map.getZoom();
    //     if (zoomLevel !== this.state.zoomLevel) {
    //         this.setState({
    //             zoomLevel: zoomLevel,
    //         });
    //     }
    // }

    // _handleCenterChanged() {
    //     console.log('handle center changed called');
    //     const center = this.refs.map.getCenter();
    //     console.log('getCenter gave = ', center);
    //     if (!center.equals(this.state.center)) {
    //         this.setState({ center });
    //     }
    // }

    handleMarkerClicked = clickedMarker => {
        const lat = clickedMarker.latLng.lat();
        const lng = clickedMarker.latLng.lng();
        //  console.log(`(lat,lng) = (${lat},${lng})`);

        this.setState({
            center: { lat: lat, lng: lng },
            zoomLevel: 15,
        });
    };

    handleMouseOver = marker => {
        //  console.log("marker = ", marker);
        this.setState({
            hoveredEntityLatLng: { lat: marker.lat, long: marker.long },
        });
    };
    handleMouseOut = () => {
        if (this.state.hoveredEntityLatLng) {
            this.setState({ hoveredEntityLatLng: null });
        }
    };

    render() {
        const markers = this.props.locations
            ? this.props.locations.map((loc, index) => {
                if (
                    //  Check if this entity is the one user is hovering on on left side list
                    (this.props.hoveredEntity &&
                        this.props.hoveredEntity.lat === loc.lat &&
                        this.props.hoveredEntity.long === loc.long) || //  Check for hover on map ping
                    (this.state.hoveredEntityLatLng &&
                        this.state.hoveredEntityLatLng.lat === loc.lat &&
                        this.state.hoveredEntityLatLng.long === loc.long)
                ) {
                    const customKey = `${loc.lat}${loc.long}${index}`;
                    return (
                        <MarkerWithLabel
                            onMouseOut={this.handleMouseOut}
                            key={customKey}
                            onClick={() => this.handleLabelClicked(loc.name)}
                            position={{ lat: loc.lat, lng: loc.long }}
                            labelAnchor={new window.google.maps.Point(30, 0)}
                            labelStyle={labelStyles}
                        >
                            <div
                                onClick={() =>
                                    this.handleLabelClicked(loc.name)
                                }
                            >
                                {loc.label}
                            </div>
                        </MarkerWithLabel>
                    );
                } else {
                    const customKey = `${loc.lat}${loc.long}${index}`;
                    return (
                        <MarkerWithLabel
                            onMouseOver={this.handleMouseOver.bind(this, loc)}
                            key={customKey}
                            icon={MapPin}
                            onClick={() => this.handleLabelClicked(loc.name)}
                            position={{ lat: loc.lat, lng: loc.long }}
                            labelAnchor={new window.google.maps.Point(30, -5)}
                            labelStyle={labelStyles}
                        >
                            <div
                                onClick={() =>
                                    this.handleLabelClicked(loc.name)
                                }
                            />
                        </MarkerWithLabel>
                    );
                }
                // if (
                //     this.props.hoveredEntity &&
                //     this.props.hoveredEntity.lat === loc.lat &&
                //     this.props.hoveredEntity.long === loc.long
                // ) {
                //     return (
                //         <MarkerWithLabel
                //           position={{ lat: loc.lat, lng: loc.long }}
                //           labelAnchor={new window.google.maps.Point(0, 0)}
                //           labelStyle={{backgroundColor: "yellow", fontSize: "32px", padding: "16px"}}
                //         >
                //           <div>Label here</div>
                //         </MarkerWithLabel>
                //       //   <Marker
                //       //       key={`${loc.lat}${loc.long}`}
                //       //       position={{ lat: loc.lat, lng: loc.long }}
                //       //       onClick={marker =>
                //       //           this.handleMarkerClicked(marker)
                //       //       }
                //       //   />
                //     );
                // } else {
                //     return (
                //         <Marker
                //             key={`${loc.lat}${loc.long}`}
                //             icon={MapPin}
                //             position={{ lat: loc.lat, lng: loc.long }}
                //             onClick={marker =>
                //                 this.handleMarkerClicked(marker)
                //             }
                //         />
                //     );
                // }
            })
            : null;

        return (
            <GoogleMap
                containerProps={{
                    style: {
                        height: "100%",
                    },
                }}
                ref="map"
                zoom={this.state.zoomLevel}
                center={this.state.center}
                defaultOptions={{
                    mapTypeControl: false,
                    styles: mapStyle,
                }}
            >
                <MarkerClusterer
                    onClick={this.props.onMarkerClustererClick}
                    averageCenter
                    enableRetinaIcons
                    gridSize={60}
                    styles={clusterIconStyles}
                >
                    {markers}
                </MarkerClusterer>
            </GoogleMap>
        );
    }
}

export default withRouter(MapCanvas);
