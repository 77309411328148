import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Field, FieldArray, reduxForm, change } from "redux-form";
import { toast } from "react-toastify";

import FormInputField from "../form-elements/FormInputField";
// import TechAreaCapTag from "../form-elements/TechAreaCapTag";

import validate from "../utils/validate";

import saperatorImg from "../../../img/form-imgs/object-separator.svg";

import api from '../../../api';

class FormPart9 extends Component {
    state = {
        fileBlog: Array(9).fill({ status: false, text: 'Browse' }),
        filePatent: Array(9).fill({ status: false, text: 'Browse' })
    }


    fileUpload = (e, type, section, index) => {
        const file = e.target.files[0];
        if (!file) return;

        const filesize = (file.size / 1024 / 1024).toFixed(4);
        if (filesize > 15) {
            toast.error("The size of file should be less than 15 MB!");
            return;
        }
        
        // file${type} computes to 'fileBlog' or 'filePatent' which are objects in the state
        this.setState(prevState => {
            // Create updated state
            const updatedState = prevState[`file${type}`].map((paper, idx) => {
                if (idx !== index) return paper;
                return { status: true, text: 'Uploading' };
            });

            // Return the updated state object
            return { [`file${type}`]: updatedState };
        });

        const fileReader = new FileReader();
        const fileName = file.name;
        let fileUri;

        fileReader.readAsDataURL(file);
        fileReader.onloadend = () => {
            if (fileReader.readyState === 2) {
                fileUri = fileReader.result;
            }

            const fileuUploadData = {
                company_name: this.props.entityName,
                type: type,
                filename: fileName,
                content: fileUri,
            };
            const requestConfig = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(fileuUploadData)
            };

            fetch(api.companyFileUpload, requestConfig)
                .then(response => {
                    if (!response || !response.ok) throw Error(response.staus);
                    return response.json();
                })
                .then(({ data }) => {
                    const { dispatch } = this.props;
                    dispatch(change("feature_yourself_old", `${section}[${index}].url`, data.url));

                    // Update state to show status of the uploaded files
                    this.setState(prevState => {
                        const updatedState = prevState[`file${type}`].map((paper, idx) => {
                            if (idx !== index) return paper;
                            return { status: false, text: fileName };
                        });

                        // Return the updated state object
                        return { [`file${type}`]: updatedState };
                    });
                })
                .catch(err => {
                    // console.log('Error uploading document', err);
                    this.setState(prevState => {
                        const updatedState = prevState[`file${type}`].map((paper, idx) => {
                            if (idx !== index) return paper;
                            return { status: false, text: 'Try Again' };
                        });

                        // Return the updated state object
                        return { [`file${type}`]: updatedState };
                    });
                });
        };
    };

    addPatent = () => this.setState(prevState => ({
        filePatent: prevState.filePatent.concat({ status: false, text: 'Browse' })
    }));

    addBlog = () => this.setState(prevState => ({
        fileBlog: prevState.fileBlog.concat({ status: false, text: 'Browse' })
    }));

    render() {
        const { handleSubmit } = this.props;
        const onKeyPress = event => {
            if (event.key === "Enter") {
                event.preventDefault();
            }
        };

        return (
            <Fragment>
                <p className="feature_form__head">Patents & Blogs</p>
                <form
                    onSubmit={handleSubmit(this.props.onSubmit)}
                    onKeyPress={onKeyPress}
                >
                    <div className="feature_form__inner-wrapper">
                        <FieldArray
                            name="patents"
                            section="patents"
                            type="Patent"
                            fileUpload={this.fileUpload}
                            addField={this.addPatent}
                            filePatent={this.state.filePatent}
                            component={renderPatents}
                            technologyAreas={this.props.technologyAreas}
                        />
                        <hr />
                        <FieldArray
                            name="blogs"
                            section="blogs"
                            type="Blog"
                            fileUpload={this.fileUpload}
                            addField={this.addBlog}
                            fileBlog={this.state.fileBlog}
                            component={renderBlogs}
                        />
                    </div>
                    <div className="feature_form__btn-wrapper">
                        <button
                            className="feature_form__btn feature_form__btn-back"
                            onClick={this.props.prevPage}
                        >
                            GO BACK
                        </button>
                        <button type="submit" className="feature_form__btn">
                            CONTINUE
                        </button>
                    </div>
                </form>
            </Fragment>
        );
    }
}

const renderBlogs = ({
    fields,
    fileUpload,
    fileBlog,
    // fileBlogText,
    addField,
    section,
    type,
}) => (
    <div className="feature_form__blogs">
        <div className="feature_form__part_head">Blogs</div>
        {fields.map((blog, index) => (
            <div key={index} className="form__row">
                <Field
                    name={`${blog}.title`}
                    component={FormInputField}
                    widthClass="form__field-wrapper--40"
                    label="Title"
                    tabable={true}
                />
                <Field
                    name={`${blog}.url`}
                    component={FormInputField}
                    widthClass="form__field-wrapper--60"
                    label="URL"
                    tabable={true}
                />

                <div className="feature_form__upload-fields">
                    <div className="feature_form__upload-fields-text">
                        Upload File
                    </div>
                    <div className="feature_form__upload-fields-files">
                        <input
                            className="feature_form__upload-input"
                            id={`blog${index}`}
                            type="file"
                            accept="application/pdf"
                            // onChange={fileUpload}
                            onChange={e => fileUpload(e, type, section, index)}
                        />
                        <label
                            className="feature_form__upload-label"
                            htmlFor={`blog${index}`}
                        >
                            <div className="feature_form__upload-label-icon-wrapper">
                                <div className="feature_form__upload-label-icon">
                                    <i className="fa fa-file-text-o" />
                                </div>
                                {fileBlog[index].status && (
                                    <i className="fa fa-circle-o-notch fa-spin" />
                                )}
                            </div>
                            <div className="feature_form__upload-label-text">
                                {fileBlog[index].text.substr(0, 22)}
                            </div>
                            <i className="fa fa-upload" />
                        </label>
                    </div>
                </div>
                {fields.length > 1 && (
                    <div className="feature_form__field-array-remove">
                        <span onClick={() => fields.remove(index)}>
                            - Remove
                        </span>
                    </div>
                )}
                {fields.length > index + 1 && (
                    <div className="feature_form__saperator-img-wrapper">
                        <img src={saperatorImg} alt="Saperator" />
                    </div>
                )}
            </div>
        ))}

        <div className="feature_form__field-array-add">
            <span
                onClick={() => {
                    addField();
                    fields.push({});
                }}
            >
                + Add another blog
            </span>
        </div>
    </div>
);

const renderPatents = ({
    fields,
    fileUpload,
    filePatent,
    // filePatentText,
    addField,
    section,
    type,
    // technologyAreas,
}) => (
    <div className="feature_form__patents">
        <div className="feature_form__part_head">Patents</div>

        {fields.map((patent, index) => (
            <div key={index} className="form__row">
                <Field
                    name={`${patent}.title`}
                    component={FormInputField}
                    widthClass="form__field-wrapper--40"
                    label="Title"
                    tabable={true}
                />
                <Field
                    name={`${patent}.url`}
                    component={FormInputField}
                    widthClass="form__field-wrapper--60"
                    label="URL"
                    tabable={true}
                />

                {/* <TechAreaCapTag
                    technologyAreas={technologyAreas}
                    arrayName={`${patent}.technology_type`}
                /> */}

                <div className="feature_form__upload-fields">
                    <div className="feature_form__upload-fields-text">
                        Upload File
                    </div>
                    <div className="feature_form__upload-fields-files">
                        <input
                            className="feature_form__upload-input"
                            id={`patent${index}`}
                            type="file"
                            accept="application/pdf"
                            // onChange={fileUpload}
                            onChange={e => fileUpload(e, type, section, index)}
                        />
                        <label
                            className="feature_form__upload-label"
                            htmlFor={`patent${index}`}
                        >
                            <div className="feature_form__upload-label-icon-wrapper">
                                <div className="feature_form__upload-label-icon">
                                    <i className="fa fa-file-text-o" />
                                </div>
                                {filePatent[index].status && (
                                    <i className="fa fa-circle-o-notch fa-spin" />
                                )}
                            </div>
                            <div className="feature_form__upload-label-text">
                                {filePatent[index].text.substr(0, 22)}
                            </div>
                            <i className="fa fa-upload" />
                        </label>
                    </div>
                </div>
                {fields.length > 1 && (
                    <div className="feature_form__field-array-remove">
                        <span onClick={() => fields.remove(index)}>
                            - Remove
                        </span>
                    </div>
                )}
                {fields.length > index + 1 && (
                    <div className="feature_form__saperator-img-wrapper">
                        <img src={saperatorImg} alt="Seperator" />
                    </div>
                )}
            </div>
        ))}

        <div className="feature_form__field-array-add">
            <span
                onClick={() => {
                    addField();
                    fields.push({ technology_type: [{}] });
                }}
            >
                + Add another patent
            </span>
        </div>
    </div>
);

const mapStateToProps = state => ({
    entityName: state.form.feature_yourself_old.values.name,
});

FormPart9 = connect(mapStateToProps)(FormPart9);

export default reduxForm({
    form: "feature_yourself_old", // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(FormPart9);
