import React from 'react';

const NotificationFrequencyForm = ({
  handleNotificationToggle,
  areNotificationsEnabled,
  handleNotificationRadioChange,
  currentNotificationFrequency,
  handleChangeNotificationSettings,
  isButtonDisabled
}) => {
  return (
    <div>
      <div className='heading-toggle-wrapper'>
        <p className='my-account__section-heading'
        >Notifications</p>

        <div 
          className="search-filter--view-toggle"
          style={{ marginLeft: 'auto' }}
        >
          <input
            type="checkbox"
            id="view-toggle-button"
            checked={areNotificationsEnabled}
            onChange={handleNotificationToggle}
          />
          <label
            className="view-toggle-label view-toggle-label--2"
            htmlFor="view-toggle-button"
          >Toggle</label>
        </div>
      </div>

      <p className='my-account__section-subheading'
      >Enable notifications and set frequency</p>

      <div className='notification-frequency-radios-container'>
        <div className='notification-frequency-radio-wrapper'>
          <input
            type='radio'
            name='notif_freq'
            value='daily'
            id='notif_freq-daily'
            className='notif_freq-input'
            checked={currentNotificationFrequency === 'daily'}
            onChange={handleNotificationRadioChange}
            disabled={!areNotificationsEnabled}
          />
          <label
            htmlFor='notif_freq-daily'
            className='notif_freq-label'
          >Daily</label>
          <span className='customRadioBtn'></span>
        </div>

        <div className='notification-frequency-radio-wrapper'>
          <input
            type='radio'
            name='notif_freq'
            value='weekly'
            id='notif_freq-weekly'
            className='notif_freq-input'
            checked={currentNotificationFrequency === 'weekly'}
            onChange={handleNotificationRadioChange}
            disabled={!areNotificationsEnabled}
          />
          <label
            htmlFor='notif_freq-weekly'
            className='notif_freq-label'
          >Weekly</label>
          <span className='customRadioBtn'></span>
        </div>

        <div className='notification-frequency-radio-wrapper'>
          <input
            type='radio'
            name='notif_freq'
            value='monthly'
            id='notif_freq-monthly'
            className='notif_freq-input'
            checked={currentNotificationFrequency === 'monthly'}
            onChange={handleNotificationRadioChange}
            disabled={!areNotificationsEnabled}
          />
          <label
            htmlFor='notif_freq-monthly'
            className='notif_freq-label'
          >Monthly</label>
          <span className='customRadioBtn'></span>
        </div>

      </div>
      <button
        type="button"
        disabled={isButtonDisabled}
        onClick={handleChangeNotificationSettings}
        className='edit-profile-form__submit-btn'
        style={{zIndex: "0"}}
      >SAVE CHANGES</button>
    </div>
  )

}

export default NotificationFrequencyForm;