import React, { Component } from "react";
import { Link } from "react-router-dom";

import CardsWithFilters from "./CardsWithFilters";
import AboutComponents from "./AboutComponents";
import SimilarEntitiesCarousel from "./SimilarEntitiesCarousel";

import "../../css/EntityView.css";
import locationIcon from '../../img/location-light.svg';
import { httpfyUrl } from "../../utils/helpers";
import InitiativesProject from "./InitiativesProject";

class AcademiaWrapper extends Component {
  // state = {
  //   currentMenuItem: "researchers",
  // };

  constructor(props) {
    super(props);

    const filteredProjectsCalc = this.props.details.products.filter(item => item.name);
    const filteredWorkshopsCalc = this.props.details.services.filter(item => item.name);

    let filteredInitiativesCalc = [];
    if ("initiatives" in this.props.details) {
      filteredInitiativesCalc = this.props.details.initiatives.filter(item => item.name);
    }

    const totalCountInitiativesCalc = filteredInitiativesCalc.length + filteredProjectsCalc.length + filteredWorkshopsCalc.length;

    this.state = {
      currentMenuItem: (this.props.researchersList && this.props.researchersList.length > 0)
        ? "researchers"
        : totalCountInitiativesCalc > 0
        ? "projects"
        : "about",
    };
  }

  changeCurrentMenuItem = item => {
    this.setState({
      currentMenuItem: item,
    });
  };

  render() {
    const filteredProjects = this.props.details.products.filter(item => item.name);
    const filteredWorkshops = this.props.details.services.filter(item => item.name);
    // console.log(this.props.details)
    // console.log(this.props.researchersList)
    var filteredInitiatives = []
    var news = []
    if ("initiatives" in this.props.details) {
      var filteredInitiatives = this.props.details.initiatives.filter(item => item.name);
    }
    if ("news" in this.props.details) {
      var news = this.props.details.news
    }

    const totalCountInitiatives = filteredInitiatives.length + filteredProjects.length + filteredWorkshops.length
    // console.log("totalCountInitiatives: ", totalCountInitiatives)

    //console.log(filteredInitiatives)
    // console.log(this.props.path)
    //console.log(filteredProjects)
    const path = this.props.path
    document.title = `${this.props.details.name} - Technology Capability Repository || DSCI`;
    //document.getElementsByTagName("LINK")[0].href=  `https://techsagar.in/institute/${this.props.details.name.replace("%", "percentage").replace(/ /g, "-").replaceAll(".","").replace("---","-").replace(",","").toLowerCase()}`
    document.getElementsByTagName("LINK")[0].href = ["company", "institute", "rd-centre", "researcher"].includes(this.props.path[1]) ? `https://www.techsagar.in` : `https://www.techsagar.in/technology/${path[1].replaceAll("_", "-")}`

    // console.log("details: ", this.props.details)
    // console.log("researchersList: ", this.props.researchersList)
    return (
      <div className="entityview__wrapper">
        <div className="entityview__top">
          <div className="entityview__top-desc-redesign-wrapper">
            {this.props.path.length === 4 ?
              <div className="entityview__top-breadcrumb">
                <Link to="/">Home</Link> / {" "}
                {<Link to={{ pathname: `/technology/${this.props.path[1].replace(/ /g, "_")}` }}>
                  {this.props.path[1].replaceAll("_", " ")}
                </Link>
                }{" "}/
                {<Link to={{ pathname: `/technology/${this.props.path[1].replaceAll(/ /g, "_")}/${this.props.path[2].replaceAll('_', " ")}` }}>
                  {this.props.path[2].replaceAll("_", " ").replace("12", "/")}
                </Link>
                }{" "}/
                {this.props.details.name}
              </div> :

              this.props.path.length === 3 && this.props.path[1] != "institute" ?
                <div className="entityview__top-breadcrumb">
                  <Link to="/">Home</Link> / {" "}
                  {<Link to={{ pathname: `/technology/${this.props.path[1].replaceAll(/ /g, "_")}` }}>
                    {this.props.path[1].replaceAll("_", " ")}
                  </Link>
                  }{" "}/{this.props.details.name}
                </div> :
                this.props.path[1] === "institute" ?
                  <div className="entityview__top-breadcrumb">
                    <Link to="/">Home</Link> / {this.props.details.name}
                  </div> : <></>}
            <div>
              <p className="entityview__top-head">
                {this.props.details.name}
              </p>
              <div className="entityview__subhead">
                <div className="entityview__subhead-section entityview__stats-section">
                  {this.props.details.address && (
                    <div className="entityview__subhead-desc">
                      <img src={locationIcon} alt='briefcase icon' className='entityview__subhead-desc-icon' />
                      {this.props.details.address.city}
                    </div>
                  )}
                </div>
                <div className="entityview__subhead-section entityview__subhead__buttons">
                  <div
                    className="entityview__subhead-btn"
                    onClick={this.props.toggleModal}
                  >
                    <i className="fas fa-share-alt" />
                  </div>
                  {this.props.details.url && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={httpfyUrl(
                        this.props.details.url
                      )}
                      className="entityview__subhead-btn"
                    >
                      <i className="fas fa-globe" />
                      &nbsp; Website
                    </a>
                  )}
                  {/* The follow button */}
                  {this.props.followButton}
                </div>
              </div>
            </div>
          </div>

          <div className="entityview__top-nav">
            {
              (this.props.researchersList && this.props.researchersList.length > 0)
                &&
              <div
                className={`entityview__top-nav-btn ${this.state
                  .currentMenuItem === "researchers" &&
                  "entityview__top-nav-btn--active"}`}
                onClick={this.changeCurrentMenuItem.bind(
                  null,
                  "researchers"
                )}
              >
                {`RESEARCHERS ${this.props.researchersList &&
                  this.props.researchersList.length > 0
                  ? "(" +
                  this.props.researchersList.length +
                  ")"
                  : ""
                  }`}
              </div>
            }


            {/*  <div
              className={`entityview__top-nav-btn ${this.state
                .currentMenuItem === "recognitions" &&
                "entityview__top-nav-btn--active"}`}
              onClick={this.changeCurrentMenuItem.bind(
                null,
                "recognitions"
              )}
            >
              {`RECOGNITIONS ${
                this.props.details.recognitions &&
                  this.props.details.recognitions.length > 0
                  ? "(" +
                  this.props.details.recognitions.length +
                  ")"
                  : ""
                }`}
              </div>*/}

            
            {
              totalCountInitiatives > 0 &&
            <div
              className={`entityview__top-nav-btn ${this.state
                .currentMenuItem === "projects" &&
                "entityview__top-nav-btn--active"}`}
              onClick={this.changeCurrentMenuItem.bind(
                null,
                "projects"
              )}
            >
              {`INITIATIVES AND PROJECTS ${filteredProjects && filteredProjects.length
                ? "(" + totalCountInitiatives + ")"
                : ""
                }`}
            </div>
            }

            {/*<div
              className={`entityview__top-nav-btn ${this.state
                .currentMenuItem === "workshops" &&
                "entityview__top-nav-btn--active"}`}
              onClick={this.changeCurrentMenuItem.bind(
                null,
                "workshops"
              )}
            >
              {`WORKSHOPS ${
                filteredWorkshops && filteredWorkshops.length
                  ? "(" + this.props.details.services.length + ")"
                  : ""
                }`}
              </div>*/}
            {/*  <div
              className={`entityview__top-nav-btn ${this.state
                .currentMenuItem === "patents" &&
                "entityview__top-nav-btn--active"}`}
              onClick={this.changeCurrentMenuItem.bind(
                null,
                "patents"
              )}
            >
              {`PATENTS ${
                this.props.details.patents &&
                  this.props.details.patents.length > 0
                  ? "(" +
                  this.props.details.patents.length +
                  ")"
                  : ""
                }`}
              </div>*/}
            {/*<div
              className={`entityview__top-nav-btn ${this.state
                .currentMenuItem === "blogs" &&
                "entityview__top-nav-btn--active"}`}
              onClick={this.changeCurrentMenuItem.bind(
                null,
                "blogs"
              )}
            >
              {`BLOGS ${
                this.props.details.blogs &&
                  this.props.details.blogs.length > 0
                  ? "(" +
                  this.props.details.blogs.length +
                  ")"
                  : ""
                }`}
              </div>*/}
            <div
              className={`entityview__top-nav-btn ${this.state
                .currentMenuItem === "about" &&
                "entityview__top-nav-btn--active"}`}
              onClick={this.changeCurrentMenuItem.bind(
                null,
                "about"
              )}
            >
              PROFILE
            </div>
          </div>
        </div>

        {this.state.currentMenuItem === "researchers" && (

          <CardsWithFilters
            type = 'academia'
            title="Researchers"
            researchersList={this.props.researchersList}
            technologyAreas={this.props.details.technology_type}
            disableFilters={true}
            items={this.props.details.individual_researchers}
          />
        )}
        {/*this.state.currentMenuItem === "recognitions" && (
          <CardsWithFilters
            title="Recognitions"
            technologyAreas={this.props.details.technology_type}
            disableFilters={true}
            items={this.props.details.recognitions}
          />
        )*/}

        {this.state.currentMenuItem === "projects" && (
          <InitiativesProject
            title="Projects"
            type = 'academia'
            technologyAreas={this.props.details.technology_type}
            projects={filteredProjects}
            workshops={filteredWorkshops}
            initiatives={filteredInitiatives}
          />
        )}
        {/*this.state.currentMenuItem === "workshops" && (
          <CardsWithFilters
            title="Workshops"
            technologyAreas={this.props.details.technology_type}
            items={filteredWorkshops}
          />
        )*/}
        {/*this.state.currentMenuItem === "patents" && (
          <CardsWithFilters
            title="Patents"
            technologyAreas={this.props.details.technology_type}
            items={this.props.details.patents}
            disableFilters={true}
          />
        )*/}
        {/*this.state.currentMenuItem === "blogs" && (
          <CardsWithFilters
            title="Blogs"
            technologyAreas={this.props.details.technology_type}
            items={this.props.details.blogs}
            disableFilters={true}
          />
        )*/}
        {this.state.currentMenuItem === "about" && (
          <AboutComponents
            title="Recognitions"
            details={this.props.details}
            technologyAreas={this.props.details.technology_type}
            disableFilters={true}
            recognitions={this.props.details.recognitions}
            patents={this.props.details.patents}
            news={news}
            showStats={false}
            showGeographies={false}
            name={this.props.details.name}
            about={this.props.details.about}
            businessHeads={this.props.details.business_heads}
            sectorsServed={this.props.details.sectors_served}
            geographiesServed={
              this.props.details.geographies_served
            }
            // Contact information
            url={this.props.details.url}
            address={this.props.details.address}
            contactPerson={this.props.details.contact_person}
          />
        )}

        {this.props.similarEntities &&
          this.props.similarEntities.length && (
            <div className="entityview__similar-wrapper">
              <div className="entityview__similar-head"
              >Similar Academia</div>

              <SimilarEntitiesCarousel
                carouselItems={this.props.similarEntities}
              />
            </div>
          )}
      </div>
    );
  }
}

export default AcademiaWrapper;
