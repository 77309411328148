import React from "react";

function ResearchersSvg(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 72 56'
      preserveAspectRatio="xMinYMin meet"
      className={props.className}
    >
      <g fill='none' transform='matrix(-1 0 0 1 72 0)'>
        <path
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M49 44c-2 4-2 7-2 9H27c2-5 8-10 18-15l4 6z'
        ></path>
        <path
          fill='#FFF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M47 41l2 3c1 2 6 3 9 3 2 0 2-5 2-6 0-2 2-2 2-3l-2-2h2v-3s3 0 2-3l-4-6v-9C55 6 47 3 36 5 19 8 22 24 25 30s4 15 2 23'
        ></path>
        <path fill='#C9DFFF' d='M28 50h19l-1 1v2H28v-3z'></path>
        <path
          fill='#FFF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M46 18h6m-6 4h6v5m-16-9H26m10 4h-5v6m7-13v-5m6 5v-5m0 20v-5m-6 5v-5'
        ></path>
        <path fill='#C9DFFF' d='M36 15h3v10h-3z'></path>
        <path
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M36 15h10v10H36z'
        ></path>
        <circle
          cx='31'
          cy='28'
          r='2'
          fill='#FFF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></circle>
        <circle
          cx='52'
          cy='18'
          r='2'
          fill='#FFF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></circle>
        <g
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          transform='translate(13 11)'
        >
          <path fill='#4C96FF' d='M7 1l6 4v7l-6 3-6-3V5z'></path>
          <circle cx='7' cy='1' r='1' fill='#FFF'></circle>
          <circle cx='1' cy='4' r='1' fill='#FFF'></circle>
          <circle cx='1' cy='11' r='1' fill='#FFF'></circle>
          <circle cx='13' cy='4' r='1' fill='#FFF'></circle>
          <circle cx='7' cy='15' r='1' fill='#FFF'></circle>
          <circle cx='13' cy='11' r='1' fill='#FFF'></circle>
        </g>
      </g>
    </svg>
  );
}

export default ResearchersSvg;
