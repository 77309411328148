import React from "react";

function EmptyStateIconSvg(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 490 240'
      preserveAspectRatio="xMinYMin meet"
      className={props.className}
    >
      <defs>
        <linearGradient id='a' x1='50%' x2='50%' y1='0%' y2='100%'>
          <stop offset='0%' stopColor='#142F70' stopOpacity='0.4'></stop>
          <stop offset='100%' stopColor='#142F70' stopOpacity='0'></stop>
        </linearGradient>
        <linearGradient id='b' x1='50%' x2='50%' y1='0%' y2='100%'>
          <stop offset='0%' stopColor='#142F70' stopOpacity='0.4'></stop>
          <stop offset='100%' stopColor='#142F70' stopOpacity='0'></stop>
        </linearGradient>
        <linearGradient id='c' x1='50%' x2='50%' y1='0%' y2='100%'>
          <stop offset='0%' stopColor='#142F70' stopOpacity='0.4'></stop>
          <stop offset='100%' stopColor='#142F70' stopOpacity='0'></stop>
        </linearGradient>
        <linearGradient id='d' x1='50%' x2='50%' y1='0%' y2='100%'>
          <stop offset='0%' stopColor='#142F70' stopOpacity='0.4'></stop>
          <stop offset='100%' stopColor='#142F70' stopOpacity='0'></stop>
        </linearGradient>
        <linearGradient id='e' x1='50%' x2='50%' y1='0%' y2='100%'>
          <stop offset='0%' stopColor='#142F70' stopOpacity='0.4'></stop>
          <stop offset='100%' stopColor='#142F70' stopOpacity='0'></stop>
        </linearGradient>
        <linearGradient id='f' x1='50%' x2='50%' y1='0%' y2='100%'>
          <stop offset='0%' stopColor='#142F70' stopOpacity='0.4'></stop>
          <stop offset='100%' stopColor='#142F70' stopOpacity='0'></stop>
        </linearGradient>
        <linearGradient id='g' x1='0%' y1='50%' y2='50%'>
          <stop offset='0%' stopColor='#142F70' stopOpacity='0.4'></stop>
          <stop offset='100%' stopColor='#142F70' stopOpacity='0'></stop>
        </linearGradient>
        <linearGradient id='h' x1='50%' x2='50%' y1='0%' y2='100%'>
          <stop offset='0%' stopColor='#142F70' stopOpacity='0.4'></stop>
          <stop offset='100%' stopColor='#142F70' stopOpacity='0'></stop>
        </linearGradient>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <g opacity='0.5' transform='rotate(-90 139 65)'>
          <path fill='url(#a)' d='M38 1h2v118h-2z'></path>
          <circle cx='39.2' cy='2.4' r='2.4' fill='#19A7B1'></circle>
          <path fill='url(#b)' d='M125 33h38v1h-38v61h-2V33h2z'></path>
          <path fill='url(#c)' d='M74 49h2v46h-2z'></path>
          <circle cx='75.3' cy='50.4' r='2.4' fill='#1E61A4'></circle>
          <path fill='url(#c)' d='M165 86h2v46h-2z'></path>
          <circle cx='165.8' cy='87.2' r='2.4' fill='#19A7B1'></circle>
          <path fill='url(#c)' d='M5 98h1v46H5z'></path>
          <circle cx='5.6' cy='96.8' r='2.4' fill='#19A7B1'></circle>
          <path fill='url(#d)' d='M143 12h2v22h-2z'></path>
          <circle cx='144.2' cy='13.6' r='2.4' fill='#1E61A4'></circle>
          <path fill='url(#d)' d='M2 39h1v23H2z'></path>
          <circle cx='2.4' cy='40.8' r='2.4' fill='#1E61A4'></circle>
          <path
            fill='url(#e)'
            d='M86 67h2v23h-2z'
            transform='rotate(-90 87 78)'
          ></path>
          <circle
            cx='100.8'
            cy='78.4'
            r='2.4'
            fill='#1E61A4'
            transform='rotate(-90 101 78)'
          ></circle>
          <path
            fill='url(#f)'
            d='M60 1h1v40h-1z'
            transform='rotate(-90 61 21)'
          ></path>
          <circle
            cx='83.2'
            cy='20.8'
            r='2.4'
            fill='#1E61A4'
            transform='rotate(-90 83 21)'
          ></circle>
          <circle
            cx='166.4'
            cy='33.6'
            r='2.4'
            fill='#142F70'
            transform='rotate(-90 166 34)'
          ></circle>
          <path fill='url(#g)' d='M2 60h38v2H2z'></path>
          <path fill='url(#h)' d='M134 70h1v54h-1z'></path>
        </g>
        <g opacity='0.5' transform='rotate(90 191 225)'>
          <path fill='url(#a)' d='M38 1h2v118h-2z'></path>
          <circle cx='39.2' cy='2.4' r='2.4' fill='#19A7B1'></circle>
          <path fill='url(#b)' d='M125 33h38v1h-38v61h-2V33h2z'></path>
          <path fill='url(#c)' d='M74 49h2v46h-2z'></path>
          <circle cx='75.3' cy='50.4' r='2.4' fill='#1E61A4'></circle>
          <path fill='url(#c)' d='M165 86h2v46h-2z'></path>
          <circle cx='165.8' cy='87.2' r='2.4' fill='#19A7B1'></circle>
          <path fill='url(#c)' d='M5 98h1v46H5z'></path>
          <circle cx='5.6' cy='96.8' r='2.4' fill='#19A7B1'></circle>
          <path fill='url(#d)' d='M143 12h2v22h-2z'></path>
          <circle cx='144.2' cy='13.6' r='2.4' fill='#1E61A4'></circle>
          <path fill='url(#d)' d='M2 39h1v23H2z'></path>
          <circle cx='2.4' cy='40.8' r='2.4' fill='#1E61A4'></circle>
          <path
            fill='url(#e)'
            d='M86 67h2v23h-2z'
            transform='rotate(-90 87 78)'
          ></path>
          <circle
            cx='100.8'
            cy='78.4'
            r='2.4'
            fill='#1E61A4'
            transform='rotate(-90 101 78)'
          ></circle>
          <path
            fill='url(#f)'
            d='M60 1h1v40h-1z'
            transform='rotate(-90 61 21)'
          ></path>
          <circle
            cx='83.2'
            cy='20.8'
            r='2.4'
            fill='#1E61A4'
            transform='rotate(-90 83 21)'
          ></circle>
          <circle
            cx='166.4'
            cy='33.6'
            r='2.4'
            fill='#142F70'
            transform='rotate(-90 166 34)'
          ></circle>
          <path fill='url(#g)' d='M2 60h38v2H2z'></path>
          <path fill='url(#h)' d='M134 70h1v54h-1z'></path>
        </g>
        <path
          fill='#FFF'
          fillRule='nonzero'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M210 73h64v80h-64z'
        ></path>
        <path
          fill='#FFF'
          fillRule='nonzero'
          stroke='#4C96FF'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M216 79h64v80h-64z'
        ></path>
        <path
          fill='#9CA6CC'
          d='M227 93h42a1 1 0 010 2h-42a1 1 0 010-2zm0 8h42a1 1 0 010 2h-42a1 1 0 010-2zm0 8h42a1 1 0 010 2h-42a1 1 0 010-2zm0 8h42a1 1 0 010 2h-42a1 1 0 010-2zm0 8h42a1 1 0 010 2h-42a1 1 0 010-2zm0 8h21a1 1 0 010 2h-21a1 1 0 010-2z'
        ></path>
        <path
          fill='#FFF'
          fillRule='nonzero'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M222 90v-5h5m47 5v-5h-5m5 63v5h-5m-47-5v5h5'
        ></path>
        <g
          fillRule='nonzero'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          transform='translate(254 136)'
        >
          <path fill='#FFF' d='M5 13h12v18l-6-5-6 5z'></path>
          <path
            fill='#4C96FF'
            d='M11 21l-2 1-2-2H5l-1-2-2-1v-2l-2-2 1-2-1-2 2-2V5l2-1 1-2h2l2-2 2 1 2-1 2 2h2l1 2 2 1v2l2 2-1 2 1 2-2 2v2l-2 1-1 2h-2l-2 2z'
          ></path>
          <circle cx='11' cy='11' r='5' fill='#FFF'></circle>
        </g>
      </g>
    </svg>
  );
}

export default EmptyStateIconSvg;
