import React from 'react';
//import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import '../css/ResetPasswordModal.css'

const ResetPasswordModal = ({ showModal, handleClose, handleReset,  }) => {

    return (
        <div className={`reset_password_modal__wrapper ${showModal ? 'reset_password_modal__wrapper--shown' : ''}`} id='reset_password_modal'>
            <div className='reset_password_modal__background--hightlight' />
            <div className={`reset_password_modal ${showModal ? 'reset_password_modal--shown' : ''}`}>
                <div className='reset_password_modal__header'>
                    Logged Out!!
                </div>
                <hr className='reset_password_modal__underline' />
                <div className='reset_password_modal__body'>
                    <p className='reset_password_modal__content'>Your session has expired due to a password change or simultaneous logins from different devices. Would you like to reset your password?</p>
                    <hr className='reset_password_modal__underline' />

                    <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'flex-end' }}>
                        <div style={{
                            width: 84, height: 40, backgroundColor: '#d82123', marginTop: 10, border: '1px solid #D9d9d9',
                            borderRadius: 8
                        }}>
                            <button
                                type='button' className='reset_password_modal__btn' onClick={handleClose}>
                                Close</button>
                        </div>
                        <div style={{
                            width: 84, height: 40, backgroundColor: '#00acee', marginTop: 10, border: '1px solid #D9d9d9',
                            borderRadius: 8, marginLeft: '1rem'
                        }}>
                            <button
                                type='button' className='reset_password_modal__btn' onClick={handleReset}>
                                Reset Password</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>




    )
}

export default ResetPasswordModal