import React, { Fragment, Component } from "react";
import { reduxForm } from "redux-form";
import FormProducts from "../form-elements/FormProducts";
import { connect } from "react-redux";
import { toast } from "react-toastify";

// import validate from '../utils/validate';
import validate from "../utils/validate";

class FormPart7 extends Component {
    validateOfferings = () => {
        if (
            this.props.enteredProducts[0].name ||
            this.props.enteredServices[0].name ||
            this.props.enteredSolutions[0].name
        ) {
            this.props.onSubmit();
        } else {
            toast.error(
                "You must enter at least one product, service or solution to continue"
            );
        }
    };

    render() {
        const { handleSubmit } = this.props;
        const onKeyPress = event => {
            if (event.key === "Enter") {
                event.preventDefault();
            }
        };

        return (
            <Fragment>
                <p className="feature_form__head">Solutions</p>
                <form
                    onSubmit={handleSubmit(this.validateOfferings)}
                    onKeyPress={onKeyPress}
                >
                    <div className="feature_form__inner-wrapper">
                        <FormProducts
                            title="Solutions"
                            singular="Solution"
                            section="solutions"
                            formType="company"
                            technologyAreas={this.props.technologyAreas}
                        />
                    </div>
                    <div className="feature_form__btn-wrapper">
                        <button
                            className="feature_form__btn feature_form__btn-back"
                            onClick={this.props.prevPage}
                        >
                            GO BACK
                        </button>
                        <button type="submit" className="feature_form__btn">
                            CONTINUE
                        </button>
                    </div>
                </form>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        enteredProducts: state.form.feature_yourself_old.values.products,
        enteredServices: state.form.feature_yourself_old.values.services,
        enteredSolutions: state.form.feature_yourself_old.values.solutions,
    };
};

FormPart7 = reduxForm({
    form: "feature_yourself_old", // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(FormPart7);

export default connect(mapStateToProps)(FormPart7);
