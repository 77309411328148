import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setUserInfo } from '../actions/auth';
import { reset } from 'redux-form';

import EditProfileForm from './dashboard-components/EditProfileForm';
import ChangePasswordForm from './dashboard-components/ChangePasswordForm';
import NotificationFrequencyForm from './dashboard-components/NotificationFrequencyForm';

import '../css/MyAccount.css';
import profile from '.././img/user.png';
import api from '../api';
import { toast } from 'react-toastify';
import ProfileUpload from '../modal/ProfileUpload';
import { event } from 'jquery';
import IdleTimeOutModal from '../modal/IdleTimeOutModal'
import PasswordChangeModal from '../components/PasswordChangeModal'
import {encryptAES} from "../utils/helpers"
class MyAccount extends React.Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    phoneNo: '',
    designation: '',
    uploadProfileImage:false,
    areNotificationsEnabled: true,            //  Controlled input for notification ON/OFF toggle
    currentNotificationFrequency: 'daily',    //  For managing the selected input radio button
    notif_config: {                           //  notif_config stores the original retrieved notif_config
      status: true,
      type: 'daily',
    },
    imageBase64:'',
    imageName:'',
    profileUrl:'',
    idleTimeOut : false
  }

  componentDidMount() {
    this.getProfileSettings();
    this.firstNameRef = React.createRef();
    this.changePassRef = React.createRef();
  }

  UploadProfile=(event)=>{
    this.setState({uploadProfileImage:true})
  }
handleClose =(event)=>{
  this.setState({uploadProfileImage:false})
}
handleChange=(file)=>{
 
  this.setState({
    imageBase64:file.base64,
    imageName:file.name
  })
  // console.log(this.state.imageName)
}
  getProfileSettings = () => {
    const requestConfig = {
      method: 'GET',
      headers: { TCRAUTH: this.props.userInfo.token }
    };

    fetch(api.getProfilePage, requestConfig).then(response => {
      if (!response || !response.ok) throw new Error('Status code: ' + response.status);
      return response.json();
    }).then(response => {
      // Get notification config (if not present, default to ON and DAILY )
      const areNotificationsEnabled = response.data.notif_config
        ? response.data.notif_config.status : false;
      const currentNotificationFrequency = response.data.notif_config
        ? response.data.notif_config.type : 'daily';
      // Store values in state
      this.setState({
        firstName: response.data.first_name,
        lastName: response.data.last_name,
        email: response.data.email,
        company: response.data.company_name,
        phoneNo: response.data.contact_number,
        designation: response.data.designation,
        profileUrl: response.data.profile_url,
        areNotificationsEnabled: areNotificationsEnabled,
        currentNotificationFrequency: currentNotificationFrequency,
        notif_config: {
          status: areNotificationsEnabled,
          type: currentNotificationFrequency,
        }
      });
    }).catch(err => {
      // console.log('Error fetching account settings', err);
    })
  }

  focusFirstName = () => this.firstNameRef.current.focus();
  focusPassword = () => this.changePassRef.current.focus();

  handleChangePassword = values => {
    const oldEncryptedPassword = encryptAES(values.currentPassword)
    const newEncryptedPassword = encryptAES(values.newPassword)


    const requestBody = {
      old_password: oldEncryptedPassword,
      new_password: newEncryptedPassword,
      email: this.props.userInfo.email,
      user_checked_in_time: this.props.userInfo.user_checked_in_time ,
      user_track_id : this.props.userInfo.user_track_id
    };

    const requestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        TCRAUTH: this.props.userInfo.token
      },
      body: JSON.stringify(requestBody)
    };

    fetch(api.changePassword, requestConfig).then(response => {
       
    if(response.status === 404){
      // console.log("404 status")
      localStorage.setItem("reloading", "true");
      localStorage.removeItem("user");
      this.props.setUserInfo(null);
      this.props.history.push('/')
      toast.success("You're Logged Out!");
      window.location.reload();
      return
    }
    if (response && response.status === 403) {
      toast.info('Your current password is incorrect!');
      return;
    } else if (!response || !response.ok) {
      // console.log('Something went wrong while changing password', response.status);
      toast.info('Something went wrong while changing password');
      return;
      
    } else if(response.status === 404){
      // console.log("print")
    } 
  
    else {
      toast.success('Password successfully changed!');
      this.setState({idleTimeOut:true})
      this.props.resetPasswordForm();
    }
    
    }).catch(err => {
      // console.log('An error occurred while trying to change password', err);
      toast.error('Could not change password at this time, please try again later');
    });
  }

  handleProfileFormSubmit = values => {
    
     


    const requestBody = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      company_name: values.company,
      contact_number: values.phoneNo,
      designation: values.designation,
      profile:this.state.imageBase64,
      profile_name: this.state.imageName,
      user_track_id : this.props.userInfo.user_track_id
    };
 
    const requestConfig = {
      method: 'POSt',
      headers: {
        'Content-Type': 'application/json',
        'TCRAUTH': this.props.userInfo.token
      },
      body: JSON.stringify(requestBody)
    };

    fetch(api.changeProfile, requestConfig).then(response => {
      if(response.status === 404){
        // console.log("404 status")
        localStorage.setItem("reloading", "true");
        localStorage.removeItem("user");
        this.props.setUserInfo(null);
        this.props.history.push('/')
        toast.success("You're Logged Out!");
        window.location.reload();
        return
      }
      
      if (!response || !response.ok) {
        // console.log('Could not update profile', response.status);
        toast.info('Could not update the profile, please try later');
        return;
      }

      // console.log('Profile update response = ', response);
      toast.success('Profile Successfully Updated!');
      response.json().then(response => {
        try {
          let userObj = JSON.parse(localStorage.getItem('user'));
          userObj = { ...userObj, ...requestBody };
          this.props.setUserInfo(userObj);
          localStorage.setItem('user', JSON.stringify(userObj));
        } catch (err) {
          // console.log('Error occurred while parsing', err);
        }
      });
    }).catch(err => {
      // console.log('An error occurred while trying to update profile', err);
      toast.error('Could not update profile at this time, please try again later');
    });;
  }

  handleNotificationToggle = () => {
    this.setState(prevState => ({
      areNotificationsEnabled: !prevState.areNotificationsEnabled
    }));
  }

  handleNotificationRadioChange = e => {
    // console.log('e = ', e.target.value);
    this.setState({ currentNotificationFrequency: e.target.value });
  }

  handleChangeNotificationSettings = () => {
    const requestBody = {
      notif_config: {
        status: this.state.areNotificationsEnabled,
        type: this.state.currentNotificationFrequency
      }
    };

    const requestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'TCRAUTH': this.props.userInfo.token
      },
      body: JSON.stringify(requestBody)
    };

    fetch(api.editNotificationSettings, requestConfig).then(response => {
      if (!response || !response.ok) throw new Error('Status code: ' + response.status);
      return response.json();
    }).then(response => {
      // console.log('Response is => ', response);
      toast.success('Notification settings updated successfully!');
    }).catch(err => {
      // console.log('Error occurred in changing notification settings = ', err);
      toast.error('Could not update settings right now, please try again later');
    });
  }

  handleLogout = () => {
    const requestPostConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'TCRAUTH': this.props.userInfo.token
      }
    };

    fetch(api.consumerLogout, requestPostConfig).then(response => {
      if (!response || !response.ok) throw new Error('Status code: ' + response.status);
      return response.json();
    }).then(response => {
      this.setState({ showModal: false })
      localStorage.removeItem("user");
      this.props.setUserInfo(null);
      this.props.history.push('/')
      window.location.reload();
      toast.success("You're Logged Out!");
    }).catch(err => {
      // console.log('Error fetching notification records', err);
    })

  }


  handleClose() {
    this.setState({ idleTimeOut: false })
  }

  render() {
    document.title = "My Account- Technology Capability Repository || DSCI";

    const profileFormInitialValues = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      company: this.state.company,
      phoneNo: this.state.phoneNo,
      designation: this.state.designation
    };
 
    let isNotificationsButtonDIsabled = true;
    if ((this.state.areNotificationsEnabled !== this.state.notif_config.status)
      || (this.state.currentNotificationFrequency !== this.state.notif_config.type)) {
      isNotificationsButtonDIsabled = false;
    }
  
//    if(this.state.idleTimeOut===false){
//      return(
//<><IdleTimeOutModal
//  showModal={true}
//  handleClose={this.handleClose}
//  handleLogout={this.handleLogout}/>
//</>
//      )
//    }
     
    return (
      <div>
     
        <div className="entityview__top">
          <div className="entityview__top-desc-wrapper">
            <nav aria-label="Breadcrumb" class="breadcrumb">
              <ul>
                <li><a href="/">Home</a></li>
                <li><span aria-current="page">My Account</span></li>
              </ul>
            </nav>
            {this.state.profileUrl && this.state.profileUrl.length>0 ?
              <img src={this.state.profileUrl}  alt="Submit" className='myaccount_profile' onClick={this.UploadProfile}/> :
              <img src={profile}   className='myaccount_profile' alt="Submit" onClick={this.UploadProfile}/>
            }   
            {/*this.state.uploadProfileImage &&  
            <ProfileUpload    
               profileImage = {this.state.profileUrl}
                showModal={true}
                handleClose={this.handleClose}
                handleChange = {this.handleChange}
                onSubmit={this.handleProfileFormSubmit}
               // handleProcessSubmit={handleProcessSubmit} 
          />*/}
            <div>
              <p className="entityview__top-head">My Account</p>
            </div>
          </div>
        </div>

        <main className='my-account'>
          <div className='my-account__left-nav'>
            <ul className='my-account__left-nav-ul'>
              <li
                onClick={this.focusFirstName}
                className='my-account__left-nav-li'
              >Profile</li>
              <li
                onClick={this.focusPassword}
                className='my-account__left-nav-li'
              >Change Password</li>
              {/* <li className='my-account__left-nav-li'>Notification Settings</li> */}
            </ul>
          </div>

          <div className='my-account__content'>
            <section className='my-account__section'>
              <p className='my-account__section-heading'
              >Profile</p>

              <EditProfileForm
                myRef={this.firstNameRef}
                onSubmit={this.handleProfileFormSubmit}
                initialValues={profileFormInitialValues}
              />
            </section>

            <section className='my-account__section'>
              <p className='my-account__section-heading'
              >Change Password</p>

              <ChangePasswordForm
                myRef={this.changePassRef}
                onSubmit={this.handleChangePassword}
              />
            </section>

            <section className='my-account__section'>
              <NotificationFrequencyForm
                areNotificationsEnabled={this.state.areNotificationsEnabled}
                currentNotificationFrequency={this.state.currentNotificationFrequency}
                handleNotificationToggle={this.handleNotificationToggle}
                handleNotificationRadioChange={this.handleNotificationRadioChange}
                handleChangeNotificationSettings={this.handleChangeNotificationSettings}
                isButtonDisabled={isNotificationsButtonDIsabled}
              />
            </section>
        
          </div>
        </main>

      </div>
    )
  }
}

const mapStateToProps = state => ({
  userInfo: state.auth.userInfo
});

const mapDispatchToProps = dispatch => ({
  setUserInfo: userInfo => dispatch(setUserInfo({ userInfo: userInfo })),
  resetPasswordForm: () => dispatch(reset('change_password'))
});

MyAccount = connect(mapStateToProps, mapDispatchToProps)(MyAccount);
export default withRouter(MyAccount);