import React, { Fragment } from "react";

import IndividualInvestor from "./investorview-components/IndividualInvestor";
import CompanyInvestor from "./investorview-components/CompanyInvestor";
import {
    //  dummyPersonInvestorData,
    dummyCompanyInvestorData,
    dummyPersonInvestorData 
} from "./investorview-components/dummyData.js";
class InvestorsView extends React.Component {
    state = {
        investor: {},
        investorType: "",
        currentMenuItem: "investor",
    };

    componentDidMount() {
        //  TODO: Call API to get details
        //  Set state based on whether the entity is person or company
        const demoInvestorType = "person";
        // const demoAPIresponse2 = dummyPersonInvestorData;
        const demoAPIresponse = dummyPersonInvestorData ;
        const currentMenuItem =
            demoInvestorType === "person" ? "investor" : "portfolio";

        this.setState({
            investorType: demoInvestorType,
            investor: demoAPIresponse,
            currentMenuItem: currentMenuItem,
        });
    }

    changeCurrentMenuItem = itemName =>
        this.setState({ currentMenuItem: itemName });

    // TODO -- define onClick for followButton
    createFollowButton = () => {
        return (
            <div
                className="entityview__subhead-btn"
                style={{ backgroundColor: "#4c96ff" }}
            >
                {this.state.investor.liked ? (
                    <Fragment>
                        <i className="fas fa-user-minus" />
                        &nbsp; Unfollow
                    </Fragment>
                ) : (
                    <Fragment>
                        <i className="fas fa-user-plus" />
                        &nbsp; Follow
                    </Fragment>
                )}
            </div>
        );
    };

    render() {
        const followButton = this.createFollowButton();

        if (this.state.investorType === "person") {
            return (
                <IndividualInvestor
                    data={this.state.investor}
                    followButton={followButton}
                    currentMenuItem={this.state.currentMenuItem}
                    changeCurrentMenuItem={this.changeCurrentMenuItem}
                />
            );
        } else if (this.state.investorType === "company") {
            return (
                <CompanyInvestor
                    data={this.state.investor}
                    followButton={followButton}
                    currentMenuItem={this.state.currentMenuItem}
                    changeCurrentMenuItem={this.changeCurrentMenuItem}
                />
            );
        }

        return null;
    }
}

export default InvestorsView;
