import React, { Fragment } from "react";
import "../../css/InvestmentTimeline.css";
import { Link } from "react-router-dom";
import { Timeline, TimelineEvent } from "react-event-timeline";
// import LinkIcon from "../../img/link.svg";

const timelineStyle = {
    backgroundColor: "#fff",
};

const lineStyle = {
    backgroundColor: "#f1f1f1",
};

const cardStyle = {
    margin: "10px 0 0 0",
    backgroundColor: "#f8f8f8",
    border: "none",
    padding: "5px 15px",
    color: "#81858c",
    width: "100%",
    fontWeight: "bold",
    boxShadow: "none",
};

const bubbleStyles = {
    padding: "0",
    border: "none",
    top: "3px",
    left: "9px",
    width: "15px",
    height: "15px",
    backgroundColor: "#fff",
};

const titleStyles = {
    fontSize: "1.4rem",
    color: "#494b4f",
    fontWeight: "bold",
};

const monthNumberToText = num => {
    if (num === 0) return "January";
    else if (num === 1) return "February";
    else if (num === 2) return "March";
    else if (num === 3) return "April";
    else if (num === 4) return "May";
    else if (num === 5) return "June";
    else if (num === 6) return "July";
    else if (num === 7) return "August";
    else if (num === 8) return "September";
    else if (num === 9) return "October";
    else if (num === 10) return "November";
    else if (num === 11) return "December";
};

const getCreatedAtNode = date => {
    const jsDate = new Date(date);
    const month = monthNumberToText(jsDate.getMonth());
    const year = jsDate.getFullYear();
    //console.log("month = ", month);
    return <p className="timeline__created-at">{`${month}, ${year}`}</p>;
};

const getTitle = (moneyRaised, valuation, round) => {
     
    if (!moneyRaised || moneyRaised === -1) moneyRaised = "Undisclosed";
    else moneyRaised = `$${Number(parseFloat(moneyRaised))}M raised`;

    if (!valuation || valuation === -1) valuation = "";
    else
        valuation = `| $${Number(parseFloat(valuation))}M valuation`;

    return (
        <div className="timeline__amounts-wrapper">
            <p className="timeline__amount-raised">
                {moneyRaised}{" "}
                <span className="timeline__total-valuation">{valuation} </span>
            </p>
            <p className="timeline__seeding-round">{round}</p>
        </div>
    );
};

const getLedByNode = investorsObj => {
    if (!investorsObj || !investorsObj.length) return "";

    let name = null;
    let amount = null;
    const resultArray = [];

    for (let i = 0; i < investorsObj.length; i++) {
        name = investorsObj[i].investor_name;
        amount =
            !investorsObj[i].money_invested ||
            investorsObj[i].money_invested === -1
                ? ""
                : Number(parseFloat(investorsObj[i].money_invested));
       // console.log(amount)
        const node = (
            
            <Fragment key={investorsObj[i].investor_name}>
                {i === 0 && <span>Led by&nbsp;</span>}
                <Link to = "/investors/1"> 
                <p className="timeline__led-by-name" >{name}&nbsp;</p>
                </Link>
                <p className="timeline__led-by-amount">
                  {investorsObj[i].money_invested!= -1 &&  
                  <>${amount}M</>} 
                    {investorsObj[i + 1] && <span>&nbsp;・&nbsp;</span>}
                </p>
            </Fragment>
        );
        resultArray[i] = node;
    }

    return <div className="timeline__led-by">{resultArray}</div>;
};

class FinancialTimeline extends React.Component {
    render() {
        // if (!this.props.data) return null;

       // console.log("fintimeline data = ", this.props.data);
        const timelineEvents = this.props.data.map(round => {
            //console.log("round = ", round);
            const title = getTitle(
                round.money_raised,
                round.valuation,
                round.funding_type
            );
            const createdAt = getCreatedAtNode(round.announced_on);
            const subtitle = getLedByNode(round.lead_investors);

            return (
                <TimelineEvent
                    key={round.round}
                    contentStyle={cardStyle}
                    bubbleStyle={bubbleStyles}
                    titleStyle={titleStyles}
                    title={title}
                    createdAt={createdAt}
                    subtitle={subtitle}
                    icon={<i className="far fa-circle timeline-circle" />}
                >
                    <div>
                        <p className="timeline-event-content">
                            {round.annoucement_headline
                                ? round.annoucement_headline
                                : ""}
                        </p>
                    </div>
                </TimelineEvent>
            );
        });

        return (
            <Timeline
                style={timelineStyle}
                lineStyle={lineStyle}
                orientation="left"
                lineColor="#ccc"
            >
                {timelineEvents}
            </Timeline>
        );
    }
}

export default FinancialTimeline;
