import React, { Component } from "react";
import { Link } from "react-router-dom";

import { httpfyUrl } from "../../utils/helpers";

import CardsWithFilters from "./CardsWithFilters";
import AboutComponents from "./AboutComponents";
import SimilarEntitiesCarousel from "./SimilarEntitiesCarousel";
import InitiativesProject from "./InitiativesProject";
import "../../css/EntityView.css";
import locationIcon from '../../img/location-light.svg';
import businessTypeIcon from '../../img/business-type-light.svg';
import job_profile from "../../assets/business-type.svg";
import locationSvg from "../../assets/location-light.svg";
import prepareTechnologyTypeString from "../Researchers/techTypeUtils";
class IndividualWrapper extends Component {
  state = {
    currentMenuItem: "papers",
  };

  changeCurrentMenuItem = item => {
    this.setState({
      currentMenuItem: item,
    });
  };


  componentDidMount() {
    // Check if both research papers and products are empty



    if (
      (!this.props.details.research_papers || this.props.details.research_papers.length === 0) &&
      (!this.props.details.products || this.props.details.products.length === 0) &&
      (!this.props.details.services || this.props.details.services.length === 0)
    ) {
      // Set the state to "about" if conditions are met

      this.setState({
        currentMenuItem: "about",
      });
    }

    if (this.props.details.research_papers.length === 0 && (this.props.details.products.length > 0 || this.props.details.services.length > 0)) {
      // Set the state to "initiatives" if research papers are empty but initiatives have data

      this.setState({
        currentMenuItem: "projects",
      });
    }

    if (this.props.details.research_papers.length > 0 && (this.props.details.products.length === 0 || this.props.details.services.length === 0)) {
      //const sortedPapers = this.props.details.research_papers.sort((a, b) => b.year - a.year);

//console.log("papers", sortedPapers)
      this.setState({
        currentMenuItem: "papers",
      });
    }

  }


  render() {
    const sortedPapers = this.props.details.research_papers.sort((a, b) => b.year - a.year);
    //let pathList = this.props.location.pathname.split('/')[1]
    //console.log(pathList)
    let totalCitations = 0;
    var total_publish = 0;
    if (this.props.details.stats && this.props.details.stats.citations) {
      totalCitations = this.props.details.stats.citations;
    } else {

      const totalCitations = this.props.details.research_papers
      ? this.props.details.research_papers.reduce((total, currObj) => {
          return total + (currObj.citations ? Number(currObj.citations) : 0);
        }, 0)
      : 0; // If research_papers doesn't exist, set totalCitations to 0
    }
    if (this.props.details.hasOwnProperty("total_document")) {
      // console.log("yes",this.props.details.total_document)
      var total_publish = this.props.details.total_document
    }
    else {
      var total_publish = this.props.details.research_papers.length
    }
    //console.log(total_publish)


    //  Filter out the projects and services that have no name
    const filteredProjects = this.props.details.products.filter(item => item.name);
    const filteredWorkshops = this.props.details.services.filter(item => item.name);
    const researchPapers = this.props.details.research_papers



    var filteredInitiatives = []
    var news = []
    if ("initiatives" in this.props.details) {
      var filteredInitiatives = this.props.details.initiatives.filter(item => item.name);
    }
    if ("news" in this.props.details) {
      var news = this.props.details.news
    }
    const totalCountInitiatives = filteredInitiatives.length + filteredProjects.length + filteredWorkshops.length

    const averageCitations = this.props.details.average_citations
      ? this.props.details.average_citations
      : totalCitations / total_publish;
    const path = this.props.path
    document.title = `${this.props.details.name} - Technology Capability Repository || DSCI`;
    // document.getElementsByTagName("LINK")[0].href=  `https://techsagar.in/researcher/${this.props.details.name.replace("%", "percentage").replace(/ /g, "-").replaceAll(".","").replace("---","-").replace(",","").toLowerCase()}`
    document.getElementsByTagName("LINK")[0].href = ["company", "institute", "rd-centre", "researcher"].includes(this.props.path[1]) ? `https://www.techsagar.in` : `https://www.techsagar.in/technology/${path[1].replaceAll("_", "-")}`
   
    return (
      <div className="entityview__wrapper">
        <div className="entityview__top">
          <div className="entityview__top-desc-redesign-wrapper">
            {this.props.path.length === 4 ?
              <div className="entityview__top-breadcrumb">
                <Link to="/">Home</Link> / {" "}
                {<Link to={{ pathname: `/technology/${this.props.path[1].replace(/ /g, "_")}` }}>
                  {this.props.path[1].replaceAll("_", " ")}
                </Link>
                }{" "}/
                {<Link to={{ pathname: `/technology/${this.props.path[1].replaceAll(/ /g, "_")}/${this.props.path[2].replaceAll('_', " ")}` }}>
                  {this.props.path[2].replaceAll("_", " ").replace("12", "/")}
                </Link>
                }{" "}/
                {this.props.details.name}
              </div> :

              this.props.path.length === 3 && this.props.path[1] != "researcher" ?
                <div className="entityview__top-breadcrumb">
                  <Link to="/">Home</Link> / {" "}
                  {<Link to={{ pathname: `/technology/${this.props.path[1].replaceAll(/ /g, "_")}` }}>
                    {this.props.path[1].replaceAll("_", " ")}
                  </Link>
                  }{" "}/{this.props.details.name}
                </div> :
                this.props.path[1] === "researcher" &&
                <div className="entityview__top-breadcrumb">
                  <Link to="/">Home</Link> / {this.props.details.name}
                </div>}
            <div >
              <p className="entityview__top-head">
                {this.props.details.title} &nbsp;
                {this.props.details.name}
              </p>
              <div className="entityview__subhead">
                <div className="entityview__subhead-section entityview__stats-section">
                  {this.props.details.designation && (
                    <div className="entityview__subhead-desc" style={{display: "flex", alignItems: "flex-start"}}>
                      <img src={job_profile} alt='briefcase icon' className='entityview__subhead-desc-icon' />
                      {this.props.details.designation}
                    </div>
                  )}
                  {this.props.details.affiliated_to && (
                    <div className="entityview__subhead-desc" style={{display: "flex", alignItems: "flex-start"}}>
                      <img src={locationSvg} alt='briefcase icon' className='entityview__subhead-desc-icon' />
                      {this.props.details.affiliated_to}
                    </div>
                  )}
                  {this.props.details.address && (
                    
                    <div className="entityview__subhead-desc" style={{display: "flex", alignItems: "flex-start"}}>
                      {this.props.details.technology_type.length !== 0 && (
                      // <p className='research_area'>
                        <div style={{display: "flex", alignItems: "flex-start", gap: "2px"}}>
                          <i className="fa fa-solid fa-book" style={{ fontSize: "1.5em", marginRight: "0.5rem" }}></i>
                          {/* this.props.details.res_area || "Data Science: ML, AI" */}
                          {prepareTechnologyTypeString(this.props.details.technology_type)}
                        </div>
                      // </p>
                    )}
                    </div>
                  )}
                  {/* {this.props.details.address && (
                    <div className="entityview__subhead-desc">
                      <img src={locationIcon} alt='briefcase icon' className='entityview__subhead-desc-icon' />
                      {this.props.details.address.city}
                    </div>
                  )} */}
                </div>
                <div className="entityview__subhead-section entityview__subhead__buttons" style={{maxHeight: "44px"}}>
                  <div
                    className="entityview__subhead-btn"
                    onClick={this.props.toggleModal}
                  >
                    <i className="fas fa-share-alt" />
                  </div>
                  {/* <div> */}
                    {this.props.details.url && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={httpfyUrl(
                          this.props.details.url
                        )}
                        className="entityview__subhead-btn"
                        style={{display: "flex", alignItems: "center", gap: "6px"}}
                      >
                        <i className="fas fa-globe" />
                        Website
                      </a>
                    )}
                  {/* </div> */}
                  {this.props.followButton}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="entityview__top-nav">
            {this.props.details.research_papers.length > 0 &&
              <div
                className={`entityview__top-nav-btn ${this.state
                  .currentMenuItem === "papers" ?
                  "entityview__top-nav-btn--active" : ""}`}
                onClick={this.changeCurrentMenuItem.bind(
                  null,
                  "papers"
                )}
              >
                {`PAPERS ${this.props.details.research_papers &&
                  this.props.details.research_papers.length > 0
                  ? "(" +
                  this.props.details.research_papers
                    .length +
                  ")"
                  : ""
                  }`}
              </div>}
            {(filteredProjects.length > 0 || filteredInitiatives.length > 0) &&
              <div
                className={`entityview__top-nav-btn ${this.state
                  .currentMenuItem === "projects" ?
                  "entityview__top-nav-btn--active" : ""}`}
                onClick={this.changeCurrentMenuItem.bind(
                  null,
                  "projects"
                )}
              >
                {`INITIATIVES AND PROJECTS ${filteredProjects && filteredProjects.length
                  ? "(" + filteredProjects.length + ")"
                  : ""
                  }`}
              </div>
            }

            <div
              className={`entityview__top-nav-btn ${this.state.currentMenuItem === "about" ? "entityview__top-nav-btn--active" : ""}`}
              onClick={this.changeCurrentMenuItem.bind(null, "about")}
            >
              PROFILE
            </div>
          </div> */}
        </div>
        {/* {this.state.currentMenuItem === "papers" && ( */}
        <div className="individual__wrapper_components">
          <AboutComponents
            title="Recognitions"
            technologyAreas={this.props.details.technology_type}
            disableFilters={true}
            recognitions={this.props.details.recognitions}
            patents={this.props.details.patents}
            news={news}
            showGeographies={false}
            showStats={true}
            name={this.props.details.name}
            about={this.props.details.about}
            businessHeads={this.props.details.business_heads}
            sectorsServed={this.props.details.sectors_served}
            geographiesServed={
              this.props.details.geographies_served
            }
            papersPublished={total_publish}
            averageCitations={averageCitations}
            totalCitations={totalCitations}
            //  Contact information
            url={this.props.details.url}
            address={this.props.details.address}
            contactPerson={this.props.details.contact_person}
          />
          {
            (this.props.details.hasOwnProperty("research_papers") && Array.isArray(this.props.details.research_papers) && this.props.details.research_papers.length > 0)
            &&
              <CardsWithFilters
                //cardsCss1 = 'entityview__desc-cards-wrapper-researcher'
                //cardscss2 = 'entityview__desc-wrapper-researcher'
                type = 'researcher'
                disableFilters={true}
                title="Papers"
                technologyAreas={this.props.details.technology_type}
                items={sortedPapers}
              />
          }

          <InitiativesProject
            title="Projects"
            type = 'researcher'
            technologyAreas={this.props.details.technology_type}
            projects={filteredProjects}
            workshops={filteredWorkshops}
            initiatives={filteredInitiatives}
          />
        </div>

          
        {/* )} */}
        {/* {this.state.currentMenuItem === "projects" && (
          <InitiativesProject
            title="Projects"
            technologyAreas={this.props.details.technology_type}
            projects={filteredProjects}
            workshops={filteredWorkshops}
            initiatives={filteredInitiatives}
          />
        )} */}

        {/* {this.state.currentMenuItem === "about" && (
          <AboutComponents
            title="Recognitions"
            technologyAreas={this.props.details.technology_type}
            disableFilters={true}
            recognitions={this.props.details.recognitions}
            patents={this.props.details.patents}
            news={news}
            showGeographies={false}
            showStats={true}
            name={this.props.details.name}
            about={this.props.details.about}
            businessHeads={this.props.details.business_heads}
            sectorsServed={this.props.details.sectors_served}
            geographiesServed={
              this.props.details.geographies_served
            }
            papersPublished={total_publish}
            averageCitations={averageCitations}
            totalCitations={totalCitations}
            //  Contact information
            url={this.props.details.url}
            address={this.props.details.address}
            contactPerson={this.props.details.contact_person}
          />
        )} */}

        {/* {this.props.similarEntities && this.props.similarEntities.length &&
          <div className="entityview__similar-wrapper">
            <div className="entityview__similar-head">
              Similar Researchers
            </div>

            <SimilarEntitiesCarousel
              carouselItems={this.props.similarEntities}
            />
          </div>} */}
      </div>
    );
  }
}

export default IndividualWrapper;
