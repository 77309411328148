import React from "react";

// CSS
import "./Backdrop.css";



const Backdrop = props =>
    props.show ? (
        <div
            className="backdrop"
            onClick={props.clicked}
            style={{ zIndex: props.zIndex }}
        />
    ) : null;

export default Backdrop;
