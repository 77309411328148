import React from "react";
import { Field, reduxForm } from "redux-form";
import FormInputField from "../form-components/FormInputField";
import FormButton from "../form-components/FormButton";
import FormSelect from "../form-components/FormSelect";
import FormSelect2 from "../form-components/FormSelect2";
import FormCheckBoxField from "../form-components/FormCheckBoxField";
import Captcha from "../form-components/Captcha";
import validate from "./signup-validate";

import "../../css/UserForm.css";
import "../../css/Form.css";
import api from '../../api';

const SignupPart2 = props => {
  const {
    handleSubmit,
    countries,
    selectedCountry,
    onCountryChange,
    cities,
    formLoader,
    changeType,
  } = props;

  // console.log('selectedCountry default = ', selectedCountry);

  return (
    <div className="user-form__right">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p className="user-form__progress">Step 2 of 3</p>
        <p
          className="user-form__progress"
          style={{ marginRight: "30px", cursor: "pointer" }}
          onClick={props.prevPage}
        >Previous Page</p>
      </div>

      <div className="user-form__right-head user-form__right-head--signup"
      >Additional Details</div>

      <form onSubmit={handleSubmit}>
        <div className="user__form-container">
          <div className="row user__form-row">
            <Field
              // usesCustomValue
              // customValue={selectedCountry}
              name="country"
              component={FormSelect2}
              placeholder="Country"

              options={countries.map(country => ({
                id: country.code,
                name: country.name,
                code: country.code,
                className: "select-option",
              }))}
              onChange={onCountryChange}
            />
          </div>
          <div className="row user__form-row">
            <Field
              widthClass="col-md-12"
              name="company_name"
              component={FormInputField}
              type="text"
              placeholder="Company Name"
              tabable={true}
            />
            <Field
              widthClass="col-md-12"
              name="designation"
              component={FormInputField}
              type="text"
              placeholder="Designation"
              tabable={true}
            />
            <Field
              widthClass="col-md-4"
              name="pincode"
              component={FormInputField}
              type="text"
              placeholder="Pincode"
              tabable={true}
            />
            <Field
              name="city"
              component={FormSelect}
              placeholder="City"
              widthClass="col-md-8"
              selectConfig={{
                closeOnSelect: true,
                disabled: false,
                multi: false,
                simpleValue: true,
                removeSelected: false,
              }}
              options={cities.map(city => ({
                label: city.name,
                value: city.name,
                className: "select-option",
              }))}
            />
          </div>
          <div className="user__form-row user__form-row-mobile">
            <div className="user__form-country-code">
              +{selectedCountry ? selectedCountry.code : 91}
            </div>
            <Field
              name="contact_number"
              inputClass=""
              component={FormInputField}
              type="text"
              placeholder="Mobile Number"
              tabable={true}
            />
          </div>

          <Field
            name="signup_confirm"
            id="signup_confirm"
            component={FormCheckBoxField}
          // label="I hereby confirm to have read the terms of use and declare my agreement."
          />
          <div className="row user__form-row">
            <div className="signup__form-captcha">
              <Field
                name="recaptcha"
                component={Captcha}
                errorMargin={"45px"}
              />
            </div>
          </div>
        </div>

        <div className="form__signup-btn-and-login--wrapper">
          <FormButton label="REGISTER" formLoader={formLoader} />
          <p>
            Already a member?&nbsp;
            <span
              className="form__login-span"
              onClick={() => changeType("login")}
            >Login</span>
          </p>
        </div>
      </form>
    </div>
  );
};

export default reduxForm({
  form: "signup", // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  asyncValidate: asyncValidateMobile,
  asyncBlurFields: ['contact_number'],
})(SignupPart2);

async function asyncValidateMobile(values) {
  const url = `${api.checkNumberAvailability}?contact_number=91${values.contact_number}`;
  return fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  }).then(response => {
    if (!response || !response.ok) {
      // eslint-disable-next-line
      throw { contact_number: 'This mobile number is already registered' };
    }
  });
}