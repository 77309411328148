import React, { Fragment } from "react";
import { reduxForm, Field } from "redux-form";
import { toast } from "react-toastify";
import FormButton from "./form-components/FormButton";
import FormInputField from "./form-components/FormInputField";
import validate from "./user-form-components/resetpassword-validate";

import Loader from "./ui/loader/Loader";

import "../css/ResetPassword.css";
import api, { getGETConfig } from "../api";
import {encryptAES} from "../utils/helpers"
class ResetPassword extends React.Component {
   state = {
      requestCheckStatus: 'pending',
      isRequestValid: false
   };

   componentDidMount() {
      this.verifyRequestValidity();
   }

   verifyRequestValidity = () => {
      const { token, uidb64, userType,requestNo } = this.props.match.params;
      const url = `${api.validateResetRequest}`;
         fetch(url, {
            method: "POST",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
            },
            body: JSON.stringify({ 
               token :token,
               // uidb64 : uidb64,
               reset_token : uidb64,
               user_type :encryptAES(atob(userType)),
               request_no :requestNo
            }),
         }) .then(response => {
           // console.log(response)
            if (response.status == 500) {
               toast.error(
                  "Password Reset Link Expired"  
               );
               this.props.history.push("/");
               //setTimeout(() => {
               //   this.props.history.push("/");
               // }, 6000);
               return;
            }

            
         })
         .then(data => {
        
            if (!data.success) throw Error('Unsuccessful');

            // Successfully verified reset password request 
            this.setState({
               requestCheckStatus: "complete",
               isRequestValid: true
            });
         })
         .catch(err => {
            //console.log('Password reset request not valid!', err);
            this.setState({
               requestCheckStatus: 'complete',
               isRequestValid: false
            });
         });

          
   }


   submitForm = formValues => {
     // const { uidb64, userType } = this.props.match.params;
      const newPassword = encryptAES(formValues.newPassword);
      const confirmnewPassword = encryptAES(formValues.confirmNewPassword);
      const uidb64 = this.props.match.params.uidb64
      const userType = encryptAES(atob( this.props.match.params.userType))
      const requestNo = this.props.match.params.requestNo
      
      const requestObj = {
         password: newPassword,
         confirm_password: confirmnewPassword,
         // uidb64: uidb64,
         reset_token : uidb64,
         user_type: userType,
         request_no : requestNo
      };

    

      fetch(api.change_user_password, {
         method: "POST",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
         },
         body: JSON.stringify({ ...requestObj }),
      })
         .then(response => {
             
            if (response.status !== 200) {
               toast.error(
                  "Looks like there was a problem. Status code: " +
                  response.status
               );
               return;
            }
          
            response.json().then(response => {
               if (response.success) {
                  toast.success("Password changed successfully!");
                  this.props.history.push("/");
               }
            });
         })
         .catch(err => {
            toast.error("Something went wrong. Please try again later!");
            this.props.history.push("/");
         });
   };

   render() {
      const { handleSubmit } = this.props;
      let requestStatus = this.state.requestCheckStatus,
         requestValid = true;

      const isLoading = requestStatus === 'pending';

      return (
         <Loader loading={isLoading} height="500px">
            <div className="container resetpassword--wrapper">
               {!requestValid ? (
                  <p className="resetpassword-text">The Link has either expired or is invalid...</p>
               ) : (
                     <Fragment>
                        <p className="resetpassword-text">Reset Password</p>
                        <form
                           autoComplete="false"
                           onSubmit={handleSubmit(this.submitForm)}
                        >
                           <div>
                              <Field
                                 component={FormInputField}
                                 type="password"
                                 name="newPassword"
                                 widthclassName="resetpassword__input-field"
                                 placeholder="Enter New Password"
                                 tabable={true}
                              />
                           </div>

                           <div>
                              <Field
                                 component={FormInputField}
                                 type="password"
                                 name="confirmNewPassword"
                                 placeholder="Confirm New Password"
                                 widthclassName="resetpassword__input-field"
                                 tabable={true}
                              />
                           </div>
                           <div style={{ marginTop: "25px" }}>
                              <FormButton label="Reset Password" />
                           </div>
                        </form>
                     </Fragment>
                  )}
            </div>
         </Loader>
      );
   }
}

export default reduxForm({
   form: "resetPassword",
   validate,
})(ResetPassword);
