import React, { useEffect, useState } from 'react';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import '../css/BlogSlider.css';

const BlogSlider = ({ eventWebinarsListArray }) => {
    useEffect(() => {

        const script = document.createElement('script');
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.3.5/js/swiper.min.js';
        script.async = true;
        script.onload = () => {
            const swiper = new window.Swiper('.blog-slider', {
                spaceBetween: 30,
                effect: 'fade',
                loop: true,
                mousewheel: {
                    invert: false,
                    eventsTarget: '.blog-slider__wrp',
                    forceToAxis: true,
                },
                pagination: {
                    el: '.blog-slider__pagination',
                    clickable: true,
                },
            });


        };
        document.body.appendChild(script);
        return () => {
            const swiperContainer = document.querySelector('.blog-slider');
            swiperContainer && swiperContainer.swiper && swiperContainer.swiper.destroy();
        };


    }, [eventWebinarsListArray]);

    // console.log(eventWebinarsListArray)

    return (
        <div className="blog-slider">
            <div className="blog-slider__wrp swiper-wrapper">

                {eventWebinarsListArray.map((data, i) => (
                    <div className="blog-slider__item swiper-slide" >
                        <div className="blog-slider__img">
                            <img
                                src={data.image_url}
                                alt=""
                            />
                        </div>
                        <div className="blog-slider__content">
                            <span className="blog-slider__code">{data.heading_1}</span>
                            <h3 className="blog-slider__title">{data.heading_2}</h3>
                            <div className="blog-slider__text">
                                {data.description}
                            </div>
                            <a href={data.redirect_url} className="blog-slider__button" target="_blank">
                                Read More
                            </a>
                        </div>
                    </div>))}


                {/* // ))
            // } */}


            </div>
            <div className="blog-slider__pagination"></div>
        </div>
    );
};

export default BlogSlider;