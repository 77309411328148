import React, { Component } from "react";
import { Link } from "react-router-dom";

import { httpfyUrl } from "../../utils/helpers";

import CardsWithFilters from "./CardsWithFilters";
import OfferingsTab from "./OfferingsTab";
import About from "./About";
import FinancialInfo from "./FinancialInfo";
import SimilarEntitiesCarousel from "./SimilarEntitiesCarousel";

import locationIcon from '../../img/location-light.svg';
import businessTypeIcon from '../../img/business-type-light.svg';
import peopleIcon from '../../img/people-light.svg';
import Patents from "./Patents";
import "../../css/EntityView.css";
import { ContentCutOutlined } from "@mui/icons-material";

class CompanyWrapper extends Component {
  // state = {
  //   currentMenuItem: "offerings",
  //   offeringsTotal: null,
  // };

  state = {
    offeringsTotal: null,
    currentMenuItem: (
      (this.props.details.hasOwnProperty("products") && this.props.details.products.length > 0) ||
      (this.props.details.hasOwnProperty("services") && this.props.details.services.length > 0) ||
      (this.props.details.hasOwnProperty("solutions") && this.props.details.solutions.length > 0)
    )
      ? "offerings" : "about"

  };

  changeCurrentMenuItem = item => {
    this.setState({
      currentMenuItem: item,
    });
  };

  getOfferingsTotal = () => {
    //console.log(this.props.details)
    //  Temporary references to the offerings
    const prods = this.props.details.products;
    const servs = this.props.details.services;
    const sols = this.props.details.solutions;
    const details = this.props.details;
    // console.log("details: ", details)

    const result =
      (prods && prods.length > 0 ? prods.length : 0) +
      (servs && servs.length > 0 ? servs.length : 0) +
      (sols && sols.length > 0 ? sols.length : 0);

    return result > 0 ? `(${result})` : "";
  };





  render() {

    const products = this.props.details.products
      ? this.props.details.products.filter(prod => prod.name)
      : [];

    const services = this.props.details.services
      ? this.props.details.services.filter(serv => serv.name)
      : [];

    const solutions = this.props.details.solutions
      ? this.props.details.solutions.filter(sol => sol.name)
      : [];

    // TODO  --> Change later to use API for premium users
    //  Preprare the financial info object
    const financialData = {
      shareholders: this.props.details.shareholders,
      companiesAcquired: this.props.details.companies_acquired,
      fundingRounds: this.props.details.funding_rounds,
      totalValuation: this.props.details.total_valuation,
      totalInvestors: this.props.details.total_investors,
      totalFunding: this.props.details.total_funding,

    };
    // console.log(this.props)
    //  console.log(this.props.details.is_claimed)
    document.title = `${this.props.details.name} - Technology Capability Repository || DSCI`;

    //  document.getElementsByTagName("LINK")[0].href= this.props.path.length > 3 ?  
    //  `${process.env.REACT_APP_API_URL}/technology/${this.props.path[2].replace(/ /g, "-")}/company/${this.props.details.name.replace("%", "percentage").replace(/ /g, "-").replaceAll(".","").replace("---","-").replace(",","").toLowerCase()}` :
    //  `${process.env.REACT_APP_API_URL}/company/${this.props.details.name.replace("%", "percentage").replace(/ /g, "-").replaceAll(".","").replace("---","-").replace(",","").toLowerCase()}`
    const path = this.props.path
    // console.log(this.props.path)
    // console.log(['company',"institute","rd-centre","researcher"].includes(this.props.path[1]))
    document.getElementsByTagName("LINK")[0].href = ["company", "institute", "rd-centre", "researcher"].includes(this.props.path[1]) ? `https://www.techsagar.in` : `https://www.techsagar.in/technology/${path[1].replaceAll("_", "-")}`

    const claimClickHandler = () => {
      // console.log("shobhit")
      // console.log(this.props.details)
      // this.props.onValueChange ( {companyName : this.props.details.name , 
      //   companyUrl : this.props.details.url
      // })
      const newDetails = {
        name: this.props.details.name,
        url: this.props.details.url
      };

      // Call the passed-in onValueChange method with new details
      this.props.onValueChange(newDetails);
      // console.log(this.props)
    }

    //console.log(this.props.path[2])
    return (
      <div className="entityview__wrapper">
        <div className="entityview__top">
          <div className="entityview__top-desc-wrapper">
            {this.props.path.length === 4 ?
              <div className="entityview__top-breadcrumb">
                <Link to="/">Home</Link> / {" "}
                {<Link to={{ pathname: `/technology/${this.props.path[1].replace(/ /g, "_")}` }}>
                  {this.props.path[1].replaceAll("_", " ")}
                </Link>
                }{" "}/
                {<Link to={{ pathname: `/technology/${this.props.path[1].replaceAll(/ /g, "_")}/${this.props.path[2].includes("-") ? this.props.path[2] : this.props.path[2]}` }}>
                  {this.props.path[2].replaceAll("_", " ").replace("12", "/")}
                </Link>
                }{" "}/
                {this.props.details.name}
              </div> :

              this.props.path.length === 3 && this.props.path[1] != "company" && this.props.path[1] != "institute" && this.props.path[1] != "rd-centre" && this.props.path[1] != "researcher" ?
                <div className="entityview__top-breadcrumb">
                  <Link to="/">Home</Link> / {" "}
                  {<Link to={{ pathname: `/technology/${this.props.path[1].replaceAll(/ /g, "_")}` }}>
                    {this.props.path[1].replaceAll("_", " ")}
                  </Link>
                  }{" "}/{this.props.details.name}
                </div> :

                <div className="entityview__top-breadcrumb">
                  <Link to="/">Home</Link> / {this.props.details.name}
                </div>}
            <div>
              <h1 className="entityview__top-head">
                {this.props.details.name}
              </h1>
              <div className="entityview__subhead">
                <div className="entityview__subhead-section entityview__stats-section">
                  {this.props.details.address && (
                    <div className="entityview__subhead-desc">
                      <img src={locationIcon} alt='briefcase icon' className='entityview__subhead-desc-icon' />
                      {this.props.details.address.city}
                    </div>
                  )}
                  {this.props.details.type && (
                    <div className="entityview__subhead-desc">
                      <img src={businessTypeIcon} alt='briefcase icon' className='entityview__subhead-desc-icon' />
                      {this.props.details.type}
                    </div>
                  )}
                  {this.props.details.size && (
                    <div className="entityview__subhead-desc">
                      <img src={peopleIcon} alt='briefcase icon' className='entityview__subhead-desc-icon' />
                      {this.props.details.size} employees
                    </div>
                  )}
                </div>

                <div className="entityview__subhead-section entityview__subhead__buttons">
                  {this.props.details.is_claimed ? (
                    <div
                      style={{ cursor: "auto" }}
                      className="header__section__part header__section__btn entityview__subhead-btn"
                    >
                      Claimed
                    </div>
                  ) : (
                    <Link
                      onClick={claimClickHandler}
                      to={"/claim"}
                      className="header__section__part header__section__btn entityview__subhead-btn"
                    >
                      Claim
                    </Link>
                  )}
                  <div
                    className="entityview__subhead-btn"
                    onClick={this.props.toggleModal}
                  >
                    <i className="fas fa-share-alt" />
                  </div>
                  {this.props.details.url && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={httpfyUrl(
                        this.props.details.url
                      )}
                      className="entityview__subhead-btn"
                    >
                      <i className="fas fa-globe" />
                      &nbsp; Website
                    </a>
                  )}
                  {this.props.followButton}
                </div>
              </div>
            </div>
          </div>
          <div className="entityview__top-nav">
            {
              (
                (this.props.details.hasOwnProperty("products") && Array.isArray(this.props.details.products) && this.props.details.products.length > 0) ||
                (this.props.details.hasOwnProperty("services") && Array.isArray(this.props.details.services) && this.props.details.services.length > 0) ||
                (this.props.details.hasOwnProperty("solutions") && Array.isArray(this.props.details.solutions) && this.props.details.solutions.length > 0)
              )
              &&
              <div
                className={`entityview__top-nav-btn ${this.state
                  .currentMenuItem === "offerings" &&
                  "entityview__top-nav-btn--active"}`}
                onClick={this.changeCurrentMenuItem.bind(
                  null,
                  "offerings"
                )}
              >
                OFFERINGS {this.getOfferingsTotal()}
              </div>
            }

            <div
              className={`entityview__top-nav-btn ${this.state
                .currentMenuItem === "about" &&
                "entityview__top-nav-btn--active"}`}
              onClick={this.changeCurrentMenuItem.bind(
                null,
                "about"
              )}
            >
              PROFILE
            </div>

            {/* <div
                            className={`entityview__top-nav-btn ${this.state
                                .currentMenuItem === "products" &&
                                "entityview__top-nav-btn--active"}`}
                            onClick={this.changeCurrentMenuItem.bind(
                                null,
                                "products"
                            )}
                        >
                            {`PRODUCTS ${
                                this.props.details.products &&
                                this.props.details.products.length > 0
                                    ? "(" +
                                      this.props.details.products.length +
                                      ")"
                                    : ""
                            }`}
                        </div>

                        <div
                            className={`entityview__top-nav-btn ${this.state
                                .currentMenuItem === "services" &&
                                "entityview__top-nav-btn--active"}`}
                            onClick={this.changeCurrentMenuItem.bind(
                                null,
                                "services"
                            )}
                        >
                            {`SERVICES ${
                                this.props.details.services &&
                                this.props.details.services.length > 0
                                    ? "(" +
                                      this.props.details.services.length +
                                      ")"
                                    : ""
                            }`}
                        </div>

                        <div
                            className={`entityview__top-nav-btn ${this.state
                                .currentMenuItem === "solutions" &&
                                "entityview__top-nav-btn--active"}`}
                            onClick={this.changeCurrentMenuItem.bind(
                                null,
                                "solutions"
                            )}
                        >
                            {`SOLUTIONS ${
                                this.props.details.solutions &&
                                this.props.details.solutions.length > 0
                                    ? "(" +
                                      this.props.details.solutions.length +
                                      ")"
                                    : ""
                            }`}
                        </div> */}

            {/* Latest change  
          <div
              className={`entityview__top-nav-btn ${this.state
                .currentMenuItem === "recognitions" &&
                "entityview__top-nav-btn--active"}`}
              onClick={this.changeCurrentMenuItem.bind(
                null,
                "recognitions"
              )}
            >
              {`RECOGNITIONS ${this.props.details.recognitions &&
                  this.props.details.recognitions.length > 0
                  ? "(" +
                  this.props.details.recognitions.length +
                  ")"
                  : ""
                }`}
              </div>*/}

            {/* <div
              className={`entityview__top-nav-btn ${this.state
                .currentMenuItem === "technicalReports" &&
                "entityview__top-nav-btn--active"}`}
              onClick={this.changeCurrentMenuItem.bind(
                null,
                "technicalReports"
              )}
            >
              {`TECHNICAL REPORTS ${this.props.details.research_papers &&
                  this.props.details.research_papers.length > 0
                  ? "(" +
                  this.props.details.research_papers
                    .length +
                  ")"
                  : ""
                }`}
              </div>*/}



            {/*   <div
              className={`entityview__top-nav-btn ${this.state
                .currentMenuItem === "blogs" &&
                "entityview__top-nav-btn--active"}`}
              onClick={this.changeCurrentMenuItem.bind(
                null,
                "blogs"
              )}
            >
              {`BLOGS ${this.props.details.blogs &&
                  this.props.details.blogs.length > 0
                  ? "(" +
                  this.props.details.blogs.length +
                  ")"
                  : ""
                }`}
              </div> */}

            {
              (this.props.details.hasOwnProperty("funding_rounds")
                &&
                Array.isArray(this.props.details.funding_rounds) && this.props.details.funding_rounds.length > 0
              )

              &&
              <div
                className={`entityview__top-nav-btn ${this.state
                  .currentMenuItem === "financial" &&
                  "entityview__top-nav-btn--active"}`}
                onClick={this.changeCurrentMenuItem.bind(
                  null,
                  "financial"
                )}
              >
                FINANCIAL INFO
              </div>
            }


            {
              (this.props.details.hasOwnProperty("patents")
                &&
                Array.isArray(this.props.details.patents) && this.props.details.patents.length > 0
              )

              &&
              <div
                className={`entityview__top-nav-btn ${this.state
                  .currentMenuItem === "patents" &&
                  "entityview__top-nav-btn--active"}`}
                onClick={this.changeCurrentMenuItem.bind(
                  null,
                  "patents"
                )}
              >
                {`PATENTS ${this.props.details.patents &&
                  this.props.details.patents.length > 0
                  ? "(" +
                  this.props.details.patents.length +
                  ")"
                  : ""
                  }`}
              </div>
            }
          </div>
        </div>

        {this.state.currentMenuItem === "offerings" && (
          <OfferingsTab
            title="Offerings"
            technologyAreas={this.props.details.technology_type}
            products={products}
            services={services}
            solutions={solutions}
            // websiteUrl={this.props?.details?.url || ""}
            websiteUrl={this.props.details.hasOwnProperty("url") ? this.props.details.url : ""}
          />
        )}
        {/* {this.state.currentMenuItem === "products" && (
                    <CardsWithFilters
                        title="Products"
                        technologyAreas={this.props.details.technology_type}
                        items={this.props.details.products}
                    />
                )}
                {this.state.currentMenuItem === "services" && (
                    <CardsWithFilters
                        title="Services"
                        technologyAreas={this.props.details.technology_type}
                        items={this.props.details.services}
                    />
                )}
                {this.state.currentMenuItem === "solutions" && (
                    <CardsWithFilters
                        title="Solutions"
                        technologyAreas={this.props.details.technology_type}
                        items={solutions}
                    />
                )} */}
        {/*this.state.currentMenuItem === "recognitions" && (
          <CardsWithFilters
            title="Recognitions"
            technologyAreas={this.props.details.technology_type}
            disableFilters={true}
            items={this.props.details.recognitions}
          />
        )*/}
        {/*this.state.currentMenuItem === "technicalReports" && (
          <CardsWithFilters
            title="Technical Reports"
            technologyAreas={this.props.details.technology_type}
            disableFilters={true}
            items={this.props.details.research_papers}
        /> 
        )}*/}
        {this.state.currentMenuItem === "patents" && (
          <Patents
            title="Patents"
            technologyAreas={this.props.details.technology_type}
            disableFilters={true}
            items={this.props.details.patents}
            reports={this.props.details.research_papers}
          />
        )}
        {this.state.currentMenuItem === "blogs" && (
          <CardsWithFilters
            type='company'
            title="Blogs"
            technologyAreas={this.props.details.technology_type}
            disableFilters={true}
            items={this.props.details.blogs}
          />
        )}
        {this.state.currentMenuItem === "about" && (

          <About
            title="Recognitions"
            technologyAreas={this.props.details.technology_type}
            disableFilters={true}
            items={this.props.details.recognitions}
            showStats={false}
            showGeographies={true}
            name={this.props.details.name}
            about={this.props.details.about}
            sectorsServed={this.props.details.sectors_served}
            businessHeads={this.props.details.business_heads}
            technologyHeads={this.props.details.technology_heads}
            geographiesServed={this.props.details.geographies_served}
            //  Contact information
            url={this.props.details.url}
            address={this.props.details.address}
            contactPerson={this.props.details.contact_person}
            news={this.props.details.news}
          />
        )}

        {this.state.currentMenuItem === "financial" && (
          <FinancialInfo data={financialData} />
        )}

        {this.props.similarEntities && this.props.similarEntities.length &&
          <div className="entityview__similar-wrapper">
            <div className="entityview__similar-head"
            >Similar Companies</div>

            <SimilarEntitiesCarousel
              carouselItems={this.props.similarEntities}
            />
          </div>}
      </div>
    );
  }
}

export default CompanyWrapper;
