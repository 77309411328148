import React, { Component, Fragment } from "react";
// import Select from "react-select";

import api from "../../api";
import filterIcon from "../../img/filter.svg";
import SearchFiltersDrawer from "../ui/SearchFiltersDrawer/SearchFiltersDrawer";
import ToggleSwitch from "./ToggleSwitch";

class Filters extends Component {
  state = {
    currentFilter: "",
    technologyAreas: [],
    companyTypes: [],
    companySizes: [],
    geographiesServed: [],
    sectorsServed: [],
    companyCategories: [],
    applicationArea: [],
    techAreaFilter: "",
    sectorsServedFilter: "",
    showSearchFiltersDrawer: false,
  };

  handleViewTypeToggle = e => {
    e.persist();

    if (e.target.checked) this.props.setViewType("map");
    else this.props.setViewType("list");
  };

  handleProductsTypeToggle = e => {
    e.persist();
    this.props.setOnlyProductsView(e.target.checked ? true : false);
  }

  componentDidMount() {
    this.getTechnologyTypes();
    this.getCompanyTypes();
    this.getCompanySizes();
    this.getGeographiesServed();
    this.getSectorsServed();
    this.getCompanyCategories();
    this.getApplicationAreas();
    this.filterType();
  }


  getApplicationAreas = () => {
    const requestConfig = {
      method: "GET",
      headers: { "Content-Type": "application/json" }
    };

    fetch(api.application_areas, requestConfig)
      .then(response => {
        if (!response || !response.ok) throw new Error(response.status);
        return response.json();
      })
      .then(data => this.setState({ applicationArea: data.data }))
      .catch(err => console.log('Error fetching application areas', err));
  };

  getTechnologyTypes = () => {
    const requestConfig = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };

    fetch(api.tech_areas, requestConfig)
      .then(response => {
        if (!response || !response.ok) throw new Error(response.status);
        return response.json();
      })
      .then(data => this.setState({ technologyAreas: data.data }))
      .catch(err => console.log('Could not fetch tech areas', err));
  };

  getCompanyTypes = () => {
    const requestConfig = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };

    fetch(api.company_types, requestConfig)
      .then(response => {
        if (!response || !response.ok) throw new Error(response.status);
        return response.json();
      })
      .then(data => this.setState({ companyTypes: data.data }))
      .catch(err => console.log('Could not fetch company types', err));
  };

  getCompanySizes = () => {
    const requestConfig = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };

    fetch(api.company_sizes, requestConfig)
      .then(response => {
        if (!response || !response.ok) throw new Error(response.status);
        return response.json();
      })
      .then(data => this.setState({ companySizes: data.data }))
      .catch(err => console.log('Could not fetch company sizes', err));
  };

  getSectorsServed = () => {
    const requestConfig = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };

    fetch(api.sectors_served, requestConfig)
      .then(response => {
        if (!response || !response.ok) throw new Error(response.status);
        return response.json();
      })
      .then(data => this.setState({ sectorsServed: data.data }))
      .catch(err => console.log('Could not fetch company sizes', err));
  };

  getGeographiesServed = () => {
    const requestConfig = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };

    fetch(api.geographies_served, requestConfig)
      .then(response => {
        if (!response || !response.ok) throw new Error(response.status);
        return response.json();
      })
      .then(data => this.setState({ geographiesServed: data.data }))
      .catch(err => console.log('Could not fetch company sizes', err));
  };

  getCompanyCategories = () => {
    const requestConfig = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };

    fetch(api.company_categories, requestConfig)
      .then(response => {
        if (!response || !response.ok) throw new Error(response.status);
        return response.json();
      })
      .then(data => this.setState({ companyCategories: data.data }))
      .catch(err => console.log('Could not fetch company sizes', err));
  };

  toggleSearchFiltersDrawer = () => {
    this.setState(prevState => ({
      showSearchFiltersDrawer: !prevState.showSearchFiltersDrawer,
    }));
  };

  applyDrawerFilters = () => {
    this.toggleSearchFiltersDrawer();
    this.props.filterSearch();
  };

  resetDrawerFilters = () => {
    this.toggleSearchFiltersDrawer();
    this.props.handleResetFilters();
  };

  filterType = () => {
    if (this.props.resultType == "r_and_d") {
      return "R&D CENTRES"
    }
    if (this.props.resultType == "tech_area") {
      return "TECH AREA"
    }
    else {
      return this.props.resultType
    }
  }

  render() {
    let clearAllFiltersButton = null;

    //  For company tab, if use_case or category filters are selected, show clear all filters button
    if (this.props.showCompanyTypeFilters) {
      if (
        this.props.application_area.length > 0 ||
        this.props.company_category.length > 0
      ) {
        clearAllFiltersButton = (
          <span
            className="search__filters-clear"
            onClick={this.props.handleResetFilters}
          >Clear All</span>
        );
      }
    }

    //  Check for all other filter types now
    //  Only create button if its not already created
    if (
      !clearAllFiltersButton &&
      (this.props.technology_type.length > 0 ||
        this.props.size.length > 0 ||
        this.props.type.length > 0 ||
        this.props.sectors_served.length > 0 ||
        this.props.geographies_served.length > 0)
    ) {
      clearAllFiltersButton = (
        <span
          className="search__filters-clear"
          onClick={this.props.handleResetFilters}
        >Clear All</span>
      );
    }

    // Generate options for technology areas filter
    const techAreaFilters = this.state.technologyAreas.map((area, index) => (
      <label key={index} className="search__filters-option-label">
        <input
          type="checkbox"
          name={area}
          data-filtertype="technology_type"
          checked={this.props.technology_type.includes(area)}
          onChange={this.props.handleFilterCheckboxInputChange}
        />
        {area}
        <span className="customCheckbox" />
      </label>
    ));
    //  console.log(this.state.companyTypes)
    const companyTypeFilters = this.state.companyTypes.map((type, index) => (

      <label key={index} className="search__filters-option-label">
        <input
          type="checkbox"
          name={type}
          data-filtertype="type"
          checked={this.props.type.includes(type)}
          onChange={this.props.handleFilterCheckboxInputChange}
        />
        {type}
        <span className="customCheckbox" />
      </label>
    ));
    var oldestnewestvisibilty = false
    var newesttooldest = false

    if (this.props.handle === "Oldest to Newest") {
      //console.log("check 1")
      oldestnewestvisibilty = false
      newesttooldest = true
    }
    else if (this.props.handle === "Newest to Oldest") {
      //console.log("check 2")
      newesttooldest = false
      oldestnewestvisibilty = true
    }
    return (
      <Fragment>
        <SearchFiltersDrawer
          //  From Filters.js (list of all options)
          show={this.state.showSearchFiltersDrawer}
          applyDrawerFilters={this.applyDrawerFilters}
          techAreas={this.state.technologyAreas}
          companyTypes={this.state.companyTypes}
          companySizes={this.state.companySizes}
          sectorsServed={this.state.sectorsServed}
          geographiesServed={this.state.geographiesServed}
          companyCategories={this.state.companyCategories}
          applicationArea={this.state.applicationArea}
          //  From SearchResults.js (list of appliedfilters)
          prop_techAreas={this.props.technology_type}
          prop_companyTypes={this.props.type}
          prop_companySizes={this.props.size}
          prop_sectorsServed={this.props.sectors_served}
          prop_geographiesServed={this.props.geographies_served}
          prop_companyCategories={this.props.company_category}
          prop_applicationArea={this.props.application_area}
          showCompanyTypeFilters={this.props.showCompanyTypeFilters}
          toggleDrawer={this.toggleSearchFiltersDrawer}
          filterSearch={this.props.filterSearch}
          resetDrawerFilters={this.resetDrawerFilters}
          handleFilterCheckboxInputChange={
            this.props.handleFilterCheckboxInputChange
          }
          resultType={this.props.resultType}
        />

        <div className="search__filters-wrapper">
          {this.props.resultType ?
            <div>
              <p style={{ "textTransform": "uppercase", fontSize: "1.4rem", fontWeight: "bold" }}>SHOWING {this.props.totalCount} {this.filterType()}
              </p>

            </div> : <></>}


          {clearAllFiltersButton}


          <div className='searchResults__togglesWrapper'>
            <p className="search-filter__sort-by--text">Filter By</p>
            <div className="search__filter">
              Area{" "}
              <i className="fas fa-angle-down search-filter--icon" />
              <div className="search__dropdown-wrapper">
                <div className="search__dropdown">
                  <div className="search__dropdown-options-wrapper">
                    {techAreaFilters}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display:
                    this.props.technology_type.length > 0
                      ? "inline"
                      : "none",
                }}
                className="search__filters--filter-selected-marker"
              />
            </div>

            <div className="search__filter">
              Type{" "}
              <i className="fas fa-angle-down search-filter--icon" />
              <div className="search__dropdown-wrapper">
                <div className="search__dropdown">
                  <div
                    style={{ maxWidth: "40rem" }}
                    className="search__dropdown-options-wrapper"
                  >
                    {companyTypeFilters}
                  </div>
                </div>
              </div>
              <div
                className="search__filters--filter-selected-marker"
                style={{ display: this.props.type.length > 0 ? "inline" : "none" }}
              />
            </div>

            <div className="search__filter">
              Size{" "}
              <i className="fas fa-angle-down search-filter--icon" />
              <div className="search__dropdown-wrapper">
                <div
                  className="search__dropdown"
                  style={{
                    maxWidth: "40rem",
                  }}
                >
                  <div className="search__dropdown-options-wrapper">
                    {this.state.companySizes.map(
                      (size, index) => (
                        <label
                          className="search__filters-option-label"
                          key={index}
                        >
                          <input
                            type="checkbox"
                            name={size}
                            data-filtertype="size"
                            checked={this.props.size.includes(
                              size
                            )}
                            onChange={
                              this.props
                                .handleFilterCheckboxInputChange
                            }
                          />
                          {size}
                          <span className="customCheckbox" />
                        </label>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display:
                    this.props.size.length > 0
                      ? "inline"
                      : "none",
                }}
                className="search__filters--filter-selected-marker"
              />
            </div>

            {/*    <div className="search__filter">
              Sector{" "}
              <i className="fas fa-angle-down search-filter--icon" />
              <div className="search__dropdown-wrapper">
                <div
                  className="search__dropdown"
                  style={{
                    maxWidth: "70rem",
                  }}
                >
                  <div className="search__dropdown-options-wrapper">
                    {this.state.sectorsServed.map(
                      (sector, index) => (
                        <label
                          className="search__filters-option-label"
                          key={index}
                        >
                          <input
                            type="checkbox"
                            name={sector}
                            data-filtertype="sectors_served"
                            checked={this.props.sectors_served.includes(
                              sector
                            )}
                            onChange={
                              this.props
                                .handleFilterCheckboxInputChange
                            }
                          />
                          {sector}
                          <span className="customCheckbox" />
                        </label>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display:
                    this.props.sectors_served.length > 0
                      ? "inline"
                      : "none",
                }}
                className="search__filters--filter-selected-marker"
              />
            </div>

            <div className="search__filter">
              Geography{" "}
              <i className="fas fa-angle-down search-filter--icon" />
              <div className="search__dropdown-wrapper">
                <div
                  className="search__dropdown"
                  style={{
                    maxWidth: "50rem",
                  }}
                >
                  <div className="search__dropdown-options-wrapper">
                    {this.state.geographiesServed.map(
                      (geography, index) => (
                        <label
                          className="search__filters-option-label"
                          key={index}
                        >
                          <input
                            type="checkbox"
                            name={geography}
                            data-filtertype="geographies_served"
                            checked={this.props.geographies_served.includes(
                              geography
                            )}
                            onChange={
                              this.props
                                .handleFilterCheckboxInputChange
                            }
                          />
                          {geography}
                          <span className="customCheckbox" />
                        </label>
                      )
                    )}
                  </div>
                </div>
                          </div> 
              <div
                style={{
                  display:
                    this.props.geographies_served.length > 0
                      ? "inline"
                      : "none",
                }}
                className="search__filters--filter-selected-marker"
              />
            </div>*/}

            {this.props.showCompanyTypeFilters && (
              <div className="search__filter">
                Category
                <i className="fas fa-angle-down search-filter--icon" />
                <div className="search__dropdown-wrapper">
                  <div className="search__dropdown">
                    <div
                      className="search__dropdown-options-wrapper"
                      style={{
                        maxWidth: "40rem",
                      }}
                    >
                      {this.state.companyCategories.map(
                        (companyCategory, index) => (
                          <label
                            className="search__filters-option-label"
                            key={index}
                          >
                            <input
                              type="checkbox"
                              name={companyCategory}
                              data-filtertype="company_category"
                              checked={this.props.company_category.includes(
                                companyCategory
                              )}
                              onChange={
                                this.props
                                  .handleFilterCheckboxInputChange
                              }
                            />
                            {companyCategory}
                            <span className="customCheckbox" />
                          </label>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display:
                      this.props.company_category.length > 0
                        ? "inline"
                        : "none",
                  }}
                  className="search__filters--filter-selected-marker"
                />
              </div>
            )}

            {/*this.props.resultType !== 'companies' ? null : (
              <div className="search-filter--view-toggle search-filter--view-toggle--products">
                <p className="search-filter--view-toggle--text"
                >Product-Companies</p>

                <input
                  type="checkbox"
                  id="view-toggle-button--products"
                  onChange={this.handleProductsTypeToggle}
                />
                <label
                  className="view-toggle-label"
                  htmlFor="view-toggle-button--products"
                >Toggle</label>
              </div>
            )*/}

            {/* Don't show map toggle for technologies and products */}
            {/*!['tech_area', 'products'].includes(this.props.resultType) && (
              <div className="search-filter--view-toggle">
                <p className="search-filter--view-toggle--text"
                >Show Map View</p>

                <input
                  type="checkbox"
                  id="view-toggle-button"
                  onChange={this.handleViewTypeToggle}
                />
                <label
                  className="view-toggle-label"
                  htmlFor="view-toggle-button"
                >Toggle</label>
              </div>
            )*/}

            {this.props.resultType != "tech_area" &&
              <div className="search__filter">
                Order{" "}
                <i className="fas fa-angle-down search-filter--icon" />
                <div className="search__dropdown-wrapper">
                  <div
                    className="search__dropdown"
                    style={{
                      maxWidth: "70rem",
                    }}
                  >
                    <div className="search__dropdown-options-wrapper">

                      <>
                        <label className="search__filters-option-label">
                          <input
                            type="checkbox"
                            name="Newest to Oldest"
                            disabled={newesttooldest}
                            data-filtertype="recently_added"
                            checked={this.props.recently_added.includes("Newest to Oldest")}
                            onChange={this.props.handleFilterCheckboxInputChange}
                          />
                          Newest to Oldest
                          <span className="customCheckbox" />
                        </label><br />
                        <label className="search__filters-option-label">
                          <input
                            type="checkbox"
                            name="Oldest to Newest"
                            disabled={oldestnewestvisibilty}
                            data-filtertype="recently_added"

                            checked={this.props.recently_added.includes("Oldest to Newest")}
                            onChange={this.props.handleFilterCheckboxInputChange}
                          />
                          Oldest to Newest
                          <span className="customCheckbox" />
                        </label>
                      </>

                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display:
                      this.props.sectors_served.length > 0
                        ? "inline"
                        : "none",
                  }}
                  className="search__filters--filter-selected-marker"
                />
              </div>}

            <div
              onClick={this.toggleSearchFiltersDrawer}
              className="filter-toggle--mobileOnly"
            >
              <p className="filter-toggle--mobileOnly-text">Filters</p>
              <img src={filterIcon} alt="Mobile filters" />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Filters;
