import React from "react";

function RnDCenterSvg(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 72 56'
      preserveAspectRatio="xMinYMin meet"
      className={props.className}
    >
      <g fill='none'>
        <path
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M8 12h32v40H8z'
        ></path>
        <path
          fill='#FFF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M12 8h32v40H12z'
        ></path>
        <path fill='#C9DFFF' d='M13 9h2v39h-2z'></path>
        <path
          fill='#FFF'
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M30 14H19m9 4h-9m9 4h-9m10 4H19m14 4H19m19 4H19m19 4H19m19 5H19'
        ></path>
        <g
          stroke='#2F416B'
          strokeLinecap='round'
          strokeLinejoin='round'
          transform='rotate(-45 39 -23)'
        >
          <circle cx='9' cy='9' r='9' fill='#FFF'></circle>
          <path fill='#FFF' d='M7 18h4v9a2 2 0 11-4 0v-9z'></path>
          <circle cx='9' cy='9' r='6' fill='#4C96FF'></circle>
        </g>
        <g transform='translate(49 23)'>
          <path
            stroke='#2F416B'
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M19 14l-2 7v2H6v-2l-6-5v-6'
          ></path>
          <path
            fill='#FFF'
            stroke='#2F416B'
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M3 14V2a2 2 0 114 0v10m8 0V8a2 2 0 114 0v6M7 12V6a2 2 0 114 0v6m0 0V6a2 2 0 114 0v6'
          ></path>
          <path fill='#C9DFFF' d='M5 23h3v6H5z'></path>
          <path
            stroke='#2F416B'
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M5 23h13v6H5z'
          ></path>
          <circle
            cx='15'
            cy='26'
            r='1'
            fill='#4C96FF'
            stroke='#2F416B'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></circle>
        </g>
      </g>
    </svg>
  );
}

export default RnDCenterSvg;
