import React from 'react';
import { reduxForm, Field } from 'redux-form';

import Input from '../ui/paymentFormFields/Input';
import FormSelect from "../form-components/FormSelect";
import validate from '../../utils/validatePasswordForm';

const ChangePasswordForm = props => {
    const handleOnKeyPress = event => {
        if (event.key === "Enter") event.preventDefault();
    };

    const { invalid, submitting, pristine } = props;
const userTypes = [{"name":"Blogs","type":"blogs"},
                    {"name":"Reports","type":"reports"},
                    {"name":"Case Study","type":"case_study"},
                    ]
    return (
        <div>
            <form
                onSubmit={props.handleSubmit}
                className='edit-profile-form'
                onKeyPress={handleOnKeyPress}
                initialValues={props.initialValues}
            >

                <Field
                    component={FormSelect}
                    name="contribution"
                    label="contribution"
                    placeholder="Contribution"
                    options={userTypes.map(type => ({
                        label: type.name,
                        value: type.type,
                        className: "select-option",
                      }))}
                    type='name'
                    labelClass="payment__field-label"
                    inputClass="payment__field-input"
                    wrapperClass="payment__field-wrapper edit-profile-field-wrapper--name"
                    errorClass="payment__field-error"
                    myRef={props.myRef}
                />
 
 


{/*                <button
                    type="submit"
                    disabled={invalid || pristine || submitting}
                    className='edit-profile-form__submit-btn'
                >SAVE CHANGES</button>*/}
                    </form> 
        </div>
    )
}

export default reduxForm({
    form: 'change_password',
    validate: validate,
})(ChangePasswordForm);
