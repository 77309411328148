import React, { useState } from "react";

import HomeTop from "./HomeTop";
import LazyLoad from 'react-lazy-load';
import "../css/HomeNew.css";
const discoverArray = [
    {
        "name": "Company",
        "info": "<p>TechSagar, being a repository of tech companies and their products, budding & established researchers, accomplished academicians, offers a great opportunity to the companies in the following dimensions.</p>",
        "bulletPoints": ["<p><span>Enhance</span> depth & breadth of understanding about <span>country’s technology</span> capabilities</p>", "<p>Keep yourself <span>updated</span> with <span>emerging technology</span> trends</p>", "<p>Connect with <span>companies</span> with <span>capabilities</span> you are looking for</p>", "<p>Browse through <span>technology research</span> carried in the <span>country</span></p>", "<p>Identify the possibilities of <span>collaboration</span> and co-creation</p>", "<p>Showcase your <span>technology capabilities</span>, products, services, and solutions</p>"],
        "img": "https://tcr-phase2-documents.s3.ap-south-1.amazonaws.com/static-js/images/company.jpg",
    },
    {
        "name": "Academia",
        "info": "<p>TechSagar is the repository of information about technology capabilities developed by the leading academic institutions. TechSagar promises to add value to the academic institution in the following dimensions.</p>",
        "bulletPoints": [
            "<p>Keep <span>updated</span> with <span>emerging technology</span> trends </p>",
            "<p>Understand the commercial <span>significance</span> of the <span>technology capabilities</span></p>",
            "<p>Discover products, <span>services, and solutions</span> relevant to your <span>research interest</span></p>",
            "<p>Connect with companies for <span>collaboration</span> in its <span>academic and research</span> work</p>",
            "<p>Identify the possibility of <span>co-creation</span> & <span>productization</span> with relevant companies </p>",
            "<p>Showcase your <span>academic</span> and <span>research</span> capabilities and facilities </p>"],
        "img": "https://tcr-phase2-documents.s3.ap-south-1.amazonaws.com/static-js/images/academiaIn.jpg",
    },
    {
        "name": "R&D Centers",
        "info": "<p>TechSagar is a repository of information about research work carried out or in progress in the 27 technology areas. TechSagar can add value to the R&D Centers in the following dimensions.</p>",
        "bulletPoints": [
            "<p>Understand the <span>commercial significance</span> of the <span>R&D</span> you are doing</p>",
            "<p>Discover <span>products, services, and solutions</span> relevant to your research work</p>",
            "<p>Carry analysis of <span>capabilities</span> exist in the <span>country</span> in the area of your interest</p>",
            "<p><span>Connect</span> with companies for <span>collaboration</span> for your research work</p>",
            "<p><span>Identify</span> the possibility of co-development of core <span>technology capabilities</span></p>",
            "<p>Showcase your <span>research</span> work and <span>facilities</span></p>",],
        "img": "https://tcr-phase2-documents.s3.ap-south-1.amazonaws.com/static-js/images/r%26D.jpg",
    },
    {
        "name": "Researchers",
        "info": "<p>TechSagar is a repository of information about research work carried out or in progress in the 27 technology areas. TechSagar can be useful for technology researchers in the following dimensions.</p>",
        "bulletPoints": [
            "<p>Discover <span>products, services, and solutions</span> relevant to your research work</p>",
            "<p>Understand the commercial significance of the <span>research</span> work you are doing</p>",
            "<p><span>Explore</span> the work of peers to find possible <span>research collaborators</span></p>",
            "<p><span>Connect</span> with companies for <span>collaboration</span> in your research work</p>",
            "<p>Showcase your <span>research</span> work and <span>projects</span></p>"],
        "img": "https://tcr-phase2-documents.s3.ap-south-1.amazonaws.com/static-js/images/researcher.jpg",
    },
]
const DiscoverSection = () => {
    const [selectedItem, setSelectedItem] = useState(discoverArray[0].name);
    const [showDropDownn, setShowDropDownn] = useState(false)
    const handleItemClick = (itemName) => {
        setSelectedItem(itemName);
        setShowDropDownn(!showDropDownn)
    };

    var selectedItemInfo = discoverArray.find(function (item) {
        return item.name === selectedItem;
    });
    const selectedInfo = discoverArray.find((item) => item.name === selectedItem)
        ? discoverArray.find((item) => item.name === selectedItem).info
        : '';
    const selectedBulletPoints = selectedItemInfo ? selectedItemInfo.bulletPoints : '';

    const showDropDown = () => {
        setShowDropDownn(!showDropDownn);
    }
    var selectedImg = selectedItemInfo ? selectedItemInfo.img : null;
    return (
        <div style={{ marginTop: "15px" }} className="home__discover sections-Container-Top discoverSecMain">
            <div className="home__discover__section-left infosection">
                <h3>Discover Techsagar</h3>
                <div className="dropdown">
                    <h4 onClick={showDropDown}>
                        I'm a <span className="dropdown-toggle">{selectedItem}&nbsp;<i className="fas fa-angle-down" /></span>
                    </h4>
                    {showDropDownn ? <ul className={`dropdown-menu ${selectedItem ? 'show' : ''}`}>
                        {discoverArray.map((item) => (
                            <li key={item.name} onClick={() => handleItemClick(item.name)}>
                                {item.name}
                            </li>
                        ))}
                    </ul> : ''}

                </div>
                <div className="infoInnerHtml" dangerouslySetInnerHTML={{ __html: selectedInfo }} />

                <div className="discoverInfoCardsContainer">
                    {selectedBulletPoints && (
                        selectedBulletPoints.map((item, index) => (

                            <div className="col-md-6 col-lg-4 d-flex align-items-stretch" style={{ marginBottom: "5%" }} key={`bullet-point-${index}`}>
                                <div className="discoverInfoCardsItem" dangerouslySetInnerHTML={{ __html: item }} />
                            </div>

                        ))
                    )}
                </div>
            </div>
            <div className="home__cta__section-left imgSection">
                <LazyLoad offset={300}>
                    {selectedImg && <img src={selectedImg} alt={selectedItem} className='home__discover_img' />}
                </LazyLoad>
            </div>


        </div>
    );
};

export default DiscoverSection;