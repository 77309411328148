import React, { useState, useEffect } from 'react';
import api from '../api';
import { useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Loader from './ui/loader/Loader';

function BlogDetail() {
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  

  const location = useLocation();

  useEffect(() => {
    const link = location.pathname;
    const authKey = process.env.REACT_APP_AUTH_KEY;

    const fetchBlog = async () => {
      try {
        const response = await fetch(`${api.BlogPage}${link}`, {
          headers: {
            'Auth-Key': authKey
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setBlog(data.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchBlog();
  }, [location.pathname]);

  if (loading) {
    return <div><Loader loading={loading} height="500px"></Loader></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!blog) {
    return <div>Blog not found.</div>;
  }

  const sanitizedBody = DOMPurify.sanitize(blog.body);

  return (
    
    
    <div className='blog-detail'>
      <div className="published-date">
        {/*<i className="fa fa-calendar calendar-icon"></i>*/} 
        {blog.published_date}
      </div>
      <h2 className="blog-title">{blog.title}</h2>
      <div className="blog-body" dangerouslySetInnerHTML={{ __html: sanitizedBody }} />
    </div>
    
    
  );
}

export default BlogDetail;
