import React, { Component, Fragment } from "react";
import { Field, FieldArray, reduxForm } from "redux-form";

import FormInputField from "../form-elements/FormInputField";
import Captcha from "../../form-components/Captcha";

import validate from "../utils/validate";

import saperatorImg from "../../../img/form-imgs/object-separator.svg";

class FormPart10 extends Component {
    render() {
        const { handleSubmit } = this.props;
        const onKeyPress = event => {
            if (event.key === "Enter") {
                event.preventDefault();
            }
        };

        return (
            <Fragment>
                <p className="feature_form__head">Miscellaneous</p>
                <form
                    onSubmit={handleSubmit(this.props.onSubmit)}
                    onKeyPress={onKeyPress}
                >
                    <div className="feature_form__inner-wrapper">
                        <FieldArray
                            name="key_global_competitors"
                            component={renderGlobalComps}
                        />
                        <hr />
                        <FieldArray
                            name="recognitions"
                            component={renderRecognitions}
                        />
                        <hr />
                        <Field
                            className="form-globalinfo__recaptcha"
                            name="recaptcha"
                            component={Captcha}
                        />
                    </div>
                    <div className="feature_form__btn-wrapper">
                        <button
                            className="feature_form__btn feature_form__btn-back"
                            onClick={this.props.prevPage}
                        >
                            GO BACK
                        </button>
                        <button type="submit" className="feature_form__btn">
                            SUBMIT
                        </button>
                    </div>
                </form>
            </Fragment>
        );
    }
}

const renderGlobalComps = ({ fields, formType }) => (
    <div className="form__globalinfo-global-competitors">
        <div className="feature_form__part_head">Similar Works</div>

        {fields.map((comp, index) => (
            <div key={index} className="form__row">
                <div className="form__globalinfo-row form__row form__row--60">
                    <Field
                        name={`${comp}.name`}
                        component={FormInputField}
                        widthClass="form__field-wrapper--40"
                        label="Name"
                        tabable={true}
                    />
                    <Field
                        name={`${comp}.url`}
                        component={FormInputField}
                        widthClass="form__field-wrapper--60"
                        label="URL"
                        tabable={true}
                    />
                </div>
                {fields.length > 1 && (
                    <div className="feature_form__field-array-remove">
                        <span onClick={() => fields.remove(index)}>Remove</span>
                    </div>
                )}

                {fields.length > index + 1 && (
                    <div className="feature_form__saperator-img-wrapper">
                        <img src={saperatorImg} alt="Saperator" />
                    </div>
                )}
            </div>
        ))}

        <div className="feature_form__field-array-add">
            <span onClick={() => fields.push({})}>+ Add More</span>
        </div>
    </div>
);

const renderRecognitions = ({ fields }) => (
    <div className="feature_form__local-competitors">
        <div className="feature_form__part_head">Recognitions</div>

        {fields.map((rec, index) => (
            <div key={index} className="form__row">
                <Field
                    name={`${rec}.content`}
                    component={FormInputField}
                    label="Recognitions"
                    placeholder="Share any related recognitions"
                    tabable={true}
                />
                <Field
                    name={`${rec}.year`}
                    component={FormInputField}
                    label="Year"
                    optional={true}
                    tabable={true}
                />
                {fields.length > 1 && (
                    <div className="feature_form__field-array-remove">
                        <span onClick={() => fields.remove(index)}>Remove</span>
                    </div>
                )}
                {fields.length > index + 1 && (
                    <div className="feature_form__saperator-img-wrapper">
                        <img src={saperatorImg} alt="Seperator" />
                    </div>
                )}
            </div>
        ))}

        <div className="feature_form__field-array-add">
            <span onClick={() => fields.push({})}>+ Add More</span>
        </div>
    </div>
);

export default reduxForm({
    form: "feature_yourself_old", // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(FormPart10);
