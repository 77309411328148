
import React from 'react'
import "../css/ReDesignSummaryGlance.css"

import { Link } from "react-router-dom";


export default function ReDesignSummaryGlance(props) {
    
  return (
                    <Link to={`/search/?keyword=${props.url}&resultType=${props.search}`} key={props.key}>
                    <div className='reDesignSummaryGlance-Cards'>
                        <div className='reDesignSummaryGlance-Cards-Text'>
                            <div>
                                <span>{props.data.total_count}</span>
                            </div>
                            <span>{props.name}</span>
                        </div>
                        <div className='reDesignSummaryGlance-Cards-Img'>
                            <img src={props.logo} alt="companies" />
                        </div>
                    </div>
                </Link>
  )
}






