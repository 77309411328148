import React from "react";
import { Field, reduxForm } from "redux-form";

import FormInputField from "../form-components/FormInputField";
import FormButton from "../form-components/FormButton";
import FormSelect from "../form-components/FormSelect";
import validate from "./signup-validate";

import "../../css/UserForm.css";
import "../../css/Form.css";
import api from '../../api';

const SignupPart1 = props => {


  const { handleSubmit, changeType } = props;
  return (
    <div className="user-form__right">
      <p className="user-form__progress">Step 1 of 3</p>
      <div className="user-form__right-head user-form__right-head--signup"
      >Personal Details</div>

      <form className='user-form__signup-1' onSubmit={handleSubmit}>
        <div className="user__form-container">
          <div className="row user__form-row">
            <Field
              name="first_name"
              widthClass="col-md-6"
              component={FormInputField}
              type="text"
              placeholder="First Name"
              tabable={true}
            />
            <Field
              widthClass="col-md-6"
              name="last_name"
              component={FormInputField}
              type="text"
              placeholder="Last Name"
              tabable={true}
            />
          </div>
          <div className="row user__form-row">
            <Field
              name="email"
              component={FormInputField}
              type="text"
              placeholder="Email Address"
              tabable={true}
            />
          </div>

          <div className="row user__form-row">
            <Field
              name="user_type"
              component={FormSelect}
              placeholder="Affiliation"
              selectConfig={{
                closeOnSelect: true,
                disabled: false,
                multi: false,
                simpleValue: true,
                removeSelected: false,
              }}
              options={props.userTypes.map(type => ({
                label: type.name,
                value: type.name,
                className: "select-option",
              }))}
            />
          </div>

          <div className="row user__form-row">
            <Field
              name="password"
              component={FormInputField}
              type="password"
              placeholder="Create Password"
              tabable={true}
            />
          </div>
        </div>
        <div className="form__signup-btn-and-login--wrapper">
          <FormButton label="CONTINUE" />
          <p>
            Already a member?&nbsp;
            <span
              className="form__login-span"
              onClick={() => changeType("login")}
            >
              Login
            </span>
          </p>
        </div>
      </form>
    </div>
  );
};

export default reduxForm({
  form: "signup", // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate: validate,
  asyncValidate: asyncValidateEmail, asyncValidatePassword,
  asyncBlurFields: ['email']
})(SignupPart1);

async function asyncValidateEmail(values) {
  const url = `${api.checkEmailAvailability}?email=${values.email}`;
  return fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  }).then(response => {
    if (!response || !response.ok) {
      // eslint-disable-next-line
      throw { email: 'This email is already taken' };
    }
  });
}
async function asyncValidatePassword(values) {
  const url = `${api.checkPasswordValidation}`;
  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  }).then(response => {
    if (!response || !response.ok) {
      // eslint-disable-next-line
      throw { email: 'This email is already taken' };
    }
  });
}