import React from "react";
import PropTypes from "prop-types";

const FormButton = props => (
    
    <div > 
    <button type="submit" className="user-button  user-form__button" >
        <span
            style={{
                display: `${!props.formLoader ? "inline-block" : "none"}`,
            }}
        >
            {props.label}
        </span>
        <i
            className="fas fa-spinner fa-spin"
            style={{
                display: `${props.formLoader ? "inline-block" : "none"}`,
            }}
        />
    </button>
    </div>
);

FormButton.propTypes = {
    label: PropTypes.string,
    formLoader: PropTypes.bool,
};

export default FormButton;
