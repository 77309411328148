import React from 'react';

const EntityLookupSuggestions = ({ item, switchToClaimEntity }) => {
  return (
    <div className='feature-form__search-entity-suggestion-item' onClick={() => switchToClaimEntity(item)}>
      <div>
        <p className='feature-form__search-entity-suggestion-name'
        >{item.name}</p>
        <p className='feature-form__search-entity-suggestion-city'
        >{item.city}</p>
      </div>

      {/* <button
      onClick={() => switchToClaimEntity(item)}
      className={`feature-form__claim-entity-btn ${
        !item.claimed ? '' : 'feature-form__claim-entity-btn--disabled' 
      }`}>
        {item.claimed ? 'CLAIMED' : 'CLAIM'}
      </button> */}
    </div>
  );
}

export default EntityLookupSuggestions;