import {
    UPDATE_SEARCH_QUERY,
    UPDATE_SEARCH_REDIRECT,
    UPDATE_CAPABILITY,
    UPDATE_AREA,
} from "./_types";

export const updateSearchQuery = ({ query }) => ({
    type: UPDATE_SEARCH_QUERY,
    query,
});

export const updateSearchRedirect = ({ redirect }) => ({
    type: UPDATE_SEARCH_REDIRECT,
    redirect,
});

export const updateArea = ({ area }) => ({
    type: UPDATE_AREA,
    area,
});

export const updateCapability = ({ capability }) => ({
    type: UPDATE_CAPABILITY,
    capability,
});
