//*  ----------------------------------------------------------------------------------------------------------------
//*  THIS CONTAINS THE CODE TO HANDLE CHECKING / UNCHECKING OF THE TECHNOLOGY FILTERS ON THE ENTITY PAGE
//*  RECEIVES THE PREVIOUS FILTERS AND REQUIRED DATA, AND RETURNS THE UPDATED FILTERS ARRAY FOR AREA AND CAPABILITIES
//*  ----------------------------------------------------------------------------------------------------------------

//  @param entityTechAreas --> technology_type of entity
//  @param prevStateFilters --> filters object from the state (filters that were previously applied)

//  Handles technology area filter change
export const handleAreaFilterChange = (
    name,
    checked,
    prevStateFilters,
    entityTechAreas
) => {
    //  Handles check
    if (checked) {
        const updatedAreaFilters = [...prevStateFilters.techArea, name];
        const updatedCapabilityFilters = [
            ...prevStateFilters.capability,
            ...entityTechAreas.filter(area => area.name === name)[0]
                .tech_capability,
        ];

        return {
            techArea: updatedAreaFilters,
            capability: updatedCapabilityFilters,
        };
    }

    //  Handles uncheck
    const updatedAreaFilters = prevStateFilters.techArea.filter(
        value => value !== name
    );
    const updatedCapabilityFilters = prevStateFilters.capability.filter(
        value =>
            !entityTechAreas
                .filter(area => area.name === name)[0]
                .tech_capability.includes(value)
    );

    return {
        techArea: updatedAreaFilters,
        capability: updatedCapabilityFilters,
    };
};

//  Handles technology capability filter change
export const handleCapabilityFilterChange = (
    name,
    checked,
    selectedArea,
    prevStateFilters,
    entityTechAreas
) => {
    //  Handles check
    if (checked) {
        const updatedCapabilityFilters = [...prevStateFilters.capability, name];
        const updatedAreaFilters = prevStateFilters.techArea.includes(
            selectedArea
        )
            ? prevStateFilters.techArea
            : [...prevStateFilters.techArea, selectedArea];

        return {
            techArea: updatedAreaFilters,
            capability: updatedCapabilityFilters,
        };
    }

    //  Handles uncheck
    const updatedCapabilityFilters = prevStateFilters.capability.filter(
        value => value !== name
    );

    const areaFiltersCopy = [...prevStateFilters.techArea];
    const updatedAreaFilters = areaFiltersCopy.filter(area => {
        if (area !== selectedArea) return true;

        let includeArea = false;
        entityTechAreas.forEach(areaObj => {
            if (areaObj.name !== selectedArea) return;

            //  Only set includeArea to true (include this area in filters) if one of the capability of that
            //  technology area (other than the one just unchecked) is present in the previous capability filters
            //  eslint-disable-next-line
            for (let capability of areaObj.tech_capability) {
                if (
                    capability !== name &&
                    prevStateFilters.capability.includes(capability)
                ) {
                    includeArea = true;
                    break;
                }
            }
        });
        return includeArea;
    });

    return {
        techArea: updatedAreaFilters,
        capability: updatedCapabilityFilters,
    };
};
