import React from "react";

const TermsAndConditions = props => (
  <>
    {/* Banner Section */}
    <div className="infoPage__banner infoPage__banner--about">
      <p className="infoPage__bannerTitle">Terms Of Service</p>
    </div>
    {/* End of banner section */}

    {/* Main section */}
    <main className="infoPage__body">
      <div className="infoPage__bodyBox">
        <p className="infoPage__bodyText"> 
        The Terms of Service govern the use of this TechSagar Portal and the content contained therein.
        All Users/Organizations/Visitors of the Portal who may or may not be registered on the portal are
        expected to adhere to the Terms of Service of the said Portal. When any user uses this portal, 
        then they acknowledge to have read the Terms of Service and accept that they will be bounded by the terms 
        hereof and such terms as may be modified from time to time.<br /><br />

        Any information and content featured on this portal may not be distributed, modified, reproduced,
         or used, in whole or in part, without the prior written consent of DSCI; except that you may download 
         the content or test from this system to any single computer, provided, you keep intact all copyright, 
         trademark, and other proprietary notices; and wherever the information is being published, reproduced,
          or issued to others, the source must be prominently acknowledged. The information on the portal should 
          be reproduced accurately and must not be used in a derogatory manner or a misleading context.<br /><br />
          
          TechSagar Portal trademarks and copyrights may not be used in connection with any product or service 
          that is not related to the portal, in any manner that is likely to confuse customers, or in any way that 
          disparages or discredits TechSagar portal. All other trademarks and logos used in this system are the 
          trademarks or logos of their respective owners. Users gain no right to use any representations of TechSagar
           Portal or DSCI or any other entity by virtue of the use of this system.<br /><br />
          
           Unless otherwise indicated in this system, including the Privacy Policy or in connection with one of the 
           services, any communications or material of any kind that is transmitted through this portal (excluding 
           personally identifiable information of individuals) including, reports, and other statistical information,
            will be treated as non-confidential and non-proprietary. The use of personally identifiable information 
            of an individual is outlined in the Privacy Policy.<br /><br />

            DSCI reserves the right to modify, suspend/cancel, or discontinue any or all sections, or service at any time 
            without notice. It also reserves the right to make modifications and alterations in the information contained 
            on the portal without notice.<br /><br />

            DSCI reserves the right to discontinue the member login on account of non-payment of subscription fee or violation 
            of the ‘code of conduct’ set by itself.<br /><br />

            DSCI reserves the right to deny, at its sole discretion, any user whatsoever access to the portal or a part thereof.
          <br /><br />
          
          DSCI reserves the right to modify the terms of use of any service without any liability. DSCI reserves the right to 
          take all measures necessary to prevent access to any service or termination of service if the terms of use are not 
          complied with or are contravened or there is any violation of copyright, trademark, or other proprietary rights.<br /><br />

          Notwithstanding Our efforts to ensure that the state portal system is secure, you acknowledge that all 
          electronic data transfers are potentially susceptible to interception by others<br/><br/>

          <strong>Refund & cancellation policy</strong>
          <br/><br/>

          Currently, the access to TechSagar is free of cost. Once you register, you will be able to access all 
          the tabs at no cost. In future when TechSagar begins paid knowledge offerings (documents, reports and 
          limited access to technology areas etc.), the refunds and cancellation will be subject to this policy. 
          All the subscribers will have access to free-resources at all times. There will be certain offerings 
          which will be subject to subscription cost (clearly labelled). Once the subscriber downloads the document,
          the fee for the document or knowledge asset will not be refunded under any circumstances. In order to help
          the subscriber to assess the relevance of the document or knowledge asset such as reports, TechSagar will
          provide preview to Table Of Content (TOC). We reserve the right to change the refund & cancellation policy
          at any time without prior notice. In the event that any changes are made, the revised policy shall be posted
          on this website immediately.
        </p>
      </div>
    </main>
  </>
);

export default TermsAndConditions;
