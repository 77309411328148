import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import NotFound from "./NotFound";

import PieChart from "./browsingtech-components/PieChart";
import MapRenderer from "./map-components/MapRenderer";
import ShareLink from "./ShareLink";
import Modal from "./ui/modal/Modal";
import Loader from "./ui/loader/Loader";

//  Icons and Image Imports
import ShareIcon from "../img/share-dark.svg";
import FollowIcon from "../img/follow.svg";

import AcademiaSvg from '../inline-svg-components/AcademiaSvg';
import UniqueProductsSvg from '../inline-svg-components/UniqueProductsSvg';
import CompaniesSvg from '../inline-svg-components/CompaniesSvg';
import ResearchersSvg from '../inline-svg-components/ResearchersSvg';
import RnDCenterSvg from '../inline-svg-components/RnDCenterSvg';

// import PDFIcon from "../img/pdf.svg";
import arrowImage from "../img/card-arrow.svg";

import { pieColors } from "../utils/graphColors";
import noPieChartDataImg from '../img/no-pie-chart.svg';
import "../css/BrowsingTech.css";
import api from "../api";

import getBannerImage from "./helper-functions/bannerImageDecider";
import {
  updateSearchQuery,
  updateArea,
  updateCapability,
} from "../actions/search";

class BrowsingCapability extends Component {
  state = {
    fetching: true,
    capability: "",
    capabilityDetails: {
      patent_count: "",
      companies: [],
      description: "",
      name: "",
      technology_capabilities: [],
      techarea_pi_areawise: [],
      techarea_pi_typewise: [],
      top_liked_capabilities: [],
    },
    trendingProducts: [],
    locations: [],
    locationFilter: "",
    loadingMap: false,
    expandAbout: false,
    expandCapabilities: false,
    showShareModal: false,
    liked: false,
    bannerImage: null,
  };

  componentDidMount() {
    this.getQueryParams();
    this.props.updateSearchQuery("");
  }

  // Get Query Params
  getQueryParams = () => {
    // create new search params object from location
    var format = /[_]+/;
    // OLD code
    // const urlQueryParams = new URLSearchParams(this.props.location.search);

    // NEW code
    // removing queryparam for that using direct path --- below building directh path

    let pathList = this.props.location.pathname.split('/')

    // console.log("this.props.location", this.props.location);
    // console.log("pathList", pathList);
  
    //  if found any percentage then converting percentage word to symbol character else rest of them
    // console.log(pathList)
     let capabilityFromlocation = pathList[pathList.length - 1].replace('percentage', "%").replaceAll("_", " ").replaceAll("2906","/").replaceAll("--","/")

    // console.log("capabilityFromlocation",capabilityFromlocation);
    //let capabilityFromlocation =  localStorage.getItem('capability')
    //let capabilityFromlocation = format.test(pathList[pathList.length - 1]) && pathList[pathList.length - 1].includes("-") ? pathList[pathList.length - 1].replaceAll("_", " ").replaceAll("12", "/") :
    //  pathList[pathList.length - 1].includes("_") && !pathList[pathList.length - 1].includes("12") ? pathList[pathList.length - 1].replaceAll("_", "-") : pathList[pathList.length - 1].replaceAll("-", " ").replaceAll("12", "/").replaceAll("  ", "% ").replaceAll("_", "-")


    // console.log(capabilityFromlocation)

    this.setState(
      {
        // capability: urlQueryParams.get("capability")//OLD
        capability: capabilityFromlocation
      },
      () => {
        this.getCapabilityDetails();
        this.getTrendingProducts();
        this.getLocations("company");
        this.props.updateSearchQuery(this.state.capability);
        this.props.updateArea(this.state.capability);
        this.props.updateCapability("");
      }
    );
  };

  setBannerImage = () => {
    this.setState({
      bannerImage: getBannerImage(
        this.state.capabilityDetails.tech_area_alias
      ),
    });
  };

  toggleExpandAbout = () => {

    this.setState(prevState => ({
      expandAbout: !prevState.expandAbout,
    }));
  };

  toggleExpandCapabilities = () => {
    ("toggleExpandCapabilities called");
    this.setState(prevState => ({
      expandCapabilities: !prevState.expandCapabilities,
    }));
  };

  // Fetch tech browsing details
  getCapabilityDetails = () => {

    // console.log(this.state.capability)
    this.setState({
      fetching: true,
    });

    const requestConfig = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    if (this.props.userInfo && this.props.userInfo.token) {
      requestConfig.headers['TCRAUTH'] = this.props.userInfo.token;
    }



    fetch(
      `${process.env.REACT_APP_API_URL
      }/bulletin/techcapability/browsing/?capability=${encodeURIComponent(this.state.capability)}`,
      requestConfig
    )
      .then(response => {
        if (response.status !== 200) {
          // console.log(
          //   "Looks like there was a problem. Status Code: " +
          //   response.status
          // );
          return;
        }

        // Examine the text in the response
        response.json().then(response => {
          // console.log(response)
          if (response.data.tech_area_alias === "Data Not Available") {
            //console.log("data")
            this.props.history.push("/NotFound");
            return;
          }
          this.setState(
            {
              likes: response.data.likes,
              liked:
                this.props.userInfo &&
                this.props.userInfo.username &&
                response.data.liked_by &&
                response.data.liked_by.some(
                  userObj =>
                    userObj.username ===
                    this.props.userInfo.username
                ),
              capabilityDetails: response.data,
              fetching: false,
            },
            this.setBannerImage
          );
        });
      })
      .catch(err => {
        // console.log("Cannot retrieve Data:", err);
      });
  };

  getTrendingProducts = () => {
    this.setState({
      fetching: true,
    });

    fetch(
      `${api.trending_products}?request_type=capability&keyword=${encodeURIComponent(this.state.capability)
      }&limit=12`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          // "Content-Type": "application/json",
        },
      }
    )
      .then(response => {
        if (response.status !== 200) {
          // console.log(
          //   "Looks like there was a problem. Status Code: " +
          //   response.status
          // );
          return;
        }

        // Examine the text in the response
        response.json().then(response => {

          this.setState({
            trendingProducts: response.data,
          });
        });
      })
      .catch(err => {
        // console.log("Cannot retrieve Data:", err);
      });
  };

  // Fetch locations
  getLocations = filter => {
    if (this.state.locationFilter !== filter) {
      this.setState({
        locations: [],
        loadingMap: true,
      });
      fetch(
        `${api.entities_locations
        }?search_type=tech_capability&filter_type=${filter}&keyword=${encodeURIComponent(this.state.capability)
        }`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            // "Content-Type": "application/json",
          },
        }
      )
        .then(response => {
          if (response.status !== 200) {
            // console.log(
            //   "Looks like there was a problem. Status Code: " +
            //   response.status
            // );
            return;
          }

          // Examine the text in the response
          response.json().then(response => {

            this.setState({
              loadingMap: false,
              locations: response.data,
              locationFilter: filter,
            });
          });
        })
        .catch(err => {
          // console.log("Cannot retrieve Data:", err);
          this.setState({
            loadingMap: false,
          });
        });
    }
  };

  followToggle = () => {
    if (!this.props.userInfo || !this.props.userInfo.username) {
      toast.error("You must login first to follow");
      return;
    }

    fetch(`${api.action_on_technology}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        TCRAUTH: this.props.userInfo.token,
      },
      body: JSON.stringify({
        technology: encodeURIComponent(this.state.capability),
        action: "like",
      }),
    }).then(response => {

      if (response.status !== 200) {
        // console.log("Problem liking/following the tech area");
        return;
      }

      response.json().then(response => {
        if (response.success) {
          this.setState(prevState => ({ liked: !prevState.liked }));
        }
      });
    });
  };

  shareArea = () => {
    //  Disabled the login check, share modal works without logging in
    // if (this.props.userInfo) {
    //     this.toggleShareModal();
    // } else if (!this.prevToastId || !toast.isActive(this.prevToastId)) {
    //     this.prevToastId = toast.error("Please login first!");
    // }
    this.toggleShareModal();
  };

  toggleShareModal = () => {

    this.setState(prevState => {
      return {
        showShareModal: !prevState.showShareModal,
      };
    });
  };

  render() {
    //console.log(this.state.capability)

    document.title = `${this.state.capability.replace("-plus", "+")} - Technology Capability Repository || DSCI`;
    const pieArrLen = pieColors.length;
    const pieChartData = this.state.capabilityDetails.techcapability_pi_typewise
      ? this.state.capabilityDetails.techcapability_pi_typewise.map(
        (obj, index) => ({
          name: obj.name,
          value: obj.value,
          itemStyle: { color: pieColors[index % pieArrLen] },
        })
      )
      : [];

    const entityTypePieChart = pieChartData && pieChartData.length
      ? <PieChart data={pieChartData} />
      : <img alt='No data' src={noPieChartDataImg} className='no-pie-data-image' />;

    if (this.state.capabilityDetails.tech_area_alias != "Undefined") {
      var techarea = this.state.capabilityDetails.tech_area_alias
    }
    //console.log(this.props.history.location.pathname.split('/')[2])
    var techarea = this.props.history.location.pathname.split('/')[2]
    var routeCapability = this.props.history.location.pathname.split('/')[this.props.history.location.pathname.split('/').length - 1]
    return (
      <div className="browsingtech">
        {this.state.capability && (
          <Modal
            show={this.state.showShareModal}
            toggleModal={this.toggleShareModal}
            width="fitContent"
            className="shareLinkModal"
          >
            <ShareLink
              url={`${process.env.REACT_APP_CONSUMER_LINK
                }` + this.props.location.pathname}
            />
          </Modal>
        )}
        <Loader loading={this.state.fetching} height="500px">
          {this.state.capabilityDetails.tech_area ===
            "Data Not Available" ? (
            <NotFound />
          ) : (
            <Fragment>
              <div className="browsingtech__jumbotron">
                {/* Left half of jumbotron, with all the texts and links */}
                <div className="browsingtech__jumbotron-text">
                  <div className="browsingtech__top">
                    <div className="browsingtech__breadcrumb">
                      <Link to="/">Home</Link> /{" "}
                      {
                        <Link
                          to={{
                            pathname: `/technology/${String(this.state
                              .capabilityDetails
                              .tech_area_alias).replace(/ /g, "_")
                              }`
                          }
                          }
                        >
                          {
                            this.state
                              .capabilityDetails
                              .tech_area_alias
                          }
                        </Link>
                      }{" "}
                      / {this.state.capability.replace("-plus", "+")}
                    </div>
                  </div>
                  <h1 className="browsingtech__jumbotron-head">
                    {this.state.capability.replace("-plus", "+")}
                  </h1>

                  {this.state.capabilityDetails
                    .tech_capability_description && (
                      <div className="browsingtech__jumbotron-desc">
                        <div>
                          {this.state.capabilityDetails.tech_capability_description
                            .split("\n")
                            .map((p, index) => (
                              <p key={index}>{p}</p>
                            ))}
                        </div>
                      </div>
                    )}

                  <div className="browsingtech__top-links-wrapper">
                    <div
                      className="browsingtech__top-link"
                      onClick={this.shareArea}
                    >
                      {/* <i className="fas fa-user-plus" /> Follow */}
                      <img
                        className="browsingtech__top-link-icon"
                        src={ShareIcon}
                        alt="Share"
                      />
                    </div>
                    <div
                      onClick={this.followToggle}
                      className="browsingtech__top-link browsingtech__top-link--follow"
                    >
                      {/* <i className="fas fa-share-alt" /> Share */}
                      <img
                        className="browsingtech__top-link-icon"
                        src={FollowIcon}
                        alt="Follow Icon"
                      />
                      &nbsp; &nbsp;
                      {this.state.liked ? (
                        <span>Unfollow</span>
                      ) : (
                        <span>Follow</span>
                      )}
                    </div>
                  </div>
                </div>
                {/* End of left half of jumbotron */}

                {/* Jumbotron banner image on the right */}
                <div className="browsingtech__banner-img-wrapper">
                  {this.state.bannerImage && (
                    <img
                      className="browsingtech__banner-img"
                      src={this.state.bannerImage}
                      alt="IOT Banner"
                    />
                  )}
                </div>
              </div>

              {/* Count Cards Section */}
              <div className="browsingtech__counts-cards-container">
                <div className="browsingtech__counts-card--wrapper">
                  <Link
                    className="browsingtech__counts-card"
                    to={{
                      pathname: `/search/technology/${techarea}/capability`,
                      //search: `?keyword=${this.state.capability.replace(/ /g, "_").replace("/", "12")}&resultType=companies`,
                      search: `?keyword=${encodeURIComponent(this.state.capability.replace(/ /g, "_"))}&resultType=companies`,
                    }}
                  // to={{
                  //   pathname: "/searchresults",
                  //   search: `?q=${encodeURIComponent(
                  //     JSON.stringify({
                  //       search_type:
                  //         "tech_capability",
                  //       keyword: this.state
                  //         .capability,
                  //       resultType: "companies",
                  //     })
                  //   )}`,
                  // }}
                  >
                    <div className="card-type-and-arrow-wrapper">
                      <div className="browsingtech__counts-card-type">
                        Companies
                      </div>
                      <div className="card-arrow-img-wrapper">
                        <img
                          src={arrowImage}
                          alt="Go"
                        />
                      </div>
                    </div>
                    <div className="browsingtech__counts-card__icon-and-count-wrapper">
                      <div>
                        <CompaniesSvg className='statIcons' />
                      </div>
                      <p className="browsingtech__counts-card-count">
                        {
                          this.state.capabilityDetails
                            .companies_count
                        }
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="browsingtech__counts-card--wrapper">
                  <Link
                    className="browsingtech__counts-card"
                    //   to={{
                    //     pathname: "/searchresults",
                    //     search: `?q=${encodeURIComponent(
                    //       JSON.stringify({
                    //         search_type:
                    //           "tech_capability",
                    //         keyword: this.state
                    //           .capability,
                    //         resultType: "academia",
                    //       })
                    //     )}`,
                    //   }}

                    to={{
                      pathname: `/search/technology/${techarea}/capability`,
                      search: `?keyword=${encodeURIComponent(this.state.capability.replace(/ /g, "_"))}&resultType=academia`,
                    }}
                  >
                    <div className="card-type-and-arrow-wrapper">
                      <div className="browsingtech__counts-card-type">
                        Academia
                      </div>
                      <div className="card-arrow-img-wrapper">
                        <img
                          src={arrowImage}
                          alt="Go"
                        />
                      </div>
                    </div>

                    <div className="browsingtech__counts-card__icon-and-count-wrapper">
                      <div>
                        <AcademiaSvg className='statIcons' />
                      </div>
                      <div className="browsingtech__counts-card-count">
                        {
                          this.state.capabilityDetails
                            .academia_count
                        }
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="browsingtech__counts-card--wrapper">
                  <Link
                    className="browsingtech__counts-card"
                    to={{
                      pathname: `/search/technology/${techarea}/capability`,
                      search: `?keyword=${encodeURIComponent(this.state.capability.replace(/ /g, "_"))}&resultType=r_and_d`,
                    }}
                  //   to={{
                  //     pathname: "/searchresults",
                  //     search: `?q=${encodeURIComponent(
                  //       JSON.stringify({
                  //         search_type:
                  //           "tech_capability",
                  //         keyword: this.state
                  //           .capability,
                  //         resultType: "r_and_d",
                  //       })
                  //     )}`,
                  //   }}
                  >
                    <div className="card-type-and-arrow-wrapper">
                      <div className="browsingtech__counts-card-type">
                        R&D
                      </div>
                      <div className="card-arrow-img-wrapper">
                        <img
                          src={arrowImage}
                          alt="Go"
                        />
                      </div>
                    </div>
                    <div className="browsingtech__counts-card__icon-and-count-wrapper">
                      <div>
                        <RnDCenterSvg className='statIcons' />
                      </div>
                      <div className="browsingtech__counts-card-count">
                        {
                          this.state.capabilityDetails
                            .r_and_d_count
                        }
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="browsingtech__counts-card--wrapper">
                  <Link
                    className="browsingtech__counts-card"

                    to={{
                      pathname: `/search/technology/${techarea}/capability`,
                      search: `?keyword=${encodeURIComponent(this.state.capability.replace(/ /g, "_"))}&resultType=individual`,
                    }}

                  //  to={{
                  //    pathname: "/searchresults",
                  //    search: `?q=${encodeURIComponent(
                  //      JSON.stringify({
                  //        search_type:
                  //          "tech_capability",
                  //        keyword: this.state
                  //          .capability,
                  //        resultType: "individual",
                  //      })
                  //    )}`,
                  //  }}
                  >
                    <div className="card-type-and-arrow-wrapper">
                      <div className="browsingtech__counts-card-type"
                      >Researchers</div>
                      <div className="card-arrow-img-wrapper">
                        <img src={arrowImage} alt="Go" />
                      </div>
                    </div>

                    <div className="browsingtech__counts-card__icon-and-count-wrapper">
                      <div>
                        <ResearchersSvg className='statIcons' />
                      </div>
                      <div className="browsingtech__counts-card-count">
                        {
                          this.state.capabilityDetails
                            .individuals_count
                        }
                      </div>
                    </div>
                  </Link>
                </div>

                {/* TODO: UNIQUE PRODUCTS CARD */}
                <div className="browsingtech__counts-card--wrapper">
                  <Link
                    className="browsingtech__counts-card"
                    to={{
                      pathname: `/search/technology/${techarea}/capability`,
                      search: `?keyword=${encodeURIComponent(this.state.capability.replace(/ /g, "_"))}&resultType=products`,
                    }}
                  //  to={{
                  //    pathname: "/searchresults",
                  //    search: `?q=${encodeURIComponent(
                  //      JSON.stringify({
                  //        search_type:
                  //          "tech_capability",
                  //        keyword: this.state
                  //          .capability,
                  //        resultType: "products",
                  //      })
                  //    )}`,
                  //  }}
                  >
                    <div className="card-type-and-arrow-wrapper">
                      <div className="browsingtech__counts-card-type"
                      >Products</div>
                      <div className="card-arrow-img-wrapper">
                        <img src={arrowImage} alt="Go" />
                      </div>

                    </div>
                    <div className="browsingtech__counts-card__icon-and-count-wrapper">
                      <div>
                        <UniqueProductsSvg className='statIcons' />
                      </div>
                      <div className="browsingtech__counts-card-count">
                        {
                          this.state.capabilityDetails
                            .unique_products
                        }
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="browsingtech__info-section-wrapper">
               {this.state.trendingProducts && this.state.trendingProducts.length > 0 && (
  <p className="browsingtech__info-head">
    Trending Products
  </p>
)}


                <div className="container-fluid browsingtech__trending-products__cards-wrapper">
                  <div className="row">
                    {this.state.trendingProducts && this.state.trendingProducts.length>0 && 
                      this.state.trendingProducts.map(
                        (product, index) => (
                          <Link
                            to={{
                              pathname: `/${techarea}/${this.state.capability.replace(/ /g, "_")}/${product.name.replace("%", "percentage").replace(/ /g, "_")}`,
                            }}
                            key={index}
                            className="col-sm-6 col-md-3 browsingtech__products-card"
                          >
                            <div className="browsingtech__products-card-head">
                              {
                                product.product_name
                              }
                            </div>
                            <div className="browsingtech__products-card-subhead">
                              {product.name}
                            </div>
                          </Link>
                        )
                      )}
                  </div>
                </div>
              </div>

              {/* Geographical Distribution */}
              <div className="browsingtech__info browsingtech__info--cap">
                <div className="browsingtech__info-left">
                  <div className="browsingtech__info-left-section browsingtech__map-wrapper">
                    <p className="browsingtech__distribution-map__head"
                    >Geographical Distribution</p>

                    <div className="browsingtech__map-filters-and-comp-sizes--wrapper">
                      <div className="browsingtech__map-filters-wrapper">
                        <p className="browsingtech__map-filters-head"
                        >Filter By Entity</p>

                        <div className="browsingtech__map-filters__container">
                          <div>
                            <input
                              className="map-filter-radio"
                              name="map_filter"
                              id="company_filter"
                              type="radio"
                              onClick={this.getLocations.bind(null, "company")}
                              defaultChecked={true}
                            />
                            <label
                              htmlFor="company_filter"
                              className="browsingtech__map-filter"
                            >
                              <span className="custom-radio-btn" />
                              Companies
                            </label>
                          </div>

                          <div>
                            <input
                              className="map-filter-radio"
                              name="map_filter"
                              id="academia_filter"
                              type="radio"
                              onClick={this.getLocations.bind(null, "academia")}
                            />
                            <label
                              htmlFor="academia_filter"
                              className="browsingtech__map-filter"
                            >
                              <span className="custom-radio-btn" />
                              Academia
                            </label>
                          </div>

                          <div>
                            <input
                              className="map-filter-radio"
                              name="map_filter"
                              id="individual_filter"
                              type="radio"
                              onClick={this.getLocations.bind(
                                null,
                                "individual"
                              )}
                            />
                            <label
                              htmlFor="individual_filter"
                              className="browsingtech__map-filter"
                            >
                              <span className="custom-radio-btn" />
                              Researchers
                            </label>
                          </div>
                          <div>
                            <input
                              className="map-filter-radio"
                              name="map_filter"
                              id="rnd_filter"
                              type="radio"
                              onClick={this.getLocations.bind(null, "r_and_d")}
                            />
                            <label
                              htmlFor="rnd_filter"
                              className="browsingtech__map-filter"
                            >
                              <span className="custom-radio-btn" />
                              R & D Centres
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* End of map filters wrapper */}

                      <div className="browsingtech__comp-sizes--wrapper">
                        <p className="browsingtech__map-filters-head"
                        >Distribution Against Cluster Size</p>

                        <div className="browsingtech__comp-sizes-container">
                          <div className="browsingtech__comp-sizes__size">
                            <div className="comp-size__marker sqr--1" />
                            2-9
                          </div>

                          <div className="browsingtech__comp-sizes__size">
                            <div className="comp-size__marker sqr--2" />
                            10-99
                          </div>

                          <div className="browsingtech__comp-sizes__size">
                            <div className="comp-size__marker sqr--3" />
                            100-999
                          </div>

                          <div className="browsingtech__comp-sizes__size">
                            <div className="comp-size__marker sqr--4" />
                            1,000-9,999
                          </div>

                          <div className="browsingtech__comp-sizes__size">
                            <div className="comp-size__marker sqr--5" />
                            10,000+
                          </div>
                        </div>
                      </div>
                    </div>
                    <MapRenderer
                      techarea={techarea}
                      capability={routeCapability}
                      locations={this.state.locations}
                      loading={this.state.loadingMap}
                      height="130%"
                    // zoom={4}
                    />
                  </div>
                </div>

                <div className="browsingtech__info-right">
                  <div className="browsingtech__pie-chart-wrapper">
                    <p className="browsingtech__pie-chart-head"
                    >Distribution Against Entity Type</p>

                    {entityTypePieChart}
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </Loader>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.auth.userInfo,

});

const mapDispatchToProps = dispatch => ({
  updateSearchQuery: query => dispatch(updateSearchQuery({ query: query })),
  updateArea: area => dispatch(updateArea({ area: area })),
  updateCapability: capability =>
    dispatch(updateCapability({ capability: capability })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrowsingCapability);
