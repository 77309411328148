import React, { Component } from 'react';
import ResetPasswordModal from '../modal/ResetPasswordModal'
import api from '../api';
import FormInputField from './form-components/FormInputField';
import { connect } from "react-redux";
import { setFormType } from "../actions/user-form";
import UserForm from './UserForm';
import Modal from './ui/modal/Modal';
import ForgotPassword from './user-form-components/ForgotPassword';

export class AutoLogout extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showModal: true,
            showUserForm: false,
        }

        // this.changeType = this.changeType.bind(this);
    }

    adjustBackgroundScrolling = () => {
        const state = this.state.showUserForm ? 'hidden' : 'visible';
        document.querySelector("body").style.overflow = state;
        document.querySelector("html").style.overflow = state;
    }

    // changeType(type) {
    //      
    //     this.props.setFormType(type);
    // }

    toggleModal = () => this.setState({ showUserForm: !this.state.showUserForm },
        () => this.adjustBackgroundScrolling());


    toggleUserForm = () => {
        this.props.setFormType('forgotPassword');
        this.toggleModal();
    };

    handleClose() {
        this.setState({ showModal: !this.state.showModal }, () => {
            document.querySelector("body").style.overflow = 'visible';
            document.querySelector("html").style.overflow = 'visible';
        })
    }

    handleReset = () => {
      
        this.setState({ showModal: false, showUserForm: true }, () => {
            document.querySelector("body").style.overflow = 'visible';
            document.querySelector("html").style.overflow = 'visible';
        })
        this.toggleUserForm()
    }


    render() {
 

        return (
            <>
                <ResetPasswordModal
                    showModal={this.state.showModal}
                    handleClose={() => this.handleClose()}
                    handleReset={this.handleReset}
                />

                <UserForm
                    show={this.state.showUserForm}
                    toggle={this.toggleModal}
                />


            </>

        )
    }
}



const mapDispatchToProps = dispatch => ({
    setFormType: formType => dispatch(setFormType({ formType: formType })),
});

export default connect(
    null,
    mapDispatchToProps
)(AutoLogout);