export async function fetchFoamData(techarea, request_type,level,capability='') {
   
    //let domain= "http://dsci-tech-env-al2-new-stage.ap-south-1.elasticbeanstalk.com/";
    let domain= "https://www.techsagar.in" + "/backend";
    let respData="";
    if(level==1){
        var url=domain+'/technology/techsagar-tools/level2/?format=json&techarea='+techarea+'&request_type='+request_type;
         await fetch(url, {
            method: 'GET',
            mode: "cors",
            headers: {
              "Accept": "application/json"
            },
          }).then(response => { respData= response.json()});
          
    }else if(level==2){
        var url=domain+'/technology/techsagar-tools/level3/?format=json&techarea='+techarea+'&request_type='+request_type+'&capability='+capability.split('(')[0];
		 await fetch(url, {
            method: 'GET',
            mode: "cors",
            headers: {
              "Accept": "application/json"
            },
          }).then(response => { respData= response.json()});
    }
    return respData;
}