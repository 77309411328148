import React from 'react'

import { useState } from 'react';
import "../css/ReDesignHomeTech.css"
import { Link } from "react-router-dom";

import showMoreIcon from "../../src/img/redesign-home/redesign-home-tech/showmore.png"

const imageBaseURL = 'https://tcr-phase2-documents.s3.ap-south-1.amazonaws.com/home-asset/technologies_icons';
const imageBackgroundUrl = 'https://tcr-phase2-documents.s3.ap-south-1.amazonaws.com/home-asset/Technology-Background-Image'
const iconsDict = {
  IOTIcon: imageBaseURL + '/IOT.svg',
  IotBackground: imageBackgroundUrl + '/internet-of-things.webp',

  BlockchainIcon: imageBaseURL + '/Blockchain.svg',
  BlockChainBackground: imageBackgroundUrl + '/blockchain.webp',

  CryptologyIcon: imageBaseURL + '/Cryptology.svg',
  CryptologyBackground: imageBackgroundUrl + '/cryptology.webp',

  CompVisionIcon: imageBaseURL + '/Computer_Vision.svg',
  ComputerBackground: imageBackgroundUrl + '/computer-vision.webp',

  DataScienceIcon: imageBaseURL + '/Data_Science.svg',
  DataScienceBackground: imageBackgroundUrl + '/data-science.webp',

  ARVRIcon: imageBaseURL + '/Augmented_Virtual Reality.svg',
  ARVRBackground: imageBackgroundUrl + '/unmanned-aerial-vehicles.webp',

  WirelessIcon: imageBaseURL + '/Wireless_&_Networking_technologies.svg',
  WirelessBackground: imageBackgroundUrl + '/wireless-and-networking-technologies.webp',

  OSIcon: imageBaseURL + '/Operating_Systems.svg',
  OperatingBackground: imageBackgroundUrl + '/operating-systems.webp',

  EnterpriseBusinessIcon: imageBaseURL + "/Enterprise_Business_Technology.svg",
  EnterpriseBackground: imageBackgroundUrl + "/enterprise-business-and-rechnologies.webp",

  LocationPresenceIcon: imageBaseURL + "/Location_and_Presence.svg",
  LocationBackground: imageBackgroundUrl + "/location-and-presence.webp",

  MaterialScienceIcon: imageBaseURL + "/Material_Science_for_IT.svg",
  MaterialScienceBackground: imageBackgroundUrl + "/material-science-for-IT.webp",

  AIMLIcon: imageBaseURL + "/AL_ML_DL.svg",
  AIMLBackground: imageBackgroundUrl + "/ai-and-ml.webp",

  CloudIcon: imageBaseURL + "/Cloud_and_virtualization.svg",
  CloudBackground: imageBackgroundUrl + "/cloud-computing-and-virtualization.webp",

  NextGenComputingIcon: imageBaseURL + "/Next_Generation_Computing.svg",
  NextGenBackground: imageBackgroundUrl + "/next-generation-computing.webp",

  DigitalForensicsIcon: imageBaseURL + "/Digital_Forensics.svg",
  ForensicBackground: imageBackgroundUrl + "/digital-forensic.webp",

  RoboticsAutomationIcon: imageBaseURL + "/Robotics_And_Automation.svg",
  AutomationBackground: imageBackgroundUrl + "/robotics-and-automation.webp",

  HardwaresIcon: imageBaseURL + "/Hardware_and_ semiconductors.svg",
  HardwaresBackground: imageBackgroundUrl + "/hardware-semiconductor-and-embedded.webp",

  IdentityManagementIcon: imageBaseURL + "/Identity_management_and_Authentication.svg",
  IDMBackground: imageBackgroundUrl + "/identity-management-and-authentication.webp",

  HumanComputerInteractionIcon: imageBaseURL + "/Human_Computer_Interaction.svg",
  HCIBackground: imageBackgroundUrl + "/human-computer-interaction.webp",

  NLPIcon: imageBaseURL + "/Natural_language_processing(NLP).svg",
  NLPBackground: imageBackgroundUrl + "/natural-language-processing.webp",

  SoftwareDefinedNetworksIcon: imageBaseURL + "/Software_Defined_Networks_and_Infrastructrures.svg",
  SDNBackground: imageBackgroundUrl + "/software-defined-infrastructure.webp",

  MobilityAndEndPointsIcon: imageBaseURL + "/Mobility_and_End_points.svg",
  MobileEndpointBackground: imageBackgroundUrl + "/mobility-and-end-points.webp",

  AppInfrastructureIcon: imageBaseURL + "/Application_Infrastructure_and_Software.svg",
  ApplicationInfrastructureBackground: imageBackgroundUrl + "/application-infrastructure-and-software.webp",

  UAVIcon: imageBaseURL + '/UAV.svg',
  UavBackground: imageBackgroundUrl + '/augmented-and-virtual-reality.webp',

  CyberSecurityAreaIcon: imageBaseURL + '/Cybersecurity.svg',
  CyberSecurityBackground: imageBackgroundUrl + '/cybersecurity.webp',

  QuantumComputing: imageBaseURL + '/QuantumComputing_White.svg',
  QuantumComputingBackground: imageBackgroundUrl + '/quantum-technology.webp',

  fiveGtoSixG: imageBaseURL + '/5g-and-6g-1.svg',
  FiveGToSixBackground: imageBaseURL + '/5g-and-6g.webp'

};
// This is the object of all areas with their names and image URL
const allAreas = [
  {
    name: "AI and ML",
    image: iconsDict.AIMLIcon,
    backgroundImage: iconsDict.AIMLBackground
  },
  {
    name: "Internet of Things",
    // image: iconsDict.IOTIcon,
    image: iconsDict.IOTIcon,
    backgroundImage: iconsDict.IotBackground
  },
  {
    name: "Blockchain",
    image: iconsDict.BlockchainIcon,
    backgroundImage: iconsDict.BlockChainBackground
  },
  {
    name: "Application Infrastructure and Software",
    image: iconsDict.AppInfrastructureIcon,
    backgroundImage: iconsDict.ApplicationInfrastructureBackground
  },
  {
    name: "Augmented and Virtual Reality",
    // image: VirtualIcon,
    image: iconsDict.ARVRIcon,
    backgroundImage: iconsDict.ARVRBackground
  },
  {
    name: "Cloud Computing and Virtualization",
    image: iconsDict.CloudIcon,
    backgroundImage: iconsDict.CloudBackground
  },
  {
    name: "Computer Vision",
    image: iconsDict.CompVisionIcon,
    backgroundImage: iconsDict.ComputerBackground
  },
  {
    name: "Cryptology",
    image: iconsDict.CryptologyIcon,
    backgroundImage: iconsDict.CryptologyBackground
  },
  {
    name: "Cybersecurity",
    image: iconsDict.CyberSecurityAreaIcon,
    backgroundImage: iconsDict.CyberSecurityBackground
  },
  {
    name: "Wireless and Networking Technologies",
    image: iconsDict.WirelessIcon,
    backgroundImage: iconsDict.WirelessBackground
  },
  {
    name: "Hardware, Semiconductors and Embedded",
    image: iconsDict.HardwaresIcon,
    backgroundImage: iconsDict.HardwaresBackground
  },
  {
    name: "Robotics and Automation",
    image: iconsDict.RoboticsAutomationIcon,
    backgroundImage: iconsDict.AutomationBackground
  },
  {
    name: "Natural Language Processing",
    image: iconsDict.NLPIcon,
    backgroundImage: iconsDict.NLPBackground
  },
  {
    name: "Data Science",
    image: iconsDict.DataScienceIcon,
    backgroundImage: iconsDict.DataScienceBackground
  },
  {
    name: "Material Science",
    image: iconsDict.MaterialScienceIcon,
    backgroundImage: iconsDict.MaterialScienceBackground
  },
  {
    name: "Next Generation Computing",
    image: iconsDict.NextGenComputingIcon,
    backgroundImage: iconsDict.NextGenBackground
  },
  {
    name: "Digital Forensics",
    image: iconsDict.DigitalForensicsIcon,
    backgroundImage: iconsDict.ForensicBackground
  },
  {
    name: "Identity Management and Authentication",
    image: iconsDict.IdentityManagementIcon,
    backgroundImage: iconsDict.IDMBackground
  },
  {
    name: "Enterprise Business Technologies",
    image: iconsDict.EnterpriseBusinessIcon,
    backgroundImage: iconsDict.EnterpriseBackground
  },
  {
    name: "Operating Systems",
    image: iconsDict.OSIcon,
    backgroundImage: iconsDict.OperatingBackground
  },
  {
    name: "Software Defined Infrastructure",
    image: iconsDict.SoftwareDefinedNetworksIcon,
    backgroundImage: iconsDict.SDNBackground
  },
  {
    name: "Unmanned Aerial Vehicles",
    image: iconsDict.UAVIcon,
    backgroundImage: iconsDict.UavBackground
  },
  {
    name: "Human Computer Interaction",
    image: iconsDict.HumanComputerInteractionIcon,
    backgroundImage: iconsDict.HCIBackground
  },
  {
    name: "Mobility and End Points",
    image: iconsDict.MobilityAndEndPointsIcon,
    backgroundImage: iconsDict.MaterialScienceBackground
  },
  {
    name: "Location and Presence",
    image: iconsDict.LocationPresenceIcon,
    backgroundImage: iconsDict.LocationBackground
  },
  {
    name: "Quantum Technology",
    image: iconsDict.QuantumComputing,
    // image: quantumtechnologySVGIcon,
    backgroundImage: iconsDict.QuantumComputingBackground
  },
  {
    name: "5G and 6G",
    image: iconsDict.fiveGtoSixG,
    backgroundImage: iconsDict.ForensicBackground
  }
];

const sortTechAreasAlphabetically = (areas) => {
  const sortedAreas = [...areas].sort((a, b) => {
    const aIsLetter = isNaN(a.name[0]);
    const bIsLetter = isNaN(b.name[0]);

    if (aIsLetter === bIsLetter) {
      return a.name.localeCompare(b.name);
    }

    return aIsLetter ? -1 : 1;
  });

  return sortedAreas;
};

const newAllAreas = sortTechAreasAlphabetically(allAreas);

const ReDesignHomeTech = () => {
  const [techAreaBtnClickedStatus, setTechAreaBtnClickedStatus] = useState(false)

  return (
    <div className='reDesignHomeTech-Container'>
      <div className='reDesignHomeTech-Heading'>
        <p>Browse by Technologies</p>
      </div>


      {/* <div className='reDesignHomeTech-Tech-Card  row home__browse__container'> */}
      <div className='reDesignHomeTech-Tech-Card  home__browse__container'>

        {
          newAllAreas.map((area, i) =>
            techAreaBtnClickedStatus ?
              (
                <Link to={`/technology/${area.name.replace(/ /g, "_")}`}
                  // className="col-lg-2 col-md-3 col-sm-4 col-xs-6 home__area__card__wrapper" key={i}>
                  className="col-lg-2 col-md-3 col-sm-4 home__area__card__wrapper" key={i} style={{ height: '200px' }}>
                  <div style={{ backgroundImage: `url(${area.backgroundImage})` }} className="reDesignHomeTech-Tech-Cards home__area__card">
                    <div className='content'>
                      <img
                        className="home__area__card__img"
                        src={area.image}
                        alt="IOT"
                      />
                      <div className="reDesignHomeTech-Tech-Name  home__area__card__name">{area.name}</div>
                    </div>
                  </div>
                </Link>
              )
              :
              i < 12 && (
                <Link to={`/technology/${area.name.replace(/ /g, "_")}`}
                  // className="col-lg-2 col-md-3 col-sm-4 col-xs-6 home__area__card__wrapper" key={i}>
                  className="col-lg-2 col-md-3 col-sm-4 home__area__card__wrapper" key={i} style={{ minHeight: '225px' }}>
                  <div style={{ backgroundImage: `url(${area.backgroundImage})` }} className="reDesignHomeTech-Tech-Cards home__area__card">
                    <div className='content'>
                      <img
                        className="home__area__card__img"
                        src={area.image}
                        alt="IOT"
                      />
                      <div className="reDesignHomeTech-Tech-Name  home__area__card__name">{area.name}</div>
                    </div>
                  </div>
                </Link>
              )
          )
        }

      </div>


      <div className='reDesignHomeTech-Bottom' onClick={() => setTechAreaBtnClickedStatus(!techAreaBtnClickedStatus)}>
        <img src={showMoreIcon} alt="show more" />
        <span>
          {techAreaBtnClickedStatus ? "See less" : "See more"}
        </span>
      </div>

    </div>
  )
}


export default ReDesignHomeTech