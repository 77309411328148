import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";

import { useEffect } from "react";
import { techAreas } from "./TechAreaDropdownNew";


const TechAreasDrawer = ({ updatePadding, onSelectTechArea }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const sortTechAreasAlphabetically = (areas) => {
        const sortedAreas = [...areas].sort((a, b) => {
            const aIsLetter = isNaN(a.name[0]);
            const bIsLetter = isNaN(b.name[0]);

            if (aIsLetter === bIsLetter) {
                return a.name.localeCompare(b.name);
            }

            return aIsLetter ? -1 : 1;
        });

        return sortedAreas;
    };
    const sortedAreas = sortTechAreasAlphabetically(techAreas);
    // console.log(sortedAreas)
    const areasColumn1 = sortedAreas.slice(0, 9);
    const areasColumn2 = sortedAreas.slice(9, 18);
    const areasColumn3 = sortedAreas.slice(18, 27);




    const areasColumn1Content = areasColumn1.map(areaObj => {

        return (
            <div
                onClick={() => handleItemClick(areaObj.name)}
                key={areaObj.name}
                className="header__technologies--dropdown__element"
                to={{
                    pathname: `/technology/${areaObj.name.replace(/ /g, "-")}`,

                }}

            >

                {areaObj.name}
            </div>)

    });

    const areasColumn2Content = areasColumn2.map(areaObj => (

        <div
            rel="canonical"
            key={areaObj.name}
            onClick={() => handleItemClick(areaObj.name)}
            className="header__technologies--dropdown__element"
            to={{
                pathname: `/technology/${areaObj.name.replace(/ /g, "-")}`,
                state: areaObj.name
            }}
        >
            {areaObj.name}
        </div>

    ));

    const areasColumn3Content = areasColumn3.map(areaObj => (

        <div
            rel="canonical"
            key={areaObj.name}
            onClick={() => handleItemClick(areaObj.name)}
            className="header__technologies--dropdown__element"
            to={{
                pathname: `/technology/${areaObj.name.replace(/ /g, "-")}`,
                state: areaObj.name
            }}
        >
            {areaObj.name}
        </div>

    ));
    const handleItemClick = (itemName) => {
        setSelectedItem(itemName);
        updatePadding(calculatePadding(itemName));
        // Pass the selected tech area back to the parent component
        onSelectTechArea(itemName); // Assuming itemName holds the tech area value
    };

    const calculatePadding = (itemName) => {
        const span = document.createElement("span");
        span.textContent = itemName;
        span.className = 'searchFilter';
        span.style.position = 'absolute';
        span.style.visibility = 'hidden';
        document.body.appendChild(span);
        const width = span.offsetWidth + 30;
        document.body.removeChild(span);
        return width + 10;
    };

    return (
        <div className="header__technologies dropDownRes" style={{ marginLeft: '0', paddingLeft: '0', cursor: 'pointer' }}>
            {selectedItem ? (
                <>
                    {selectedItem}&nbsp;
                    <i className="fas fa-angle-down header__technologies-caret" />
                </>
            ) : (
                <>
                    Technologies&nbsp;
                    <i className="fas fa-angle-down header__technologies-caret" />
                </>
            )}
            <div className="header__technologies--dropdown">
                <div className="header__technologies--dropdown-content">
                    <div className="header__technologies--dropdown-content-col">
                        {areasColumn1Content}
                    </div>
                    <div className="header__technologies--dropdown-content-col">
                        {areasColumn2Content}
                    </div>
                    <div className="header__technologies--dropdown-content-col">
                        {areasColumn3Content}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TechAreasDrawer;
