import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";

import FormInputField from "../form-elements/FormInputField";
import FormSelectAsyncCreatable from "../form-elements/FormSelectAsyncCreatable";

import validate from "../utils/validate";
import FormTextAreaField from "../form-elements/FormTextAreaField";

import api from '../../../api';

class FormPart2 extends Component {
    getSimilarCompanies = (input, cb) => {
        let options = [];

        // For query less than 3 letters
        if (input.length < 3) {
            cb(options);
            return;
        }

        const requestConfig = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: input })
        };

        fetch(api.entityLookup, requestConfig)
            .then(response => {
                if (!response || !response.ok) throw Error(response.status);
                return response.json();
            })
            .then(({ data }) => {
                options = data.map(company => ({
                    value: company.name,
                    label: company.name,
                    id: company.id,
                    className: 'select-option'
                }));
                cb(options);
            })
            .catch(err => console.log('Error performing company lookup for similar companies', err));
    };


    render() {
        const { handleSubmit, entityType } = this.props;
        const onKeyPress = event => {
            if (event.key === "Enter") {
                event.preventDefault();
            }
        };

        return (
            <Fragment>
                <p className="feature_form__head">Basic Details</p>
                <form
                    onSubmit={handleSubmit(this.props.onSubmit)}
                    onKeyPress={onKeyPress}
                >
                    <div className="feature_form__inner-wrapper">
                        <Field
                            name="name"
                            component={FormSelectAsyncCreatable}
                            label={`${entityType}’s Legal Name`}
                            placeholder="E.g Indian Institute of Technology Delhi"
                            selectConfig={{
                                isClearable: true,
                                isOptionDisabled: option => {
                                    // console.log(option);
                                    if (option.label.indexOf("Create"))
                                        return true;
                                },
                            }}
                            loadOptions={this.getSimilarCompanies}
                            components={{DropdownIndicator: null}}
                            // components={entityType === 'R&D Centre' ? {DropdownIndicator: null} : null}
                        />
                        <Field
                            name="url"
                            // widthClass="col-md-6"
                            component={FormInputField}
                            type="text"
                            placeholder={`Enter the URL of the ${entityType}`}
                            label={`${entityType}’s Website`}
                            tabable={true}
                        />
                        <Field
                            name="alias"
                            // widthClass="col-md-6"
                            component={FormInputField}
                            type="text"
                            placeholder="Enter the alias"
                            label="Alias / Alternate Name"
                            tabable={true}
                        />
                        <Field
                            name="affiliated_to"
                            // widthClass="col-md-6"
                            component={FormInputField}
                            type="text"
                            placeholder="Enter any affiliations with organisations or bodies"
                            label="Affiliations"
                            tabable={true}
                        />
                        <Field
                            name="about"
                            // widthClass="col-md-6"
                            component={FormTextAreaField}
                            placeholder={`Describe about ${entityType}`}
                            label="About"
                            tabable={true}
                        />
                        <Field
                            name="comment"
                            // widthClass="col-md-6"
                            component={FormTextAreaField}
                            label="Comments"
                            tabable={true}
                        />
                    </div>
                    <div className="feature_form__btn-wrapper">
                        <button
                            className="feature_form__btn feature_form__btn-back"
                            onClick={this.props.prevPage}
                        >
                            GO BACK
                        </button>
                        <button type="submit" className="feature_form__btn">
                            CONTINUE
                        </button>
                    </div>
                </form>
            </Fragment>
        );
    }
}

export default reduxForm({
    form: "feature_yourself_old", // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(FormPart2);
