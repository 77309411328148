import React from "react";

import TabPortfolio from "./tabs/TabPortfolio";

const CompanyInvestor = ({
    data,
    followButton,
    currentMenuItem,
    changeCurrentMenuItem,
}) => {
    return (
        <div>
            <div className="entityview__top">
                <div className="entityview__top-desc-wrapper">
                    <div className="entityview__top-breadcrumb">&nbsp;</div>

                    <div>
                        <p className="entityview__top-head">
                            Blume Ventures (India)
                        </p>

                        <div className="entityview__subhead">
                            <div className="entityview__subhead-section entityview__stats-section">
                                {/* // TODO -- add checks for address type size and then only render  */}
                                <div className="entityview__subhead-desc">
                                    <i className="fas fa-map-marker-alt entityview__subhead-desc-icon" />
                                    New Delhi
                                </div>

                                <div className="entityview__subhead-desc">
                                    <i className="fas fa-briefcase entityview__subhead-desc-icon" />
                                    Public Private Partnership
                                </div>

                                <div className="entityview__subhead-desc">
                                    <i className="fas fa-users entityview__subhead-desc-icon" />
                                    1000+ employees
                                </div>
                            </div>

                            <div className="entityview__subhead-section entityview__subhead__buttons">
                                <div
                                    className="entityview__subhead-btn"
                                    //  onClick={this.props.toggleModal}
                                >
                                    <i className="fas fa-share-alt" />
                                </div>
                                {followButton}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="entityview__top-nav">
                    <div
                        onClick={changeCurrentMenuItem.bind(null, "portfolio")}
                        className={`entityview__top-nav-btn ${currentMenuItem ===
                            "portfolio" && "entityview__top-nav-btn--active"}`}
                    >
                        PORTFOLIO
                    </div>

                    <div
                        onClick={changeCurrentMenuItem.bind(null, "about")}
                        className={`entityview__top-nav-btn ${currentMenuItem ===
                            "about" && "entityview__top-nav-btn--active"}`}
                    >
                        ABOUT
                    </div>

                    <div
                        onClick={changeCurrentMenuItem.bind(null, "contact")}
                        className={`entityview__top-nav-btn ${currentMenuItem ===
                            "contact" && "entityview__top-nav-btn--active"}`}
                    >
                        CONTACT
                    </div>
                </div>
            </div>

            {currentMenuItem === "portfolio" && <TabPortfolio data={data} />}
        </div>
    );
};

export default CompanyInvestor;
