import React from "react";
import '../css/infoPage.css';


 
const AboutUs = () => {
  document.title =`About - Technology Capability Repository || DSCI`;
  return(
  <>
    <div className="infoPage__banner infoPage__banner--about">
      <h1 className="infoPage__bannerTitle">About TechSagar</h1>
    </div>

    <main className="infoPage__body">
      <div className="infoPage__bodyBox">

        <p className="infoPage__bodyText">
        Emerging technology capabilities have become central to National strategic outlook. There is
        a need for a concerted effort in developing critical technology capabilities in the country for
        India’s geopolitical advantage. Start-ups, enterprises, academia, researchers, and R&amp;D
        institutes in the country need to synergize their efforts and work in tandem to achieve this
        national goal.<br /><br />

        The office of National Cyber Security Coordinator, in partnership with Data Security Council
        of India conceptualized ‘National Technology Capability Repository’, and this gave rise to
        TechSagar. The office of National Cyber Security Coordinator continues to support
        TechSagar.<br /><br />

        TechSagar is a consolidated and comprehensive repository of India’s emerging tech
        capabilities which provides actionable insights about capabilities of the Indian Industry,
        Academia &amp; Research across 27 technology areas like IoT, AI/ML, Blockchain, Cloud &amp;
        Virtualization, Robotics &amp; Automation, AR/VR, Wireless &amp; Networking, and more. It allows
        targeted search, granular navigation and drilldown methods using more than 3000 niche
        capabilities. TechSagar features entities from large &amp; small enterprises, start-ups, academia,
        R&amp;D centers and even individual researchers, providing a country level view of India’s
        emerging tech competencies.<br /><br />

        In addition to entities, TechSagar also provides information about 5000+ products &amp;
        solutions, and 3500+ services from start-ups and large enterprises. It also provides
        information on key investments and fund flows in various technology areas and companies,
        subject to availability in public domain.<br /><br />

        TechSagar is a dynamic platform and shall be frequently updated with new entities and
        information to maintain its relevancy and usefulness. The repository facilitates several
        opportunities to collaborate, connect and innovate, and also endeavors to provide direction
        for a strategic emerging tech roadmap of the country in R&amp;D, industry development, and
        strengthening the preparedness of National Security.
        </p>
      </div>
    </main>
  </>)
};

export default AboutUs;
