import React from "react";
import { toast } from "react-toastify";

// Css
import "../css/ShareLink.css";

const ShareLink = ({ url }) => {
    const shareUrlRef = React.createRef();

    const copyUrl = () => {
        const shareUrlNode = shareUrlRef.current;
        shareUrlNode.select();
        document.execCommand("copy");
        toast.info("Link copied to clipboard");
    };

    return (
        <div className="sharelink__listing-share-modal">
            <div className="sharelink__listing-share-social-icons-wrapper">
                <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                    target="_blank"
                    className="sharelink__listing-share-social-icon"
                    style={{
                        background: "#3b5998",
                    }}
                    rel="noopener noreferrer"
                >
                    <i className="fab fa-facebook-f" />
                </a>
                <a
                    href={`https://twitter.com/home?status=Check%20out%20this%20info%3A%20%0A${url}`}
                    target="_blank"
                    className="sharelink__listing-share-social-icon"
                    style={{
                        background: "#1ca1f2",
                    }}
                    rel="noopener noreferrer"
                >
                    <i className="fab fa-twitter" />
                </a>
                <a
                    href={`https://plus.google.com/share?url=${url}`}
                    target="_blank"
                    className="sharelink__listing-share-social-icon"
                    style={{
                        background: "#d34836",
                    }}
                    rel="noopener noreferrer"
                >
                    <i className="fab fa-google-plus-g" />
                </a>
                <a
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=Tech%20Repository&summary=Check%20out%20this%20info!&source=`}
                    target="_blank"
                    className="sharelink__listing-share-social-icon"
                    style={{
                        background: "#0077b5",
                    }}
                    rel="noopener noreferrer"
                >
                    <i className="fab fa-linkedin-in" />
                </a>
                <a
                    href={`mailto:?subject=Shared || Tech Repository&body=Check%20out%20this%20info%3A%20%0A${url}`}
                    target="_blank"
                    className="sharelink__listing-share-social-icon"
                    style={{
                        background: "#888888",
                    }}
                    rel="noopener noreferrer"
                >
                    <i className="fas fa-envelope" />
                </a>
            </div>
            <div className="sharelink__listing-share-or">or</div>
            <input
                className="sharelink__listing-share-url"
                ref={shareUrlRef}
                defaultValue={url}
                readOnly="readonly"
            />
            <button
                className="sharelink__listing-share-url-copy-btn"
                onClick={copyUrl}
            >
                Copy URL
            </button>
        </div>
    );
};

export default ShareLink;
