import { SET_USER_INFO } from "../actions/_types";

const initialState = {
    userInfo: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_INFO:
            return {
                ...state,
                userInfo: action.userInfo,
            };
        default:
            return state;
    }
};

export default authReducer;
