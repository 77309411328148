import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { setFormType } from '../../actions/user-form';

import businessTypeImg from "../../img/business-type.svg";
import locationImg from "../../img/location.svg";
import peopleImg from "../../img/people.svg";
import productIcon from "../../img/search-products.svg";
import serviceIcon from "../../img/search-services.svg";


class EntityCard extends React.Component {
  handleEntityCardClick = (event) => {
     
    const { entity, location,history } = this.props;
    const path = location.search;
    const pathname = location.pathname.split('/').filter(e => e);
    const splitPath = path.split('&');
    const index = splitPath[0].indexOf("=");
    const resultType = splitPath[0].slice(index + 1);
     
    const entityName = entity.name;
    if (entityName){
    const name = entityName.replace("%", "percentage").replace(/ /g, "_");
   
    let entityType = entity.type;
    let type = "";
    
    if (entityType === "Academia") {
      type = "institute";
    } else if (entityType === "R & D Centres") {
      type = "rd-centre";
    } else if (entityType === "Individual Researcher") {
      type = "researcher";
    } else if (entityType !== "Academia" && entityType !== "R & D Centres" && entityType !== "Individual Researcher") {
      type = "company";
    }
    
    const pathlength = location.pathname.split('/').filter(e => e);
    
    const cap_path = location.pathname.split('/').slice(0, 3).join('/')
    let newPath = "";
    // console.log(Boolean(resultType))
    
    if (pathlength.length >= 3) {
      newPath = pathlength.length ===3 ?  `${cap_path}/${name}` : `/${location.pathname.split('/')[3]}/${resultType}/${name}`;
    } else if (pathlength.length === 2) {
      newPath =['company', 'institute', 'rd-centre', 'researcher'].includes(pathlength[0]) ? `/${pathlength[0]}/${name}` :  Boolean(resultType) ? `/${resultType}/${name}` : `/${pathlength[0]}/${name}`;
    }  
      
     else {
      newPath = pathlength.length > 1 ? `/${resultType}/${name}` : `/${type}/${name}`;
    }
   return window.location.origin + newPath;
     
  }
    
  }

  prepareTechnologyTypeString(techTypeArray) {
    //console.log(techTypeArray,"&*&*&*&*&*&*&*&")
    const unique = [];
    techTypeArray.map(x => unique.filter(a => a.name == x.name).length > 0 ? null : unique.push(x));
    //const resultStr = unique
    //  .slice(0, 3)
    //  .map(tech_type => tech_type.name)
    //  .join(" ・ ");
    // 
    var techArea = []
    var techArea1 = []
    for (let i = 0; i < unique.length; i++) {
      if ("capability_type" in unique[i]) {
        techArea.push(unique[i]["name"]);
      }
      else {
        techArea1.push(unique[i]["name"])
        techArea1 = techArea1.slice(0, 2)
      }
    }
    const finalTechArea = techArea.concat(techArea1)
    //console.log(techArea.slice(0,3).toString().replaceAll(","," ・ "))
    return finalTechArea.toString().replaceAll(",", " ・ ")
  };

  prepareString(offerings, entityType, offeringsType) {
    if (!offerings || !offerings.length) return null;
    const count = offerings.length;
    let text = 'SOLUTION';

    // If offeringsType is 'solutions'
    if (offeringsType === 'solutions') return (
      <span>{count} {text}{count > 1 ? 'S' : ''}</span>
    );

    // entityType == academia/individual -> text = Project/Workshop
    // entityType == company/rnd -> text = Product / Service
    text = ["Academia", "Individual Researcher"].includes(entityType)
      ? (offeringsType === 'products' ? 'PROJECT' : 'WORKSHOP')
      : (offeringsType === 'products' ? 'PRODUCT' : 'SERVICE');

    return <span>{count} {text}{count > 1 ? 'S' : ''}</span>;
  }


  render() {
    const { entity, skipBootstrapGridClasses } = this.props;
    const entityType = this.props.location.pathname.split('/').filter(e => e)[0]
    let classes = ["search__listing-wrapper"];
    if (!skipBootstrapGridClasses) classes.push("col-xs-12 col-sm-6 col-md-4");

    return (
      <div className={classes.join(" ")} >
        <a
          href={this.handleEntityCardClick()}
          //target="_blank"
          rel="noopener noreferrer"
          className="search__listing"
        >
          <div >

            <div className="search__listing-head" >
              {entity.name}

            </div>
            {entity.technology_type && (
              <div className="search__listing-tech-areas">
                {this.prepareTechnologyTypeString(entity.technology_type)}
              </div>
            )}
          </div>

          <div className="search__listing-subhead">
            <div className="search__listing-subhead-left">
              {entity.size && (
                <div>
                  <img
                    className="search__listing-subhead-img"
                    src={peopleImg}
                    alt="Size"
                  />{" "}{entity.size} PEOPLE
                </div>
              )}
              {entity.address && (
                <div>
                  <img
                    className="search__listing-subhead-img"
                    src={locationImg}
                    alt="location"
                  />{" "}
                  {entity.address.city}
                </div>
              )}
              {entity.type && (
                <div>
                  <img
                    className="search__listing-subhead-img"
                    src={businessTypeImg}
                    alt="Business type"
                  />{" "}
                  {entity.type}
                </div>
              )}
            </div>
            <div className="search__listing-subhead-right">
              {entity.products && entity.products.length > 0 && (
                <div>
                  <img
                    className="search__listing-subhead-img"
                    src={productIcon}
                    alt="Products"
                  />{' '}{this.prepareString(entity.products, entity.type, 'products')}
                </div>
              )}

              {entity.services && entity.services.length > 0 && (
                <div>
                  <img
                    className="search__listing-subhead-img"
                    src={serviceIcon}
                    alt="Services"
                  />{' '}{this.prepareString(entity.services, entity.type, 'services')}
                </div>
              )}

              {entity.solutions && entity.solutions.length > 0 && (
                <div>
                  <img
                    className="search__listing-subhead-img"
                    src={productIcon}
                    alt="Solutions"
                  />{' '}{this.prepareString(entity.solutions, entity.type, 'solutions')}
                </div>
              )}
            </div>
          </div>
        </a>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setFormType: formType => dispatch(setFormType({ formType: formType }))
});

EntityCard = connect(null, mapDispatchToProps)(EntityCard);
export default withRouter(EntityCard);
