import React from "react";
import PropTypes from "prop-types";

const FormInputField = props => {
    let cls = `feature_form__input-field-wrapper ${
        props.widthClass ? props.widthClass : ""
    }`;
    return (
        <div className={cls}>
            <label className="feature_form__input-label">{props.label}</label>
            {props.img && (
                <div className="feature_form__input-icon">
                    {/* <i className="fa fa-building-o" /> */}
                    <img src={props.img} alt="input" />
                </div>
            )}
            <input
                {...props.input}
                style={{
                    paddingLeft: props.img ? "40px" : "",
                }}
                className={`feature_form__input-field ${props.inputClass}`}
                type={props.type}
                name={props.name}
                placeholder={props.placeholder}
                tabIndex={!props.tabable ? "-1" : ""}
            />
            <div className="feature_form__field-error">
                {props.meta && props.meta.touched ? props.meta.error : ""}
            </div>
        </div>
    );
};

FormInputField.propTypes = {
    input: PropTypes.object,
    type: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
};

export default FormInputField;
