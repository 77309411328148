// Form validations
const validate = values => {
    const errors = {};

    // Errors
    const reqError = "This field is required";
    const urlError = "Enter a valid url";
    const addressLineError =
        "Cannot contain any special characters other than ' , - , / & .";
    const addressPincodeError = "Enter a valid pincode";
    const phoneError = "Enter a valid 10 digit number";
    const emailError = "Enter a valid email";
    const facebookError = "Enter a valid link";
    const linkedinError = "Enter a valid link";
    const twitterError = "Enter a valid link";
    const min120CharsError = "Minimum 120 characters are required";

    // Regex
    /* eslint-disable no-useless-escape */
    const urlRegex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;;
    const addressLineRegex = /^[a-zA-Z0-9\s,.'-/&]*$/;
    const addressPincodeRegex = /^\d{6}$/;
    const yearRegex = /^\d{4}$/;
    const phoneRegex = /^[456789]\d{9}$/;
    const emailRegex = /^([a-z A-Z 0-9 _\.\-])+\@(([a-z A-Z 0-9\-])+\.)+([a-z A-z 0-9]{2,})$/;
    const facebookRegex = /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9/_.]{2,256}/;
    const linkedinRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9/_.]{2,10}linkedin[a-zA-Z0-9/_.]{2,10}\/[a-zA-Z0-9-/_.]{2,256}/;
    const twitterRegex = /^(https?:\/\/)?(www\.)?twitter.com\/[a-zA-Z0-9/_.]{2,256}/;
    const nameRegex = /^[ A-Za-z.]*$/;
    const numericRegex = /^\d+$/;
    const companyRegex = /^[A-Z]([a-zA-Z0-9]|[-.])*$/
    /* eslint-enable no-useless-escape */

    //  --> Form part 2 validation
    if (!values.name || !values.name.trim()) errors.name = reqError;
    if (!values.url || !values.url.trim()) errors.url = reqError;
    if (!values.type) errors.type = reqError;
    if (!values.size) errors.size = reqError;
    if (!values.sectors_served || !values.sectors_served.length) {
        errors.sectors_served = reqError;
    }

    if (!values.company_category) errors.company_category = reqError;
    if (!values.application_area) errors.application_area = reqError;

    if (!values.geographies_served || !values.geographies_served.length) {
        errors.geographies_served = reqError;
    }
    if (!values.about || !values.about.trim()) errors.about = reqError;

    if (values.url && !values.url.match(urlRegex)) errors.url = urlError;
     
    
    if (values.about && !(values.about.trim().length >= 120))
        errors.about = min120CharsError;

    //  --> Only for academia and individual researchers
    //  technology_type
    if (!values.technology_type || !values.technology_type.length) {
        errors.technology_type = {
            _error: "At least one technology must be entered",
        };
    } else {
        const technologyTypeArrayErrors = [];

        values.technology_type.forEach((technology, technologyIndex) => {
            const technologyErrors = {};

            if (!technology || !technology.name) {
                technologyErrors.name = reqError;
                technologyTypeArrayErrors[technologyIndex] = technologyErrors;
            }

            if (
                !technology.tech_capability ||
                !technology.tech_capability.length
            ) {
                technologyErrors.tech_capability = reqError;
                technologyTypeArrayErrors[technologyIndex] = technologyErrors;
            }
        });
        if (technologyTypeArrayErrors.length) {
            errors.technology_type = technologyTypeArrayErrors;
        }
    }

    //  --> Form part 3 validation
    // address
    if (values.address) {
        const addressErrors = {};
        const line1 = values.address.line1;
        const pin_code = values.address.pin_code;
        const city = values.address.city;

        if (line1.trim() === "") addressErrors.line1 = reqError;
        if (pin_code.trim() === "") addressErrors.pin_code = reqError;
        if (city.trim() === "") addressErrors.city = reqError;

        //  line1
        if (line1 && !line1.trim().match(addressLineRegex)) {
            addressErrors.line1 = addressLineError;
        }
        //  pin code
        if (pin_code && !pin_code.trim().match(addressPincodeRegex)) {
            addressErrors.pin_code = addressPincodeError;
        }

        errors.address = addressErrors;
    }

    // contact_person
    if (!values.contact_person) {
        errors.contact_person = {
            email: reqError,
        };
    }
    if (values.contact_person) {
        const contactErrors = {};
        const contactArrayErrors = [];
        const persons = values.contact_person.persons;

        const facebook = values.contact_person.facebook;
        const twitter = values.contact_person.twitter;
        const linkedin = values.contact_person.linkedin;

        // number
        if (persons && persons.length) {
            persons.forEach((person, personIndex) => {
                const personErrors = {};
                const { name, number, email } = person;

                if (!name || !name.trim()) personErrors.name = reqError;
                else if (!name.match(nameRegex)) personErrors.name = 'Please use only alphabets and .';

                if (!number || !number.trim()) personErrors.number = reqError;
                else if (!number.match(phoneRegex)) personErrors.number = phoneError;

                if (!email || !email.trim()) personErrors.email = reqError;
                else if (!email.match(emailRegex)) personErrors.email = emailError

                contactArrayErrors[personIndex] = personErrors;
            });
        }

        // facebook, linkedin and twitter
        if (facebook && !facebook.trim().match(facebookRegex))
            contactErrors.facebook = facebookError;
        if (linkedin && !linkedin.trim().match(linkedinRegex))
            contactErrors.linkedin = linkedinError;
        if (twitter && twitter.trim().match(twitterRegex))
            contactErrors.twitter = twitterError;

        errors.contact_person = contactErrors;

        if (contactArrayErrors.length) {
            errors.contact_person.persons = contactArrayErrors;
        }
    }

    const { technology_heads: tHeads, business_heads: bHeads } = values;
    if (tHeads && tHeads.length) {
        const tHeadsErrorsArray = [];

        tHeads.forEach((head, index) => {
            const { name, email, number, linkedin } = head;
            // If the indes is 0 (first tHead) and none of the inputs are filled
            // throw no error (because we display one form by default)
            if (index === 0 && !name && !email && !number && !linkedin) {
                return;
            }

            const tHeadErrors = {};
            // If index is not 0, or any of the field is filled, others become mandatory
            if (!name || !name.trim()) tHeadErrors.name = reqError;
            if (!email || !email.trim()) tHeadErrors.email = reqError;

            if (email && !email.match(emailRegex)) tHeadErrors.email = emailError;
            if (number && !number.match(phoneRegex)) tHeadErrors.number = phoneError;

            // If there's 1 or more error in this tHead, attach it to the root array of errors
            if (Object.keys(tHeadErrors).length) {
                tHeadsErrorsArray[index] = tHeadErrors;
            }
        });
        
        // If one or more technology head has error in one or more fields, pass this whole array
        if (tHeadsErrorsArray.length) {
            errors.technology_heads = tHeadsErrorsArray;
        }
    }

    if (bHeads && bHeads.length) {
        const bHeadsErrorsArray = [];

        bHeads.forEach((head, index) => {
            const { name, email, number, linkedin } = head;
            // If the indes is 0 (first tHead) and none of the inputs are filled
            // throw no error (because we display one form by default)
            if (index === 0 && !name && !email && !number && !linkedin) {
                return;
            }

            const bHeadErrors = {};
            // If index is not 0, or any of the field is filled, others become mandatory
            if (!name || !name.trim()) bHeadErrors.name = reqError;
            if (!email || !email.trim()) bHeadErrors.email = reqError;

            if (email && !email.match(emailRegex)) bHeadErrors.email = emailError;
            if (number && !number.match(phoneRegex)) bHeadErrors.number = phoneError;

            // If there's 1 or more error in this bHead, attach it to the root array of errors
            if (Object.keys(bHeadErrors).length) {
                bHeadsErrorsArray[index] = bHeadErrors;
            }
        });
        
        // If one or more technology head has error in one or more fields, pass this whole array
        if (bHeadsErrorsArray.length) {
            errors.business_heads = bHeadsErrorsArray;
        }
    }


    //  --> Form Part 5 validation
    //  products
    if (values.products && values.products.length) {
        const productsArrayErrors = [];

        values.products.forEach((product, productIndex) => {
            const productErrors = {};
            // let shouldValidate = true;



            if (product.url && !product.url.trim().match(urlRegex)) {
                productErrors.url = urlError;
                productsArrayErrors[productIndex] = productErrors;
            }

            if (product.patent_url && !product.patent_url.match(urlRegex)) {
                productErrors.patent_url = urlError;
                productsArrayErrors[productIndex] = productErrors;
            }

            // technology_type
            if (product.name && !product.technology_type) {
                productErrors.technology_type = {
                    _error: "At least one technology must be entered",
                };
            }

            //  If name has been entered or technology has been entered
            else if (
                product.name ||
                product.url ||
                product.about_product ||
                product.technology_type[0].name
            ) {
                const technologyTypeArrayErrors = [];

                product.technology_type.forEach(
                    (technology, technologyIndex) => {
                        const technologyErrors = {};

                        if (!product.name) {
                            productErrors.name = reqError;
                            productsArrayErrors[productIndex] = productErrors;
                        }

                        if (!product.about_product) {
                            productErrors.about_product = reqError;
                            productsArrayErrors[productIndex] = productErrors;
                        } else if (product.about_product.trim().length < 120) {
                            productErrors.about_product = min120CharsError;
                            productsArrayErrors[productIndex] = productErrors;
                        }

                        if (!technology || !technology.name) {
                            technologyErrors.name = reqError;
                            technologyTypeArrayErrors[
                                technologyIndex
                            ] = technologyErrors;
                        }

                        if (
                            !technology.tech_capability ||
                            !technology.tech_capability.length
                        ) {
                            technologyErrors.tech_capability = reqError;
                            technologyTypeArrayErrors[
                                technologyIndex
                            ] = technologyErrors;
                        }
                    }
                );

                if (technologyTypeArrayErrors.length) {
                    productErrors.technology_type = technologyTypeArrayErrors;
                    productsArrayErrors[productIndex] = productErrors;
                }
            }
        });

        if (productsArrayErrors.length) {
            errors.products = productsArrayErrors;
        }
    }

    // services
    if (values.services && values.services.length) {
        const servicesArrayErrors = [];

        values.services.forEach((service, serviceIndex) => {
            const serviceErrors = {};

            if (service.url && !service.url.match(urlRegex)) {
                serviceErrors.url = urlError;
                servicesArrayErrors[serviceIndex] = serviceErrors;
            }

            if (service.patent_url && !service.patent_url.match(urlRegex)) {
                serviceErrors.patent_url = urlError;
                servicesArrayErrors[serviceIndex] = serviceErrors;
            }

            // technology_type
            if (service.name && !service.technology_type) {
                serviceErrors.technology_type = {
                    _error: "At least one technology must be entered",
                };
            } else if (
                service.name ||
                service.url || 
                service.about_service ||
                service.technology_type[0].name
            ) {
                const technologyTypeArrayErrors = [];

                service.technology_type.forEach(
                    (technology, technologyIndex) => {
                        const technologyErrors = {};

                        if (!service.name) {
                            serviceErrors.name = reqError;
                            servicesArrayErrors[serviceIndex] = serviceErrors;
                        }

                        if (!service.about_product) {
                            //  Field is named about_product for all products, solutions and services
                            serviceErrors.about_product = reqError;
                            servicesArrayErrors[serviceIndex] = serviceErrors;
                        } else if (service.about_product.trim().length < 120) {
                            serviceErrors.about_product = min120CharsError;
                            servicesArrayErrors[serviceIndex] = serviceErrors;
                        }

                        if (!technology || !technology.name) {
                            technologyErrors.name = reqError;
                            technologyTypeArrayErrors[
                                technologyIndex
                            ] = technologyErrors;
                        }

                        if (
                            !technology.tech_capability ||
                            !technology.tech_capability.length
                        ) {
                            technologyErrors.tech_capability = reqError;
                            servicesArrayErrors[
                                technologyIndex
                            ] = technologyErrors;
                        }
                    }
                );
                if (technologyTypeArrayErrors.length) {
                    serviceErrors.technology_type = technologyTypeArrayErrors;
                    servicesArrayErrors[serviceIndex] = serviceErrors;
                }
            }
        });

        if (servicesArrayErrors.length) {
            errors.services = servicesArrayErrors;
        }
    }

    //  solutions
    if (values.solutions && values.solutions.length) {
        const solutionsArrayErrors = [];

        values.solutions.forEach((solution, solutionIndex) => {
            const solutionErrors = {};

            if (solution.url && !solution.url.match(urlRegex)) {
                solutionErrors.url = urlError;
                solutionsArrayErrors[solutionIndex] = solutionErrors;
            }

            if (solution.patent_url && !solution.patent_url.match(urlRegex)) {
                solutionErrors.patent_url = urlError;
                solutionsArrayErrors[solutionIndex] = solutionErrors;
            }

            // technology_type
            if (solution.name && !solution.technology_type) {
                solutionErrors.technology_type = {
                    _error: "At least one technology must be entered",
                };
            } else if (
                solution.name ||
                solution.about_solution ||
                solution.technology_type[0].name
            ) {
                const technologyTypeArrayErrors = [];

                solution.technology_type.forEach(
                    (technology, technologyIndex) => {
                        const technologyErrors = {};

                        if (!solution.name) {
                            solutionErrors.name = reqError;
                            solutionsArrayErrors[
                                solutionIndex
                            ] = solutionErrors;
                        }

                        if (!solution.about_product) {
                            //  Field is named about_product for all products, solutions and services
                            solutionErrors.about_product = reqError;
                            solutionsArrayErrors[
                                solutionIndex
                            ] = solutionErrors;
                        } else if (solution.about_product.trim().length < 120) {
                            solutionErrors.about_product = min120CharsError;
                            solutionsArrayErrors[
                                solutionIndex
                            ] = solutionErrors;
                        }

                        if (!technology || !technology.name) {
                            technologyErrors.name = reqError;
                            technologyTypeArrayErrors[
                                technologyIndex
                            ] = technologyErrors;
                        }

                        if (
                            !technology.tech_capability ||
                            !technology.tech_capability.length
                        ) {
                            technologyErrors.tech_capability = reqError;
                            technologyTypeArrayErrors[
                                technologyIndex
                            ] = technologyErrors;
                        }
                    }
                );

                if (technologyTypeArrayErrors.length) {
                    solutionErrors.technology_type = technologyTypeArrayErrors;
                    solutionsArrayErrors[solutionIndex] = solutionErrors;
                }
            }
        });

        if (solutionsArrayErrors.length) {
            errors.solutions = solutionsArrayErrors;
        }
    }

    // research_papers
    const { research_papers } = values;

    if (research_papers && research_papers.length) {
        const researchPapersArrayErrors = [];

        research_papers.forEach((paper, paperIndex) => {
            const paperErrors = {};
            const { title, url, citations, year, authors } = paper;

            // only for the first paper
            if (paperIndex === 0 && !title && !url && !citations && !year) {
                let canContinue = true;

                if (authors && authors.length) {
                    for (let i = 0; i < authors.length; i++) {
                        if (
                            (authors[i].name && authors[i].name.trim()) || 
                            (authors[i].url && authors[i].url.trim())
                         ) {
                            canContinue = false;
                            break;
                        }
                    }
                }
                if (canContinue) return;
            }

            if (!title) paperErrors.title = reqError;
            if (url && !url.match(urlRegex)) paperErrors.url = urlError;
            if (citations && !citations.match(numericRegex)) paperErrors.citations = 'This must be a number';
            if (year && (!year.match(yearRegex) || !(year > 1800 && year <= (new Date()).getFullYear()))) {
                paperErrors.year = 'Please enter a valid year';
            }

            researchPapersArrayErrors[paperIndex] = paperErrors;

            const authorArrayErrors = [];
            if (paper.authors && paper.authors.length) {
                paper.authors.forEach((author, index) => {
                    const authorErrors = {};
                    let hasError = false;

                    if (!author.name) {
                        authorErrors.name = reqError;
                        hasError = true;
                    }
                    if (!author.url) {
                        authorErrors.url = reqError;
                        hasError = true;
                    } else if (author.url && !author.url.match(urlRegex)) {
                        authorErrors.url = urlError;
                        hasError = true;
                    }

                    if (hasError) authorArrayErrors[index] = authorErrors;
                });
            }

            if (authorArrayErrors.length) paperErrors.authors = authorArrayErrors;
        });
        if (researchPapersArrayErrors.length) {
            errors.research_papers = researchPapersArrayErrors;
        }
    }

    // blogs
    if (values.blogs && values.blogs.length) {
        const blogsArrayErrors = [];

        values.blogs.forEach((blog, blogIndex) => {
            const blogErrors = {};

            if (blog.url && !blog.title) {
                blogErrors.title = reqError;
                blogsArrayErrors[blogIndex] = blogErrors;
            }

            if (blog.url && !blog.url.match(urlRegex)) {
                blogErrors.url = urlError;
                blogsArrayErrors[blogIndex] = blogErrors;
            }
        });
        if (blogsArrayErrors.length) {
            errors.blogs = blogsArrayErrors;
        }
    }

    // patents
    if (values.patents && values.patents.length) {
        const patentsArrayErrors = [];

        values.patents.forEach((patent, patentIndex) => {
            const patentErrors = {};

            if (patent.url && !patent.title) {
                patentErrors.title = reqError;
                patentsArrayErrors[patentIndex] = patentErrors;
            }

            if (patent.url && !patent.url.match(urlRegex)) {
                patentErrors.url = urlError;
                patentsArrayErrors[patentIndex] = patentErrors;
            }
        });
        if (patentsArrayErrors.length) {
            errors.patents = patentsArrayErrors;
        }
    }

    // global competitors
    const { key_global_competitors: kgc } = values;
    if (kgc && kgc.length) {
        const globalCompetitorsArrayErrors = [];

        kgc.forEach((competitor, competitorIndex) => {
            const competitorErrors = {};

            if (competitor.url && !competitor.name) {
                competitorErrors.name = reqError;
                globalCompetitorsArrayErrors[
                    competitorIndex
                ] = competitorErrors;
            }

            if (competitor.url && !competitor.url.match(urlRegex)) {
                competitorErrors.url = urlError;
                globalCompetitorsArrayErrors[
                    competitorIndex
                ] = competitorErrors;
            }
        });
        if (globalCompetitorsArrayErrors.length) {
            errors.key_global_competitors = globalCompetitorsArrayErrors;
        }
    }

    // local competitors
    if (values.key_local_competitors && values.key_local_competitors.length) {
        const localCompetitorsArrayErrors = [];

        values.key_local_competitors.forEach((competitor, competitorIndex) => {
            const competitorErrors = {};

            if (competitor.url && !competitor.name) {
                competitorErrors.name = reqError;
                localCompetitorsArrayErrors[competitorIndex] = competitorErrors;
            }

            if (competitor.url && !competitor.url.match(urlRegex)) {
                competitorErrors.url = urlError;
                localCompetitorsArrayErrors[competitorIndex] = competitorErrors;
            }
        });
        if (localCompetitorsArrayErrors.length) {
            errors.key_local_competitors = localCompetitorsArrayErrors;
        }
    }

    const { recognitions } = values;
    if (recognitions && recognitions.length) {
        const allRecognitionsErrors = [];

        recognitions.forEach((recognition, index) => {
            const recognitionError = {};

            // If none of the two fields are filled, just return
            if (
                (!recognition.content || !recognition.content.trim()) && 
                (!recognition.year || !recognition.year.trim())
            ) {
                return;
            }

            if (recognition.content && !recognition.year) {
                recognitionError.year = reqError;
            } 
            if (recognition.year && !recognition.content) {
                recognitionError.content = reqError;
            }

            if (recognition.year && 
                (!recognition.year.match(yearRegex) || 
                !(recognition.year > 1800 && recognition.year <= (new Date()).getFullYear()))
            ) {
                recognitionError.year = 'Please enter a valid year';
            }

            if (Object.keys(recognitionError).length) {
                allRecognitionsErrors[index] = recognitionError;
            }
        });

        if (allRecognitionsErrors.length) errors.recognitions = allRecognitionsErrors;
    };

    // recaptcha
    if (!values.recaptcha) {
        errors.recaptcha = reqError;
    }

    return errors;
};

export default validate;
