import React, { Component, Fragment } from "react";
import { reduxForm, FieldArray } from "redux-form";

import ProductServiceItem from "./ProductServiceItem";

class FormProducts extends Component {
    render() {
        return (
            <FieldArray
                name={this.props.section}
                component={renderProductsSection}
                singular={this.props.singular}
                technologyAreas={this.props.technologyAreas}
                formType={this.props.formType}
                dispatch={this.props.dispatch}
            />
        );
    }
}

const renderProductsSection = ({
    fields,
    singular,
    technologyAreas,
    formType,
    dispatch
}) => {
    const productServiceItems = fields.map((product, index) => {
        return <ProductServiceItem
            key={index}
            product={product}
            fields={fields}
            index={index}
            singular={singular}
            technologyAreas={technologyAreas}
            formType={formType}
            dispatch={dispatch}
        />;
    })

    return (
        <Fragment>
            {productServiceItems}

            <button
                className="feature_form__field-array-add"
                type="button"
                onClick={() =>
                    fields.push({
                        competitors: [{ name: "", url: "" }],
                        technology_type: [{}],
                    })
                }
            >
                + Add Another {singular}
            </button>
        </Fragment>
    )
};

FormProducts = reduxForm({
    form: "feature_yourself_old",
    destroyOnUnmount: false,
})(FormProducts);

export default FormProducts;
