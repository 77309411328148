import React from "react";

import { httpfyUrl } from "../../utils/helpers";

import emailImg from "../../img/email.svg";
import phoneImg from "../../img/phone.svg";
import websiteImg from "../../img/website-dark.svg";
import EntityViewMap from "./EntityViewMap";

import linkedinIcon from '../../img/linkedin-blue.svg';
import twitterIcon from '../../img/twitter-blue.svg';
import facebookIcon from '../../img/facebook-blue.svg';


const Contact = ({ address, contactPerson, url }) => {
  let socialLinks = null;
  const linkedin = contactPerson.linkedin;
  const twitter = contactPerson.twitter;
  const facebook = contactPerson.facebook;

  
  //  If any of the link is avallable, display this section
  if (facebook || linkedin || twitter) {
    socialLinks = (
      <div className='about__social-links-wrapper'>
        <p className='about__social-links-heading'>Connect With Us</p>

        {facebook && <a href={facebook} target="_blank" rel="noopener noreferrer">
          <img src={facebookIcon} alt='Facebook' className='about__social-link-image' />
        </a>}

        {twitter && <a href={twitter} target="_blank" rel="noopener noreferrer">
          <img src={twitterIcon} alt='Twiter' className='about__social-link-image' />
        </a>}

        {linkedin && <a href={linkedin} target="_blank" rel="noopener noreferrer">
          <img src={linkedinIcon} alt='LinkedIn' className='about__social-link-image' />
        </a>}
      </div>
    );
  }


  return (
      <div className="entityview__about-section">
        <div className="entityview__contact-left" style={{width: "100%", padding: "20px 0px", display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", rowGap: "8px", columnGap: "35px"}}>
          <div className="entityview__contact-details-wrapper">
            {contactPerson &&
              contactPerson.persons &&
              contactPerson.persons.length &&
              contactPerson.persons.map((person, index) => (
                <div key={index}>
                  {person.email && person.email.length > 5 && (
                    <div className="entityview__contact-details" style={{wordBreak: "break-all"}}>
                      <img
                        className="entityview__contact-img"
                        src={emailImg}
                        alt="email"
                      />
                      {person.email}
                    </div>
                  )}
                  {person.number && person.number.length > 5 && (
                    <div className="entityview__contact-details">
                      <img
                        className="entityview__contact-img"
                        src={phoneImg}
                        alt="phone"
                      />
                      {person.number}
                    </div>
                  )}
                </div>
              ))}
            {url && (
              <div className="entityview__contact-details entityview__contact-url">
                <img
                  className="entityview__contact-img"
                  src={websiteImg}
                  alt="website"
                />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={httpfyUrl(url)}
                >
                  <p className="entityview__websiteurl">{httpfyUrl(url)}</p>
                </a>
              </div>
            )}
          </div>
          {socialLinks}
          {address && (
            <div className="entityview__about-address--wrapper">
              <p className="entityview__about-head">Address</p>
              {address.line1}, {address.pin_code}, {address.city}
            </div>
          )}
        </div>
      </div>
      
  );


  // return (
  //   <div className="entityview__desc-wrapper entityview__desc__contact-container">
  //     <div className="entityview__contact-wrapper">
  //       <div className="entityview__contact-left">
  //         <div className="entityview__contact-details-wrapper">
  //           {contactPerson &&
  //             contactPerson.persons &&
  //             contactPerson.persons.length &&
  //             contactPerson.persons.map((person, index) => (
  //               <div key={index}>
  //                 {person.email && person.email.length > 5 && (
  //                   <div className="entityview__contact-details">
  //                     <img
  //                       className="entityview__contact-img"
  //                       src={emailImg}
  //                       alt="email"
  //                     />
  //                     {person.email}
  //                   </div>
  //                 )}
  //                 {person.number && person.number.length > 5 && (
  //                   <div className="entityview__contact-details">
  //                     <img
  //                       className="entityview__contact-img"
  //                       src={phoneImg}
  //                       alt="phone"
  //                     />
  //                     {person.number}
  //                   </div>
  //                 )}
  //               </div>
  //             ))}
  //           {url && (
  //             <div className="entityview__contact-details entityview__contact-url">
  //               <img
  //                 className="entityview__contact-img"
  //                 src={websiteImg}
  //                 alt="website"
  //               />
  //               <a
  //                 target="_blank"
  //                 rel="noopener noreferrer"
  //                 href={httpfyUrl(url)}
  //               >
  //                 <p className="entityview__websiteurl">{httpfyUrl(url)}</p>
  //               </a>
  //             </div>
  //           )}
  //         </div>
  //         {socialLinks}
  //         {address && (
  //           <div className="entityview__about-address--wrapper">
  //             <p className="entityview__about-head">Address</p>
  //             {address.line1}, {address.pin_code}, {address.city}
  //           </div>
  //         )}
  //       </div>
  //       <div className="entityview__contact-map">
  //         {address &&
  //           address.location &&
  //           address.location.coordinates &&
  //           address.location.coordinates.length > 1 ? (
  //             <EntityViewMap
  //               center={{
  //                 lat: address.location.coordinates[1],
  //                 lng: address.location.coordinates[0],
  //               }}
  //             />
  //           ) : (
  //             ""
  //           )}
  //       </div>
  //     </div>
      
  //   </div>
  // );
};

export default Contact;
