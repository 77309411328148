import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import {phone} from 'phone';

const validate = values => {
    const errors = {};

    // Errors
    const reqError = "This field is required";
    const emailError = "Enter a valid email";
    const passwordError =
        "Should be atleast 8 characters, with atleast one special char and one numeral";
    const addressLineError =
        "Cannot contain any special characters other than ' , - , / & .";
    const addressPincodeError = "Enter a valid pincode";
    const phoneError = "Please enter a valid number";
    const usernamePassword = "Password cannot be same as Username"
    // Regex  /^([a-zA-Z]){3,}[0-20]*$/;
    /* eslint-disable no-useless-escape */
    const firstNameRegex = /^[A-Za-z ]+$/;
    const lastNameRegex =  /^[A-Za-z]+$/;
    const alphaNumRegex = /[a-zA-Z]+[a-zA-Z0-9 .,]{1,12}$/; 
    const emailRegex = /^([a-z A-Z 0-9 _\.\-])+\@(([a-z A-Z 0-9\-])+\.)+([a-z A-z 0-9]{2,})$/;
    const passwordRegex = /^(?=.*\d)(?=.*[#$@!%&._*])[A-Za-z\d#$@!%&._*]{8,}$/;
    const addressLineRegex = /^[a-zA-Z0-9\s,.'-/&]*$/;
    //const addressPincodeRegex = /^\d{6}$/;
    // const phoneRegex = /^(\+)?((\d){0,4})([-\s])?(\d{6,11})$/;
    const phoneRegex = /^[456789]\d{9,9}$/;
    const companyRegex = /^[A-Z]([a-zA-Z0-9]|[- @\.#&!])*$/ 
    const fullnameRegex =/^[a-zA-Z ]*$/
    //const ZipcodeRegex = /^(?=.*[a-zA-Z\d].*)[a-zA-Z\d!@#$%&*]{10,}$/
    /* eslint-enable no-useless-escape */


  
    // first name
    if (!values.first_name) {
        errors.first_name = reqError;
    } else if (!firstNameRegex.test(values.first_name.trim())) {
      errors.first_name = 'Please enter a valid first name';
    }
    else if(values.first_name.length <3){
        errors.first_name = 'Minimum 3 characters required';   
    }
    //full name
    if (!values.full_name) {
        errors.full_name = reqError;
    } 
    else if (!fullnameRegex.test(values.full_name.trim())) {
      errors.full_name = 'Please enter a valid full name';
    }
    // last name
    if (!values.last_name) {
        errors.last_name = reqError;
    } else if (!lastNameRegex.test(values.last_name.trim())) {
      errors.last_name = 'Please enter a valid last name'
    }
    else if(values.last_name.length <3){
        errors.last_name = 'Minimum 3 characters required';   
    }

    //company_name
    if (!values.company_name) {
        errors.company_name = reqError;
    } 
    else if (values.company_name.length <3) {
      errors.company_name =  'Minimum 3 characters required'
    }
    //designation
    if (!values.designation) {
        errors.designation = reqError;
    }  

    //organization
    if (!values.organization) {
        errors.organization = reqError;
    }
    else if(!alphaNumRegex.test(values.organization.trim())){
        errors.organization = "Please enter a valid organization name"
    }
    // email
    if (!values.email) {
        errors.email = reqError;
    } else if (!emailRegex.test(values.email.trim())) {
        errors.email = emailError;
    }

    // user type
    if (!values.user_type) {
        errors.user_type = reqError;
    }
   
    if (!values.country) {
        errors.country = reqError;
    }
//zip code

    // city
    if (!values.city) {
        errors.city = reqError;
    }

    // pincode
    if (!values.pincode) {
        errors.pincode = reqError;
    }
    //console.log(postcodeValidator(values.pincode, 'IN'))
    if (!postcodeValidator(values.pincode, 'IN')) {
        errors.pincode = addressPincodeError;
    }

    // contact number
    //const phoneValidation = phone(values.contact_number, {country: values.country.name}) 
    if(values.contact_number && !values.country){
        errors.contact_number = "Please select country"
     }
     if (!values.contact_number) {
        errors.contact_number = reqError;
    }
    if (values.contact_number && values.country) {
        const phoneValidation = phone(values.contact_number, {country: values.country["name"]}) 
    // console.log(phoneValidation)
    if(!phoneValidation.isValid){
        errors.contact_number = phoneError;}
    }

    const phoneValidation = phone(values.contributor_number, {country:"India"})
    if(!values.contributor_number){
      errors.contributor_number = reqError
    }
    else if(values.contributor_number && !phoneValidation.isValid ){
        errors.contributor_number = phoneError
    }
    // password
    if (!values.password) {
        errors.password = reqError;
    }
    if (!values.confirm_password) {
        errors.confirm_password = reqError;
    }
    if (values.password && !values.password.match(passwordRegex)) {
        errors.password = passwordError;
    }
    if (values.password && values.password.match(values.first_name)) {
        //console.log(values.first_name)
        //console.log(values.password)
        errors.password = usernamePassword;
    }
    if (
        values.password &&
        values.confirm_password &&
        values.password !== values.confirm_password
    ) {
        errors.confirm_password = "Password does not match";
    }

    // recaptcha
    if (!values.recaptcha) {
        errors.recaptcha = reqError;
    }

    // signup confirm
    if (!values.signup_confirm) {
        errors.signup_confirm = "You must agree to the terms and conditions";
    }

    if (values.designation && !alphaNumRegex.test(values.designation.trim())) {
      errors.designation = 'Please enter a valid input';
    }

    if (values.company_name && !alphaNumRegex.test(values.company_name.trim())) {
      errors.company_name = 'Only alphanumeric characters and spaces allowed in company name';
    }

    return errors;
};

export default validate;
