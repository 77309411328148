 
import { UPDATE_NOTIFICATION } from "../actions/_types";

const initialState = {
    count: 0,
};

const NotificationReducer  = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_NOTIFICATION:
            return {
                ...state,
                count: action.count,
            };
        default:
            return state;
    }
};

export default NotificationReducer ;

 