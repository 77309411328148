import React from 'react';

import { HomeOutlined, InfoCircleOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons';

function App(ques) {
  return (
    <div >
      <Card icon={ques.text.icon} text={ques.text.title} trending={ques.text.trending}/>
    </div>
  );
}

const Card = ({ icon, text, trending }) => (
  <div className="card_ai">
    {trending
      && <div className="trending__box">
        <div className='trending__elem'>Trending</div>
      </div>
    }
    <div className='ai_search_card_icon_container'>
      <img className="ai_search_card_icon" src={icon} />
    </div>
    <div className='card__text'>{text}</div>
  </div>
);

export default App;
