import React, { Component } from "react";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import '../css/inline-svgs.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { setUserInfo } from "../actions/auth";
import { setFormType } from "../actions/user-form";
import { updateArea, updateCapability } from "../actions/search";

import api, { getGETConfig, getAuthGETConfig } from "../api";

import "../css/Home.css";

import DiscoverSection from "./DiscoverSection";



import HomeTop from "./HomeTop";
import BriefAbout from "./BriefAbout";
import FeatureYourself from "./FeatureYourself";
import ResourcesSection from "./ResourcesSection";
import ReDesignHomeTech from "./ReDesignHomeTech";
import ReDesignSummaryGlance from "./ReDesignSummaryGlance";
import ReDesignEventWebinar from "./ReDesignEventWebinar";



class HomeNew extends Component {

    state = {
        showAllAreas: false,
        searchQuery: "",
        CarouselImages: [],
        showSuggestions: false,
        searchRedirect: false,
        resultType: "companies",
        suggestedEntities: [],
        suggestedLocations: [],
        suggestedTechAreas: [],
        suggestedProducts: [],
        eventData: [],
        eventState: '',
        signalNewsData: [],
        articleData: [],
        recentlyAddData: [],
        entitySummaryData: {},
        caseStudyData: [],
        contributorData: [],
        trendingData: [],
        contributorEmailId: "",
        liked: false,
        isloading: true,
        token: '',
    };

    componentDidMount() {
        window.scrollTo(0, 0)
        const user = JSON.parse(localStorage.getItem("user"));
        //console.log(user)
        if (user != null) {
            var token = user.token
        }
        this.setState({ token: token })


        // setTimeout(() => {
        //     this.getCarouselImages();
        //   }, 10);
          
        // If user is coming from a verify_email link from their email, execute email verification
        if (this.props.match.path === "/verify_email/:token") {
            this.verifyEmail();
        }


        //window.addEventListener('scroll', this.handleScroll);
        document.addEventListener("click", this.handleClickOutside);
        setTimeout(this.getEntitySummaryData, 1000)
    }


    getEntitySummaryData = () => {
        const url = `${api.entitySummaryApi}?keyword=count_info&page=1&page_size=10`;
        fetch(url, getAuthGETConfig(this.state.token))
            .then(response => {
                if (!response || !response.ok) throw Error(response.status);
                return response.json();
            })
            .then(({ data }) => {
                //console.log(data, 'entity data')
                this.setState({ entitySummaryData: data })
            })
            .catch(err => console.log('Error fetching all entity data', err));
    }



    handleFeatureYourself = () => {
        //console.log("call handle",this.props.userInfo)

        if (!this.props.userInfo) {
            toast.info("Please login to feature yourself!");
            this.props.setFormType('login');
            this.setState({ showUserForm: true });
        } else if (!this.props.userInfo.is_approved) {
            toast.error("Please verify your email id to feature yourself");
        } else {
            this.props.history.push('/feature_yourself');
        }
    };
    render() {

        return (
            <>
                <div style={{ background: '#EBEBEB', display: 'flex', flexDirection: 'column' }}>
                    <HomeTop />

                    <ReDesignHomeTech />

                    {/* <div style={{ backgroundImage: "linear-gradient(274deg,#2f416b,#4c96ff)" }}> */}
                    <ReDesignEventWebinar />
                    {/* </div> */}
                    {/* <ReDesignSummaryGlance data={this.state.entitySummaryData} /> */}

                    {Object.keys(this.state.entitySummaryData).length ?
                        <div className='reDesignSummaryGlance-Container'>
                            <div className='reDesignHomeTech-Heading  reDesignSummaryGlanceHeading reDesignHomeTech-Heading'>
                                {/* <div></div> */}
                                <p>Browse From</p>
                            </div>
                            <div className="reDesignSummaryGlance-Card-Container">
                                {[
                                    { 'url': 'Companies', 'search_name': 'companies', 'short_name': 'company', 'name': 'Companies', 'imgLink': 'https://tcr-documents.s3.ap-south-1.amazonaws.com/svg/companies.svg' },
                                    { 'url': 'Academia', 'search_name': 'academia', 'short_name': 'academia', 'name': 'Academia', 'imgLink': 'https://tcr-documents.s3.ap-south-1.amazonaws.com/svg/academia.svg' },
                                    { 'url': 'R_and_D_Centers', 'search_name': 'r_and_d', 'short_name': 'r_and_d', 'name': 'R&D Centers', 'imgLink': 'https://tcr-documents.s3.ap-south-1.amazonaws.com/svg/r-d-center.svg' },
                                    { 'url': 'Researchers', 'search_name': 'individual', 'short_name': 'individuals', 'name': 'Researchers', 'imgLink': 'https://tcr-documents.s3.ap-south-1.amazonaws.com/svg/researchers.svg' },
                                    { 'url': 'Unique_products', 'search_name': 'products', 'short_name': 'products', 'name': 'Products', 'imgLink': 'https://tcr-documents.s3.ap-south-1.amazonaws.com/svg/unique-products.svg' }
                                ]
                                    .map((item, i) => {
                                        return <ReDesignSummaryGlance
                                            key={i}
                                            name={item['name']}
                                            logo={item['imgLink']}
                                            search={item['search_name']}
                                            url={item["url"]}
                                            data={this.state.entitySummaryData[item.short_name]} />
                                    })}
                            </div>
                        </div>
                        : ""}


                    <ResourcesSection />
                    {/* <BriefAbout /> */}
                    <DiscoverSection />
                    <FeatureYourself handleFeatureYourself={this.handleFeatureYourself} />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    userInfo: state.auth.userInfo
});

const mapDispatchToProps = dispatch => ({
    setUserInfo: userInfo => dispatch(setUserInfo({ userInfo: userInfo })),
    setFormType: formType => dispatch(setFormType({ formType: formType })),
    updateArea: area => dispatch(updateArea({ area: area })),
    updateCapability: capability =>
        dispatch(updateCapability({ capability: capability })),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeNew);
