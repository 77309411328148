import React from "react";

const Disclaimer = props => {
  document.title =`Disclaimer - Technology Capability Repository || DSCI`;
  return(
  <>
    {/* Banner Section */}
    <div className="infoPage__banner infoPage__banner--about">
      <h1 className="infoPage__bannerTitle">Disclaimer</h1>
    </div>
    {/* End of banner section */}

    {/* Main section */}
    <main className="infoPage__body">
      <div className="infoPage__bodyBox">
        <p className="infoPage__bodyText">
          This TechSagar Portal is designed, developed, and hosted by the Data Security Council of
          India (hereafter referred to as DSCI). The portal aims to provide information about the
          emerging tech capabilities of various entities such as companies, start-ups, R&amp;D labs,
          academic institutions, and researchers.<br /><br />

          The portal contents, including those related to finances, have been compiled based on
          information freely available in the public domain. The best possible efforts have been made
          in collecting, compiling, organizing, and representing the information about capabilities,
          products, solutions, services, and finances of various entities. The accuracy of the
          information depends on the accuracy of the source. Though all efforts have been made to
          ensure the accuracy and currency of the content, the same should not be construed as a
          statement of law or used for any legal purposes. The information provided on the portal does
          not constitute a legal or professional opinion. DSCI accepts no responsibility in relation to the
          accuracy, reliability, completeness, exhaustiveness, usefulness or otherwise, of the
          content/information provided on the portal. Users are advised to verify/check any information
          with the corresponding entities listed on the portal and/or relevant stakeholders, institutions,
          government department(s) and/or other source(s), and/or obtain appropriate professional
          advice before acting on the information provided in the portal. The portal assumes no liability
          or responsibility for any errors or omissions in the content.<br /><br />

          In no event shall DSCI be liable for any expense, loss or damage including, without
          limitation, indirect or consequential expense, loss or damage, whatsoever arising from use,
          or loss of use of data, arising out of or in connection with the use of this portal.<br /><br />
          
          Links to other websites owned by other/third parties that have been included on this portal
          are provided for public convenience only. DSCI does not directly / indirectly promote these
          third parties and is not responsible for the contents or reliability of linked websites and
          neither endorse the views expressed within them. We cannot always guarantee the
          availability of such linked pages.<br /><br />
          
          While it cannot guarantee that there are no errors in the portal, DSCI does endeavour, where
          appropriate, to correct those that are drawn to its attention.
        </p>
      </div>
    </main>
  </>)
};

export default Disclaimer;
