const validate = values => {
  const errors = {};
  const nameRegex = /^[A-Za-z]+$/;
  const firstNameRegex = /^[A-Za-z .]+$/;
  const lastNameRegex = /^[A-Za-z .]+$/;
  const designationRegex = /^[A-Za-z0-9 . _]+$/;
  // const designationRegex = /^([ A-Za-z0-9_.]){2,60}$/
  const companyRegex = /([A-Za-z]*[ A-Za-z0-9_.!$#%&]){2,120}$/
  // //  First Name /^(?=[A-Za-z0-9]*[A-Za-z]){2,120}[ ]*$/
  if (!values.firstName || !values.firstName.trim()) {
    errors.firstName = 'First name is required';
  } 
  else if(values.firstName.length>120) {
    errors.firstName = 'First name length should not exceed 120 characters';
  }
  else if (!firstNameRegex.test(values.firstName.trim())) {
    errors.firstName = 'Name can contain only alphabets';
  }
 
  // Last Name  
  if (!values.lastName || !values.lastName.trim()) {
    errors.lastName = 'Last name is required';
  } 
  else if(values.lastName.length>120) {
    errors.lastName = 'Last name length should not exceed 120 characters';
  }
  else if (!lastNameRegex.test(values.lastName.trim())) {
    errors.lastName = 'Name can contain only alphabets';
  }
  if (!values.designation || !values.designation.trim()) {
    errors.designation = 'Designation is required';
  } 
  else if(values.designation.length>255) {
    errors.designation = 'Designation length should not exceed 255 characters';
  }
  else if (!designationRegex.test(values.designation.trim())) {
    errors.designation = 'Please enter a valid input';
  }
 var pattern = /[a-zA-Z]/
  if (!values.company || !values.company.trim()) {
    errors.company = 'Company name is required';
  } 
  else if(values.company.length>255) {
    errors.company = 'Company name length should not exceed 255 characters';
  }
  // else if (!companyRegex.test(values.company.trim())) {
  //   errors.company = 'Company name can contain alphabets , some special character[.!$#%&]';
  // }
  // else if(values.company && !pattern.test(values.company.trim())){
  //   errors.company = 'Company name must contain alphabets , some special character[.!$#%&]';
  // }

  return errors;
}

export default validate;