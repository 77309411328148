import React from 'react';
import arrow from '../img/right-arrow.png';
import { withRouter, Redirect } from 'react-router-dom';
import "../css/Resources.css";
import api from '../api'

import { Link } from "react-router-dom";
import { connect } from 'react-redux';

class Resource extends React.Component {



  render() {
    document.title = "Resouces - Technology Capability Repository || DSCI";
    document.getElementsByTagName("META")[3].content = "This Section contains Resources created  by DSCI and end user";
    document.getElementsByTagName("LINK")[0].href = "https://www.techsagar.in/resources"

    return (

      <div>
        <div className="entityview__top">
          <div className="entityview__top-desc-wrapper">
            {/* <nav aria-label="Breadcrumb" class="breadcrumb">
              <ul>
                <li><a href="/">Home</a></li>
                <li><span aria-current="page">Resources</span></li>
              </ul>
    </nav>*/}
            <h1 className='resouces__heading'>Resources</h1>
          </div>
        </div>
        <div className='resources'>

          <Link
            to="/resources/reports"
          >
            <div className="resources__desc-card">
              <div className='resources__card'>
                <p className='resources__heading'>
                  Reports
                </p>
                <img src={arrow} className="arrow_img" alt="arrow" />
              </div>
            </div>
          </Link>

          <Link
            to="/resources/whitepapers"
          >
            <div className="resources__desc-card">
              <div className='resources__card'>
                <p className='resources__heading'>
                  Whitepapers
                </p>
                <img src={arrow} className="arrow_img" alt="arrow" />
              </div>
            </div>
          </Link>
          <Link
            to={{
              pathname: "/resources/featured_article",
              state: 'featuredArticle'
            }}
          >
            <div className="resources__desc-card">
              <div className='resources__card'>
                <p className='resources__heading'>
                  Featured Article
                </p>
                <img src={arrow} className="arrow_img" alt="arrow" />
              </div>
            </div>
          </Link>
          {/*<a
            href="https://blogs.techsagar.in/"
          >
            <div className="resources__desc-card">
              <div className='resources__card'>
                <p className='resources__heading'>
                  Blogs
                </p>
                <img src={arrow} className="arrow_img" alt="arrow" />
              </div>
            </div>
          </a>*/}

          <Link
            to={{
              pathname: "/resources/signal_news",
              state: 'signalNews'
            }}

          >
            <div className="resources__desc-card">
              <div className='resources__card'>
                <p className='resources__heading'>
                  Signals and News
                </p>
                <img src={arrow} className="arrow_img" alt="arrow" />
              </div>
            </div>
          </Link>

          <Link
            to={{
              pathname: "/resources/summary_documents",
              state: 'summary'
            }}
          >
            <div className="resources__desc-card">
              <div className='resources__card'>
                <p className='resources__heading'>
                  Summary Documents
                </p>
                <img src={arrow} className="arrow_img" alt="arrow" />
              </div>
            </div>
          </Link>

          <Link
            to={{
              pathname: "/resources/events_and_webinar",
              state: 'event_webinar'
            }}
          >
            <div className="resources__desc-card">
              <div className='resources__card'>
                <p className='resources__heading'>
                  Event and Webinar
                </p>
                <img src={arrow} className="arrow_img" alt="arrow" />
              </div>
            </div>
          </Link>

          <Link
            to={{
              pathname: "/resources/policy_updates",
              state: 'policyUpdates'
            }}
          >
            <div className="resources__desc-card">
              <div className='resources__card'>
                <p className='resources__heading'>
                  Policy Updates
                </p>
                <img src={arrow} className="arrow_img" alt="arrow" />
              </div>
            </div>
          </Link>

          <Link
            to={{
              pathname: "/resources/external_content",
              state: 'externalContent'
            }}
          >
            <div className="resources__desc-card">
              <div className='resources__card'>
                <p className='resources__heading'>
                  External Content
                </p>
                <img src={arrow} className="arrow_img" alt="arrow" />
              </div>
            </div>
          </Link>

          <Link
            to={{
              pathname: "/resources/case_study",
              state: 'casestudy'
            }}

          >
            <div className="resources__desc-card">
              <div className='resources__card'>
                <p className='resources__heading'>
                  Case Study
                </p>
                <img src={arrow} className="arrow_img" alt="arrow" />
              </div>
            </div>
          </Link>

          <Link
            to={{
              pathname: "/resources/spotlight",
              state: "spotlight"
            }}

          >
            <div className="resources__desc-card">
              <div className='resources__card'>
                <p className='resources__heading'>
                  Spotlight
                </p>
                <img src={arrow} className="arrow_img" alt="arrow" />
              </div>
            </div>
          </Link>
        </div>
      </div>


    )
  }
}


const mapStateToProps = state => ({
  userInfo: state.auth.userInfo
});
Resource = connect(mapStateToProps, null)(Resource);
export default withRouter(Resource);