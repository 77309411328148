import React from "react";
import ReactEcharts from "echarts-for-react";

const BarChart = props => {
    const xAxisPoints = props.data.map(investment => investment.name);

    const option = {
        tooltip: {
            trigger: "axis",
            formatter: "{c}M",
            backgroundColor: "#494b4f",
            padding: [5, 10],
            textStyle: {
                color: "#fff",
                fontSize: "1.4rem",
                fontWeight: "bold",
            },
        },
        grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
        },
        xAxis: [
            {
                type: "category",
                data: xAxisPoints,
                axisTick: {
                    show: false,
                    alignWithLabel: true,
                },
            },
        ],
        yAxis: [
            {
                type: "value",
                show: false,
            },
        ],
        series: [
            {
                name: "直接访问",
                type: "bar",
                barWidth: "35%",
                barGap: "10%",
                data: props.data,
            },
        ],
    };

    return (
        <ReactEcharts
            style={{ minHeight: "50px" }}
            option={option}
            notMerge={true}
            lazyUpdate={true}
        // theme={"theme_name"}
        // onChartReady={this.onChartReadyCallback}
        // onEvents={EventsDict}
        // opts={}
        />
    );
};

export default BarChart;
