import React from "react";
import { Link } from "react-router-dom";

import NotFoundImage from "../img/404-bg.png";

import "../css/NotFound.css";

const NotFound = () => (
    <div className="content-wrapper">
        <img src={NotFoundImage} className="not-found-image" alt="not found" />

        {/* Text section on the right of image */}
        <div className="content-wrapper-text">
            <p className="text-404">404</p>
            <p className="text-somethingWentWrong">SOMETHING WENT WRONG</p>
            <p className="text-wrongWay">You might have taken a wrong way</p>
            <Link to="/" className="home-button">
                GO BACK TO HOME
            </Link>
        </div>
        {/* End of text section */}
    </div>
);

export default NotFound;
