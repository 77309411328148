const validate = values => {
    const errors = {};
    const passwordRegex = /^(?=.*\d)(?=.*[#$@!%&._*])[A-Za-z\d#$@!%&._*]{8,}$/;

    const reqError = "This field is required";
    const passwordError =
        "Should be atleast 8 characters, with atleast one special char and one numeral";

    // password
    if (!values.newPassword) {
        errors.newPassword = reqError;
    } else if (!passwordRegex.test(values.newPassword)) {
        errors.newPassword = passwordError;
    }

    if (!values.confirmNewPassword) {
        errors.confirmNewPassword = reqError;
    } else if (values.confirmNewPassword !== values.newPassword) {
        errors.confirmNewPassword = "Passwords do not match!";
    }

    return errors;
};

export default validate;
