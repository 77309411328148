import React, { Component } from 'react';
import "../css/BrowsingTech.css";

import download from "../img/download.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import caseImg from '../img/casestudy.png'
import '../css/Home.css'
import { getFormattedDateTime } from '../components/helper-functions/helper'
import EventsWebinar from './EventsWebinar';
import { httpfyUrl } from "../../src/utils/helpers";

class Trending extends Component {


    render() {

        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 3
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1
            }
        };

        //const data = this.props.data
        //const finalData = []
        //for (let i = 0; i < data.length; i++) {
        //   if(data[i].type==="events_webinars"){
        //  //   
        //    finalData.push(data[i])
        //   }
        //  }
        // 


        return (
            <div>
                <div className='resource_external_content'>
                    <p className='case_heading_ec'>External Content ({this.props.count})</p>
                    {this.props.data && this.props.data.length > 0 ?
                        <Carousel responsive={responsive}>
                            {this.props.data && this.props.data.map((obj, i) => {

                                return (
                                    <div className='events__carousel_container'>
                                        <div className='home_external__carousel_conatiner'>
                                            <img src={obj.thumbnail_url} className='events_img' alt="Thumbnail image" />
                                            <div className='events__webinar__container'>
                                                <div className='events_webinar_content'>
                                                    <p className='card-text'>{obj.title}</p>
                                                    {obj.publish_date && <div className="home__previewCard-time">{getFormattedDateTime(obj['publish_date'])}</div>}
                                                </div>


                                                {obj.url ?

                                                    <a href={httpfyUrl(obj.url)}
                                                        target="_blank"
                                                        rel="noopener noreferrer"

                                                    >
                                                        <div className='events__btn'>
                                                            <button className='events__register_btn'>Register</button>
                                                        </div>
                                                    </a>

                                                    :
                                                    <a
                                                        href={httpfyUrl(obj.file_url)}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <div className='events__btn'>
                                                            <button className='events__register_btn'>Download</button>
                                                        </div>
                                                    </a>}

                                            </div>

                                        </div>
                                    </div>

                                )

                            })
                            }
                        </Carousel> : <div className='techarea__desc-card'>
                            <div className='techarea__card'>
                                <div>
                                <p className='techarea__no_data'>
                                    The External Content Information is coming soon!
                                </p>
                                <p>
                                    Once available, you can view the External Content
                                    information here.
                                    <br />
                                    Stay Tuned!
                                </p>
                                </div>
                            </div>
                        </div>}
                </div>

            </div>
        );
    }
}

export default Trending;