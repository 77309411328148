export const validate = values => {
    const pincodeRegex = /^\d{6}$/;
    const nameRegex = /^[A-Za-z]+$/;
    const addressRegex = /^[A-Za-z0-9 ]+$/;
    const phoneRegex = /^[6789]\d{9}$/;

    const requiredError = "Required";
    const nameError = "Please enter a valid name";
    const pincodeError = 'Please enter a valid pin code';
    const phoneError = 'Enter a valid 10 digit mobile number';

    let errors = {};

    //  Validate first name
    if (!values.firstName) {
      errors.firstName = requiredError;
    } else if (!nameRegex.test(values.firstName.trim())) {
      errors.firstName = nameError;
    }

    //  Validate last name
    if (!values.lastName) {
      errors.lastName = requiredError;
    } else if (!nameRegex.test(values.lastName.trim())) {
      errors.lastName = nameError;
    }

    //  Validate address
    if (!values.address) {
      errors.address = requiredError;
    }

    //  Validate pincode
    if (!values.pincode) {
      errors.pincode = requiredError;
    } else if (!pincodeRegex.test(values.pincode.trim())) {
      errors.pincode = pincodeError;
    }

    //  Validate city
    if (!values.city) {
      errors.city = requiredError;
    } else if (!addressRegex.test(values.city.trim())) {
      errors.city = 'Please enter a valid value';
    }

    //  Validate state
    if (!values.state) {
      errors.state = requiredError;
    } else if (!addressRegex.test(values.state.trim())) {
      errors.state = 'Please enter a valid value';
    }

    //  Validate country
    if (!values.country) {
      errors.country = requiredError;
    } 

    //  Validate phone number
    if (!values.phone) {
      errors.phone = requiredError;
    } else if (!phoneRegex.test(values.phone.trim())) {
      errors.phone = phoneError;
    }

    // console.log("errors = ", errors);
    // console.log("values = ", values);
    return errors;
};
