import React from "react";
import ReactEcharts from "echarts-for-react";

const PieChart2 = props => {
  const isTabView = window.screen.width >= 760 && window.screen.width <= 1200;
  
  // legend params
  let yLegend = '60%';
  let xLegend = 'center';

  // series params
  let seriesCenter = ["50%", "30%"];
  let seriesRadius = ["40%", "55%"];

  if (isTabView) {
    xLegend = 'right';
    yLegend = '15%';

    seriesCenter = ['30%', '50%'];
    seriesRadius = ["55%", "80%"];
  }

  

  const option = {
    tooltip: {
      trigger: "item",
      // eslint-disable-next-line
      formatter: "{b}: ${c}M ({d}%)",
    },
    legend: {
      type: "scroll",
      orient: "vertical",
      x: xLegend,
      y: yLegend, 
      data: props.data, 
      selected: props.data[0],
      formatter: function(name) {
        const currElem = props.data.find(item => item.name === name);
        return `${name} ($${currElem.value} M)`;
      }
    },
    series: [
      {
        type: "pie",
        radius: seriesRadius,
        center: seriesCenter,
        data: props.data,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          normal: {
            show: false,
            position: "center",
          },
        },
      },
    ],
  };

  return (
    <ReactEcharts
      style={{ minHeight: isTabView ? '30rem' : '60rem' }}
      option={option}
      notMerge={true}
      lazyUpdate={true}
    // theme={"theme_name"}
    // onChartReady={this.onChartReadyCallback}
    // onEvents={EventsDict}
    // opts={}
    />
  );
};

export default PieChart2;
