import React, { Fragment } from 'react';
import { reduxForm, Field, FormSection, FieldArray } from 'redux-form';

//  Redux form field component imports
import FormInputField from '../../feature_yourself-components/form-elements/FormInputField';
import FormSelect from '../../feature_yourself-components/form-elements/FormSelect';
// import FormSelectAsyncCreatable from '../../feature_yourself-components/form-elements/FormSelectAsyncCreatable';
import FormTextAreaField from '../../feature_yourself-components/form-elements/FormTextAreaField';

//  Asset imports
import facebookInputIcon from "../../../img/form-imgs/facebook.svg";
import twitterInputIcon from "../../../img/form-imgs/twitter.svg";
import linkedinInputIcon from "../../../img/form-imgs/linkedin.svg";
import validate from '../validate';
class IndustryForm2 extends React.Component {

  render() {
    const { totalParts, handleSubmit, onSubmit, cities } = this.props;

    return (
      <div className="feature_form__wrapper">
        <p className="feature__form__subhead"
        >Step 4 of {totalParts}</p>

        <p className="feature_form__head"
        >Contact Details</p>

        <div className="feature_form__inner-wrapper">
          <form
            autoComplete='off'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="feature_form__inner-wrapper">
              <FormSection name="address">
                <Field
                  name="line1"
                  // widthClass="col-md-6"
                  component={FormTextAreaField}
                  type="text"
                  placeholder="Building number, street address etc."
                  label="Office Address"
                  tabable={true}
                />
                <Field
                  name="pin_code"
                  // widthClass="col-md-6"
                  component={FormInputField}
                  type="text"
                  label="Pincode"
                  tabable={true}
                />
                <Field
                  name="city"
                  component={FormSelect}
                  placeholder="Select"
                  label="City"
                  valueType="string"
                  options={cities.map(city => ({
                    label: city.name,
                    value: city.name,
                  }))}
                />
              </FormSection>

              <FormSection name="contact_person">
                <FieldArray
                  name="persons"
                  component={renderContactPersons}
                />
                <hr />

                <p className='feature_form_sub_heading'>Social Media Handles</p>
                <Field
                  name="facebook"
                  placeholder='Facebook'
                  component={FormInputField}
                  label="Facebook URL"
                  img={facebookInputIcon}
                />
                <Field
                  name="twitter"
                  placeholder='Twitter'
                  component={FormInputField}
                  label="Twitter URL"
                  img={twitterInputIcon}
                />
                <Field
                  name="linkedin"
                  placeholder='Linkedin'
                  component={FormInputField}
                  label="Linkedin URL"
                  img={linkedinInputIcon}
                />
              </FormSection>
            </div>

            <div className="feature_form__btn-wrapper">
              <button
                type='submit'
                className="feature_form__btn"
              >SUBMIT</button>

              <button
                onClick={this.props.prevPage}
                className="feature_form__btn feature_form__btn--back"
              >Previous Page</button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const renderContactPersons = ({ fields }) => (
  <Fragment>
    {fields.map((person, index) => (
      <Fragment key={index}>
        {fields.length > 0 && (
          <p className='feature_form_sub_heading' style={{
            marginTop: '3rem'
          }}>
            Contact Persons
          </p>
        )}
        <Field
          name={`${person}.name`}
          component={FormInputField}
          placeholder="Contact Person's Name"
          label="Name"
        />
        <Field
          name={`${person}.number`}
          component={FormInputField}
          placeholder="10 digit mobile no."
          label="Mobile No."
        />
        <Field
          name={`${person}.email`}
          component={FormInputField}
          label="Email Address"
          placeholder="E.g. hello@yourcompany.com"
        />
        {fields.length > 1 && (
          <div className="feature_form__field-array-remove">
            <span onClick={() => fields.remove(index)}>
              - Remove
                        </span>
          </div>
        )}
      </Fragment>
    ))}

    <div className="feature_form__field-array-add">
      <span onClick={() => fields.push({})}>+ Add A Contact Person</span>
    </div>
  </Fragment>
);

export default reduxForm({
  form: 'feature_yourself',
  destroyOnUnmount: false,
  validate
})(IndustryForm2);