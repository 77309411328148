export const dummyPersonInvestorData = {
    name: "Rajeev Bajaj",
    liked: false,
    company: "Nexus Ventures Private Limited",
    designation: "Chief Executive Officer (CEO)",
    work_experience: "8 years",
    experience: [
        {
            company: "Gartner Inc",
            position: "Senior Partner",
            city: "Boston",
            country: "USA",
            since: "Nov 2018",
            till: "Aug 2019",
        },
        {
            company: "Redhat Technologies LLC",
            position: "Group Head",
            city: "California",
            country: "USA",
            since: "Jan 2017",
            till: "Nov 2018",
        },
        {
            company: "Adobe Systems Inc",
            position: "Lead Manager",
            city: "New York",
            country: "USA",
            since: "Jan 2016",
            till: "Nov 2017",
        },
    ],
    positions: [
        { company: "Swiggy", position: "Board Member", stake: "2%" },
        { company: "DLF Group", position: "Board Member", stake: "0.2%" },
    ],
    personal_investments: [
        {
            company: "Swiggy",
            announced_in: "August 2018",
            rounds: ["Pre series A", "Round 1"],
            lead_investor: true,
            amount_invested: "4M",
        },
        {
            company: "Zomato",
            announced_in: "August 2018",
            rounds: ["Series B", "Round 1"],
            lead_investor: false,
            amount_invested: "4M",
        },
        {
            company: "Piggy",
            announced_in: "August 2018",
            rounds: ["Seed", "Round 2"],
            lead_investor: true,
            amount_invested: "4M",
        },
        {
            company: "Pomato",
            announced_in: "August 2018",
            rounds: ["Series C", "Round 1"],
            lead_investor: false,
            amount_invested: "4M",
        },
    ],
    company_investments: [
        {
            company: "Swiggy",
            announced_in: "August 2018",
            rounds: ["Series C", "Seed"],
            lead_investor: false,
            amount_invested: "4M",
        },
        {
            company: "Piggy",
            announced_in: "August 2018",
            rounds: ["Series C", "Round 1"],
            lead_investor: true,
            amount_invested: "8M",
        },
    ],
};

export const dummyCompanyInvestorData = {
    name: "Blume Venturs (India)",
    liked: false,
    type: "Public Private Partnership)",
    employee_count: "1000+",
    total_investments: "343.32 Million",
    headquarters: "UAE, Singapore",
    exits: [
        {
            company: "Swiggy",
            announced_in: "August 2018",
            amount_invested: "4M",
        },
        {
            company: "Zomato",
            announced_in: "August 2018",
            amount_invested: "4M",
        },
    ],
    active_investments: [
        {
            company: "Swiggy",
            announced_in: "August 2018",
            rounds: ["Pre series A", "Round 1"],
            lead_investor: true,
            amount_invested: "4M",
        },
        {
            company: "Zomato",
            announced_in: "August 2018",
            rounds: ["Series B", "Round 1"],
            lead_investor: false,
            amount_invested: "4M",
        },
        {
            company: "Piggy",
            announced_in: "August 2018",
            rounds: ["Seed", "Round 2"],
            lead_investor: true,
            amount_invested: "4M",
        },
        {
            company: "Pomato",
            announced_in: "August 2018",
            rounds: ["Series C", "Round 1"],
            lead_investor: false,
            amount_invested: "4M",
        },
    ],

    roundInvestmentData: [
        { name: "Angel", value: 23 },
        { name: "Seed", value: 43 },
        { name: "Preseries A", value: 54 },
        { name: "Preseries B", value: 54 },
        { name: "Preseries C", value: 54 },
    ],
    technologyInvestmentData: [
        { name: "IOT", value: 10 },
        { name: "AI", value: 20 },
        { name: "Cybersecurity", value: 15 },
        { name: "Blockchain", value: 14 },
        { name: "Hardware", value: 11 },
    ],
    investmentTrendsData: [
        { name: "2014", value: 11, itemStyle: { color: "#4c96ff" } },
        { name: "2015", value: 10, itemStyle: { color: "#81b5ff" } },
        { name: "2016", value: 15, itemStyle: { color: "#a5caff" } },
        { name: "2017", value: 40, itemStyle: { color: "#7990ba" } },
        { name: "2018", value: 25, itemStyle: { color: "#2f4b7c" } },
        { name: "2019", value: 51, itemStyle: { color: "#203456" } },
        { name: "2014", value: 11, itemStyle: { color: "#4c96ff" } },
        { name: "2015", value: 10, itemStyle: { color: "#81b5ff" } },
        { name: "2016", value: 15, itemStyle: { color: "#a5caff" } },
        { name: "2017", value: 40, itemStyle: { color: "#7990ba" } },
        { name: "2018", value: 25, itemStyle: { color: "#2f4b7c" } },
        { name: "2019", value: 51, itemStyle: { color: "#203456" } },
    ],

    team_members: [
        {
            name: "Gaurav Sharma",
            position: "Senior Partner",
            linkedIn: null,
            companies_invested_in: ["Swiggy", "Zomato", "ZoomCar"],
        },
        {
            name: "Sarbvir Singh",
            position: "Senior Partner",
            linkedIn: null,
            companies_invested_in: ["Scooping Technologies", "ThoughtSpot"],
        },
        {
            name: "Kushal Mangal",
            position: "Senior Partner",
            linkedIn: null,
            companies_invested_in: ["Zomato", "Karma Innovations Pvt. Ltd.  "],
        },
        {
            name: "Harsimrat Singh",
            position: "Junior Partner",
            linkedIn: null,
            companies_invested_in: ["Zomato", "Swiggy"],
        },
    ],
};
