import React, { Component } from "react";
import { connect } from "react-redux";

import Modal from "./ui/modal/Modal";
import Signup from "./user-form-components/Signup";
import ForgotPassword from "./user-form-components/ForgotPassword";
import Login from "./user-form-components/Login";
import LinkSent from "./user-form-components/LinkSent";
import { reduxForm ,reset} from 'redux-form';
import { setFormType } from "../actions/user-form";

class UserForm extends Component {
  constructor(props) {
    super(props);

    this.changeType = this.changeType.bind(this);
    this.formToRender = this.renderForm.bind(this);
  }

  changeType(type) {
    this.props.setFormType(type);
  }

  renderForm() {
    const { handleSubmit,dispatch } = this.props;
    if(!this.props.show){
    //  console.log(dispatch)
    dispatch(reset("login_form"));
    dispatch(reset("forgot_password"));
    dispatch(reset("signup"));
  }
    
    switch (this.props.formType) {
      case 'signup': return (
        <Modal show={this.props.show} toggleModal={this.props.toggle}>
          <Signup toggle={this.props.toggle} changeType={this.changeType} />
        </Modal>
      );

      case 'login': return (
        <Modal show={this.props.show} toggleModal={this.props.toggle}>
          <Login  toggle={this.props.toggle} changeType={this.changeType} />
        </Modal>
      );

      case 'forgotPassword': return (
        <Modal show={this.props.show} toggleModal={this.props.toggle}>
          <ForgotPassword 
            toggle={this.props.toggle} 
            changeType={this.changeType} 
            userInfo={this.props.userInfo}  
          />
        </Modal>
      );

      case 'linkSent': return (
        <Modal show={this.props.show} toggleModal={this.props.toggle}>
          <LinkSent toggle={this.props.toggle} changeType={this.changeType} />
        </Modal>
      );

      default: return null;
    }
  }

  render() {
    const formToRender = this.renderForm();
    return formToRender;
  }
}

UserForm = reduxForm({
  //form: 'contact' // a unique name for this form
  form: "user_form"
})(UserForm);

const mapStateToProps = state => ({
  formType: state.userForm.formType,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = dispatch => ({
  setFormType: formType => dispatch(setFormType({ formType: formType })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserForm);
