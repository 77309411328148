export const deepClone = (object) => {
  let cloneObject = JSON.parse(JSON.stringify(object));
  return cloneObject;
};

//export const initialData = {
//  groups: [
//    {
//      label: "Companies",
//      weight: "10",
//      level: "1",
//      fetchLabel: "company",
//      groups: [],
//    },
//    {
//      label: "Academia",
//      weight: "10",
//      level: "1",
//      fetchLabel: "academia",
//      groups: [],
//    },
//    {
//      label: "R & D Centers",
//      weight: "10",
//      level: "1",
//      fetchLabel: "r_and_d",
//      groups: [],
//    },
//    {
//      label: "Researcher",
//      weight: "10",
//      level: "1",
//      fetchLabel: "researcher",
//      groups: [],
//    },
//    {
//      label: "Products",
//      weight: "10",
//      level: "1",
//      fetchLabel: "products",
//      groups: [],
//    },
//  ],
//};
//

export const initialData = {
  groups: [
    {
      label: "Researchers",
      weight: "10",
      level: "1",
      fetchLabel: "researcher",
      groups: [],
    }
  ],
};

