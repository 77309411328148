import React, { Fragment } from "react";

import { Redirect } from "react-router-dom";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import PaymentStatusModal from './ui/PaymentStatusModal';

import Input from "./ui/paymentFormFields/Input";
import { validate } from "../utils/validatePaymentForm";

import "../css/Payment.css";
import sprite from "../img/payment-icons-sprite.svg";
import freeUserImg from '../img/free-user.svg';

import api from "../api";

class Payment extends React.Component {
  state = {
    // shouldRedirect: false,
    expandedStep: 1,
    step1Complete: false,
    step2Complete: false,

    selectedPlan: "fixed",
    credits: 200,
    amount: null,
    unitCost: 2.495,
    currentCredits: 0,
    expirationDate: '',

    payu_url: '',
    dataToPostToPayU: {},

    showStatusModal: false,
    paymentStatus: {
      status: false,
      amount: -1,
      transactionId: '',
    }
  };

  componentDidMount() {
    if (!this.props.user || !this.props.user.token) {
      this.props.history.push('/');
      toast.warning('You are not logged in!');
      return;
    }



    const urlParams = new URLSearchParams(this.props.location.search);
    const transactionId = urlParams.get('txnid');
     
    if (transactionId) {
      this.getPaymentStatus(transactionId);
    }

    this.getPayuUrl();
    this.getCreditMetrics();
    this.getCurrentCredits();
  }

  closeStatusModal = () => this.setState({ showStatusModal: false });

  getPaymentStatus = transactionId => {
    fetch(`${api.getTransaction}?txnid=${transactionId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        TCRAUTH: this.props.user.token
      }
    }).then(response => {
      if (response && response.ok) return response.json();
      throw new Error('Status code: ' + response.status);
    }).then(response => {
     // console.log('response = ', response);
      this.setState({
        showStatusModal: true,
        paymentStatus: {
          status: response.data.final_status === 'success',
          amount: response.data.amount,
          transactionId: response.data.transaction_id,
        }
      });
    }).catch(err => {
      // console.log('Error fetching transaction details', err);
      this.setState({
        showStatusModal: false,
        paymentStatus: {
          status: false,
          amount: -1,
          transactionId: -1
        }
      });
    });
  }

  generateDateString = dateTimeStamp => {
    
    const date = new Date(dateTimeStamp);
    const day = date.getDate();
    const year = date.getFullYear();
    let month = date.getMonth();

    if (month === 0) month = 'January';
    else if (month === 1) month = 'February';
    else if (month === 2) month = 'March';
    else if (month === 3) month = 'April';
    else if (month === 4) month = 'May';
    else if (month === 5) month = 'June';
    else if (month === 6) month = 'July';
    else if (month === 7) month = 'August';
    else if (month === 8) month = 'September';
    else if (month === 9) month = 'October';
    else if (month === 10) month = 'November';
    else if (month === 11) month = 'December';

    return `Till ${month} ${day}, ${year}`;
  }

  getPayuUrl = () => {
    const requestConfig = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };

    fetch(api.getPayuURL, requestConfig).then(response => {
      if (!response || !response.ok) throw new Error(`Could not fetch PayU url ${response.status}`);
      return response.json();
    }).then(response => {
      // console.log('PayU Url =', response.data.payu_url);
      this.setState({ payu_url: response.data.payu_url });
    }).catch(err => {
      // console.log('Error occurred', err);
    });
  }

  getCreditMetrics = () => {
    fetch(api.getCreditMetrics).then(response => {
      if (!response || !response.ok) {
        // console.log('Could not fetch credit metrics', response.status);
        return;
      }

      response.json().then(response => {
        this.setState({
          unitCost: response.data.credit_price,
          amount: this.state.credits * response.data.credit_price
        });
      })
    }).catch(err => {
      // console.log('Error fetching credits', err);
    })
  }

  getCurrentCredits = () => {
    if (!this.props.user) return;

    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'TCRAUTH': this.props.user.token
      }
    };

    fetch(api.getCredits, requestConfig).then(response => {
      if (!response || !response.ok) {
        // console.log('Could not fetch current credits', response.status);
        return;
      }

      response.json().then(response => {
        this.setState({
          currentCredits: response.data.credits_remaining,
          expirationDate: this.generateDateString(response.data.expire_date)
        });
      })
    }).catch(err => {
      // console.log('Error fetching current credits', err);
    })
  }

  createPaymentHash = async values => {
    const authToken = this.props.user.token;
    values.plan = this.state.selectedPlan;
    values.amount = this.state.amount;

    let credits = this.state.credits;
    if (values.plan === "unlimited") credits = -1;

    //  Form data to be posted
    const postData = {
      credits: credits,
      billing_details: {
        first_name: values.firstName,
        last_name: values.lastName,
        address: values.address,
        pincode: values.pincode,
        city: values.city,
        state: values.state,
        country: values.country,
        phone: values.phone
      }
    };

    //  POST request configuration
    const requestConfig = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        TCRAUTH: authToken,
      },
      body: JSON.stringify(postData),
    };

    let response = null;
    try {
      response = await fetch(api.createHash, requestConfig);
      if (!response) throw new Error('No response received');
      else if (!response.ok) throw new Error('Status code: ', response.status);

      //  Response is OK, examine JSON response
      response = await response.json();
      return response.data;
    } catch (err) {
      // console.log('Error while getting hash', err);
      return null;
    }
  }

  // handleBilling = values => {
  //   const authToken = this.props.user.token;
  //   values.plan = this.state.selectedPlan;
  //   values.amount = this.state.amount;

  //   let credits = this.state.credits;
  //   if (values.plan === "unlimited") credits = -1;

  //   //  TODO - Call Payment gateway API before calling own backend API
  //   // CALL PAYMENT GATEWAY API HERE

  //   const postData = {
  //     credits: credits,
  //     billing_details: {
  //       name: values.name,
  //       address: values.address,
  //       pincode: values.pincode,
  //       city: values.city,
  //       state: values.state,
  //       country: values.country,
  //     },
  //   };

  //   const requestConfig = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       TCRAUTH: authToken,
  //     },
  //     body: JSON.stringify(postData),
  //   };

  //   fetch(api.buy_credits, requestConfig)
  //     .then(response => {
  //       if (response.status !== 200) {
  //         console.log(
  //           "Something went wrong in api.buy_credits, status code = ",
  //           response.status
  //         );
  //         toast.error("Something went wrong, please try again later");
  //         return;
  //       }

  //       //  Proceed for successful request
  //       response.json().then(response => {
  //         // console.log("response of buy_credits = ", response);
  //         //  TODO - Do any actions if needed after receiving response
  //         toast.success(response.message);
  //         this.props.history.push('/');
  //       });
  //     })
  //     .catch(err => {
  //       console.log(
  //         "An error occurred in api.buy_credits, error = ",
  //         err
  //       );
  //       toast.error("An error occurred, please try again later");
  //     });
  // };

  handleBilling = async values => {
    let hashResponse = null;
    // let payuResponse = null;

    try {
      //  Make the API call to createHash 
      hashResponse = await this.createPaymentHash(values);
      if (!hashResponse) throw new Error('Could not get hash response');
      //  Send the response object ot the PayU url
      if (this.state.payu_url === '') throw new Error('Payment URL not available');

    //  console.log('payment hash response = ', hashResponse);
      this.setState({
        dataToPostToPayU: {
          amount: hashResponse.amount,
          email: hashResponse.email,
          firstname: hashResponse.firstname,
          hash: hashResponse.hash,
          key: hashResponse.key,
          lastname: hashResponse.lastname,
          phone: hashResponse.phone,
          productinfo: hashResponse.productinfo,
          txnid: hashResponse.txnid,
          udf1: hashResponse.udf1,
          surl: `${process.env.REACT_APP_API_URL}/payments/buy_credits/`,
        }
      }, this.postToPayU);
      // TODO - Handle the errors and what to do in case it happens in catch()
    } catch (err) {
      // console.log('Error in billing', err);
    }
  }

  postToPayU = async data => {
    try {
      document.getElementById('payment__pvt-form').submit();
    } catch (err) {
      // console.log("Error in submitting payu form", err);
    }
  }

  goToNextStep = (currStep, isFormInvalid = false) => {
    //  For invalid form , open billing details and show a toast message to fill the form
    // if ((currStep === 2 || currStep === 3) && isFormInvalid) {
    //   this.setExpandedStep(2);
    //   toast.warn("Please fill out the billing details to proceed");
    //   return;
    // }

    if (currStep === 1) {
      this.setState({
        step1Complete: true,
        expandedStep: currStep + 1,
        // amount: this.state.selectedPlan === 'fixed'
      });
    } else if (currStep === 2) {
      this.setState({
        step2Complete: true,
        expandedStep: currStep + 1,
        // amount: this.calcAmount(this.state.credits)
      });
    }
  };

  setExpandedStep = step => this.setState({ expandedStep: step });

  setDesiredCredits = event => {
    const value = parseInt(event.target.value);
    const updatedCredits = value > 0 ? value : 1;
    this.setState({
      credits: updatedCredits,
      amount: this.calcAmount(updatedCredits),
    });
  };

  calcAmount = value =>
    Number(parseFloat(value * this.state.unitCost).toFixed(3));

  increaseCredits = value => {
    if (this.state.selectedPlan === "unlimited") return;

    this.setState(prevState => {
      const updatedCredits = Number(prevState.credits) + value;
      return {
        credits: updatedCredits,
        amount: this.calcAmount(updatedCredits),
      };
    });
  };

  //  Handles the radio button change between fixed and unlimited plans
  changePlan = event => {
    const plan = event.target.value;
     

    if (plan === "fixed") {
      this.setState({
        selectedPlan: plan,
        amount: this.calcAmount(this.state.credits),
      });
    } else {
      this.setState({
        selectedPlan: plan,
        amount: 50000,
      });
    }
  };

  render() {
    // console.log('Payment props = ', this.props);
    //  Redirect if
    //  - User is not logged in
    //  - Redirect flag in state is set
    let redirect = null;
    if (!this.props.user || this.state.shouldRedirect) {
      toast.info("Please login first");
      return <Redirect to='/reports' />;
      // redirect = <Redirect to="/reports" />;
    }

    let creditBalanceDisplay = (
      <Fragment>
        <p className="payment__available-credits-heading"
        >Available credit balance</p>

        <p className="payment__available-credits-balance"
        >{this.state.currentCredits} credits</p>
      </Fragment>
    );
    if (this.state.currentCredits === -1) creditBalanceDisplay = (
      <Fragment>
        <p className='payment__available-credits-heading--unlimited'
        >Unlimited Credits</p>

        <p className="payment__available-credits-balance payment__available-credits-balance--unlimited"
        >{this.state.expirationDate}</p>
      </Fragment>
    )


    //  Redux form props
    const { handleSubmit, submitting, invalid } = this.props;
    const expandedStep = this.state.expandedStep;
    const step1Complete = this.state.step1Complete;
    const step2Complete = this.state.step2Complete;
    // console.log("Logging props for Payment", this.props);

    //  If Enter is hit while filling out the form, disable default action of submission
    const handleOnKeyPress = event => {
      if (event.key === "Enter") event.preventDefault();
    };

    //  Define the page heading
    //  Render this UI for unlimited members that doesn't display the form 
    let sectionHeading = <p className="payment__heading">You are a Premium member!</p>;
    let mainContent = (
      <section className='payment__unlimited-member'>
        <div className='payment__premium-ui'>
          <img src={freeUserImg} alt='You are a premium user' className='payment__premium-member-img' />
          <p
            className='payment__premium-member-text'
          >Enjoy the benefits, access premium information,<br />
            technology reports, financial data and more..</p>
        </div>
      </section>
    );

    //  In case user has no credits or has a fixed plan with finite credits
    //  Display this UI instead with the form to allow buying of more credits
    if (this.state.currentCredits !== -1) {
      sectionHeading = <p className="payment__heading">Buy Credits</p>;

      mainContent = (
        <section className="payment__billing">
          <form
            id="payment__pvt-form"
            method="POST"
            action={this.state.payu_url}
          >
            <input type="hidden" name="amount" value={this.state.dataToPostToPayU.amount} />
            <input type="hidden" name="email" value={this.state.dataToPostToPayU.email} />
            <input type="hidden" name="firstname" value={this.state.dataToPostToPayU.firstname} />
            <input type="hidden" name="hash" value={this.state.dataToPostToPayU.hash} />
            <input type="hidden" name="key" value={this.state.dataToPostToPayU.key} />
            <input type="hidden" name="lastname" value={this.state.dataToPostToPayU.lastname} />
            <input type="hidden" name="phone" value={this.state.dataToPostToPayU.phone} />
            <input type="hidden" name="productinfo" value={this.state.dataToPostToPayU.productinfo} />
            <input type="hidden" name="txnid" value={this.state.dataToPostToPayU.txnid} />
            <input type="hidden" name="udf1" value={this.state.dataToPostToPayU.udf1} />
            <input type="hidden" name="surl" value={this.state.dataToPostToPayU.surl} />
            <input type="hidden" name="furl" value={this.state.dataToPostToPayU.surl} />
          </form>

          <form
            autoComplete="off"
            className="payment__form"
            onKeyPress={handleOnKeyPress}
            onSubmit={handleSubmit(this.handleBilling)}
          >
            <div className="payment__form-section">
              <div className="payment__form-section-header">
                <span
                  className={`payment__form-section-number ${
                    step1Complete
                      ? "payment__form-section-number--green"
                      : ""
                    }`}
                >1</span>

                <p className="payment__form-section-title"
                >Plans</p>

                {expandedStep !== 1 && step1Complete && (
                  <p className="payment__breifing-info">
                    {this.state.selectedPlan === 'fixed' ? this.state.credits : 'Unlimited'}
                    &nbsp;Credits for&nbsp;
                      <span className="payment__breifing-info--bold">
                      &#8377;{this.state.amount}
                    </span>
                  </p>
                )}

                <p
                  className="payment__form-section-right-link"
                  onClick={step1Complete
                    ? this.setExpandedStep.bind(null, 1)
                    : null
                  }
                >
                  {expandedStep !== 1 && step1Complete
                    ? "Edit"
                    : "Learn more about the plans"}
                </p>
              </div>

              <div
                className={`payment__form-section-content ${
                  expandedStep === 1
                    ? "payment__form-section-content--visible"
                    : "payment__form-section-content--hidden"
                  }`}
              >
                <div className="payment__radio-inputs-container">
                  <div className="payment__radio-input">
                    <input
                      type="radio"
                      name="plan"
                      value="fixed"
                      id="payment-plan--fixed"
                      defaultChecked
                      onChange={this.changePlan}
                      className="payment__radio-input-input"
                      selected={this.state.selectedPlan === "fixed"}
                    />
                    <label
                      htmlFor="payment-plan--fixed"
                      className="payment__radio-input-label"
                    >
                      <span className="custom-radio-btn" />
                      Fixed Credits
                      </label>

                    <div className="payment__radio-input-content">
                      <p className="payment__radio-input-text"
                      >Enter the no. of credits you require</p>

                      <div className="payment__credit-and-amount-wrapper">
                        <input
                          type="number"
                          name="credit-amount"
                          className="payment__credit-input"
                          min="1"
                          disabled={this.state.selectedPlan === "unlimited"}
                          onChange={this.setDesiredCredits}
                          value={this.state.credits}
                        />

                        <p className="payment__credit-amount">
                          &#8377;{this.calcAmount(this.state.credits)}
                        </p>
                      </div>

                      <div className="payment__amount-adders-wrapper">
                        <div
                          onClick={this.increaseCredits.bind(null, 100)}
                          className="payment__amount-adder"
                        >+100</div>

                        <div
                          onClick={this.increaseCredits.bind(null, 500)}
                          className="payment__amount-adder"
                        >+500</div>

                        <div
                          onClick={this.increaseCredits.bind(null, 1000)}
                          className="payment__amount-adder"
                        >+1000</div>
                      </div>
                    </div>
                  </div>

                  <div className="payment__radio-input">
                    <input
                      type="radio"
                      name="plan"
                      value="unlimited"
                      id="payment-plan--unlimited"
                      className="payment__radio-input-input"
                      onChange={this.changePlan}
                      selected={this.state.plan === "unlimited"}
                    />
                    <label
                      htmlFor="payment-plan--unlimited"
                      className="payment__radio-input-label"
                    >
                      <span className="custom-radio-btn" />
                      Unlimited
                      </label>

                    <div className="payment__radio-input-content payment__radio-input-content--unlimited">
                      <p className="payment__radio-input-text-unlimited"
                      >Unlimited credits and free<br />access for a year</p>

                      <div>
                        <p className="payment__unlimited-amount--discounted"
                        >&#8377;50,000</p>

                        <p className="payment__unlimited-amount--original"
                        >&#8377;70,000</p>
                      </div>
                    </div>

                    <p className="payment__unlimited-recommend"
                    >Recommended for enterprise use</p>
                  </div>
                </div>

                <button
                  type="button"
                  onClick={this.goToNextStep.bind(null, 1)}
                  className="payment__btn-continue"
                >Continue</button>
              </div>
            </div>

            <div className="payment__form-section">
              <div className="payment__form-section-header">
                <span
                  className={`payment__form-section-number ${
                    step2Complete
                      ? "payment__form-section-number--green"
                      : ""
                    }`}
                >2</span>

                <p className="payment__form-section-title"
                >Billing Details</p>

                <p
                  className="payment__form-section-right-link"
                  onClick={step2Complete
                    ? this.setExpandedStep.bind(null, 2)
                    : null
                  }
                >
                  {expandedStep !== 2 && step2Complete
                    ? "Edit"
                    : ""}
                </p>
              </div>

              <div
                className={`payment__form-section-content payment__form-section-content--2 ${
                  expandedStep === 2
                    ? "payment__form-section-content--visible"
                    : "payment__form-section-content--hidden"
                  }`}
              >
                <div className="flex-inputs-wrapper">
                  <Field
                    component={Input}
                    name="firstName"
                    label="First Name"
                    placeholder="First Name"
                    labelclassName="payment__field-label"
                    inputclassName="payment__field-input"
                    wrapperclassName="payment__field-wrapper payment__field-wrapper--name"
                    errorclassName="payment__field-error"
                  />

                  <Field
                    component={Input}
                    name="lastName"
                    label="Last Name"
                    placeholder="Last Name"
                    labelclassName="payment__field-label"
                    inputclassName="payment__field-input"
                    wrapperclassName="payment__field-wrapper payment__field-wrapper--name"
                    errorclassName="payment__field-error"
                  />
                </div>

                {/* <Field
                  component={Input}
                  name="name"
                  label="Name"
                  placeholder="Name"
                  labelclassName="payment__field-label"
                  inputclassName="payment__field-input"
                  wrapperclassName="payment__field-wrapper payment__field-wrapper--name"
                  errorclassName="payment__field-error"
                /> */}

                <Field
                  component={Input}
                  name="address"
                  label="Enter your detailed address"
                  placeholder="Enter your detailed address"
                  labelclassName="payment__field-label"
                  inputclassName="payment__field-input"
                  wrapperclassName="payment__field-wrapper payment__field-wrapper--address"
                  errorclassName="payment__field-error"
                />

                <div className="flex-inputs-wrapper">
                  <Field
                    component={Input}
                    name="pincode"
                    label="Pincode"
                    placeholder="Pincode"
                    labelclassName="payment__field-label"
                    inputclassName="payment__field-input"
                    wrapperclassName="payment__field-wrapper payment__field-wrapper--pincode"
                    errorclassName="payment__field-error"
                  />

                  <Field
                    component={Input}
                    name="city"
                    label="City"
                    placeholder="City"
                    labelclassName="payment__field-label"
                    inputclassName="payment__field-input"
                    wrapperclassName="payment__field-wrapper payment__field-wrapper--city"
                    errorclassName="payment__field-error"
                  />

                  <Field
                    component={Input}
                    name="state"
                    label="State"
                    placeholder="State"
                    labelclassName="payment__field-label"
                    inputclassName="payment__field-input"
                    wrapperclassName="payment__field-wrapper payment__field-wrapper--state"
                    errorclassName="payment__field-error"
                  />
                </div>

                <div className="flex-inputs-wrapper">
                  <Field
                    component={Input}
                    name="country"
                    label="Country"
                    placeholder="Country"
                    labelclassName="payment__field-label"
                    inputclassName="payment__field-input"
                    wrapperclassName="payment__field-wrapper payment__field-wrapper--country"
                    errorclassName="payment__field-error"
                  />

                  <Field
                    component={Input}
                    name="phone"
                    label="Phone"
                    placeholder="Phone"
                    labelclassName="payment__field-label"
                    inputclassName="payment__field-input"
                    wrapperclassName="payment__field-wrapper payment__field-wrapper--city"
                    errorclassName="payment__field-error"
                  />
                </div>

                <button
                  style={{ marginTop: "0" }}
                  type="button"
                  onClick={this.goToNextStep.bind(
                    null,
                    2,
                    invalid
                  )}
                  className="payment__btn-continue"
                >Continue</button>
              </div>
            </div>

            <div className="payment__form-section">
              <div className="payment__form-section-header">
                <span className="payment__form-section-number"
                >3</span>

                <p className="payment__form-section-title"
                >Payment</p>

                <p className="payment__form-section-right-link payment__form-section-right-link--amount"
                >&#8377;{this.state.amount}</p>
              </div>

              <div
                className={`payment__form-section-content ${
                  this.state.expandedStep === 3
                    ? "payment__form-section-content--visible"
                    : "payment__form-section-content--hidden"
                  }`}
              >
                <p className="payment__gateway-text"
                >You'll be redirected to a payment gateway</p>

                <div className="payment__checkout-btn-and-icons-wrapper">
                  <button
                    type="submit"
                    disabled={submitting || invalid}
                    className="payment__btn-continue"
                  //  onClick={this.goToNextStep.bind(null, 2)}
                  >Pay securely</button>

                  <svg className="payment__option-icon">
                    <use
                      href={sprite + "#visa-light"}
                    />
                  </svg>
                  <svg className="payment__option-icon">
                    <use
                      href={
                        sprite +
                        "#master-card-light"
                      }
                    />
                  </svg>
                  <svg className="payment__option-icon payment__option-icon--small">
                    <use href={sprite + "#checkout"} />
                  </svg>

                  <p className="payment__secure-checkout-text"
                  >Secure Checkout</p>
                </div>
              </div>
            </div>
          </form>
          <div className="payment__billing-step" />
        </section>
      );
    }

    // Prepare the paymentStatusModal
    let paymentStatusModal = null;
    if (this.state.showStatusModal) {
      paymentStatusModal = <PaymentStatusModal
        show={this.state.showStatusModal}
        toggleModal={this.closeStatusModal}
        amount={this.state.paymentStatus.amount}
        paymentStatus={this.state.paymentStatus.status}
        transactionId={this.state.paymentStatus.transactionId}
      />;
    }
    
    return (
      <div className="payment">
        {redirect}
        {paymentStatusModal}

        {sectionHeading}
        <main className="payment__content">
          {mainContent}

          <section className="payment__info">
            <div className="payment__available-credits-box">
              <svg className="payment__icon">
                <use href={sprite + "#credits"} />
              </svg>

              <div className="payment__available-credits-text-wrapper">
                {creditBalanceDisplay}
              </div>
            </div>

            <p className="payment__credit-benefits-heading"
            >Do more with credits</p>

            <ul className="payment__credit-benefits">
              <li className="payment__credit-benefits-item">
                <svg className="payment__credit-benefits-icon">
                  <use href={sprite + "#access"} />
                </svg>
                <p className="payment__credits-benefits-text"
                >Access to financial information of companies</p>
              </li>

              <li className="payment__credit-benefits-item">
                <svg className="payment__credit-benefits-icon">
                  <use href={sprite + "#reports"} />
                </svg>
                <p className="payment__credits-benefits-text"
                >View and download technology reports</p>
              </li>

              <li className="payment__credit-benefits-item">
                <svg className="payment__credit-benefits-icon">
                  <use href={sprite + "#follow"} />
                </svg>
                <p className="payment__credits-benefits-text"
                >Follow more entities and get followed back</p>
              </li>
            </ul>
          </section>
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.userInfo,
});

Payment = connect(
  mapStateToProps,
  null
)(Payment);

Payment = reduxForm({
  form: "payment_form",
  validate: validate,
})(Payment);

export default Payment;

// export default withRouter(connect(mapStateToProps)(Payment));
