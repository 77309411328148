import React, { Component } from 'react';
import '../../css/Feed.css';
import notFeedImage from '../../img/not-following.svg';
 
import ContentFeed from './ContentFeed';
class Feed extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dateTime: "",
            isCapListExpanded: false,
            techAreaName: ""

        }
    }


    toggleCapList = (e) => {
        var id = e.target.id
        this.setState(prevState => ({
            isCapListExpanded: !prevState.isCapListExpanded,
            techAreaName: id
        }));
    }
researchPapers(data){
    
    if(data.type==="Academia"){
      const  newData = []
    //console.log(data)
    //const AcademiaResearchData = data.research_papers
    // console.log(AcademiaResearchData)
    ////return AcademiaResearchData[0].research_papers
    //const newData =  AcademiaResearchData!=[]? AcademiaResearchData[0].research_papers: [] 
    return newData
     
    }
}


    render() {
        //console.log(this.props.latestFeed)
         
        const feed = this.props.latestFeed.map((element, i) => {
             
            if (element.type === "contributor") {
                return (
                    <ContentFeed
                        profileImage={element.person_details.profile_image}
                        fullName={element.person_details.full_name}
                        docType={element.doc_type}
                        time={element.created_at}
                        isCompany={"no"} />
                )
            } else if (element.type && element.type !== "contributor") {
                 //console.log(element.type)
                 if(element.type ==="Academia"){
                    return(
                        <ContentFeed
                        //profileImage={element.person_details.profile_image}
                        type = {element.type}
                        data = {this.researchPapers(element)}
                        fullName={element.name}
                        docType={"research paper"}
                        time={element.created_at}
                        isCompany={"researchPaper"} />    
                    )
                 }
                else{
                return (
                    <ContentFeed  
                        type = {element.type}
                        data = {element.research_papers}
                        fullName={element.name}
                        docType={"research paper"}
                        time={element.created_at}
                        isCompany={"researchPaper"} />
                )}
            } else if (element.data) {

                const techarea = this.state.isCapListExpanded && element.techarea === this.state.techAreaName ?
                    element.data :
                    element.data.slice(0, 5);
                return (
                    <ContentFeed
                        data={techarea}
                        index={i}
                        techAreaName={element.techarea}
                        time={element.last_updated_entity_time}
                        count={element.total_count}
                        toggleCapList={this.toggleCapList}
                        showToggleButton={element.data.length > 5}
                        totalCount={element.data.length}
                        isCompany={"yes"} />
                )
            } else {
               // console.log(element.doc_type)
               // console.log(element)
                return (
                    <ContentFeed
                        //profileImage={element.person_details.profile_image}
                        image_url = {element.thumbnail_url}
                        image = {element.file_url}
                        fullName={element.tech_area}
                        docType={element.doc_type}
                        time={element.publish_date}
                        isCompany={"no"}
                        techarea={"yes"} />
                )
            }
        });
        return (

            <div className='feed'>
                {feed && feed.length >0 ?
                <div>
                    {feed}
                </div>:<>
                <p className='no_feed'>No Information available</p>
                     </>}
            </div>

        );
    }
}

export default Feed;