import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { setFormType } from '../../actions/user-form';
import { toast } from 'react-toastify';

import companyIcon from "../../img/company.svg";

const prepareTechnologyTypeString = techTypeArray => {
  const resultStr = techTypeArray
    .slice(0, 3)
    .map(tech_type => tech_type.name)
    .join(" ・ ");
  return resultStr;
};

class EntityCard2 extends React.Component {
  handleEntityCardClick = () => {

  
  //  } 
  if (this.props.entity.company_name){
  const companyName = this.props.entity.company_name.replace("%", "percentage").replace(/ /g, "_")
   
  const path = this.props.location.search
  const pathname = this.props.location.pathname.split('/').filter(e=> e)
   
  const splitPath = path.split('&')
   
  const index = splitPath[0].indexOf("=")
   const resultType = splitPath[0].slice(index+1)

   //console.log(pathname,companyName,resultType)
   let newPath = "";
  if (pathname.length >3){
    newPath = `/${pathname[2]}/${resultType}/${companyName}`
  
  }
else{
 newPath = pathname.length >1 ? `/${resultType}/${companyName}`  :`/company/${companyName}`
  
  } 

  return window.location.origin + newPath;
}
  }

  render() {
    const { skipBootstrapGridClasses, entity } = this.props;
    let classes = ["search__listing-wrapper"];
    if (!skipBootstrapGridClasses) {
      classes.push("col-xs-12 col-sm-6 col-md-4");
    }
     

    return (
      <div className={classes.join(" ")}>
        <a
          // to={`/entities/${entity.company_id}`}
          href={this.handleEntityCardClick()}
          
          className="search__listing search__listing--product"
        >
          <div>
            <div className="search__listing-head">
              {entity.product_name}
            </div>
            {entity.technology_type && (
              <div className="search__listing-tech-areas">
                {prepareTechnologyTypeString(entity.technology_type)}
              </div>
            )}
          </div>

          <div className="search__listing-subhead--products">
            <img
              className="search__listing-subhead-img"
              src={companyIcon}
              alt="company icon"
            />{" "}
            {entity.company_name}
          </div>
        </a>
      </div>
    );
  }
} 

const mapDispatchToProps = dispatch => ({
  setFormType: formType => dispatch(setFormType({ formType: formType })),
});

EntityCard2 = connect(null, mapDispatchToProps)(EntityCard2);
export default withRouter(EntityCard2);
