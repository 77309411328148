import React from "react";
import { Link } from 'react-router-dom';

import PieChart2 from "./charts/PieChart2";
import BarChart from "./charts/BarChart";
import { barColors, pieColors } from "../../utils/graphColors";

import EmptyStateIconSvg from '../../inline-svg-components/EmptyStateIconSvg';

// const demoInvestors = {
//   angel: [
//     { name: "Helion Ventures", total_funding: "$54M" },
//     { name: "Accel Partners", total_funding: "$44M" },
//     { name: "Blume Ventures", total_funding: "$36M" },
//     { name: "Nexua Ventures", total_funding: "$18M" },
//     { name: "Tiger VC", total_funding: "$83.4" },
//     { name: "Investus Capital Partners", total_funding: "$14M" },
//   ],
//   venture_capital: [
//     { name: "Nexua Ventures 5", total_funding: "$18M" },
//     { name: "Blume Ventures 4", total_funding: "$36M" },
//     { name: "Helion Ventures 2", total_funding: "$54M" },
//     { name: "Accel Partners 3", total_funding: "$44M" },
//   ],
//   private_equity: [
//     { name: "Tiger VC 11", total_funding: "$83.4" },
//     { name: "Accel Partners 8 ", total_funding: "$44M" },
//     { name: "Blume Ventures 9", total_funding: "$36M" },
//     { name: "Nexua Ventures 10", total_funding: "$18M" },
//     { name: "Helion Ventures 7", total_funding: "$54M" },
//   ],
// };

const generateInvestmentsList = (investmentsList , techarea) => {
 //console.log('investment list = ', techarea);
  if (!investmentsList || !investmentsList.length) return null;
  return investmentsList.map(investment => {
    const key = investment.name + investment.total_funding;

    return (
      <div key={key} className="techarea__investment--single">
        <Link 
          to={`/${techarea.replace(/ /g,"-")}/${investment.name.replace(/ /g,"-")}`}
          className="techarea__investment--single-investor">
          {investment.name}
        </Link>
        <p className="techarea__investment--single-amount">
          ${investment.total_funding} M
        </p>
      </div>
    );
  });
};

const FinancialData = ({
  data,
  techarea,
  topInvestorsView,
  setTopInvestorsView,
  barchartView,
  setBarchartView,
  loginStatus,
}) => {
  const barArrLen = barColors.length;
  const pieArrLen = pieColors.length;

  let topInvestors = null;
  let barChartData = null;
  const topInvestments = generateInvestmentsList(data.top_investments,techarea);

  // if (topInvestorsView === "angel") {
  //   topInvestors = generateInvestmentsList(demoInvestors.angel);
  // } else if (topInvestorsView === "venture") {
  //   topInvestors = generateInvestmentsList(demoInvestors.venture_capital);
  // } else {
  //   topInvestors = generateInvestmentsList(demoInvestors.private_equity);
  // }
  topInvestors = <div>
    <EmptyStateIconSvg />
    <p style={{
      fontSize: '2.4rem',
      fontWeight:'bold',
      textAlign: 'center',
      color: '#81858c'
    }}>Coming Soon</p>
  </div>;

  //  Prepare data for investments by type (Pie Chart)
  const typeInvestments = data.investment_by_type.map((investment, index) => ({
    name: investment.funding_type,
    value: investment.total_amount,
    itemStyle: { color: pieColors[index % pieArrLen] },
  }));

  //  Prepare data for Bar Chart
  if (barchartView === "year") {
    barChartData = data.investments_by_year
      .sort((a, b) => a.year - b.year)
      .map((investment, index) => ({
        name: investment.year,
        value: investment.total_amount,
        itemStyle: { color: barColors[index % barArrLen] },
      }));
  } else {
    barChartData = data.investments_by_location.map((investment, index) => ({
      name: investment.city,
      value: investment.total_amount,
      itemStyle: { color: barColors[index % barArrLen] },
    }));
  }

  let totalFunding = data.total_funding < 1000 ?
    `$ ${data.total_funding.toFixed(2)} million` :
    `$ ${(data.total_funding / 1000).toFixed(2)} billion`;
  
  return (
    
    <div className={loginStatus} >
      <div className="techarea__chart-and-stats-wrapper">
        <div className="techarea_chart-wrapper">
          
          <p className="techarea__financial--investments-head"
          >Investments (in million $)</p>

          <div className="techarea__barchart-view--wrapper">
            <div
              onClick={setBarchartView.bind(null, "year")}
              className={
                barchartView === "year"
                  ? "techarea__barchart-view--selected"
                  : ""
              }
            >Year</div>

            <div
              onClick={setBarchartView.bind(null, "location")}
              className={
                barchartView === "location"
                  ? "techarea__barchart-view--selected"
                  : ""
              }
            >Location</div>
          </div>
          <BarChart data={barChartData} />
        </div>
        <div className="techarea__investment-stats-wrapper">
          <p className="techarea__financial-info-card-head techarea__financial-info-card-head--investments"
          >Top Investments</p>
          {topInvestments}
        </div>
        {/* 
          <div className='techarea__investment-stat-first'>
            <h4 className="techarea__investments-stats--head"
            >Total VC Investments</h4>
            <p className="techarea__investments-stats--content"
            >{totalFunding}</p>
          </div>
          

           <div className="techarea__investments-substats--wrapper">
            <div className="techarea_-investments-substat">
              <h4 className="techarea__investments-stats--head"
              >Investors</h4>
              <p className="techarea__investments-stats--content"
              >{data.total_investors}</p>
            </div>

            <div className="techarea__investments-substat">
              <h4 className="techarea__investments-stats--head"
              >Companies Invested</h4>
              <p className="techarea__investments-stats--content"
              >{data.total_companies_invested}</p>
            </div>

            </div> 
        </div>*/}
      </div>

      <div className="techarea__financial-info-cards-wrapper">
      {/*  {// Chart - Investments by Type}
        <div className="techarea__financial-info-card techarea__financial-info-card--pie-chart">
          <h3 className="techarea__financial-info-card-head techarea__financial-info-card-head--chart"
          >Investments by Type</h3>
          <PieChart2 data={typeInvestments} />
        </div> */}

        {/* Top Investments */}
         

        {/* Top Investors */}
       {/* <div className="techarea__financial-info-card">
          <h3 className="techarea__financial-info-card-head"
          >Top Investors</h3>

          <div className="techarea__financial-info-card--investor-types-wrapper">
            <div
              onClick={setTopInvestorsView.bind(null, "angel")}
              className={
                topInvestorsView === "angel"
                  ? "techarea__financial-info-card--investor-type--selected"
                  : ""
              }
            >Angel</div>

            <div
              onClick={setTopInvestorsView.bind(null, "venture")}
              className={
                topInvestorsView === "venture"
                  ? "techarea__financial-info-card--investor-type--selected"
                  : ""
              }
            >Venture Capital</div>

            <div
              onClick={setTopInvestorsView.bind(null, "private")}
              className={
                topInvestorsView === "private"
                  ? "techarea__financial-info-card--investor-type--selected"
                  : ""
              }
            >Private Equity</div>
          </div>

          {topInvestors}
            </div>*/}
      </div>
    </div>
  );
};

export default FinancialData;