import React from 'react';
//import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import '../css/sessionExpiredModal.css'

const IdleTimeOutModal = ({ showModal, handleClose, handleLogout, remainingTime }) => {

  return (
    <div className={`Idlemodal__wrapper ${showModal ? 'Idlemodal__wrapper--shown' : ''}`}>
      <div className='Idlemodal__background--hightlight' />
      <div className={`Idlemodal ${showModal ? 'Idlemodal--shown' : ''}`}>
        <div className='Idlemodal__header'>
          <p className='Idlemodal__text'>You have been Idle !</p>
        </div>
        <hr className='Idlemodal__underline' />
        <div className='Idlemodal__body'>
          <p className='Idlemodal__content'>You will Get Logged Out. Do You Want To Stay ?</p>
          <hr className='Idlemodal__underline' />

          <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'flex-end' }}>
            <div style={{
              width: 84, height: 40, backgroundColor: '#d82123', marginTop: 10, border: '1px solid #D9d9d9',
              borderRadius: 8
            }}>
              <button
                type='button' className='Idlemodal__btn' onClick={handleClose}>
                Stay</button>
            </div>
            <div style={{
              width: 84, height: 40, backgroundColor: '#00acee', marginTop: 10, border: '1px solid #D9d9d9',
              borderRadius: 8, marginLeft: '1rem'
            }}>
              <button
                type='button' className='Idlemodal__btn' onClick={handleLogout}>
                Logout</button>
            </div>
          </div>

        </div>
      </div>
    </div>




  )
}

export default IdleTimeOutModal