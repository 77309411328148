 
import { reduxForm } from "redux-form";

import FormButton from "../form-components/FormButton";
import linkSentBg from "../../img/linksent-bg.svg";
import { connect } from "react-redux";
import React, { useState } from "react";
let LinkSent = props => {
    const { handleSubmit } = props;
    // console.log(props.enteredEmail);
 
    const [email, setEmail] = useState(props.enteredEmail);
 
    return (
        <div className="user-form">
            <div className="user-form__left">
                <div className="user-form__left--text-wrapper">
                    <div className="close-modal--mobileOnly">
                        <i
                            className="fa fa-caret-left"
                            onClick={props.toggle}
                            aria-hidden="true"
                        />
                    </div>
                    <div className="user-form__left-head">Forgot Password</div>
                    <div className="user-form__left-desc">
                        No worries!
                        <br />
                        Happens to all of us!
                    </div>
                </div>
                <div className="user-form__left-img-wrapper">
                    <img
                        className="user-form__left-img"
                        src={linkSentBg}
                        alt="Lock background"
                    />
                </div>
            </div>

            <div className="user-form__right">
                <div className="user-form-right-linkSent-head">
                    Password reset link sent on
                </div>
                <div className="user-form-right-linkSent-email">
                    {email}
                </div>

                <form onSubmit={handleSubmit(() => props.changeType("login"))}>
                    <FormButton label="BACK TO LOGIN" />
                </form>
            </div>
        </div>
    );
};

LinkSent = reduxForm({
    form: "forgot_password",
})(LinkSent);

const mapStateToProps = state => {
    
    if("values" in state.form.forgot_password){
    return {
        enteredEmail: state.form["forgot_password"].values.email,
    };}
};

export default connect(mapStateToProps)(LinkSent);
