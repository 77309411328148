import { SET_FORM_TYPE } from "../actions/_types";

const initialState = {
    formType: null,
};

const userFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FORM_TYPE:
            return {
                ...state,
                formType: action.formType,
            };
        default:
            return state;
    }
};

export default userFormReducer;
