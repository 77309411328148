import React from "react";
import { Field, reduxForm } from "redux-form";

import FormInputField from "../form-components/FormInputField";
import FormButton from "../form-components/FormButton";
import FormSelect from "../form-components/FormSelect";
//import validate from "./signup-validate";
import Input from '../ui/paymentFormFields/Input';
import validate from "../user-form-components/signup-validate";
import PropTypes from "prop-types";
import "../../css/UserForm.css";
import "../../css/Form.css";
import "../../css/becomeContributor.css";
import api from '../../api';
import ImageField from "../form-components/ImageInputField"
import { required, email } from 'redux-form-validators'

class ContributorPart1 extends React.Component {

  render() {
    const { handleSubmit, contactNumber, formLoader } = this.props;

    return (
      <div className="become_contributor">
        <p className="user-form__progress">Step 1 of 3</p>
        <div className="user-form__right-head user-form__right-head--signup"
        >Personal Details</div>

        <form onSubmit={handleSubmit}>
       
            <div className="user__form-container">
              <div className="row user__form-row">
                <Field
                   name="full_name"
                   widthClass="col-md-6"
                   component={FormInputField}
                   type="text"
                   placeholder="Full Name"
                   tabable={true}
                 // validate={required()}
                />
                <Field
                   name="email"
                   component={FormInputField}
                   type="text"
                   widthClass="col-md-6"
                   placeholder="Email Address"
                   tabable={true}
                />

              </div>
              <div className="row user__form-row"> 
              <Field
                component={FormInputField}
                name="designation"
                widthClass="col-md-6"
                placeholder="Designation"
                tabable={true}
                type="text"
              />
              <Field
                component={FormInputField}
                name="organization"
                widthClass="col-md-6"
                placeholder="Organization"
                tabable={true}
                type="text"             
              />
              </div>
              <div className="row user__form-row"> 
              <Field
                component={FormInputField}
                name="contributor_number"
                type="text"
                widthClass="col-md-12"
                placeholder="Contact Number"
                tabable={true}
                
              />
              </div>
              <p className="text_profile">Profile Picture</p>
              
                <Field
                  name="picture"
                  
                  component={ImageField}
                  type="file"
                   
                />
               
              <div>
            
              </div>
            </div>
     
          <div className="contributor__button">
            <FormButton label="CONTINUE" />

          </div>
        </form>
      </div>
    );
  }
}




export default reduxForm({
  form: "contributor", // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate: validate,

  asyncBlurFields: ['email']
})(ContributorPart1);

