import React from 'react';
import { reduxForm, Field } from 'redux-form';

import FormInputField from '../feature_yourself-components/form-elements/FormInputField';

import infoIcon from '../../img/info-hover.svg';

const ClaimEntity = ({
  selectedType,
  claimingEntity,
  claimEntity,
  handleSubmit,
  onSubmit
}) => {
  let title = 'Company';
  if (selectedType === 'academia') title = 'Institute';
  else if (selectedType === 'individual') title = 'Researcher';
  else if (selectedType === 'r_and_d') title = 'R&D Centre';

  return (
    <div className="feature_form__wrapper">
      <p className="feature__form__subhead"
      >Step 3 of 3</p>

      <p className="feature_form__head"
      >Confirm</p>

      <div className="feature_form__inner-wrapper">
        <p className='feature_form__claiming-label'>{title} Name</p>
        <p className='feature_form__claiming-entity-name'>{claimingEntity.name}</p>
      </div>

      <p className='feature_form__claiming-entity-name'>Please provide your details</p>

      <form onSubmit={handleSubmit(claimEntity)}>
        <Field
          name="claimer__firstName"
          // widthClass="col-md-12"
          component={FormInputField}
          type="text"
          placeholder="Eg. John"
          label="First Name"
          tabable={true}
        />

        <Field
          name="claimer__lastName"
          // widthClass="col-md-12"
          component={FormInputField}
          type="text"
          placeholder="Eg. Snow"
          label="Last Name"
          tabable={true}
        />

        <Field
          name="claimer__designation"
          // widthClass="col-md-12"
          component={FormInputField}
          type="text"
          placeholder="Eg. CEO"
          label="Designation"
          tabable={true}
        />

        <Field
          name="claimer__contactNo"
          // widthClass="col-md-12"
          component={FormInputField}
          type="text"
          placeholder="Eg. 9877772615"
          label="Contact Number"
          tabable={true}
        />

        <div className="feature_form__btn-wrapper">
          <button
            style={{ marginTop: '2rem' }}
            type="submit"
            className="feature_form__btn feature_form__confirm-claim-btn"
          >Confirm and Claim</button>
        </div>

      </form>


      <div className='feature-form__claim-linking-info-wrapper'>
        <img src={infoIcon} alt='Info' className='feature-form__claim-linking-info-icon' />
        <p className='feature-form__claim-linking-info-text'
        >{title} details will be linked to your account post verification</p>
      </div>

    </div>
  );
}

export default reduxForm({
  form: 'feature_yourself',
})(ClaimEntity);