import Empty from "../../img/empty_view.svg"
import { withRouter, Redirect } from 'react-router-dom';

import "../../css/Message.css"
import React, { Component } from 'react';
import '../../css/Notifications.css'

import api from "../../api";
import { getFormattedDateTime } from "../helper-functions/helper"
class Notifications extends Component {

  state = {
    notificationData: []
  }


  componentDidMount() {
    this.getNotifications();
  }

  getNotifications() {
    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // TCRAUTH: this.props.user.token
      }
    };
    const requestUrl = api.NotificationData;

    fetch(requestUrl, requestConfig).then(response => {
      if (!response || !response.ok) throw new Error('Status code: ' + response.status);
      return response.json();
    }).then(response => {
      // console.log('Notifications count response = ', response);
      localStorage.setItem('count', 0);
      this.setState({ notificationData: response.data });    // Store notification count in state
    }).catch(err => {
      // console.log('Error fetching notifications count:', err);
    });

  }





  render() {
    document.title = "Notification - Technology Capability Repository || DSCI";
    const { userInfo } = this.props;
    const redirect = !userInfo || !userInfo.token ?
      <Redirect to='/' /> : null;
    return (
      <div>
        <div className="entityview__top">
          <div className="entityview__top-desc-wrapper">
            <p className="message-heading">Notifications</p>
          </div>
        </div>
        <div>
          {/*   <img className="img-message" src={Empty} alt="empty image"></img>
              <p className='heading'>You don't have any notifications yet</p>*/}

              {this.state.notificationData && this.state.notificationData.length >0 ?
          <div className="notifications">
            {this.state.notificationData && this.state.notificationData.map(
              (obj, index) => (
                <>
                  <div
                    className={obj.seen === true ? "notifications__desc-card" : "notifications__desc-card-seen"}
                  >
                    <div className="notifications__desc-card-top">

                      {/*  <img src={obj.file_url}  className="notification_image"/>*/}
                      <div className="notifications__desc-card-left">

                        <div className="notifications__desc-card-head">{obj.title}</div>
                        {obj.created_at && <p className='techarea__desc-card-date'>{getFormattedDateTime(obj.created_at)}</p>}
                        <div>


                        </div>
                      </div></div>
                  </div>
                </>
              )
            )
            }
          </div>: 
          <>
          <img className="img-message" src={Empty} alt="empty image"></img>
              <p className='heading'>You don't have any notifications yet</p>
              </>}

        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  userInfo: state.auth.userInfo
});

export default withRouter(Notifications);