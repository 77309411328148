import React, { Component } from 'react';
import "../../css/BrowsingTech.css";

import '../../css/Followers.css';
import Avatar from '../../img/profile.png'

class Followers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dateTime: "",
        }
    }
    render() {

        return (
            <>
                {this.props.followers && this.props.followers.length > 0 ?
                    <div className='follower'>
                        <div className="follower__desc-card">
                            {this.props.followers &&
                                this.props.followers.map(
                                    (obj, index) => (

                                        <div className="follower__desc-card-top">

                                            <div className="follower__desc-card-left">
                                                <div className='followers-card-header'>
                                                    <img src={Avatar} className='followers-avatar' alt="avatar"/>
                                                    <div>
                                                        <div className='followers-content'>
                                                            <p className='followers-title'>{obj.full_name}</p>
                                                        </div>
                                                        <p className="follower-text"> {obj.organization}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <button className='followers__remove'>Remove</button>
                                            </div>
                                        </div>

                                    )
                                )}</div>
                    </div> : <><p className='no_followers'>No Information available</p></>}
            </>
        );
    }
}

export default Followers;