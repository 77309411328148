import React, { Component } from 'react';
import "../css/BrowsingTech.css";
import { httpfyUrl } from "../../src/utils/helpers";
import '../css/Cards.css';
import { getFormattedDateTime } from '../components/helper-functions/helper'

class PolicyUpdates extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dateTime: "",

        }

    }

    render() {
       // document.getElementsByTagName("LINK")[0].href="https://www.techsagar.in/resources"
//console.log(this.props.data)
        return (

            <div className='techarea_policy'>
                <p className='techarea_heading'>POLICY UPDATES({this.props.count})</p>
                {this.props.data && this.props.data.length > 0 ?
                    <div>
                        {this.props.data &&
                            this.props.data.map(
                                (obj, index) => (
                                    <div
                                        className="techarea__desc-card"

                                    >
                                         
                                        <div className="techarea__desc-card-top">
                                        {obj.file_url.length >0 && obj.source.length>0 ?
                                                <div>
                                                    <a
                                                        href={httpfyUrl(obj.file_url)}
                                                        className="techarea__desc-card-link"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <i className="fas fa-file-pdf" style={{ color: "#F40F02", fontSize: "2rem" }} />
                                                    </a>
                                                </div>
                                                :<></>}
                                            <div className="techarea__desc-card-left">
                                                <div className="techarea__desc-card-head">{obj.title}</div>
                                                {obj.publish_date &&
                                                    <p className='techarea__desc-card-date'>{getFormattedDateTime(obj.publish_date)}</p>}
                                            </div>
                                            {obj.file_url && obj.source.length===0 ?
                                                <div>
                                                    <a
                                                        href={httpfyUrl(obj.file_url)}
                                                        className="techarea__desc-card-link"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <i className="fas fa-file-pdf" style={{ color: "#F40F02", fontSize: "2rem" }} />
                                                    </a>
                                                </div>
                                                :<></>}
                                             {obj.file_url.length === 0 && obj.source.length>0 ?
                                                <div>
                                                    <a
                                                        href={httpfyUrl(obj.source)}
                                                        className="techarea__desc-card-link"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <i className="fas fa-link"/>
                                                    </a>
                                                </div>
                                                :<></>}

                                             {obj.file_url.length > 0 && obj.source.length>0 ?
                                                <div>
                                                    <a
                                                        href={httpfyUrl(obj.source)}
                                                        className="techarea__desc-card-link"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <i className="fas fa-link"/>
                                                    </a>
                                                </div>
                                                :<></>}
                                            


                                        </div>

                                    </div>
                                )
                            )}
                    </div> : <div
                        className="techarea__desc-card"

                    >
                        <div className='techarea__card'>
                            <div>
                                <p className='techarea__no_data'>
                                    The Policy Updates Information is coming soon!
                                </p>
                                <p>
                                    Once available, you can view the
                                    information here.
                                    <br />
                                    Stay Tuned!
                                </p>
                            </div>
                        </div>
                    </div>}
                {this.props.count > 10 && this.props.pageCount != this.props.totalPage ?
                    <div>
                        <button className='button-spotlight' onClick={this.props.onChangeHandler}>SEE MORE</button>
                    </div> : ''}
            </div>


        );
    }
}

export default PolicyUpdates;