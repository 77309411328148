export const barColors = [
  "#4c96ff",
  "#81b5ff",
  "#a5caff",
  "#7990ba",
  "#2f4b7c",
  "#203456",
];

// export const barColors = [
//   "#164891",
//   "#F1788D",
//   "#4D96FF",
//   "#FFC77D",
//   "#FFAF8C",
//   "#FFE479",
//   "#B6F098",
//   "#9AFEE4",
//   "#44B0FF",
//   "#65ECE6",
//   "#4FD4F3",
// ];

export const pieColors = [
  "#164891",
  "#F1788D",
  "#4D96FF",
  "#FFC77D",
  "#FFAF8C",
  "#FFE479",
  "#B6F098",
  "#9AFEE4",
  "#44B0FF",
  "#65ECE6",
  "#4FD4F3",
];


