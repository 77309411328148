import React from 'react';
import { reduxForm, Field } from 'redux-form';

import Input from '../ui/paymentFormFields/Input';
import validate from '../../utils/validatePasswordForm';

const ChangePasswordForm = props => {
  const handleOnKeyPress = event => {
      if (event.key === "Enter") event.preventDefault();
    };

  const { invalid, submitting, pristine } = props;

  return (
    <div>
      <form 
        onSubmit={props.handleSubmit}
        className='edit-profile-form'
        onKeyPress={handleOnKeyPress}
        initialValues={props.initialValues}
      >

        <Field
          component={Input}
          name="currentPassword"
          label="Current Password"
          placeholder="Current Password"
          type='password'
          labelClass="payment__field-label"
          inputClass="payment__field-input-change-password"
          wrapperClass="payment__field-wrapper edit-profile-field-wrapper--name"
          errorClass="payment__field-error"
          myRef={props.myRef}
        />

        <div className="flex-inputs-wrapper-change-password">
          <Field
            component={Input}
            name="newPassword"
            label="New Password"
            type='password'
            placeholder="New Password"
            labelClass="payment__field-label"
            inputClass="payment__field-input-change-password"
            wrapperClass="payment__field-wrapper edit-profile-field-wrapper--name"
            errorClass="payment__field-error"
          />

          <Field
            component={Input}
            name="confirmPassword"
            label="Confirm New Password"
            type='password'
            placeholder="Confirm New Password"
            labelClass="payment__field-label"
            inputClass="payment__field-input-change-password"
            wrapperClass="payment__field-wrapper edit-profile-field-wrapper--name"
            errorClass="payment__field-error"
          />
        </div>

        <button 
          type="submit"
          disabled={invalid || pristine || submitting}
          className='edit-profile-form__submit-btn'
          style={{zIndex: "0"}}
        >SAVE CHANGES</button>
      </form>
    </div>
  )
}

export default reduxForm({
  form: 'change_password',
  validate: validate,
})(ChangePasswordForm);
