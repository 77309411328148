import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import FormInputField from "../form-components/FormInputField";
import FormButton from "../form-components/FormButton";
import Captcha from '../form-components/Captcha';

import api from "../../api";

import forgotPasswordBg from "../../img/forgotpassword-bg.svg";

//  Form validation
const validate = values => {
    const errors = {};
    const reqError = "This field is required";
    const emailError = "Enter a valid email";
    /* eslint-disable no-useless-escape */
    const emailRegex = /^([a-z A-Z 0-9 _\.\-])+\@(([a-z A-Z 0-9\-])+\.)+([a-z A-z 0-9]{2,})$/;

    //  Email validation
    if (!values.email) {
        errors.email = reqError;
    } else if (!values.email.match(emailRegex)) {
        errors.email = emailError;
    }

    if (!values.recaptcha) {
        errors.recaptcha = reqError;
    }

    //  Return the errors object
    return errors;
};

class ForgotPassword extends Component {
    submitForm = formValues => {
       // console.log(JSON.stringify({ ...formValues }));
        fetch(api.forgot_password, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ ...formValues, user_type: "CONSUMER" }),
        })
            .then(response => {
                // console.log(response);
               // window.grecaptcha.reset(0);
            })
            .catch(err => {
                // console.log("Error occurred: " + err);
            });
        this.props.changeType("linkSent");
        //console.log(this.props.changeType("linkSent"))
    };

    render() {
        //  Redux form props
        const { handleSubmit, changeType } = this.props;

        return (
            <div className="user-form">
                <div className="user-form__left">
                    <div className="user-form__left--text-wrapper">
                        <div className="close-modal--mobileOnly">
                            <i
                                className="fa fa-caret-left"
                                onClick={this.props.toggle}
                                aria-hidden="true"
                            />
                        </div>
                        <div className="user-form__left-head">
                            Forgot Password
                        </div>
                        <div className="user-form__left-desc">
                            No worries!
                            <br />
                            Happens to all of us!
                        </div>
                    </div>
                    <div className="user-form__left-img-wrapper">
                        <img
                            className="user-form__left-img"
                            src={forgotPasswordBg}
                            alt="Lock background"
                        />
                    </div>
                </div>

                <div className="user-form__right">
                    <div className="user-form-right-forgotpassword-head">
                        Enter your registered email and we’ll send a link to
                        reset your password right away!
                    </div>
                    <form onSubmit={handleSubmit(this.submitForm)}>
                        <div className="user__form-container">
                            <div className="row user__form-row">
                                <Field
                                    name="email"
                                    component={FormInputField}
                                    type="text"
                                    placeholder="Email"
                                    tabable={true}
                                />
                            </div>

                            <div className="row user__form-row">
                                <div className="login__form-captcha">
                                    <Field
                                        name="recaptcha"
                                        component={Captcha}
                                        errorMargin={"45px"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form__reset-password-btn-and-login--wrapper">
                            <FormButton label="RESET PASSWORD" />
                            {this.props.userInfo ? null : <p
                                className="form__login-span"
                                onClick={() => changeType("login")}
                            >
                                Back to Login
                            </p>}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

ForgotPassword = reduxForm({
    form: "forgot_password",
    destroyOnUnmount: false,
    validate,
})(ForgotPassword);

export default connect(null)(ForgotPassword);
