import React, { Fragment } from "react";

import Technology from "./Technology";
import OfferingSection from "./OfferingsSection";

import EntityFiltersDrawer from "../ui/EntityFiltersDrawer/EntityFiltersDrawer";

import filterIcon from "../../img/filter.svg";

import EmptyStateIconSvg from '../../inline-svg-components/EmptyStateIconSvg';

import {
  handleAreaFilterChange,
  handleCapabilityFilterChange,
} from "./filterHelper";

class OfferingsTab extends React.Component {
  state = {
    showEntityFiltersDrawer: false,
    expandedItem: "",
    filters: {
      techArea: [],
      capability: [],
    },
    showAllProducts: false,
    showAllServices: false,
    showAllSolutions: false,
  };

  toggleEntityFiltersDrawer = () =>
    this.setState(prevState => ({
      showEntityFiltersDrawer: !prevState.showEntityFiltersDrawer,
    }));

    componentDidMount() {
      // console.log(this.props.websiteUrl)
    }

  //  Sets the expanded item to view editor's comments
  setExpandedItem = item => this.setState({ expandedItem: item.name });

  //  Resets all techarea and capability filters by setting state
  resetAllFilters = () =>
    this.setState({
      filters: {
        techArea: [],
        capability: [],
      },
    });

  toggleShowAllOfferings = type =>
    this.setState(prevState => {
      return {
        showAllProducts:
          type === "products"
            ? !prevState.showAllProducts
            : prevState.showAllProducts,

        showAllServices:
          type === "services"
            ? !prevState.showAllServices
            : prevState.showAllServices,

        showAllSolutions:
          type === "solutions"
            ? !prevState.showAllSolutions
            : prevState.showAllSolutions,
      };
    });

  //*  Handle check/uncheck of technology filters
  handleFilterCheckboxInputChange = event => {
    const target = event.target;

    const name = target.name;
    const checked = target.checked;
    const filterType = target.getAttribute("data-filtertype");
    const selectedArea = target.getAttribute("data-techarea");

    this.setState(prevState => {
      const updatedFilters =
        filterType === "techArea"
          ? handleAreaFilterChange(
            name,
            checked,
            prevState.filters,
            this.props.technologyAreas
          )
          : handleCapabilityFilterChange(
            name,
            checked,
            selectedArea,
            prevState.filters,
            this.props.technologyAreas
          );

      return {
        filters: {
          techArea: updatedFilters.techArea,
          capability: updatedFilters.capability,
        },
      };
    });
  };

  filterByArea = items => {
    //  For invalid items, return empty array
    if (!items || items.length < 1) return [];
    if (!("technology_type" in items[0])) return [];
    const areaFilters = this.state.filters.techArea;

    //  For no filter selected, return all items
    if (areaFilters.length === 0) return items;

    //  For a techarea filter selected, filter the items and return them
    return items.filter(item => {
      if (!item.technology_type) return false;

      return item.technology_type.some(areaObj => {
        return areaFilters.includes(areaObj.name);
      });
    });
  }

  filterByCapability = items => {
    //  For invalid items, return empty array
    if (!items || items.length < 1) return [];
    if (!("technology_type" in items[0])) return [];

    //  For no capability filter selected, return all items
    const capFilters = this.state.filters.capability;
    if (capFilters.length === 0) return items;

    //  For >=1 capability filters selected, return filtered items
    return items.filter(item => {
      if (!item.technology_type) return false;
      return item.technology_type.some(areaObj => {
        return areaObj.tech_capability.some(capability => {
          return capFilters.includes(capability);
        });
      })
    }
    );
  };

  render() {
    let contentToDisplay = null;

    //  Filter products based on filters, slice if needed
    const filteredProducts = this.filterByCapability(
      this.filterByArea(this.props.products)
    );
    const filteredServices = this.filterByCapability(
      this.filterByArea(this.props.services)
    );
    const filteredSolutions = this.filterByCapability(
      this.filterByArea(this.props.solutions)
    );

    const products = this.state.showAllProducts
      ? filteredProducts
      : filteredProducts.slice(0, Math.min(filteredProducts.length, 3));
    const services = this.state.showAllServices
      ? filteredServices
      : filteredServices.slice(0, Math.min(filteredServices.length, 3));
    const solutions = this.state.showAllSolutions
      ? filteredSolutions
      : filteredSolutions.slice(0, Math.min(filteredSolutions.length, 3));

    let title1 = 'Product';
    let title2 = 'Service';
    if (this.props.isRnD) {
      title1 = 'Project';
      title2 = 'Workshop';
    }

    const productsSection =
      products && products.length ? (
        <OfferingSection
          title={title1}
          items={products}
          filteredCount={filteredProducts.length}
          expandedItem={this.state.expandedItem}
          setExpandedItem={this.setExpandedItem}
          toggleShowAllOfferings={this.toggleShowAllOfferings.bind(
            null,
            "products"
          )}
          showingAll={this.state.showAllProducts}
          websiteUrl={this.props.websiteUrl}
        />
      ) : null;

    const servicesSection =
      services && services.length ? (
        <OfferingSection
          title={title2}
          items={services}
          filteredCount={filteredServices.length}
          expandedItem={this.state.expandedItem}
          setExpandedItem={this.setExpandedItem}
          toggleShowAllOfferings={this.toggleShowAllOfferings.bind(
            null,
            "services"
          )}
          showingAll={this.state.showAllServices}
          websiteUrl={this.props.websiteUrl}
        />
      ) : null;

    const solutionsSection =
      solutions && solutions.length ? (
        <OfferingSection
          title="Solution"
          items={solutions}
          filteredCount={filteredSolutions.length}
          expandedItem={this.state.expandedItem}
          setExpandedItem={this.setExpandedItem}
          toggleShowAllOfferings={this.toggleShowAllOfferings.bind(
            null,
            "solutions"
          )}
          showingAll={this.state.showAllSolutions}
          websiteUrl={this.props.websiteUrl}
        />
      ) : null;

    //  If at least one of the sections has content to be displayed, display them
    if (productsSection || servicesSection || solutionsSection) {
      contentToDisplay = (
        <Fragment>
          <div
            onClick={this.toggleEntityFiltersDrawer}
            className="entity-filterToggle-mobileOnly"
          >
            <p className="entity-filterToggle-mobileOnly-text">
              Filters
            </p>
            <img src={filterIcon} alt="Filters" />
          </div>
          {productsSection}
          {servicesSection}
          {solutionsSection}
        </Fragment>
      );
    }

    //  If there are no offerings, display an empty state banner
    else {
      contentToDisplay = (
        <div className="entityview__no-items-wrapper">
          <EmptyStateIconSvg className='emptyIcon' />
          <p className="entityview__no-items-head"
          >No {this.props.title} Found!</p>

          <p className="entityview__no-items-desc">
            It seems that no {this.props.title} are available to
            display. <br /> Once available, they will appear here.
          </p>
        </div>
      );
    }

    return (
      <div className="entityview__desc-wrapper">
        <EntityFiltersDrawer
          show={this.state.showEntityFiltersDrawer}
          toggleDrawer={this.toggleEntityFiltersDrawer}
          searchQuery={this.props.searchQuery}
          technologyAreas={this.props.technologyAreas}
          techArea={this.state.filters.techArea}
          capability={this.state.filters.capability}
          disableFilters={false}
          resetAllFilters={this.resetAllFilters}
          handleFilterCheckboxInputChange={
            this.handleFilterCheckboxInputChange
          }
        />
        <div className="Technolofy-wrapper-Container" style={{ display: "flex", flexDirection: 'row', width: "100%" }}>
          <Technology
            technologyAreas={this.props.technologyAreas}
            techArea={this.state.filters.techArea}
            resetAllFilters={this.resetAllFilters}
            capability={this.state.filters.capability}
            handleFilterCheckboxInputChange={
              this.handleFilterCheckboxInputChange
            }
          />

          <div className="entityview__desc-right entityview__desc-right-width">{contentToDisplay}</div>
        </div>
      </div>
    );
  }
}

export default OfferingsTab;
