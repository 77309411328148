import React from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from "react-accessible-accordion";

import "./SearchFiltersDrawer.css";
import "react-accessible-accordion/dist/fancy-example.css";

const accordionStyles = {
    allowZeroExpanded: true,
    // allowMultipleExpanded: true,
};

 

const SearchFiltersDrawer = props => {
     
    const sideDrawerClasses = ["search-results--side-drawer"];
    if (props.show) {
        sideDrawerClasses.push("search-results--side-drawer--open");
        document.querySelector("body").style.overflow = "hidden";
        document.querySelector("html").style.overflow = "hidden";
    } else {
        sideDrawerClasses.push("search-results--side-drawer--close");
        document.querySelector("body").style.overflow = "auto";
        document.querySelector("html").style.overflow = "auto";
    }

    const techAreaFilters = props.techAreas.map((techArea, index) => (
        <label className="search-filters--side-drawer--filter-item" key={index}>
            <input
                type="checkbox"
                name={techArea}
                className="search__filters-input"
                data-filtertype="technology_type"
                checked={props.prop_techAreas.includes(techArea)}
                onChange={props.handleFilterCheckboxInputChange}
            />
            {techArea}
            <span className="customCheckbox" />
        </label>
    ));

    const companyTypeFilters = props.companyTypes.map((companyType, index) => (
        <label className="search-filters--side-drawer--filter-item" key={index}>
            <input
                type="checkbox"
                className="search__filters-input"
                name={companyType}
                data-filtertype="type"
                checked={props.prop_companyTypes.includes(companyType)}
                onChange={props.handleFilterCheckboxInputChange}
            />
            {companyType}
            <span className="customCheckbox" />
        </label>
    ));

    const companySizeFilters = props.companySizes.map((size, index) => (
        <label className="search-filters--side-drawer--filter-item" key={index}>
            <input
                type="checkbox"
                className="search__filters-input"
                name={size}
                data-filtertype="size"
                checked={props.prop_companySizes.includes(size)}
                onChange={props.handleFilterCheckboxInputChange}
            />
            {size}
            <span className="customCheckbox" />
        </label>
    ));

{/*    const geographiesServedFilters = props.geographiesServed.map(
        (geography, index) => (
            <label
                className="search-filters--side-drawer--filter-item"
                key={index}
            >
                <input
                    type="checkbox"
                    className="search__filters-input"
                    name={geography}
                    data-filtertype="geographies_served"
                    checked={props.prop_geographiesServed.includes(geography)}
                    onChange={props.handleFilterCheckboxInputChange}
                />
                {geography}
                <span className="customCheckbox" />
            </label>
        )
    );

    const sectorsServedFilters = props.sectorsServed.map((sector, index) => (
        <label className="search-filters--side-drawer--filter-item" key={index}>
            <input
                type="checkbox"
                className="search__filters-input"
                name={sector}
                data-filtertype="sectors_served"
                checked={props.prop_sectorsServed.includes(sector)}
                onChange={props.handleFilterCheckboxInputChange}
            />
            {sector}
            <span className="customCheckbox" />
        </label>
    ));*/}

    const companyCategoryFilters = props.companyCategories.map(
        (companyCategory, index) => (
            <label
                className="search-filters--side-drawer--filter-item"
                key={index}
            >
                <input
                    type="checkbox"
                    className="search__filters-input"
                    name={companyCategory}
                    data-filtertype="company_category"
                    checked={props.prop_companyCategories.includes(
                        companyCategory
                    )}
                    onChange={props.handleFilterCheckboxInputChange}
                />
                {companyCategory}
                <span className="customCheckbox" />
            </label>
        )
    );
    const RecentlyAdded = [
        "Oldest to Newest",
        "Newest to Oldest"
      ]

      const RecentlyAddedComponent = RecentlyAdded.map(
        (companyCategory, index) => (
            <label
                className="search-filters--side-drawer--filter-item"
                key={index}
            >
                <input
                    type="checkbox"
                    className="search__filters-input"
                    name={companyCategory}
                    data-filtertype="recently_added"
                    
                    onChange={props.handleFilterCheckboxInputChange}
                />
                {companyCategory}
                <span className="customCheckbox" />
            </label>
        )
    );
    return (
        <div className={sideDrawerClasses.join(" ")}>
            <div className="search-filters--side-drawer-header">
                <div>
                    <i
                        className="fa fa-caret-left search-filters--side-drawer-header-backIcon"
                        onClick={props.toggleDrawer}
                        aria-hidden="true"
                    />
                    <span className="search-filters--side-drawer-header-text">
                        Filters
                    </span>
                </div>
                <div
                    onClick={props.resetDrawerFilters}
                    className="search-filters--side-drawer-header-clearFilters-button"
                >
                    Clear Filters
                </div>
            </div>

            <Accordion {...accordionStyles}>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>AREA</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>{techAreaFilters}</AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>TYPE</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {companyTypeFilters}
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>SIZE</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {companySizeFilters}
                    </AccordionItemPanel>
                </AccordionItem>

                {/*<AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            GEOGRAPHIES SERVED
                        </AccordionItemButton>
                    </AccordionItemHeading>
                     <AccordionItemPanel>
                        {geographiesServedFilters}
    </AccordionItemPanel> 
                </AccordionItem>*/}

               {/* <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            SECTORS SERVED
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {sectorsServedFilters}
                    </AccordionItemPanel>
            </AccordionItem>*/}
            {props.resultType!=="tech_area" &&(
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>Order</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {RecentlyAddedComponent}
                    </AccordionItemPanel>
                </AccordionItem>)}
                {props.showCompanyTypeFilters && (
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                COMPANY CATEGORY
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            {companyCategoryFilters}
                        </AccordionItemPanel>
                    </AccordionItem>
                )}
            </Accordion>

            <div
                className="search-filters--side-drawer--apply-button"
                onClick={props.applyDrawerFilters}
            >
                APPLY FILTERS
            </div>
        </div>
    );
};

export default SearchFiltersDrawer;
