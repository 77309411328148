import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import Contact from "./Contact";
import { titleCase } from "../../utils/helpers";
import linkedInIcon from "../../img/linkedin2.svg";
import EmptyStateIconSvg from '../../inline-svg-components/EmptyStateIconSvg';
import { httpfyUrl } from "../../utils/helpers";
import websiteImg from "../../img/website-dark.svg";
import prepareTechnologyTypeString from "../Researchers/techTypeUtils";
class About extends React.Component {

  //* Returns the mapped array of JSX elements containing all key persons
  renderKeyPersons = () => {
    const { businessHeads, technologyHeads } = this.props;
    let keyPersons = null;

    //  Keypersons are business heads and technology heads
    //  If both of these are unavailable, keyPersonsSection is not displayed
    if (businessHeads && businessHeads.length && technologyHeads && technologyHeads.length) {
      keyPersons = businessHeads.concat(technologyHeads);
    }
    else if (businessHeads && businessHeads.length) keyPersons = businessHeads;
    else if (technologyHeads && technologyHeads.length) keyPersons = technologyHeads;
    else return null;


    const mappedKeyPersons = keyPersons.map(person => {
      // console.log(keyPersons)
      var nameSlice = person.name
      var nameIndex = nameSlice.indexOf(",")
      var personName = nameSlice.slice(0, nameIndex)
      var personPosition = nameSlice.slice(nameIndex + 2)
      // console.log(personName)
      // console.log(personPosition)
      //  Create the LinkedIn icon if user's linkedin profile URL is available
      let linkedIn = null;

      if (person.linkedin) linkedIn = (
        <div className="entityview__about-section__business-head--link">
          <a href={person.linkedin} target="_blank" rel="noopener noreferrer">
            <img src={linkedInIcon} alt="LinkedIn logo" />
          </a>
        </div>
      );

      //  Return the constructed item for one key person
      return (
        <div key={person.name} className="entityview__about-section__business-head">
          <div className="about-keyperson">
            <p className="about-keyperson-name">{titleCase(personName)}</p>
            <p className="about-keyperson-position">{personPosition}</p>
          </div>
          {linkedIn}
        </div>
      );
    });

    //  Return the required JSX
    return (
      <div className="entityview__about-section">
        <p className="entityview__about-head">Key Persons</p>
        <div className="entityview__business-heads--wrapper">
          {mappedKeyPersons}
        </div>
      </div>
    );
  }


  //* Returns the required JSX for the stats in about section
  renderStats = () => {
    if (!this.props.showStats) return null;

    let { papersPublished, totalCitations, averageCitations } = this.props;
    if (!papersPublished) papersPublished = 'NA';
    if (!totalCitations) totalCitations = 'NA';
    if (!averageCitations) averageCitations = 'NA';

    return (
      <div className="container-fluid">
        <div className="row about-stats-wrapper">

          {papersPublished != "NA" &&
            <div className="col-md-4" style={{ borderRight: "1px solid#edeef2" }}>
              <p className="about-stats--title"
              >Published Papers</p>
              <p className="about-stats--stat">{papersPublished}</p>
            </div>}

          {totalCitations != "NA" &&
            <div className="col-md-4" style={{ borderRight: "1px solid#edeef2" }}>
              <p className="about-stats--title"
              >Total Citations</p>
              <p className="about-stats--stat">{totalCitations}</p>
            </div>}
          {averageCitations != "NA" &&
            <div className="col-md-4">
              <p className="about-stats--title"
              >Average Citations</p>
              <p className="about-stats--stat">{averageCitations}</p>
            </div>
          }

        </div>
      </div>
    );
  }


  //* Returns the required JSX for the sectors served section

  //* Returns the required JSX for the geographies served section

  recognitions = (listName) => {
    //console.log(listName)
    return (
      <>
        {listName && listName.length > 0 ? (
          listName.map((item, index) => (
            <div
              key={index}
              className="entityview__desc-card entityview__papers-card"
            >
              <div className="entityview__desc-card-left">
                <div className="entityview__desc-card-head">
                  {item.content}
                </div>
                <div className="entityview__desc-card-detail">
                  {item.year ? `Published in ${item.year}` : ''}
                </div>
              </div>
            </div>
          ))
        ) :
          <div>
          </div>
        }
      </>
    )
  }
  patentsNews = (listName) => {
    return (
      <>

        {listName && listName.length > 0 ? (
          listName.map((item, index) => (
            <div
              key={index}
              className="entityview__desc-card entityview__papers-card"
            >
              <div className="entityview__desc-card-left">
                <div className="entityview__desc-card-head">
                  {item.title}
                </div>
                <div className="entityview__desc-card-detail">
                  {item.year ? `Published in ${item.year}` : ''}
                </div>
              </div>
              <div>
                {item.url && (
                  <a
                    className="entityview__desc-card-link entityview__desc-card-link--small"
                    href={httpfyUrl(item.url)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fas fa-link" />
                  </a>
                )}
              </div>
            </div>
          ))
        ) : <></>
        }
      </>
    )
  }


  render() {
    //console.log('contact person = ', this.props.contactPerson);


    const statsSection = this.renderStats();
    const keyPersonsSection = this.renderKeyPersons();
    const recognitions = this.recognitions(this.props.recognitions);
    const patents = this.patentsNews(this.props.patents);
    const news = this.patentsNews(this.props.news)
    const currentLocation = window.location.pathname.split('/')[1]
    const address = this.props.address
    //console.log(this.props.address)

    return (
      <Fragment>
        <div className="entityview__desc-wrapper-about entityview__desc__about-container">
          <div className="entityview__about-wrapper">

            <div className="entityview__about-section">
              <p className="entityview__about-head"
              >About</p>
              <div className="entityview__about-section-about">{this.props.about}</div>
            </div>
            {statsSection}
            {keyPersonsSection}
            {currentLocation === 'researcher' &&
              <div className="entityview__address">
                <div className="entityview__about-address--wrapper">
                  <p className="entityview__about-head">Address</p>
                  <p className="entityview__about-section-about">
                  {address.line1}
      {address.pin_code && address.city && `, ${address.pin_code}, ${address.city}`}
      {address.pin_code && !address.city && `, ${address.pin_code}`}
      {!address.pin_code && address.city && `, ${address.city}`} 
                  </p>
                </div>
               {/* <div>
                  <p className="entityview__about-head">URL</p>
                  <div className="entityview__contact-details entityview__contact-url">
                   <img
                      className="entityview__contact-img"
                      src={websiteImg}
                      alt="website"
    />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={httpfyUrl(this.props.url)}
                    >
                      <p className="entityview__websiteurl">{httpfyUrl(this.props.url)}</p>
                    </a>
                  </div> 
                </div>*/}
              </div>}
          </div>
        </div>
        {!currentLocation == "researcher" &&
          <Contact
            url={this.props.url}
            address={this.props.address}
            contactPerson={this.props.contactPerson}
          />}
        {!currentLocation == 'researcher' &&
          <div className='academia__about'>
            <div>
              {this.props.recognitions.length > 0 &&
                <p className="academia__about_heading">RECOGNITIONS ({this.props.recognitions.length})</p>}
              {recognitions}
            </div>
            <div className="academia__about_container">
              {this.props.patents.length > 0 &&
                <p className='academia__about_heading'>PATENTS({this.props.patents.length})</p>}
              {patents}
            </div>
            <div className="academia__about_container">
              {this.props.news.length > 0 &&
                <p className='academia__about_heading'>NEWS AND SIGNAL({this.props.news.length})</p>}
              {news}
            </div>
          </div>}
      </Fragment>
    )
  }
}

export default About;