import React, {Component} from 'react'
import ImageUpload from "../../img/profile_upload.png"


export default class FieldFileInput  extends Component{
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }
state ={
    file: null,
    base64URL: "",
    fileName: "",
}
   
  getBase64 = file => {
   // console.log(file)
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        //console.log("Called", reader);
        baseURL = reader.result;
      //  console.log(baseURL);
        resolve(baseURL);
      };
     // console.log(fileInfo);
    });
  };
  onChange=(e)=> {
    const { input: { onChange } } = this.props
    let { file } = this.state;
    //console.log(e.target.files[0])
    file = e.target.files[0];
  //  console.log(file)
     
    this.getBase64(file)
      .then(result => {
        file["base64"] = result;
      // console.log("File Is", file);
      this.setState({
        fileName:file.name
      })
       onChange(file)
      })
      .catch(err => {
        // console.log(err);
      });
      
  };


  

  render(){
    const { input: { value } } = this.props
    const {input,label, required, meta, } = this.props  //whatever props you send to the component from redux-form Field
    return(
     <div> 
     <div className='contributor__profile__upload' >
     <label for="file-input">
      <div className='contributor__profile_container'>
      <img src={ImageUpload} className='contributor__profile'/>  
      <p className='contributor__prodile__upload__text'>Upload a profile picture</p>
      </div>
      </label>
      
      <input id="file-input" type="file"  onChange={this.onChange}  accept="image/*"/>
      
    {/*   <input
        type='file'
        name="picture"
        accept="image/*"
        onChange={this.onChange}
    /> */}
     </div>
     
     </div>
    )
}
}