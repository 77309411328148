import React from 'react';

const EntitySelectorRadio = ({
  name,
  title,
  value,
  description,
  image,
  checked,
  changeEntityType
}) => {
  return (
    <div 
      onClick={() => changeEntityType(value)}
      className={`feature_form__entity-selector-radio-wrapper ${
      checked ? 'feature_form__entity-selector-radio-wrapper--selected' : ''
    }`}>
      <input
        type="radio"
        name={name}
        value="company"
        id='value'
        checked={checked}
        onChange={() => changeEntityType(value)}
        className='feature_form__entity-selector-radio-input'
      />
      <label
        htmlFor={value}
        className='feature_form__entity-selector-radio-label'
      >   
        <div className="feature_form__entity-selector-radio-label-text">
          <p className="feature_form__entity-selector-radio-label-title"
          >{title}</p>
          <p className="feature_form__entity-selector-radio-label-desc"
          >{description}</p>
        </div>     
      </label>
      <span className='custom-radio-btn'></span>

      {checked && <img 
        src={image} 
        alt='Building icon' 
        className='feature_form__entity-selector-radio-label-image' 
      />}
    </div>
  );
};

export default EntitySelectorRadio;