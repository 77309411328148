import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";

import { useEffect } from "react";


export const techAreas = [
    { name: "Internet of Things" },
    { name: "Blockchain" },
    { name: "AI and ML" },
    { name: "Cloud Computing and Virtualization" },
    { name: "Wireless and Networking Technologies" },
    { name: "Hardware, Semiconductors and Embedded" },
    { name: "Cybersecurity" },
    { name: "Robotics and Automation" },
    { name: "Natural Language Processing" },
    { name: "Computer Vision" },
    { name: "Augmented and Virtual Reality" },
    { name: "Data Science" },
    { name: "Material Science" },
    { name: "Next Generation Computing" },
    { name: "Cryptology" },
    { name: "Digital Forensics" },
    { name: "Identity Management and Authentication" },
    { name: "Enterprise Business Technologies" },
    { name: "Operating Systems" },
    { name: "Application Infrastructure and Software" },
    { name: "Software Defined Infrastructure" },
    { name: "Unmanned Aerial Vehicles" },
    { name: "Human Computer Interaction" },
    { name: "Mobility and End Points" },
    { name: "Location and Presence" },
    { name: "Quantum Technology" },
    { name: "5G and 6G" }
];

const TechAreaDropdownNew = () => {

    const sortTechAreasAlphabetically = (areas) => {
        const sortedAreas = [...areas].sort((a, b) => {
            const aIsLetter = isNaN(a.name[0]);
            const bIsLetter = isNaN(b.name[0]);

            if (aIsLetter === bIsLetter) {
                return a.name.localeCompare(b.name);
            }

            return aIsLetter ? -1 : 1;
        });

        return sortedAreas;
    };
    const sortedAreas = sortTechAreasAlphabetically(techAreas);

    const areasColumn1 = sortedAreas.slice(0, 9);
    const areasColumn2 = sortedAreas.slice(9, 18);
    const areasColumn3 = sortedAreas.slice(18, 27);




    const areasColumn1Content = areasColumn1.map(areaObj => {

        return (
            <Link

                key={areaObj.name}
                className="header__technologies--dropdown__element"
                to={{
                    pathname: `/technology/${areaObj.name.replace(/ /g, "_")}`,

                }}

            >

                {areaObj.name}
            </Link>)

    });

    const areasColumn2Content = areasColumn2.map(areaObj => (

        <Link
            rel="canonical"
            key={areaObj.name}

            className="header__technologies--dropdown__element"
            to={{
                pathname: `/technology/${areaObj.name.replace(/ /g, "_")}`,
                state: areaObj.name
            }}
        >
            {areaObj.name}
        </Link>

    ));

    const areasColumn3Content = areasColumn3.map(areaObj => (

        <Link
            rel="canonical"
            key={areaObj.name}

            className="header__technologies--dropdown__element"
            to={{
                pathname: `/technology/${areaObj.name.replace(/ /g, "_")}`,
                state: areaObj.name
            }}
        >
            {areaObj.name}
        </Link>

    ));

    return (
        <div className="header__technologies header__technologiesTop" >
            Technologies&nbsp;{" "}
            <i className="fas fa-angle-down header__technologies-caret" />
            <div className="header__technologies--dropdown">
                <div className="header__technologies--dropdown-content">
                    <div className="header__technologies--dropdown-content-col">
                        {areasColumn1Content}
                    </div>
                    <div className="header__technologies--dropdown-content-col">
                        {areasColumn2Content}
                    </div>
                    <div className="header__technologies--dropdown-content-col">
                        {areasColumn3Content}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TechAreaDropdownNew;
