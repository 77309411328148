import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";

import FormInputField from "../form-elements/FormInputField";

import FormSelectAsyncCreatable from "../form-elements/FormSelectAsyncCreatable";
import FormSelect from "../form-elements/FormSelect";
import FormTextAreaField from "../form-elements/FormTextAreaField";

import validate from "../utils/validate";
import api from '../../../api';

class FormPart2 extends Component {

  getSimilarCompanies = (input, cb) => {
    let options = [];
 
    // For query less than 3 letters
    if (input.length < 3) {
      cb(options);
      return;
    }

    const requestConfig = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: input })
    };

    fetch(api.entityLookup, requestConfig)
      .then(response => {
        if (!response || !response.ok) throw Error(response.status);
        return response.json();
      })
      .then(({ data }) => {
       // console.log(data)
        options = data.map(company => ({
          value: company.name,
          label: company.name,
          id: company.id,
          className: 'select-option'
        }));
        cb(options);
      })
      .catch(err => console.log('Error performing company lookup for similar companies', err));
  };


  render() {
    const { handleSubmit } = this.props;
    const onKeyPress = event => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };

    return (
      <Fragment>
        <p className="feature_form__head">About Company</p>
        <form
          onSubmit={handleSubmit(this.props.onSubmit)}
          onKeyPress={onKeyPress}
        >
          <div className="feature_form__inner-wrapper">
            <Field
              name="name"
              component={FormSelectAsyncCreatable}
              label="Company’s Legal Name"
              placeholder="E.g Reliance Industries Limited"
              selectConfig={{
                isClearable: true,
                isOptionDisabled: option => (option.label.indexOf('Create')) ? true : false,
              }}
              loadOptions={this.getSimilarCompanies}
              components={{ DropdownIndicator: null }}
            />
            <Field
              name="url"
              // widthClass="col-md-6"
              component={FormInputField}
              type="text"
              placeholder="Enter the URL of the company"
              label="Company’s Website"
              tabable={true}
            />
            <Field
              name="alias"
              // widthClass="col-md-6"
              component={FormInputField}
              type="text"
              placeholder="Enter the company alias"
              label="Alias / Alternate Name"
              tabable={true}
            />
            <Field
              name="type"
              component={FormSelect}
              placeholder="Select company type"
              label="Type of Company"
              valueType="string"
              options={this.props.companyTypes.map(type => ({
                label: type,
                value: type,
                 
              }))}
              
            />
            <Field
              name="application_area"
              component={FormSelect}
              placeholder="Select company's application area"
              label="Entity Application Area"
              valueType="string"
              options={this.props.applicationAreas.map(type => ({
                label: type,
                value: type,
              }))}
            />
            <Field
              name="company_category"
              component={FormSelect}
              placeholder="Select company type"
              label="Entity Category"
              valueType="string"
              options={[
                { label: "Logistics Tech", value: "Logistics Tech" },
                { label: "Fintech", value: "Fintech" },
                { label: "Agritech", value: "Agritech" },
                { label: "Health Tech", value: "Health Tech" },
                { label: "Retail Tech", value: "Retail Tech" }
              
              ]}
              
            />
            <Field
              name="size"
              placeholder="Select"
              label="Number of Employees"
              component={FormSelect}
              options={this.props.companySizes.map(type => ({
                label: type,
                value: type,
              }))}
              valueType="string"
            />

            <Field
              name="sectors_served"
              component={FormSelect}
              placeholder="Select Sectors"
              label="Sectors Served"
              valueType="string"
              isMulti
              options={this.props.sectorsServed.map(type => ({
                label: type,
                value: type,
              }))}
            />
            <Field
              name="geographies_served"
              component={FormSelect}
              placeholder="Select Geographies"
              label="Geographies Served"
              valueType="string"
              isMulti
              options={this.props.geographiesServed.map(type => ({
                label: type,
                value: type,
              }))}
            />
            <Field
              name="about"
              // widthClass="col-md-6"
              component={FormTextAreaField}
              type="text"
              placeholder="Describe about company does, like vision etc."
              label="About"
              tabable={true}
            />
            <Field
              name="comment"
              // widthClass="col-md-6"
              component={FormTextAreaField}
              type="text"
              label="Comments"
              tabable={true}
            />
          </div>
          <div className="feature_form__btn-wrapper">
            <button
              className="feature_form__btn feature_form__btn-back"
              onClick={this.props.prevPage}
            >
              GO BACK
                        </button>

            <button type="submit" className="feature_form__btn">
              CONTINUE
                        </button>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default reduxForm({
  form: "feature_yourself_old", // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(FormPart2);
