import { useEffect, useRef, memo} from "react";
import { FoamTree as FoamTreeImpl } from "@carrotsearch/foamtree";
import React from 'react';

const FoamTree = ({modelData,handleGroupDClick,foamtree,style}) => {

  const element = useRef();

  useEffect(() => {
    foamtree.current = new FoamTreeImpl({
      element: element.current,
      pixelRatio: window.devicePixelRatio || 1,
      relaxationVisible: true,
      relaxationQualityThreshold: 5,
      rolloutDuration: 0,
      pullbackDuration: 0,
      finalCompleteDrawMaxDuration: 50,
      finalIncrementalDrawMaxDuration: 20,
      groupGrowingDuration: 3000,
      relaxationMaxDuration: 15000,
      groupFillType: "plain",
      maxGroups: 1000000,
      dataObject: modelData,
      onGroupClick: handleGroupClick,
      // Include hints
      onGroupHover: handleGroupHover,
      // HammerJS for touch support
      onGroupTouchStart: handleGroupTouchStart,
      // Double click group event
      onGroupDoubleClick: handleGroupDClick,
    });

    return () => {
      if (foamtree.current) {
        foamtree.current.dispose();
      }
    };
  }, [modelData]);

  const handleGroupClick = (event) => {
    // Handle group click event
    //console.log('Group clicked:', event.group.label);
  };
  const handleGroupHover = (event) => {
    // Handle group hover event
    //console.log('Group hovered:', event.group.label);
  };
  const handleGroupTouchStart = (event) => {
    // Handle touch start event
    //console.log('Touch started on group:', event.group.label);
  };

  // onDoubleClick={replotFoamtree}
  return (
    <div className="foamtree" id="visualization"     ref={element} />
  );
};


export default memo(FoamTree);