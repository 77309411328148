import React, { Component } from 'react';
import "../css/CaseStudy.css";

 
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
 
import { getFormattedDateTime } from "../components/helper-functions/helper"
import LazyLoad from 'react-lazy-load';


class CaseStudy extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dateTime: "",
        }
    }

    truncateString(myString){
      //  console.log(myString.length)
        if (myString.length > 300){
        var myTruncatedString = myString.substring(0,300);
        return myTruncatedString + "......"} 
        else{
          return myString
        }
      }
    
    titleString(myString){
        if (myString.length > 120){
        var myTruncatedString = myString.substring(0,120);
        return myTruncatedString + "......"} 
        else{
          return myString
        }
    }


    render() {
        //console.log(this.props.data)
        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 3
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 1.3
            },
            tablet: {
                breakpoint: { max: 1024, min: 624 },
                items: 1
            },
            mobile: {
                breakpoint: { max: 624, min: 0 },
                items: 1
            }
        };
 //console.log(this.props.data)
        return (
            <div className={this.props.caseStudyCss}>
                <p className='case_heading'>CASE STUDY({this.props.count})</p>
                {this.props.data && this.props.data.length > 0 ?
                    <Carousel responsive={responsive}>
                        {this.props.data && this.props.data.map((obj, i) => {

                            return (
                                <div className="case_study_wrapper">
                                <div className="case_study">
                                    <div className="case_study__image-wrapper">
                                     
                                        <img
                                            src={obj.thumbnail_url}
                                            alt="Whitepaper Thumbnail"
                                            className="case_study__image"
                                        />
                                        
                                    </div>

                                    <div className="case_study__content">
                                        <p className="case_study__title">{this.titleString(obj.title)}</p>

                                        {obj.publish_date &&
                                        <p className="case_study__date">{getFormattedDateTime(new Date(obj.publish_date), true)}</p>}
                                        <p className="case_study__description">{this.truncateString(obj.summary)}</p>
                                        <div className='btn-position'> 
                                        <a
                                            className="case_study__download"
                                            download
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={obj.file_url}
                                        // onClick={handleClick}
                                        >Download</a>
                                        </div>
                                    </div>
                                </div>
                                </div>

                            )
                        })
                        }
                    </Carousel> :
                    <div className='events__no_data'>
                        <p className='case_heading'>                                                                                                                                                        
                            The Case Study Information is coming soon!
                        </p>
                        <p>
                            Once available, you can view the Case Study
                            information here.
                            <br />
                            Stay Tuned!
                        </p>

                    </div>}
            </div>
        );
    }
}   

export default CaseStudy;                       














