import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { reduxForm, reset, change } from "redux-form";
import { toast } from "react-toastify";

import IndustryWrapper from "./IndustryWrapper";
import AcademiaWrapper from "./AcademiaWrapper";
import IndividualWrapper from "./IndividualWrapper";

import "../../css/FeatureForm.css";

import dsciLogo from '../../img/dsc-logo/tech-sagar-logo-exploration-72.png';
import currentImg from "../../img/wizard-current.svg";
import pendingImg from "../../img/wizard-pending.svg";
import completeImg from "../../img/wizard-complete.svg";
import api from "../../api";

class FormWrapperOld extends Component {
	state = {
		formPart: 1,
		entityType: "company",
		totalParts: 10,
		completedPages: [],
	};

	changeEntityType = e => {
		let value = e.target.value;
		let totalParts;
		switch (value) {
			case "company":
				totalParts = 10;
				this.props.dispatch(change("feature_yourself_old", "type", ""));
				break;
			case "academia":
				totalParts = 9;
				this.props.dispatch(
					change("feature_yourself_old", "type", "Academia")
				);
				break;
			case "individual":
				totalParts = 9;
				this.props.dispatch(
					change("feature_yourself_old", "type", "Individual Researcher")
				);
				break;
			case "r_and_d":
				this.props.dispatch(
					change("feature_yourself_old", "type", "R & D Centres")
				);
				totalParts = 10;
				break;
			default:
				totalParts = 10;
		}

		this.setState({ entityType: value, otalParts: totalParts });
	};

	nextPage = () => {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0;
		document.getElementsByClassName(
			"feature_form__wrapper"
		)[0].scrollTop = 0;

		this.setState(prevState => ({
			formPart: prevState.formPart + 1,
			completedPages: [...prevState.completedPages, prevState.formPart],
		}));
	};

	prevPage = () => {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0;
		document.getElementsByClassName(
			"feature_form__wrapper"
		)[0].scrollTop = 0;

		this.setState(prevState => {
			// console.log("prevState.formPart = ", prevState.formPart);
			return { formPart: prevState.formPart - 1 };
		});
	};

	submitForm = (formData, dispatch) => {
		let data = {};
		let lastFormStatus = 5;
		this.setState({ loading: true });

		data = {
			...formData,
			data_type: "self_feed",
			last_form_status: lastFormStatus,
			id: this.props.formId,
		};

		const requestConfig = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data)
		};


		fetch(api.secretFeatureSubmit, requestConfig)
			.then(response => {
				if (!response || !response.ok) throw Error(response.status);
				return response.json();
			})
			.then(response => {
				if (response.success) {
					toast.success(response.message[0]);
					dispatch(reset('feature_yourself_old'));
					this.setState({
						showPrompt: false,
						redirectToDashboard: true,
						loading: false
					});
				}
				else {
					toast.error(response.message[0]);
					dispatch(reset("feature_yourself_old"));
					this.setState({
						showPrompt: false,
						redirectToDashboard: true,
						loading: false,
					});
				}

			})
			.catch(err => {
				// console.log('Could not submit secret form', err)
				this.setState({ loading: false, redirectToDashboard: true });
			});
	}

	handleLogoClick = () => {
		this.props.dispatch(reset("feature_yourself"));
		this.props.history.push("/");
	};


	getIndicatorImage(part) {
		const { formPart, completedPages } = this.state;
		if (part === formPart) return currentImg;
		if (completedPages.includes(part)) return completeImg;
		return pendingImg;
	}

	getIndicatorClass(part) {
		const { formPart, completedPages } = this.state;
		if (part !== formPart || !completedPages.includes(part)) return '';
		return 'feature_form__part-indicator--active';
	}

	getIndicatorElement(text, part) {
		return (
			<div className={`feature_form__part-indicator ${this.getIndicatorClass(part)}`}>
				<img
					src={this.getIndicatorImage(part)}
					className="feature_form__part-indicator-img"
					alt="Current Part"
				/>{text}
			</div>
		);
	}

	render() {
		const { formPart, entityType, totalParts } = this.state;

		return (
			<div className="feature_form">
				{this.state.redirectToDashboard && <Redirect to="/" />}
				<div className="feature_form__leftbar">
					<div className="feature_form__leftbar-top">
						<div className='feature_form__dsci-logo' onClick={this.handleLogoClick}>
							<img className='feature_form__dsci-logo-img' src={dsciLogo} alt="DSCI LOGO" />
						</div>
						<div className="feature_form__leftbar-top-text">
							Feature Yourself
            </div>
					</div>

					<div className="feature_form__indicators-wrapper">
						{this.getIndicatorElement('Entity Type', 1)}

						{entityType === "company" && (
							<Fragment>
								{this.getIndicatorElement('About', 2)}
								{this.getIndicatorElement('Contact', 3)}
								{this.getIndicatorElement('Management', 4)}
								{this.getIndicatorElement('Products', 5)}
								{this.getIndicatorElement('Services', 6)}
								{this.getIndicatorElement('Solutions', 7)}
								{this.getIndicatorElement('Papers', 8)}
								{this.getIndicatorElement('Patents & Blogs', 9)}
								{this.getIndicatorElement('Miscellaneous', 10)}
							</Fragment>
						)}

						{entityType === "r_and_d" && (
								<Fragment>
									{this.getIndicatorElement('Basic', 2)}
									{this.getIndicatorElement('Technologies', 3)}
									{this.getIndicatorElement('Contact', 4)}
									{this.getIndicatorElement('Head / Leads', 5)}
									{this.getIndicatorElement('Projects', 6)}
									{this.getIndicatorElement('Talks / Workshops', 7)}
									{this.getIndicatorElement('Papers', 8)}
									{this.getIndicatorElement('Patents & Blogs', 9)}
									{this.getIndicatorElement('Miscellaneous', 10)}
								</Fragment>
							)}

						{entityType === "academia" && (
								<Fragment>
									{this.getIndicatorElement('Basic', 2)}
									{this.getIndicatorElement('Technologies', 3)}
									{this.getIndicatorElement('Contact', 4)}
									{this.getIndicatorElement('Head / Leads', 5)}
									{this.getIndicatorElement('Projects', 6)}
									{this.getIndicatorElement('Talks / Workshops', 7)}
									{/* {this.getIndicatorElement('Papers', 8)}     // remove 'research papers' from academia form  */}
									{this.getIndicatorElement('Patents & Blogs', 8)}
									{this.getIndicatorElement('Miscellaneous', 9)}
								</Fragment>
							)}

						{entityType === "individual" && (
							<Fragment>
								{this.getIndicatorElement('Basic', 2)}
								{this.getIndicatorElement('Technologies', 3)}
								{this.getIndicatorElement('Contact', 4)}
								{this.getIndicatorElement('Projects', 5)}
								{this.getIndicatorElement('Talks / Workshops', 6)}
								{this.getIndicatorElement('Papers', 7)}
								{this.getIndicatorElement('Patents & Blogs', 8)}
								{this.getIndicatorElement('Miscellaneous', 9)}
							</Fragment>
						)}
					</div>
				</div>


				{formPart === 1 && (
					<div className="feature_form__wrapper">
						<div className="feature_form__subhead">STEP 1 OF {totalParts}</div>
						<p className="feature_form__head">Select an Entity Type</p>
						<div className="feature_form__inner-wrapper">
							<label
								className={`feature_form__entity-type ${
									entityType === "company"
										? "feature_form__entity-type--active"
										: ""
									}`}
							>
								<input
									type="radio"
									name="entityType"
									value="company"
									checked={entityType === "company"}
									onChange={this.changeEntityType}
									tabIndex="0"
								/>
								<div className="feature_form__entity-type-label">
									<b className="feature_form__entity-type-head">Industry</b>
									<p className="feature_form__entity-type-desc">
										Companies, Startups, NGOs, Govt organisations
									</p>
								</div>
							</label>
							<label
								className={`feature_form__entity-type ${
									entityType === "academia"
										? "feature_form__entity-type--active"
										: ""
									}`}
							>
								<input
									type="radio"
									name="entityType"
									value="academia"
									checked={entityType === "academia"}
									onChange={this.changeEntityType}
									tabIndex="0"
								/>
								<div className="feature_form__entity-type-label">
									<b className="feature_form__entity-type-head">Academia</b>
									<p className="feature_form__entity-type-desc">
										Educational and academic institutes like IIT, IIM etc.
									</p>
								</div>
							</label>
							<label
								className={`feature_form__entity-type ${
									entityType === "individual"
										? "feature_form__entity-type--active"
										: ""
									}`}
							>
								<input
									type="radio"
									name="entityType"
									value="individual"
									checked={entityType === "individual"}
									onChange={this.changeEntityType}
									tabIndex="0"
								/>
								<div className="feature_form__entity-type-label">
									<b className="feature_form__entity-type-head">Individual</b>
									<p className="feature_form__entity-type-desc">
										Professors, reseachers, Academicians etc.
									</p>
								</div>
							</label>
							<label
								className={`feature_form__entity-type ${
									entityType === "r_and_d"
										? "feature_form__entity-type--active"
										: ""
									}`}
							>
								<input
									type="radio"
									name="entityType"
									value="r_and_d"
									checked={entityType === "r_and_d"}
									onChange={this.changeEntityType}
									tabIndex="0"
								/>
								<div className="feature_form__entity-type-label">
									<b className="feature_form__entity-type-head">R&D</b>
									<p className="feature_form__entity-type-desc">
										Research and forensic labs like FSSAI, CRL etc.
									</p>
								</div>
							</label>
						</div>
						<div className="feature_form__btn-wrapper">
							<button
								className="feature_form__btn"
								onClick={this.nextPage}
							>CONTINUE</button>
						</div>
					</div>
				)}

				{formPart !== 1 &&
					entityType === "company" && (
						<IndustryWrapper
							formPart={formPart}
							nextPage={this.nextPage}
							prevPage={this.prevPage}
							totalParts={totalParts}
							submitForm={this.submitForm}
						/>
					)}
				{formPart !== 1 &&
					entityType === "academia" && (
						<AcademiaWrapper
							formPart={formPart}
							prevPage={this.prevPage}
							nextPage={this.nextPage}
							totalParts={totalParts}
							submitForm={this.submitForm}
							entityType="Academia"
						/>
					)}
				{formPart !== 1 &&
					entityType === "r_and_d" && (
						<AcademiaWrapper
							formPart={formPart}
							prevPage={this.prevPage}
							nextPage={this.nextPage}
							totalParts={totalParts}
							submitForm={this.submitForm}
							entityType="R&D Centre"
						/>
					)}
				{formPart !== 1 &&
					entityType === "individual" && (
						<IndividualWrapper
							formPart={formPart}
							prevPage={this.prevPage}
							nextPage={this.nextPage}
							totalParts={totalParts}
							submitForm={this.submitForm}
						/>
					)}
			</div>
		);
	}
}

FormWrapperOld = reduxForm({
	form: "feature_yourself_old",
	destroyOnUnmount: false,
	initialValues: {
		contact_person: {
			persons: [{}],
		},
		address: { line1: "", pin_code: "", city: "" },
		technology_heads: [{}],
		business_heads: [{}],
		technology_type: [{}],
		products: [
			{ competitors: [{ name: "", url: "" }], technology_type: [{}] },
		],
		services: [
			{ competitors: [{ name: "", url: "" }], technology_type: [{}] },
		],
		solutions: [
			{ competitors: [{ name: "", url: "" }], technology_type: [{}] },
		],
		research_papers: [
			{
				title: "",
				url: "",
				technology_type: [{}],
				authors: [{ name: "", url: "" }],
			},
		],
		blogs: [{ title: "", url: "" }],
		patents: [{ title: "", url: "", technology_type: [{}] }],
		key_global_competitors: [{ name: "", url: "" }],
		key_local_competitors: [{ name: "", url: "" }],
		recognitions: [{ content: "", year: "" }],
	},
})(FormWrapperOld);

export default FormWrapperOld;
