import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import ScrollToTop from "./ScrollToTop";

import "./css/bootstrap/bootstrap.css";
import "./css/index.css";

import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./reducers/index";
import { setUserInfo } from "./actions/auth";
import api from './api';
 

 

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const user = JSON.parse(localStorage.getItem("user"));

const checkAuth = new Promise(resolve => {
  if (!user) resolve(false);
  const requestConfig = {
    method: 'POST',
    headers: {
      'TCRAUTH': user.token,
      'Content-Type': 'application/json',
    }
  };

  fetch(api.verifyToken, requestConfig).then(response => {
    if (!response || !response.ok) resolve(false);
    return response.json();
  }).then(response => {
   // console.log(response)
    if(response.success === false){
      localStorage.removeItem("user");
      this.props.setUserInfo(null);
      this.props.history.push('/')
    }
    resolve(response.success);
  }).catch(error => {
    resolve(false);
  });
});

const renderApp = () =>
  ReactDOM.render(
    <Provider store={store}> 
    
      <BrowserRouter>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </BrowserRouter>
     
    </Provider>,
    document.getElementById("root")
  );

checkAuth.then(result => {
  
  store.dispatch(setUserInfo({ userInfo: result ? user : null }));
  renderApp();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
