import React from "react";
import PropTypes from "prop-types";

// Components
import Backdrop from "../backdrop/Backdrop";

// CSS
import "./Modal.css";

const Modal = props => {
    const { backdropClose = true } = props;
   //console.log(backdropClose,'CHECK 1');
    return (
        <div>
            <Backdrop
                show={props.show}
                clicked={backdropClose ? props.toggleModal : () => true}
            />
            <div
                className="modal"
                style={{
                    transform: props.show
                        ? "translate(-50%, -50%)"
                        : "translate(-50%, -250vh)",
                    width: props.width ? props.width : "",
                    height: props.height ? props.height : "",
                }}
                 
            >
                <div
                    className="modal-content-close-btn"
                    onClick={props.toggleModal}
                >
                    &times;
                </div>
                {props.children}
            </div>
        </div>
    );
};

    Modal.propTypes = {
    show: PropTypes.bool,
    toggleModal: PropTypes.func,
    width: PropTypes.number,
    
};

export default Modal;
