import React from "react";

function UnlockDoc(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 96 120'
      preserveAspectRatio="xMinYMin meet"
      className={props.className}
    >
      <defs>
        <path
          id='a'
          d='M4 0h65l27 28v88a4 4 0 01-4 4H4a4 4 0 01-4-4V4a4 4 0 014-4z'
        ></path>
        <path
          id='c'
          d='M31 16c-2-1-3-3-3-5 0-3 3-6 6-6s6 3 6 6c0 2-1 4-3 5v4a3 3 0 11-6 0v-4z'
        ></path>
        <filter
          id='d'
          width='108.3%'
          height='105.6%'
          x='-4.2%'
          y='-2.8%'
          filterUnits='objectBoundingBox'
        >
          <feOffset
            dy='1'
            in='SourceAlpha'
            result='shadowOffsetInner1'
          ></feOffset>
          <feComposite
            in='shadowOffsetInner1'
            in2='SourceAlpha'
            k2='-1'
            k3='1'
            operator='arithmetic'
            result='shadowInnerInner1'
          ></feComposite>
          <feColorMatrix
            in='shadowInnerInner1'
            values='0 0 0 0 0.22745098 0 0 0 0 0.384313725 0 0 0 0 0.643137255 0 0 0 1 0'
          ></feColorMatrix>
        </filter>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='b' fill='#fff'>
          <use xlinkHref='#a'></use>
        </mask>
        <use fill='#5586D1' xlinkHref='#a'></use>
        <path
          fill='#283554'
          fillOpacity='0.3'
          d='M97 28H73a4 4 0 01-4-4V-1l28 29z'
          mask='url(#b)'
        ></path>
        <g mask='url(#b)'>
          <g fillRule='nonzero' transform='translate(14 83)'>
            <use fill='#1C1C1C' xlinkHref='#c'></use>
            <use fill='#000' filter='url(#d)' xlinkHref='#c'></use>
          </g>
          <path
            fill='#6FFF9F'
            d='M36 92h12v5H35a11 11 0 111-5zm-11 8a6 6 0 100-12 6 6 0 000 12z'
          ></path>
        </g>
        <path
          fill='#FFF'
          d='M14 22h16v2H14zm0 8h34v3H14zm0 18h52v2H14zm0 8h64v2H14zm0 9h64v2H14z'
        ></path>
      </g>
    </svg>
  );
}

export default UnlockDoc;
