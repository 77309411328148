import React from 'react';
import { Redirect } from 'react-router';
//import closeIcon from '../assets/cancel.svg';
import { useHistory } from "react-router-dom";
import '../../css/Modal.scss'
//import closeIcon from '../img/close.png'

const Modal = ({ show, close, children, title, addStyle }) => {
  

  return (
    <div className={`newModal__wrapper ${show ? 'newModal__wrapper--shown' : ''}`}>
           
      <div
         
        className={`newModal__backdrop `}
      />
 
      <div className={`newModal ${show ? 'newModal--shown' : ''}`} style={addStyle}>
         
      <button  onClick={close} className='modal__btn'
       >X</button>
        {/* <div className='newModal__header'>
          <h2 className='newModal__title'>{title}</h2>
          {/* //TODO - Add the X button here to close the modal */}
           {/* <img
            src={closeIcon}
            alt='Close'
            // type='image/svg+xml' 
            // data={closeIcon}
            className='newModal__closeIcon'
            onClick={close}
          /> */}
        {/* </div>  */}
        <div className='newModal__body'>
        {children}

        </div>
        
      </div>
    </div>
  )
}

export default Modal;