import React from "react";

//  SVG Icons
import emailIcon from "../img/email.svg";
import phoneIcon from "../img/phone.svg";
import websiteIcon from "../img/website-dark.svg";
import {EmailShareButton} from "react-share";
const ContactUs = () => {
  document.title =`Contact Us - Technology Capability Repository || DSCI`;
  return(
  <>
    {/* Banner Section */}
    <div className="infoPage__banner">
      <h1 className="infoPage__bannerTitle">Contact Us</h1>
    </div>
    {/* End of banner section */}

    {/* Main section */}
    <main className="infoPage__body">
      <div className="infoPage__bodyBox">
        <p className="infoPage__bodyText">
          Data Security Council of India (DSCI), is a not-for-profit, industry body on data protection in India, 
          setup by NASSCOM®, committed to making the cyberspace safe, secure and trusted by establishing best practices, 
          standards and initiatives in cyber security and privacy. To further its objectives, DSCI engages with 
          governments and their agencies, regulators, industry sectors, industry associations and think tanks for policy 
          advocacy, thought leadership, capacity building and outreach activities.<br /><br />

          To strengthen thought leadership in cyber security and privacy, DSCI develops best practices and frameworks, 
          publishes studies, surveys and papers. It builds capacity in security, privacy and cyber forensics through 
          training and certification program for professionals and law enforcement agencies and engages stakeholders 
          through various outreach initiatives including events, awards, chapters, consultations and membership programs. 
          DSCI also endeavours to increase India’s share in the global security product and services market through global 
          trade development initiatives. These aim to strengthen the security and privacy culture in India.<br /><br />
        </p>
      </div>

      <div className="infoPage__bodyBox">
        <div className="row">
          <div className="col-md-6 col-sm-12  infoPage__contactLeft">
            <p className="infoPage__contactKey">
              <img src={emailIcon} alt="Icon" className="contact_icons" />
              <EmailShareButton>techsagar@dsci.in</EmailShareButton>
               
            </p>

            <p className="infoPage__contactKey">
              <img src={phoneIcon} alt="Icon" className="contact_icons" />
              +91-120-4990253
            </p>

            <a href="https://www.dsci.in"  target="_blank"  className="infoPage__contactKey">
              <img src={websiteIcon} alt="Icon" className="contact_icons"/>
              www.dsci.in
            </a>

            <p className="contact__address">ADDRESS</p>

            <p className="infoPage__contactAddress">
              Data Security Council of India <br/>
              4th Floor, NASSCOM Campus<br />
              Plot No- 7-10, Sector 126, <br />
              Noida (UP) 201303, India
            </p>
          </div>
          <div className="col-md-6 col-sm-12 about_us__contact__map" />
        </div>
      </div>
    </main>
  </>)
};

export default ContactUs;
