export const mapStyle = [
    {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "administrative.country",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#99b0c3",
            },
            {
                visibility: "on",
            },
            {
                weight: 1,
            },
        ],
    },
    {
        featureType: "administrative.country",
        elementType: "labels.text",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "geometry.fill",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "labels",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.locality",
        stylers: [
            {
                color: "#8aa0b2",
            },
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "administrative.locality",
        elementType: "labels.icon",
        stylers: [
            {
                color: "#c3d2dd",
            },
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "administrative.locality",
        elementType: "labels.text",
        stylers: [
            {
                color: "#8fa6b9",
            },
        ],
    },
    {
        featureType: "administrative.locality",
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: "#8aa0b2",
            },
        ],
    },
    {
        featureType: "administrative.province",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#99b0c3",
            },
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "administrative.province",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#869daf",
            },
        ],
    },
    {
        featureType: "landscape.natural.landcover",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#e8e8e8",
            },
        ],
    },
    {
        featureType: "poi",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road.arterial",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road.highway",
        stylers: [
            {
                color: "#ffffff",
            },
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#ffffff",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#ffffff",
            },
        ],
    },
    {
        featureType: "road.local",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road.local",
        elementType: "labels",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "transit",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#c5d7e5",
            },
            {
                saturation: -5,
            },
            {
                visibility: "on",
            },
        ],
    },
];
