import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";

class ScrollToTopRoute extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.path === this.props.location.pathname &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    }
  }

  render() {
    const { component: Component, compProps, ...rest } = this.props;
    // console.log('rest params = ', rest);
    return (
      <Route
        {...rest}
        render={props => <Component {...compProps} {...props} />}
      />
    );
  }
}

export default withRouter(ScrollToTopRoute);
