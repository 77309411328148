import React, { Component } from 'react';
 
import { httpfyUrl } from "../../utils/helpers";
import '../../css/Contributor.css';
import ContributorEmpty from '../../img/contributor.png'
class Contributor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dateTime: "",
        }
    }
    createDate(date) {
        const index = date.indexOf("T")
        const newDate = date.slice(0, index)
        const replaceFirst = newDate.replace("-", "/");
        this.state.dateTime = replaceFirst.replace("-", "/")
    }
    render() {
        
        return (
            <> 
            {this.props.contributorDetails.length > 0 ?
            <>
            <div className='contributor_webinar'>
                <div className='contributor-container'>
            <p className='contributor_heading'>CASE STUDY ({this.props.caseStudy.length})</p>
            {this.props.caseStudy && this.props.caseStudy.length > 0?
            <>
            {this.props.caseStudy &&
                this.props.caseStudy.map(
                    (obj, index) => (
                        <div
                            className="contributor__desc-card"
                            title="Show more"
                        >
                            <div className="contributor__desc-card-top">
                                <div className="contributor__desc-card-left">
                                    <div className="contributor__desc-card-head">{obj.title}</div>
                                    {this.createDate(obj.updated_at)}
                                    <p className='contributor__desc-card-date'>{this.state.dateTime}</p>
                                </div>
                                <div>
                                    <a
                                        href={httpfyUrl(obj.file_url)}
                                        className="contributor__desc-card-link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fas fa-link" />
                                    </a></div>
                            </div>
                        </div>
                    )
                )}</>:<>
                 <div className="contributor__desc-card" title="Show more">
                            <div className="contributor__desc-card-top">
                                <div className="contributor__desc-card-left">
                                    <div>
                                        <p className='contributor__no_information'>No Information <br/>available</p></div>
                                </div>
                            </div>
                        </div></>}
        </div>
        </div>
        <div className='contributor_webinar'>
            <div className='contributor-container'>
            <p className='contributor_heading'>REPORTS ({this.props.reports.length})</p>
            {this.props.reports && this.props.reports.length > 0 ?
            <>
            {this.props.reports &&
                this.props.reports.map(
                    (obj, index) => (
                        <div
                            className="contributor__desc-card"
                            title="Show more"
                        >
                            <div className="contributor__desc-card-top">
                                <div className="contributor__desc-card-left">
                                    <div className="contributor__desc-card-head">{obj.title}</div>
                                    {this.createDate(obj.updated_at)}
                                    <p className='contributor__desc-card-date'>{this.state.dateTime}</p>
                                </div>
                                <div>
                                    <a
                                        href={httpfyUrl(obj.file_url)}
                                        className="contributor__desc-card-link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fas fa-link" />
                                    </a></div>
                            </div>
                        </div>
                    )
                )}</>:<>
                 <div className="contributor__desc-card" title="Show more">
                            <div className="contributor__desc-card-top">
                                <div className="contributor__desc-card-left">
                                    <div>
                                        <p className='contributor__no_information'>No Information <br/>available</p></div>
                                </div>
                            </div>
                        </div>
                </>}
        </div>
        </div>
        <div className='contributor_blog'>
            <div className='contributor-container'>
            <p className='contributor_heading'>BLOGS ({this.props.blogs.length})</p>
            {this.props.blogs && this.props.length >0 ?
            <>
            {this.props.blogs &&
                this.props.blogs.map(
                    (obj, index) => (
                        <div
                            className="contributor__desc-card"
                            title="Show more"
                        >
                            <div className="contributor__desc-card-top">
                                <div className="contributor__desc-card-left">
                                    <div className="contributor__desc-card-head">{obj.title}</div>
                                    {this.createDate(obj.updated_at)}
                                    <p className='contributor__desc-card-date'>{this.state.dateTime}</p>
                                </div>
                                <div>
                                    <a
                                        href={httpfyUrl(obj.file_url)}
                                        className="contributor__desc-card-link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fas fa-link" />
                                    </a></div>
                            </div>
                            
                        </div>
                    )
                )}</>:<>
                 <div className="contributor__desc-card" title="Show more">
                            <div className="contributor__desc-card-top">
                                <div className="contributor__desc-card-left">
                                    <div>
                                        <p className='contributor__no_information'>No Information <br/>available</p></div>
                                </div>
                            </div>
                        </div></>}
                </div>
        </div>
        </>:<div>
        <img className='contributor__empty' src={ContributorEmpty} alt="contributor empty"/>
        <p className='contributor__empty_heading'>No contributions made yet</p></div>}
        </>
        );
    }
}

export default Contributor;