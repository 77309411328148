import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { titleCase } from "../../utils/helpers";

class Technology extends Component {

    goToTechAreaPage = areaName => {
         
        this.props.history.push(`/technology/${areaName.replace(/ /g, "_")}`);
    };

    goToTechCapabilityPage = (capabilityName , techarea) => {
         
    
        this.props.history.push(
            `/technology/${techarea.replace('%','percentage').replace(/ /g, "-")}/${capabilityName.includes("-") && capabilityName.includes(" ") ? capabilityName.replace(/ /g, "_"):
            capabilityName.includes("/") ? capabilityName.replace(/ /g, "-").replaceAll("/","12") :
            !capabilityName.includes(" ") && capabilityName.includes("-")  ? capabilityName.replaceAll("-", "_") :
            capabilityName.replace(/ /g, "-").replace("%","-")}`
        );
    };

    render() {
       // console.log(this.props.technologyAreas)
        return (
            <div className="entityview__tech">
                <div className="entityview__desc-head">
                    <div>Tech Areas</div>
                    <div
                        className="entityview__desc-head-resetFilters-desktopOnly"
                        onClick={this.props.resetAllFilters}
                    >
                        RESET FILTERS
                    </div>
                </div>
                <hr />
                {this.props.technologyAreas &&
                    this.props.technologyAreas.map((area, index) => (

                        <div key={index} className="entityview__tech-content">
                            <div className="entityview__tech-area">
                                <div className="entityview__tech-area-filter">
                                    <label
                                        className="entityview__tech-area-filter-label"
                                        style={{
                                            fontWeight: `${
                                                area.name &&
                                                area.name.toLowerCase() ===
                                                    this.props.searchQuery.toLowerCase()
                                                    ? "800"
                                                    : ""
                                            }`,
                                        }}
                                        title={
                                            area.name &&
                                            area.name.toLowerCase() ===
                                                this.props.searchQuery.toLowerCase()
                                                ? "You searched for this term."
                                                : ""
                                        }
                                    >
                                        <input
                                            className="entityview__tech-input"
                                            type="checkbox"
                                            name={area.name}
                                            data-filtertype="techArea"
                                            checked={this.props.techArea.includes(
                                                area.name
                                            )}
                                            onChange={
                                                this.props
                                                    .handleFilterCheckboxInputChange
                                            }
                                            disabled={this.props.disableFilters}
                                        />
                                        <span className="customCheckbox" />
                                        <p>
                                            &nbsp;
                                            {area.name}
                                        </p>
                                    </label>
                                </div>
                                <div className="filter-hover-techarea">
                                    <div
                                        className="techarea-detail"
                                        onClick={() =>
                                            this.goToTechAreaPage(area.name)
                                        }
                                    >
                                        View in detail
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    ))}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    searchQuery: state.search.query,
});

export default withRouter(connect(mapStateToProps)(Technology));
