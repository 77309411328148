import React from "react";
import { withScriptjs, withGoogleMap } from "react-google-maps";
import MapCanvas from "./MapCanvas";
import Loader from "../ui/loader/Loader";

const RenderMap = withScriptjs(withGoogleMap(MapCanvas));

const MapRenderer = props => (
    <Loader loading={props.loading} isMap={true}>
        <RenderMap
        filter = {props.locationFilter}
            techarea = {props.techarea}
            capability = {props.capability}
           zoom={props.zoom}
            locations={props.locations}
            hoveredEntity={props.hoveredEntity}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?&region=IN&key=${
                process.env.REACT_APP_GOOGLE_MAP_API_KEY
            }&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
                <div style={{ height: props.height, width: `100%` }} />
            }
            mapElement={<div style={{ height: `100%` }} />}
            mapRegionCount={props.mapRegionCount}
        />
    </Loader>
);

export default MapRenderer;
