import React from "react";

const TopBar = props => {
    //console.log(props.resultType)
    return (
        <div className="search__types-wrapper">
            <div className="search__types-section search__result-type">
                <div
                    className={`search__result-type-tab ${props.resultType ===
                        "companies" && "search__result-type-tab-selected"}`}
                    onClick={() => props.setResultType("companies")}
                >
                    Companies
                </div>
                <div
                    className={`search__result-type-tab ${props.resultType ===
                        "academia" && "search__result-type-tab-selected"}`}
                    onClick={() => props.setResultType("academia")}
                >
                    Academia
                </div>
                <div
                    className={`search__result-type-tab ${props.resultType ===
                        "r_and_d" && "search__result-type-tab-selected"}`}
                    onClick={() => props.setResultType("r_and_d")}
                >
                    R&D Centres
                </div>
                <div
                    className={`search__result-type-tab ${props.resultType ===
                        "individual" && "search__result-type-tab-selected"}`}
                    onClick={() => props.setResultType("individual")}
                >
                    Researchers
                </div>
                 
                <div
                    className={`search__result-type-tab ${props.resultType ===
                        "products" && "search__result-type-tab-selected"}`}
                    onClick={() => props.setResultType("products")}
                >
                    Products
                </div>
                <div
                    className={`search__result-type-tab ${props.resultType ===
                        "tech_area" && "search__result-type-tab-selected"}`}
                    onClick={() => props.setResultType("tech_area")}
                >
                    Technologies
                </div>
            </div>

            {/* <div className="search__types-section search__view-type">
                <div
                    className={`search__view-type-tab ${props.viewType ===
                        "list" && "search__view-type-tab-selected"}`}
                    onClick={() => props.setViewType("list")}
                >
                    List
                </div>
                <div
                    className={`search__view-type-tab ${props.viewType ===
                        "map" && "search__view-type-tab-selected"}`}
                    onClick={() => props.setViewType("map")}
                >
                    Map
                </div>
            </div> */}
        </div>
    );
};

export default TopBar;
