import React from "react";
import ReactEcharts from "echarts-for-react";

const PieChart = props => {
  // console.log('piechart props ', props);
    // const isMobileView = window.screen.width < 1300;

    const option = {
        tooltip: {
            trigger: "item",
            formatter: "{b} : {c} ({d}%)",
        },
        legend: {
            type: "plain",
            // itemWidth: 20,
            // x: isMobileView ? 'left': 'right',
            // right: isMobileView ? null : 0,
            // left: isMobileView ? 10 : null,
            // y: 'bottom',
            orient: "vertical",
            x: "center",
            y: "50%",
            // y: "-40",
            // y: isMobileView ? "bottom" : "center",
            data: props.data,
            selected: props.data[0],
            formatter: function(name) {
              const currElem = props.data.find(item => item.name === name);
              return `${name} (${currElem.value})`
            }
        },
        series: [
            {
                type: "pie",
                radius: ["80px", "120px"],
                // center: isMobileView ? ["55%", "30%"] : ["30%", "50%"],
                center: ["50%", "25%"],
                data: props.data,
                itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                },
                label: {
                    normal: {
                        show: false,
                        // show: isMobileView ? false : true,
                    },
                },
            },
        ],
    };

    return (
        <ReactEcharts
            style={{
                minHeight: "70rem",
            }}
            option={option}
            notMerge={true}
            lazyUpdate={true}
            // theme={"theme_name"}
            // onChartReady={this.onChartReadyCallback}
            // onEvents={EventsDict}
            // opts={}
        />
    );
};

export default PieChart;
