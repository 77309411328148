// Icons for the features 
import ConsolidatedIcon from "./img/FeatureIcons/consolidated@3x.png";
import OrganizedIcon from "./img/FeatureIcons/organized@3x.png";
import DetailedIcon from "./img/FeatureIcons/detailed@3x.png";
import ExploreIcon from "./img/FeatureIcons/explore@3x.png";
import ConnectIcon from "./img/FeatureIcons/connect@3x.png";
import BenchmarkIcon from "./img/FeatureIcons/benchmark@3x.png";



// This object contains all the necessary data/configuration for the
// category dropdown in the search bar that appears when web-app is
// being run on a small screen width (mobile)
export const searchDropdownData = {
  options: [
    { label: "Companies", value: "companies" },
    { label: "Academia", value: "academia" },
    { label: "Researchers", value: "individual" },
    { label: "R&D Centers", value: "r_and_d" },
    { label: "Technologies", value: "tech_area" },
    { label: "Products", value: "products" },
  ],
  config: {
    isClearable: false,
    isDisabled: false,
    isSearchable: false,
    defaultValue: { label: "Companies", value: "companies" },
  },
  styles: {
    menuList: provided => ({
      backgroundColor: "#494b4f",
      borderRadius: "4px",
    }),
    option: provided => ({
      ...provided,
      color: "#494b4f",
      fontWeight: "400",
      backgroundColor: "white",
      "&:hover": {
        cursor: "pointer",
        color: "white",
        backgroundColor: "#494b4f",
      },
    }),
    control: provided => ({
      ...provided,
      backgroundColor: "white",
      outline: "none",
      border: "none",
      "&:focus": {
        outline: "none",
      },
    }),
    indicatorSeparator: provided => ({
      display: "none",
    }),
  }
};


// iconsDict forms the S3 URLs from where the images for the tech areas are fetched
const imageBaseURL = 'https://tcr-phase2-documents.s3.ap-south-1.amazonaws.com/images';
const iconsDict = {
  IOTIcon: imageBaseURL + '/iot.svg',
  BlockchainIcon: imageBaseURL + '/blockchain.svg',
  CryptologyIcon: imageBaseURL + '/cryptology.svg',
  CompVisionIcon: imageBaseURL + '/computer-vision-image-or-video-processing.svg',
  DataScienceIcon: imageBaseURL + '/data-science.svg',
  ARVRIcon: imageBaseURL + '/augmented-virtual-reality.svg',
  WirelessIcon: imageBaseURL + '/wireless-networking-technologies.svg',
  OSIcon: imageBaseURL + '/operating-systems.svg',
  EnterpriseBusinessIcon: imageBaseURL + "/enterprise-business-technology.svg",
  LocationPresenceIcon: imageBaseURL + "/location-and-presence.svg",
  MaterialScienceIcon: imageBaseURL + "/material-science-for-it.svg",
  AIMLIcon: imageBaseURL + "/al-ml-dl.svg",
  CloudIcon: imageBaseURL + "/cloud-and-virtualization.svg",
  NextGenComputingIcon: imageBaseURL + "/next-generation-computing.svg",
  DigitalForensicsIcon: imageBaseURL + "/digital-forensics.svg",
  RoboticsAutomationIcon: imageBaseURL + "/robotics-and-automation.svg",
  HardwaresIcon: imageBaseURL + "/hardware-and-semiconductors-embedded-syst.svg",
  IdentityManagementIcon: imageBaseURL + "/identity-management-and-authentication.svg",
  HumanComputerInteractionIcon: imageBaseURL + "/human-computer-interaction.svg",
  NLPIcon: imageBaseURL + "/natural-language-processing-nlp.svg",
  SoftwareDefinedNetworksIcon: imageBaseURL + "/software-defined-networks-and-infrastructrures.svg",
  MobilityAndEndPointsIcon: imageBaseURL + "/mobility-and-end-points.svg",
  AppInfrastructureIcon: imageBaseURL + "/application-infrastructure-and-software.svg",
  UAVIcon: imageBaseURL + '/uav.svg',
  CyberSecurityAreaIcon: imageBaseURL + '/cybersecurity.svg',
  QuantumComputing : imageBaseURL + '/quantum-computing.png',
  fiveGtoSixG : imageBaseURL + '/5G+%26+6G+technology.png'
};


// This is the object of all areas with their names and image URL
export const allAreas = [
  {
    name: "Internet of Things",
    image: iconsDict.IOTIcon,
  },
  {
    name: "Blockchain",
    image: iconsDict.BlockchainIcon,
  },
  {
    name: "AI and ML",
    image: iconsDict.AIMLIcon,
  },
  {
    name: "Cloud Computing and Virtualization",
    image: iconsDict.CloudIcon,
  },
  {
    name: "Wireless and Networking Technologies",
    image: iconsDict.WirelessIcon,
  },
  {
    name: "Hardware, Semiconductors and Embedded",
    image: iconsDict.HardwaresIcon,
  },
  {
    name: "Cybersecurity",
    image: iconsDict.CyberSecurityAreaIcon,
  },
  {
    name: "Robotics and Automation",
    image: iconsDict.RoboticsAutomationIcon,
  },
  {
    name: "Natural Language Processing",
    image: iconsDict.NLPIcon,
  },
  {
    name: "Computer Vision",
    image: iconsDict.CompVisionIcon,
  },
  {
    name: "Augmented and Virtual Reality",
    image: iconsDict.ARVRIcon,
  },
  {
    name: "Data Science",
    image: iconsDict.DataScienceIcon,
  },
  {
    name: "Material Science",
    image: iconsDict.MaterialScienceIcon,
  },
  {
    name: "Next Generation Computing",
    image: iconsDict.NextGenComputingIcon,
  },
  {
    name: "Cryptology",
    image: iconsDict.CryptologyIcon,
  },
  {
    name: "Digital Forensics",
    image: iconsDict.DigitalForensicsIcon,
  },
  {
    name: "Identity Management and Authentication",
    image: iconsDict.IdentityManagementIcon,
  },
  {
    name: "Enterprise Business Technologies",
    image: iconsDict.EnterpriseBusinessIcon,
  },
  {
    name: "Operating Systems",
    image: iconsDict.OSIcon,
  },
  {
    name: "Application Infrastructure and Software",
    image: iconsDict.AppInfrastructureIcon,
  },
  {
    name: "Software Defined Infrastructure",
    image: iconsDict.SoftwareDefinedNetworksIcon,
  },
  {
    name: "Unmanned Aerial Vehicles",
    image: iconsDict.UAVIcon,
  },
  {
    name: "Human Computer Interaction",
    image: iconsDict.HumanComputerInteractionIcon,
  },
  {
    name: "Mobility and End Points",
    image: iconsDict.MobilityAndEndPointsIcon,
  },
  {
    name: "Location and Presence",
    image: iconsDict.LocationPresenceIcon,
  },
  {
    name : "Quantum Technology",
    image : iconsDict.QuantumComputing
  },
  {
    name : "5G and 6G",
    image : iconsDict.fiveGtoSixG
  }
];

export const features = [
  {
    title: "Consolidated",
    image: ConsolidatedIcon,
    desc:
      "Comprehensive compilation of country’s cyber capabilities",
  },
  {
    title: "Organized",
    image: OrganizedIcon,
    desc:
      "Easy & intuitive retrieval of meaningful & actionable insights",
  },
  {
    title: "Detailed",
    image: DetailedIcon,
    desc: "Nuanced view of capabilities, R&D, products, services & actors involved",
  },
  {
    title: "Explore",
    image: ExploreIcon,
    desc: "Discover new horizons & find new possibilities"
  },
  {
    title: "Connect",
    image: ConnectIcon,
    desc: "Extend your reach & carve out success stories",
  },
  {
    title: "Benchmark",
    image: BenchmarkIcon,
    desc: "Know where you stand & where you can reach",
  },
];
