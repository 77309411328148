import React, { Component } from "react";

import FormPart2 from "./academia-components/FormPart2";
import FormPart3 from "./academia-components/FormPart3";
import FormPart4 from "./academia-components/FormPart4";
import FormPart5 from "./academia-components/FormPart5";
import FormPart6 from "./academia-components/FormPart6";
import FormPart7 from "./academia-components/FormPart7";
import FormPart8 from "./academia-components/FormPart8";
import FormPart9 from "./academia-components/FormPart9";
import FormPart10 from "./academia-components/FormPart10";

import api, { getGETConfig } from '../../api';

class AcademiaWrapper extends Component {
   state = {
      technologyAreas: [],
      cities: [],
   };

   componentDidMount() {
      this.getTechnologyAreas();
      this.getCitiesList();
   }

   // Fetch technology areas list
   getTechnologyAreas = () => {
      fetch(api.tech_areas, getGETConfig())
         .then(response => {
            if (!response || !response.ok) throw Error(response.status);
            return response.json();
         })
         .then(({ data }) => this.setState({ technologyAreas: data }))
         .catch(err => console.log('Error fetching technology areas', err));
   };

   getCitiesList = () => {
      const url = `${api.list_of_cities}?country=India`;

      fetch(url, getGETConfig())
         .then(response => {
            if (!response || !response.ok) throw Error(response.status);
            return response.json();
         })
         .then(({ data }) => this.setState({ cities: data }))
         .catch(err => console.log('Error fetching cities', err));
   };

   getFormPart() {
      const { technologyAreas, cities } = this.state;
      const { submitForm, formPart, nextPage, prevPage, entityType } = this.props;

      if (formPart === 2) {
         return <FormPart2
            onSubmit={nextPage}
            prevPage={prevPage}
            entityType={entityType}
         />;
      }
      else if (formPart === 3) {
         return <FormPart3
            onSubmit={nextPage}
            prevPage={prevPage}
            technologyAreas={technologyAreas}
         />;
      }
      else if (formPart === 4) {
         return <FormPart4
            onSubmit={nextPage}
            prevPage={prevPage}
            cities={cities}
         />;
      }
      else if (formPart === 5) {
         return <FormPart5
            onSubmit={nextPage}
            prevPage={prevPage}
         />;
      }
      else if (formPart === 6) {
         return <FormPart6
            onSubmit={nextPage}
            prevPage={prevPage}
            technologyAreas={technologyAreas}
         />;
      }
      else if (formPart === 7) {
         return <FormPart7
            onSubmit={nextPage}
            prevPage={prevPage}
            technologyAreas={technologyAreas}
         />;
      }
      /**
       * if formPart is 8, then for academia we don't have to show 'Research Papers' form
       * In that case, render FormPart9 component, which is 'Patents & Blogs' instead
       * ------------------------------------------------------------------------------------
       * As a result, for academia, the formPart9 === 9 will correspond to FormPart10 compoennts
       */
      else if (formPart === 8) {
         if (entityType === "Academia") {
            return <FormPart9
               onSubmit={nextPage}
               prevPage={prevPage}
               technologyAreas={technologyAreas}
            />;
         } else {
            return <FormPart8
               onSubmit={nextPage}
               prevPage={prevPage}
               technologyAreas={technologyAreas}
            />;
         }
      }
      else if (formPart === 9) {
         if (entityType === 'Academia') {
            return <FormPart10
            prevPage={prevPage}
            onSubmit={submitForm} 
         />;
         } else {
            return <FormPart9
               onSubmit={nextPage}
               prevPage={prevPage}
               technologyAreas={technologyAreas}
            />;
         }
      }
      // As a result, formPart will only get to 10 for RnD, not academia
      // Still doing the little extra check, just in case because code sometimes messes up
      else if (formPart === 10 && entityType !== 'Academia') {
         return <FormPart10
            prevPage={prevPage}
            onSubmit={submitForm} 
         />;
      }
   }



   render() {
      return (
         <div className="feature_form__wrapper">
            <div className="feature_form__subhead">
               STEP {this.props.formPart} OF {this.props.totalParts}
            </div>
            {this.getFormPart()}
         </div>
      );
   }
}

export default AcademiaWrapper;
